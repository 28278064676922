import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import buildFinalImg from "../../assets/New-images/build-final-img.svg";
import botImg from "../../assets/New-images/chatbotImg1.svg";
import learningPathImg from "../../assets/New-images/learning-path.svg";
import SetupImg from "../../assets/New-images/online-test.svg";
import qAndNa from "../../assets/New-images/question-and-answer.svg";
import {
  CHAT_CUSTOMISATION,
  CONVERSATION_FLOW,
  QUIZZES_BETA,
  SUPPORT,
  TOOLKIT_GENERATE_QNA_UPLOAD_DOC,
} from "../../config";
import createQuizImg from "../../assets/New-images/create-quiz.svg";
import inviteLearnersImg from "../../assets/New-images/toolkit-invite-learners-img.svg";

const BuildFinal = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const useLocatio = useLocation();
  const windowObj: any = window;
  const query = new URLSearchParams(useLocatio.search);
  const groupId = query.get("group");
  const type = query.get("type");

  return (
    <>
      <section className="page-mid-wraper">
        <Container>
          <Row className="flex-md-row-reverse pt-lg-5 pt-3">
            <Col md={9}>
              <div className="text-center mb-32">
                <h1 className="clr-primary">
                  {t("Congratulations! Your Knowledge Base Just Got Bigger!")}
                </h1>
                {/* <div className="font-16 font-600">
                                    {t("Your document(s) have been successfully added to your knowledge base")}
                                </div> */}
              </div>
              <h2 className="font-600 font-1 text-center mb-3">
                {t(
                  "Recommended next steps to make the most of your expanded knowledge base."
                )}
              </h2>
              {/* <Row className="build-summary-docs justify-content-center">
                                <Col onClick={() => {
                                    history.push(QUIZZES_BETA)
                                }} sm={6} lg={5} className="mb-3">
                                    <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center">
                                        <figure className="h-auto mb-0">
                                            <img src={qAndNa} alt="" />
                                        </figure>
                                        <div className="text-left pl-3">
                                            <h3 className="font-weight-bold font-1 clr-primary mb-1">
                                                {t(
                                                    "Build Quiz"
                                                )}
                                            </h3>
                                            <p className="clr-grey-txt7 font-sm font-600">
                                                {t(
                                                    "Select questions from your knowledge base or question bank to create a new quiz"
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col onClick={() => {
                                    history.push(CONVERSATION_FLOW)
                                }} sm={6} lg={5} className="mb-3">
                                    <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center">
                                        <figure className="h-auto mb-0">
                                            <img src={learningPathImg} alt="" />
                                        </figure>
                                        <div className="text-left pl-3">
                                            <h3 className="font-weight-bold font-1 clr-primary mb-1">
                                                {t(
                                                    "Create Learning Pathway"
                                                )}
                                            </h3>
                                            <p className="clr-grey-txt7 font-sm font-600">
                                                {t(
                                                    "Craft customized learning pathways for learners"
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col onClick={() => {
                                    history.push(CHAT_CUSTOMISATION)
                                }} sm={6} lg={5} className="mb-3">
                                    <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center">
                                        <figure className="h-auto mb-0">
                                            <img src={botImg} alt="" />
                                        </figure>
                                        <div className="text-left pl-3">
                                            <h3 className="font-weight-bold font-1 clr-primary mb-1">
                                                {t(
                                                    "Customise Your Bot"
                                                )}
                                            </h3>
                                            <p className="clr-grey-txt7 font-sm font-600">
                                                {t(
                                                    "Tailor your bot's appearance to match your brand identity"
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col onClick={() => {
                                    history.push(SUPPORT + '?force=https://3359170.hs-sites.com/en/knowledge/test-your-setup')
                                }} sm={6} lg={5} className="mb-3">
                                    <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center">
                                        <figure className="h-auto mb-0">
                                            <img src={SetupImg} alt="" />
                                        </figure>
                                        <div className="text-left pl-3">
                                            <h3 className="font-weight-bold font-1 clr-primary mb-1">
                                                {t(
                                                    "Test Your Setup"
                                                )}
                                            </h3>
                                            <p className="clr-grey-txt7 font-sm font-600">
                                                {t(
                                                    "Learn how to view and test your setup through our video guides"
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}
              <Row className="build-summary-docs justify-content-center">
                <Col
                  onClick={() => {
                    const link = (window.location.href =
                      windowObj.NEW_PORTAL_URI.includes("staging")
                        ? "https://chatbot.staging.noodlefactory.ai/"
                        : "https://chatbot.noodlefactory.ai/");
                    window.open(link, "_blank");
                  }}
                  sm={6}
                  lg={5}
                  className="mb-3"
                >
                  <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center">
                    <figure className="h-auto mb-0">
                      <img src={SetupImg} alt="" />
                    </figure>
                    <div className="text-left pl-3">
                      <h3 className="font-weight-bold font-1 clr-primary mb-1">
                        {t("Test Your Setup")}
                      </h3>
                      <p className="clr-grey-txt7 font-sm font-600">
                        {t(
                          "Experience your setup in action using our chatbot interface"
                        )}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col
                  onClick={() => {
                    history.push(
                      `/toolkit/home?group=${groupId}&reference=create-quiz`
                    );
                  }}
                  sm={6}
                  lg={5}
                  className="mb-3"
                >
                  <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center">
                    <figure className="h-auto mb-0">
                      <img src={createQuizImg} alt="" />
                    </figure>
                    <div className="text-left pl-3">
                      <h3 className="font-weight-bold font-1 clr-primary mb-1">
                        {t("Smart Quiz")}
                      </h3>
                      <p className="clr-grey-txt7 font-sm font-600">
                        {t("Create a quiz with the document(s) uploaded")}
                      </p>
                    </div>
                  </div>
                </Col>

                {type === "automated" && (
                  <Col
                    onClick={() => {
                      history.push(
                        TOOLKIT_GENERATE_QNA_UPLOAD_DOC + "?reference=final"
                      );
                    }}
                    sm={6}
                    lg={5}
                    className="mb-3"
                  >
                    <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center">
                      <figure className="h-auto mb-0">
                        <img src={qAndNa} alt="" />
                      </figure>
                      <div className="text-left pl-3">
                        <h3 className="font-weight-bold font-1 clr-primary mb-1">
                          {t("Review Q&A pairs")}
                        </h3>
                        <p className="clr-grey-txt7 font-sm font-600">
                          {t(
                            "Review the generated question and answer pairs generated from your document(s)"
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                )}
                {type === "guided" && (
                  <Col
                    onClick={() => {
                      history.push(CHAT_CUSTOMISATION);
                    }}
                    sm={6}
                    lg={5}
                    className="mb-3"
                  >
                    <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center">
                      <figure className="h-auto mb-0">
                        <img src={botImg} alt="" />
                      </figure>
                      <div className="text-left pl-3">
                        <h3 className="font-weight-bold font-1 clr-primary mb-1">
                          {t("Customise your bot")}
                        </h3>
                        <p className="clr-grey-txt7 font-sm font-600">
                          {t(
                            "Tailor your bot's appearance to match your brand identity"
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                )}
                <Col
                  //   onClick={() => {
                  //     history.push(CHAT_CUSTOMISATION);
                  //   }}
                  sm={6}
                  lg={5}
                  className="mb-3"
                >
                  <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center">
                    <figure className="h-auto mb-0">
                      <img src={inviteLearnersImg} alt="" />
                    </figure>
                    <div className="text-left pl-3">
                      <h3 className="font-weight-bold font-1 clr-primary mb-1">
                        {t("Invite learners")}
                      </h3>
                      <p className="clr-grey-txt7 font-sm font-600">
                        {t(
                          "Start adding learners to use your newly created chatbot"
                        )}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={3} className="text-center mt-3 mt-md-0">
              <figure>
                <img src={buildFinalImg} alt="" />
              </figure>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BuildFinal;
