import React, { useEffect } from "react";
import {
  Container,
  Image,
  OverlayTrigger,
  Popover,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Tags from "../../../components/UI/Tags/Tags";
import SuggestTagsModal from "../SuggestTagsModal/SuggestTagsModal";
import backblue from "../../../assets/images/arrow-left.svg";
import alertImg from "../../../assets/images/alert.svg";
import closeimg from "../../../assets/images/close-blk.svg";
import errorLoading from "../../../assets/images/errorLoading.svg";
import success from "../../../assets/images/success-check.svg";

import help from "../../../assets/images/help-info.svg";
import {
  generateDocumentsTags,
  suggestDocumentsTags,
  addTagsToDocument,
  getSectionsBasedOnDocumentId,
} from "../../../store/knowledge/actions";
import AddTags from "../../../components/UI/AddTags/AddTags";
import Loading from "../../../components/UI/Loading/Loading";
import { HelperService } from "../../../services/helper.service";
import {
  KNOWLEDGE_GENERATE_QUESTIONS_ANSWERS,
  UPLOADEDDOCUMENTS,
  KNOWLEDGE_ALL,
  GENERATE_ANA_SUMMARISE_DOCUMENT,
} from "../../../config";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import PermissionWrapper from "../../../components/UI/PermissionWrapper/PermissionWrapper";
import classNames from "classnames";
import * as _ from "lodash";

import { useTranslation } from "react-i18next";

interface Props { }

export const GenerateTags = (props: any) => {
  const { t } = useTranslation("translation");
  const isMgs = process.env.REACT_APP_CISCO === "mgs";
  const history: any = useHistory();
  const dispatch = useDispatch();
  const helperService = new HelperService();
  const [showSuggestModal, setShowSuggestModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [selectedDocs, setSelectedDocs] = React.useState([]);
  const [generatedTags, setGeneratedTags] = React.useState([]);
  const [selectedDocumentId, setSelectedDocumentId] = React.useState<any>(null);
  const [selectedDocumentSection, setSelectedDocumentSectionId] =
    React.useState<any>(null);
  const [showTagsModal, setShowTagsModal] = React.useState(false);
  const [allTags, setAllTags] = React.useState([]);

  const tabsChangeHandler = (e: string) => {
    setActiveTab(e);
  };
  const [activeTab, setActiveTab] = React.useState(null);

  const suggestModalHandler = async () => {
    try {
      setLoading(true);
      setError(false);
      const query = new URLSearchParams(history.location.search);
      const params = query.get("question").split(",");
      const documents =
        params && params.length > 0
          ? params.map((param: any) => {
            return {
              _id: param,
            };
          })
          : props.documents;
      const documentIds =
        params && params.length > 0 ? params : documents.map((d: any) => d._id);

      let generatedTag: any = [];
      generatedTag = await dispatch(generateDocumentsTags(documentIds));
      const sectionResponse: any = await dispatch(
        getSectionsBasedOnDocumentId(documentIds)
      );

      generatedTag = generatedTag.map((tag: any) => {
        const doc = generatedTags?.find((d: any) => d._id === tag._id);

        return {
          ...doc,
          sections: tag.sections.map((section: any) => {
            const particularSection: any = doc.sections.find(
              (d: any) => d._id === section._id
            );
            return {
              ...section,
              tags: section.tags
                .map((t: any) => {
                  return {
                    value: t,
                    label: t,
                  };
                })
                .concat(particularSection.tags ? particularSection.tags : []),
            };
          }),
        };
      });

      let tags = generatedTag.map((tag: any) => {
        const document = documents.find((d: any) => d._id === tag._id);
        return {
          ...document,
          sections: tag.sections.map((section: any) => {
            const particularSection: any = sectionResponse.find(
              (d: any) => d._id === section._id
            );
            return {
              ...section,
              tags: _.uniqBy(
                section.tags
                  .concat(
                    document.tags
                      ? document.tags.map((t: any) => {
                        return {
                          value: t,
                          label: t,
                        };
                      })
                      : []
                  )
                  .concat(
                    particularSection.tags
                      ? particularSection.tags.map((t: any) => {
                        return {
                          value: t,
                          label: t,
                        };
                      })
                      : []
                  ),
                "label"
              ),
            };
          }),
        };
      });

      setGeneratedTags([...tags]);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(true);
      setGeneratedTags([]);
    }

    // setShowSuggestModal(!showSuggestModal);
  };

  useEffect(() => {
    const fun = async () => {
      try {
        setLoading(true);
        setError(false);
        const query = new URLSearchParams(history.location.search);
        const params = query.get("question").split(",");
        const documents =
          params && params.length > 0
            ? params.map((param: any) => {
              return {
                _id: param,
              };
            })
            : props.documents;
        const documentIds =
          params && params.length > 0
            ? params
            : documents.map((d: any) => d._id);
        let generatedTags: any = [];
        let generatedTagDoc: any = [];
        // console.log("props.hideHeaders", props.hideHeaders);
        if (!props.hideHeaders) {
          generatedTags = await dispatch(generateDocumentsTags(documentIds));
        }

        const sectionResponse: any = await dispatch(
          getSectionsBasedOnDocumentId(documentIds)
        );
        // console.log("[ sectionResponse ]", sectionResponse);
        if (
          sectionResponse &&
          sectionResponse.length > 0 &&
          props.hideHeaders
        ) {
          try {
            const sections: any = [];
            const groupedSections = _.groupBy(sectionResponse, "_id");
            Object.keys(groupedSections).map((d: any) => {
              sections.push(groupedSections[d][0]);
            });
            const sampleResponse: any = [
              {
                _id:
                  !props.hideHeaders && generatedTagDoc.length > 0
                    ? generatedTagDoc[0]._id
                    : props.document._id,
                name:
                  !props.hideHeaders && generatedTagDoc.length > 0
                    ? generatedTagDoc[0].name
                    : props.document.name,
                sections,
              },
            ];
            generatedTags = [...sampleResponse];
          } catch (error) {
            generatedTags = [
              {
                _id: props.document._id,
                name: props.document.name,
                sections: [],
              },
            ];
          }
        }
        await setSelectedDocs(documentIds);
        let tags = generatedTags.map((tag: any) => {
          const document = documents.find((d: any) => d._id === tag._id);
          // console.log("tag", tag);

          return {
            ...document,
            sections: tag.sections.map((section: any) => {
              const particularSection: any = sectionResponse.find(
                (d: any) => d._id === section._id
              );
              return {
                ...section,
                tags: _.uniqBy(
                  section.tags
                    .map((t: any) => {
                      return {
                        value: t,
                        label: t,
                      };
                    })
                    .concat(
                      document.tags
                        ? document.tags.map((t: any) => {
                          return {
                            value: t,
                            label: t,
                          };
                        })
                        : []
                    )
                    .concat(
                      particularSection.tags
                        ? particularSection.tags.map((t: any) => {
                          return {
                            value: t,
                            label: t,
                          };
                        })
                        : []
                    ),
                  "label"
                ),
              };
            }),
          };
        });
        // console.log('tags', tags)
        setActiveTab(tags.length > 0 ? tags[0]._id : "");
        setGeneratedTags([...tags]);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setError(true);
        setGeneratedTags([]);
      }
    };
    fun();
    // props?.location?.state
  }, []);

  const showAddTagHandler = (e: any, document?: any, sectionId?: number) => {
    if (document) {
      setSelectedDocumentId(document);
      setSelectedDocumentSectionId(sectionId);
      setAllTags([...document.sections[sectionId].tags]);
      setShowTagsModal(!showTagsModal);
    } else {
      setShowTagsModal(!showTagsModal);
    }
  };

  const confirmedTagsHandler = async (e: any, tags: any[]) => {
    e.preventDefault();
    setShowTagsModal(false);
    setAllTags(tags);
    const oldGeneratedTags = [...generatedTags];
    const document = generatedTags.find(
      (document) => document._id === selectedDocumentId._id
    );
    document.sections[selectedDocumentSection].tags = [...tags];
    const updatedSection = {
      ...document.sections[selectedDocumentSection],
      tags: document.sections[selectedDocumentSection].tags.map(
        (tags: any) => tags.value
      ),
    };
    try {
      await dispatch(
        addTagsToDocument(selectedDocumentId._id, [updatedSection])
      );
      setGeneratedTags([...oldGeneratedTags]);
    } catch (e: any) { }
  };

  const removeTagsHandler = async (
    e: any,
    value: any,
    selectedDocumentId: any,
    sectionIndex: number
  ) => {
    const oldGeneratedTags = [...generatedTags];
    const document = oldGeneratedTags.find(
      (document) => document._id === selectedDocumentId._id
    );
    const tags = [...document.sections[sectionIndex].tags];
    const hasTagPresent = tags.findIndex(
      (tag) => tag.value.toLowerCase() === value.toLowerCase()
    );
    if (hasTagPresent >= 0) {
      tags.splice(hasTagPresent, 1);
      setAllTags(tags);
      const updatedTags = [...tags];
      document.sections[sectionIndex].tags = [...updatedTags];
      const updatedSection = {
        ...document.sections[selectedDocumentSection],
        tags: _.uniq(updatedTags.map((tags: any) => tags.value)),
      };
      await dispatch(
        addTagsToDocument(selectedDocumentId._id, [updatedSection])
      );
    }
  };

  const suggestTagsHandler = async (tags: string[]) => {
    try {
      setLoading(true);
      const suggestDocuments: any = await dispatch(
        suggestDocumentsTags(
          generatedTags.map((d) => d._id),
          tags
        )
      );
      let updatedDocuments = suggestDocuments.map((tag: any) => {
        const document = generatedTags.find((d: any) => d._id === tag._id);
        tag.sections.forEach((section: any) => {
          const fetchedDocumentSection = document.sections.find(
            (documentSection: any) => documentSection._id === section._id
          );
          if (fetchedDocumentSection) {
            fetchedDocumentSection.tags = section.tags
              .map((t: any) => {
                return {
                  value: t,
                  label: t,
                };
              })
              .concat(
                fetchedDocumentSection.tags ? fetchedDocumentSection.tags : []
              );
          }
        });
        return {
          ...document,
        };
      });

      // console.log('[ updatedDocuments ]', updatedDocuments);
      // return {
      //   ...document,
      //   sections: tag.sections.map((section: any) => {
      //     return {
      //       ...section,
      //       tags: section.tags
      //         .map((t: any) => {
      //           return {
      //             value: t,
      //             label: t,
      //           };
      //         })
      //         .concat(
      //           document.tags
      //             ? document.tags.map((t: any) => {
      //                 return {
      //                   value: t,
      //                   label: t,
      //                 };
      //               })
      //             : []
      //         ),
      //     };
      //   }),
      // };
      // const updatedDocuments = suggestDocuments.map((tag: any) => {
      //   const document = generatedTags.find((d: any) => d._id === tag._id);
      //   const oldTags = document.tags ? document.tags : [];
      //   return {
      //     ...document,
      //     tags: tag.tags
      //       .map((t: any) => {
      //         return {
      //           value: t[0],
      //           label: t[0],
      //         };
      //       })
      //       .concat(oldTags),
      //   };
      // });
      setGeneratedTags([...updatedDocuments]);
      setLoading(false);
    } catch (error: any) { }
  };

  const saveTagsHandler = () => {
    setLoading(true);
    try {
      generatedTags.forEach(async (generateTag: any) => {
        const sections: any[] = [];
        generateTag.sections.forEach(async (section: any) => {
          const updatedSection = {
            ...section,
            tags: _.uniq(section.tags.map((tags: any) => tags.value)),
          };
          sections.push(updatedSection);
        });
        await dispatch(addTagsToDocument(generateTag._id, sections));
      });
      setLoading(false);
      helperService.showToaster(
        t("Tags generated successfully."),
        t("success")
      );
      if (props.hideHeaders) {
        if (
          props.summarisedView &&
          history.location.pathname.includes("document-keywords-summary")
        ) {
          const query = new URLSearchParams(history.location.search);
          const params = query.get("question").split(",");
          const references = query.get("references");
          history.push(
            GENERATE_ANA_SUMMARISE_DOCUMENT.replace(":questionId", params[0]) +
            "?question=" +
            params[0] +
            (query.get("through")
              ? `&through=document&references=${references}`
              : `&references=${references}`)
          );
        }
        return;
      } else {
        if (isMgs) {
          history.push(KNOWLEDGE_ALL);
        } else {
          const query = new URLSearchParams(history.location.search);
          const params = query.get("question").split(",");
          history.push(
            `${KNOWLEDGE_GENERATE_QUESTIONS_ANSWERS}?question=${params}`
          );
        }
      }
    } catch (error) {
      helperService.showToaster(
        t("Something went wrong while generating tags."),
        t("error")
      );
    }
  };

  return (
    <>
      <section
        className={classNames({
          "page-mid-wraper pb-0": !props.summarisedView,
          "p-0": props.hideHeaders,
        })}
      >
        <SuggestTagsModal
          show={showSuggestModal}
          onHide={suggestModalHandler}
          confirmSuggestTags={suggestTagsHandler}
        />

        <AddTags
          onHide={showAddTagHandler}
          show={showTagsModal}
          confirmhandler={confirmedTagsHandler}
          selectedTags={allTags}
        />

        <Container>
          <div className="row">
            <div className="col-md-12">
              {/* {!props.hideHeaders && (
                <>
                  <button
                    className="btn p-0 btn-back"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <Image src={backblue} /> {t("Return to previous page")}
                  </button>
                </>
              )} */}
              <div className="mb-3 text-center">
                <h1 className="mb-0 clr-primary">
                  {props.summarisedView
                    ? t("Keywords Generated from summarised document")
                    : t("Document Keywords")}
                </h1>
                <div>
                  {t(
                    "Add Keywords manually or click on generate tags to use the AI to generate tags for each of the sections!"
                  )}
                </div>
                {props.summarisedView && (
                  <h3 className="mb-0">
                    {t(
                      "These keywords would be shared among the knowledge generated from the document. You can choose to add or remove keywords from the list below."
                    )}
                  </h3>
                )}

                <div className="py-4 d-flex justify-content-between">
                  <h3>
                    {" "}
                    {history?.location?.state?.selectedDocuments?.[0]?.name}
                  </h3>
                  {!props.summarisedView && (
                    <PermissionWrapper>
                      {!isMgs && t("Did we miss some keywords?")}{" "}
                      <span
                        className="btn-link font-sm ml-1"
                        onClick={suggestModalHandler}
                      >
                        {isMgs
                          ? t("Generate Keywords")
                          : t("Suggest and Assign")}
                      </span>
                    </PermissionWrapper>
                  )}
                  {!isMgs && !props.summarisedView && (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="bottom"
                      overlay={(d) => (
                        <Popover id="popover-basic-d" {...d}>
                          <Popover.Content>
                            {t(
                              "Have more tags in mind but not sure how to assign? We got your back. Click “Suggest and Assign” and suggest us more tags. We will assign the tags to the relevant documents."
                            )}
                          </Popover.Content>
                        </Popover>
                      )}
                    >
                      <button className="btn py-0 pr-0">
                        <img src={help} alt={t("info")} />
                      </button>
                    </OverlayTrigger>
                  )}
                </div>
                {showWarning && (
                  <div className="py-2 d-flex justify-content-between tagg-info-msg">
                    <div>
                      <Image className="pl-2" src={alertImg} />
                      <span className="pl-2">
                        {t("Loading time varies depending on file size")}
                      </span>
                    </div>

                    <Image
                      className="pr-2 cursor-pointer "
                      src={closeimg}
                      onClick={() => setShowWarning(false)}
                    />
                  </div>
                )}
              </div>
              <div className="mb-3">
                {/* {error && (
                  <div className="add-ques-error mt-3 mb-3">
                    {t(
                      "Currently, we are unable to generate tags for this document. We have noted your request and you may proceed to generate questions for Knowledge."
                    )}
                  </div>
                )} */}

                {!loading &&
                  generatedTags.map((tags: any, index: number) => {
                    return (
                      // <Tab eventKey={tags?._id} title={tags?.name}>
                      <div className="mb-3">
                        {tags.sections.map((section: any, inde: number) => {
                          return (
                            <div
                              className="knowledge-tags d-flex flex-wrap align-items-center"
                              style={
                                props.summarisedView
                                  ? {
                                    overflow: "auto",
                                    maxHeight: "300px",
                                  }
                                  : {}
                              }
                            >
                              {!props.summarisedView && (
                                <h2 className="w-100">
                                  {inde + 1}. {section?.name}
                                </h2>
                              )}
                              {section.tags.map((value: any, index: number) => {
                                return (
                                  <>
                                    <Tags
                                      key={value + "_" + index}
                                      value={value.value}
                                      label={value.label}
                                      removeTagHandler={(e, value) =>
                                        removeTagsHandler(e, value, tags, inde)
                                      }
                                    />
                                  </>
                                );
                              })}
                              <PermissionWrapper>
                                <button
                                  className="btn btn-outline-primary"
                                  onClick={(e: any) => {
                                    showAddTagHandler(e, tags, inde);
                                  }}
                                >
                                  {t("+ Add keywords")}
                                </button>
                              </PermissionWrapper>
                            </div>
                          );
                        })}
                      </div>
                      // </Tab>
                    );
                  })}
                {/* <Tabs
                  defaultActiveKey={activeTab}
                  onSelect={tabsChangeHandler}
                  activeKey={activeTab}
                >
                </Tabs> */}
              </div>
              {loading && (
                <React.Fragment>
                  <SkeletonTheme
                    color="rgba(255, 0, 158, 0.05)"
                    highlightColor="rgba(255, 0, 158, 0.05)"
                  >
                    <h2 className="">
                      <Skeleton
                        duration={2}
                        count={1}
                        width={`100%`}
                        height={30}
                      />
                    </h2>
                    <Skeleton
                      duration={2}
                      count={1}
                      width={`30%`}
                      height={30}
                    />
                    <h2 className="">
                      <Skeleton
                        duration={2}
                        count={1}
                        width={`100%`}
                        height={30}
                      />
                    </h2>
                    <Skeleton
                      duration={2}
                      count={1}
                      width={`30%`}
                      height={30}
                    />
                    <h2 className="">
                      <Skeleton
                        duration={2}
                        count={1}
                        width={`100%`}
                        height={30}
                      />
                    </h2>
                    <Skeleton
                      duration={2}
                      count={1}
                      width={`30%`}
                      height={30}
                    />
                  </SkeletonTheme>
                </React.Fragment>
              )}
            </div>
          </div>
        </Container>
      </section>
      {!props.hideButtons && (
        <footer className="bg-white">
          <Container className="pl-4">
            <div className="d-flex align-items-center justify-content-between pl-lg-1">
              {error ? (
                <div className="d-flex align-items-center">
                  <Image className="pr-2" src={errorLoading} />
                  {t(
                    "Unable to generate Keywords, click on Generate Keywords to try again or manually add tags"
                  )}
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <Image className="pr-2" src={success} />
                  {t("Keywords have been generated!")}
                </div>
              )}
              <PermissionWrapper>
                <button className="btn btn-primary" onClick={saveTagsHandler}>
                  {!props.hideHeaders
                    ? isMgs
                      ? t("Save Document")
                      : t("Proceed to Generate Knowledge")
                    : isMgs
                      ? t("Save Changes")
                      : props.summarisedView &&
                        history.location.pathname.includes(
                          "document-keywords-summary"
                        )
                        ? t("Continue")
                        : t("Save")}
                </button>
              </PermissionWrapper>
            </div>
          </Container>
        </footer>
      )}
    </>
  );
};

export default GenerateTags;
