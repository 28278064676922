import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import PlanCheck from "../../assets/images/plan-check.svg";
import "./styles.scss";

export const PlanSection = ({
  isRecommened = false,
  name,
  des,
  priceMonth,
  priceYear,
  btnText,
  isCurrent,
  planIncludes,
  url,
  isCancelAble,
}: any) => {
  const { t } = useTranslation("translation");

  return (
    <div
      className={cx("plan-section-wrapper w-100", { selected: isRecommened })}
    >
      {isRecommened && <span className="recommened"> {t("Recommended")} </span>}
      <div className="name">{t(name)}</div>
      <div className="sub">{t(des)}</div>
      <section className="price">
        <div className="month">{t(priceMonth)}</div>
        <div className="year">{t(priceYear)}</div>
      </section>
      {isCurrent ? (
        <div>
          <button
            className={cx("btn btn-primary p-2 w-100")}
            disabled={isCurrent}
          >
            {t("Current Plan")}
          </button>
          {isCancelAble && (
            <a href={url} rel="noopener noreferrer">
              <button className={cx("mt-2 font-14 btn btn-link clr-red p-2 w-100")}>
                {t("Cancel Subscription")}
              </button>
            </a>
          )}
        </div>
      ) : (
        btnText && (
          <a href={url} rel="noopener noreferrer">
            <button
              className={cx("btn btn-primary p-2 w-100")}
              disabled={isCurrent}
            >
              {t(btnText)}
            </button>
          </a>
        )
      )}

      <section>
        <div className="feature-label">{t("Plan Includes")}</div>
        <div>
          {planIncludes.map((p: any) => (
            <div className="feature-item d-flex">
              <img src={PlanCheck} alt="" />
              <span className="feature"> {t(p)}</span>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default PlanSection;
