import React, { useEffect, useState } from "react";
import { Container, Image, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Tour from "reactour";

import { useTranslation } from "react-i18next";
import { organizationFlowObject } from "../../app.constant";
import close from "../../assets/images/close-blk.svg";
import AddTags from "../../components/UI/AddTags/AddTags";
import ConfirmationModal from "../../components/UI/ConfirmationModal/ConfirmationModal";
import UpdateModuleModal from "../../components/UI/KnowledgeDatatableComponent/UpdateModuleModal";
import Loading from "../../components/UI/Loading/Loading";
import ManageLevelCompact from "../../components/UI/ManageLevelCompact/ManageLevelCompact";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";
import DashboardService from "../../services/DashboardService";
import {
  getStickerSets,
  updateOrganizationFlow,
} from "../../store/common/actions";
import {
  addEntityToKnowledgeGroup,
  deleteKnowledgeById,
} from "../../store/knowledge/actions";
import { getAllUserGroupOptions } from "../../store/notifications/actions";
import {
  createIntentFromManageAnswer,
  getAllUserGroups,
  getKnowledgeById,
  getParaPhrasesBasedOnIntent,
  localUserQuestionUpdate,
  updateIntent,
  updateIntentTags,
} from "../../store/questions/actions";
import {
  AllUserGroups,
  getAllIntents as getAllIntentSelector,
  getIntent,
  isQuestionLoading,
} from "../../store/questions/selector";
import { steps } from "../Knowledge/KnowledgeCreateFromScratch/KnowledgeCreateFromScratchTour";
import ButtonsTab from "../Knowledge/KnowledgeCreateFromScratch/partials/ButtonsTab";
import SettingsTab from "../Knowledge/KnowledgeCreateFromScratch/partials/SettingsTab";
import UserSaysTab from "../Knowledge/KnowledgeCreateFromScratch/partials/UserSaysTab";
import VirtualAssistantTab from "../Knowledge/KnowledgeCreateFromScratch/partials/VirtualAssistantTab";
const service = new DashboardService();

interface Props {}

export const AddToKnowledge = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const dispatch = useDispatch();
  const useLocatio = useLocation();
  const questionLoading = useSelector(isQuestionLoading);
  const allUserGroupsData = useSelector(AllUserGroups);
  const intent = useSelector(getIntent);
  const allFetchedIntents: any = useSelector(getAllIntentSelector);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isIntentUpdating, setIsIntentUpdating] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [activeTab, setActiveTab] = useState("usersays");
  const [selectedButtonTarget, setSelectedButtonTarget] = useState(null);
  const [targetButtonsList, setTargetOptionsList] = useState([]);
  const [hasAddedQuestion, setHasAddedQuestion] = useState(false);
  const [userSaysTarget, setUserSaysTarget] = useState(null);
  const [hasAddedResponse, setHasAddedResponse] = useState(false);
  const [selectedResponseTarget, setSelectedResponseTarget] = useState(null);
  const [allTags, setAllTags] = useState([]);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [AllIntents, setAllIntents] = useState([]);
  const [paraPhrasesLoading, setParaPhrasesLoading] = useState(false);

  useEffect(() => {
    if (allFetchedIntents) {
      setAllIntents([...allFetchedIntents]);
    }
  }, [allFetchedIntents]);

  useEffect(() => {
    dispatch(
      updateOrganizationFlow({
        ...organizationFlowObject,
        prevRoute: "/knowledge",
        currentRoute: "/knowledge-create-from-scratch",
        nextRoute: "/knowledge/generate-questions",
        setup_completed: false,
      })
    );

    dispatch(getAllUserGroups());
    dispatch(getStickerSets());
    dispatch(getAllUserGroupOptions()); // user groups

    setUserSaysTarget(null);
    setSelectedResponseTarget(null);
    setHasAddedQuestion(false);
    setHasAddedResponse(false);

    const query = new URLSearchParams(useLocatio.search);
    const group = query.get("group");
    setSelectedGroupId(group ? group : null);
    if (group) {
      dispatch(localUserQuestionUpdate("knowledge_group_id", group));
    }
    return () => {
      console.log("CLEANED UP");
      dispatch({
        type: "FETCH_KNOWLEDGE_BY_ID_SUCCESS",
        payload: {
          intent: null,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (props.questionAnswerData.intent_id && !props.addAnswerFromPopup) {
      const getSelectedIntent = async (selectedIntent: any) => {
        await dispatch(getKnowledgeById(selectedIntent));
        setIsIntentUpdating(false);
      };
      setIsIntentUpdating(true);
      getSelectedIntent(props.questionAnswerData.intent_id);
      return;
    }

    dispatch({
      type: "FETCH_KNOWLEDGE_BY_ID_SUCCESS",
      payload: {
        intent: {
          questions: [
            {
              text: props.questionAnswerData.text,
            },
          ],
          responses: props.questionAnswerData?.post?.text
            ? [
                {
                  text: props.questionAnswerData.post?.text,
                  group_ids: [],
                },
              ]
            : null,
          name: props.questionAnswerData.question,
          type: 3,
          is_input_disabled: null,
          tags: [],
          parent_id: null,
          _id: props.questionAnswerData.intent_id
            ? props.questionAnswerData.intent_id
            : null,
          answers_by_openAI: props.addAnswerFromPopup ? false : true,
          conversation_log_id:
            props.questionAnswerData.conversation_log_id || null,
        },
      },
    });
  }, [props.questionAnswerData.intent_id]);

  // Levels

  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const [selectedParentId, setSelectedParentId] = React.useState(null);
  const [selectedModuleName, setSelectedModuleName] = React.useState("");
  const [moduleEditMode, setModuleEditMode] = React.useState(false);
  const [moduleDepth, setModuleDepth] = React.useState(0);
  /**
   * Used to sort data using API
   * @param param
   * @param direction
   */
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const onSelectGroupId = (level: string) => {
    // For now we are allowing server side sorting on above two fields
    setSelectedGroupId(level);

    if (!props.editMode) {
      dispatch(localUserQuestionUpdate("knowledge_group_id", level));
    }
  };

  const updateParentHandler = (
    name?: string,
    parent_id?: string,
    isEdit: boolean = false,
    depth: number = 0
  ) => {
    setSelectedParentId(parent_id);
    setSelectedModuleName(name);
    setModuleEditMode(isEdit);
    setModuleDepth(depth);
    updateModalHandler();
  };

  const updateModalHandler = () => {
    setShowRenameModal(!showRenameModal);
  };

  const confirmRenameHandler = async (data: any) => {
    // getAllYearsFun(false);
    setModuleEditMode(false);
    updateModalHandler();
  };

  // Levels

  useEffect(() => {
    if (
      !questionLoading &&
      intent &&
      props.selectedIntent &&
      props.selectedIntent.existingRecord
    ) {
      const oldList = intent.questions ? [...intent.questions] : [];
      oldList.unshift({ text: props.selectedIntent.existingRecord.question });
      dispatch(localUserQuestionUpdate("questions", oldList));

      const oldResponses = intent.responses ? [...intent.responses] : [];
      oldResponses.unshift({
        text: props.selectedIntent.existingRecord.answer,
        group_ids: [],
      });
      dispatch(localUserQuestionUpdate("responses", oldResponses));
    }
  }, [questionLoading]);

  //
  useEffect(() => {
    buttonTextHandler();

    // If intent found updated selected state
    if (intent) {
      if (intent && intent.tags) {
        setAllTags(
          intent.tags.map((value: string) => {
            return {
              label: value,
              value,
            };
          })
        );
      }
    }
  }, [intent]);

  // Create intent
  const createIntenthandler = async () => {
    // For creation
    if (
      !props.editMode &&
      activeTab === "usersays" &&
      (!intent || !intent.questions || intent.questions.length === 0)
    ) {
      try {
        if (selectedButtonTarget) {
          const oldButtonsList = [...targetButtonsList];
          oldButtonsList.push({
            ...selectedButtonTarget,
            name: selectedButtonTarget.name,
            type: selectedButtonTarget.type,
          });
          await dispatch(localUserQuestionUpdate("buttons", oldButtonsList));
          setSelectedButtonTarget(null);
        }

        if (userSaysTarget) {
          const oldList = [];
          await oldList.unshift(userSaysTarget);
          await dispatch(localUserQuestionUpdate("questions", oldList));
          setUserSaysTarget(null);
          if (!intent || !intent.responses) {
            setHasAddedResponse(true);
            tabsChangeHandler("virtualassistant");
            return;
          }
        }

        if (selectedResponseTarget) {
          const oldList = [];
          await oldList.unshift(selectedResponseTarget);
          await dispatch(localUserQuestionUpdate("responses", oldList));
        }

        await setHasAddedResponse(true);
        await setHasAddedQuestion(true);
        await dispatch(
          createIntentFromManageAnswer({
            ...intent,
            answers_by_openAI: true,
            conversation_log_id: props.questionAnswerData.conversation_log_id,
            unaswered_question_id: props.questionAnswerData.selectedAnswer
              ? props.questionAnswerData.selectedAnswer._id
              : null,
            suggested_answer_id: props.questionAnswerData.intent_id,
            author_id: props.questionAnswerData.author_id || null,
          })
        );
        resetEditKnowledgehandler();
      } catch (e) {
        console.log("e", e);
      }
      return;
    }

    // If questions but no responses
    if (
      !props.editMode &&
      activeTab === "usersays" &&
      intent &&
      intent.questions &&
      intent.questions.length > 0
    ) {
      tabsChangeHandler("virtualassistant");
      return;
    }

    if (
      !props.editMode &&
      activeTab === "virtualassistant" &&
      intent &&
      intent.questions &&
      intent.questions.length > 0
    ) {
      tabsChangeHandler("level");
      return;
    }

    // All good proceed
    setIsIntentUpdating(true);
    try {

      if (
        userSaysTarget &&
        userSaysTarget.text &&
        userSaysTarget.text.trim().length > 0
      ) {
        const oldList = [];
        await oldList.unshift(userSaysTarget);
        await dispatch(localUserQuestionUpdate("questions", oldList));
      }

      if (selectedResponseTarget) {
        const oldList = [];
        await oldList.unshift(selectedResponseTarget);
        await dispatch(localUserQuestionUpdate("responses", oldList));
      }

      await setHasAddedResponse(true);
      await setHasAddedQuestion(true);
      if (props.addAnswerFromPopup) {
        const id = await dispatch(
          createIntentFromManageAnswer({
            ...intent,
            answers_by_openAI: false,
            type: 3,
            unaswered_question_id: props.questionAnswerData.selectedAnswer
              ? props.questionAnswerData.selectedAnswer._id
              : null,
            suggested_answer_id: props.questionAnswerData.intent_id,
            author_id: props.questionAnswerData.author_id || null,
          })
        );
        if (!props.questionAnswerData.intent_id) {
          await service.setApproval(
            props.questionAnswerData._id,
            props.questionAnswerData.post._id,
            {
              intent_id: id,
              approval_status: "accepted",
            }
          );
          props?.fetchDiscussion();
        }

        resetEditKnowledgehandler();
        return;
      } else {
        await dispatch(
          createIntentFromManageAnswer({
            ...intent,
            answers_by_openAI: true,
            conversation_log_id: props.questionAnswerData.conversation_log_id,
            unaswered_question_id: props.questionAnswerData.selectedAnswer
              ? props.questionAnswerData.selectedAnswer._id
              : null,
            suggested_answer_id: props.questionAnswerData.intent_id,
            author_id: props.questionAnswerData.author_id || null,
          })
        );
      }
      resetEditKnowledgehandler();
    } catch (error: any) {}
    setIsIntentUpdating(false);
  };

  const isUserSaysActivityDetected = (e: any) => {
    setUserSaysTarget({
      text: e,
    });
  };

  const isResponseActivityDetected = (e: any) => {
    setSelectedResponseTarget(e);
  };

  const tabsChangeHandler = (e: any) => {
    setActiveTab(e);
    buttonTextHandler(e);
  };

  const buttonTextHandler = (tab = activeTab) => {
    if (!props.editMode) {
      if (tab === "usersays") {
        setButtonText("Next");
      } else if (tab === "level") {
        setButtonText("Save");
      }
    }
  };

  // Show delete modal
  const [modalShow, setModalShow] = useState(false);
  const modalhandler = (value: boolean) => {
    setModalShow(value);
  };

  const deleteConfirmed = async (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedRowIds: string[]
  ) => {
    e.preventDefault();
    setModalShow(false);
    setIsIntentUpdating(true);
    await dispatch(deleteKnowledgeById(selectedRowIds, false));
    props.resetEditKnowledgehandler("reload");
    setIsIntentUpdating(false);
  };

  const updateIntenthandler = async () => {
    if (activeTab === "level") {
      if (selectedGroupId) {
        setIsIntentUpdating(true);
        dispatch(
          addEntityToKnowledgeGroup(selectedGroupId, "intent", [intent._id])
        );
        setIsIntentUpdating(false);
      }
    } else {
      setIsIntentUpdating(true);
      await dispatch(updateIntent());
      setIsIntentUpdating(false);
      resetEditKnowledgehandler();
    }
  };

  const resetEditKnowledgehandler = () => {
    dispatch({
      type: "FETCH_KNOWLEDGE_BY_ID_SUCCESS",
      payload: {
        intent: null,
      },
    });
    props.resetEditKnowledgehandler();
  };

  const getParaPhrasesBasedOnIntentHandler = async () => {
    try {
      setParaPhrasesLoading(true);
      const { questions, is_paraphrased }: any = await dispatch(
        getParaPhrasesBasedOnIntent(intent?._id)
      );
      setParaPhrasesLoading(false);

      let oldQuestions: any[] = [];
      if (questions.length) {
        oldQuestions = [...questions];
      }
      await dispatch(localUserQuestionUpdate("questions", oldQuestions));
      await dispatch(localUserQuestionUpdate("is_paraphrased", is_paraphrased));
    } catch (error) {
      setParaPhrasesLoading(false);
      console.log("[ error ]", error);
    }
  };

  const showAddTagHandler = (e: any) => {
    setShowTagsModal(!showTagsModal);
  };

  const confirmedTagsHandler = async (e: any, tags: any[]) => {
    e.preventDefault();
    // setQuestionUpdating(true)
    setShowTagsModal(false);
    if (props.editMode) {
      await dispatch(updateIntentTags(tags.map((t: any) => t.value)));
    } else {
      dispatch(
        localUserQuestionUpdate(
          "tags",
          tags.map((t: any) => t.value)
        )
      );
    }
    // setQuestionUpdating(false)
  };


  // questionLoading
  if (isIntentUpdating) {
    return <Loading />;
  }

  return (
    <>
      <section className="page-mid-wraper pb-0">
        {intent && intent._id && (
          <ConfirmationModal
            message={t(
              "You are deleting a question. Note that all the children of this question will also be deleted. Are you sure you want to delete?"
            )}
            size={"md"}
            show={modalShow}
            selectedRow={[intent._id]}
            onHide={() => modalhandler(false)}
            confirmHandler={deleteConfirmed}
          />
        )}

        <UpdateModuleModal
          show={showRenameModal}
          onHide={updateModalHandler}
          name={selectedModuleName}
          parent_id={selectedParentId}
          editMode={moduleEditMode}
          confirmHandler={confirmRenameHandler}
          moduleDepth={moduleDepth}
        />

        <AddTags
          onHide={showAddTagHandler}
          show={showTagsModal}
          confirmhandler={confirmedTagsHandler}
          selectedTags={allTags}
        />

        <Container>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <h2 className="mb-0 ">{props.editMode ? t("Edit Knowledge"): t("Add Knowledge")}</h2>
                <button className="btn" onClick={resetEditKnowledgehandler}>
                  <Image src={close} />
                </button>
              </div>

              <div className="mt-4">
                <Tabs
                  defaultActiveKey="usersays"
                  onSelect={tabsChangeHandler}
                  activeKey={activeTab}
                >
                  <Tab eventKey="usersays" title={t("Questions")}>
                    <UserSaysTab
                      activeTab={activeTab}
                      editMode={props.editMode}
                      isActivityDetected={isUserSaysActivityDetected}
                      hasAdded={hasAddedQuestion}
                    />
                  </Tab>
                  <Tab
                    eventKey="virtualassistant"
                    title={t("Answers")}
                    disabled={
                      !props.editMode &&
                      intent &&
                      intent.questions &&
                      intent.questions.length === 0 &&
                      allUserGroupsData.length > 0
                    }
                  >
                    <VirtualAssistantTab
                      allUserGroups={[...allUserGroupsData]}
                      isActivityDetected={isResponseActivityDetected}
                      hasAdded={hasAddedResponse}
                      nodeElement={true}
                    />
                  </Tab>
                  <Tab eventKey="level" title={t("Level")}>
                    <ManageLevelCompact
                      updateParentHandler={updateParentHandler}
                      onSelectGroupId={onSelectGroupId}
                      selectedGroupId={selectedGroupId}
                      setSelectedParentId={setSelectedParentId}
                      setSelectedModuleName={setSelectedModuleName}
                      intent={intent}
                      editMode={props.editMode}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          {props.viewall && (
            <div className="d-flex align-items-center flex-wrap justify-content-end">
              {paraPhrasesLoading && (
                <div className="spinner-border spinner-width-20 spinner-border-lg clr-primary mr-2"></div>
              )}

              {paraPhrasesLoading && (
                <div className="mr-auto font-16">
                  {t("Generating training phrases...  ")}
                </div>
              )}

              {intent &&
                intent._id &&
                !paraPhrasesLoading &&
                !intent.is_paraphrased &&
                activeTab === "usersays" && (
                  <div className="mr-auto font-16">
                    {t("Looking to expand your question set?")}
                    <button
                      className="btn btn-link py-0"
                      disabled={paraPhrasesLoading}
                      onClick={getParaPhrasesBasedOnIntentHandler}
                    >
                      {t("Generate Variations")}
                    </button>
                  </div>
                )}
              <button
                className="btn btn-outline-primary mr-2"
                onClick={resetEditKnowledgehandler}
              >
                {t("Cancel")}
              </button>
              {props.editMode && (
                <PermissionWrapper>
                  <button
                    className="btn btn-primary mr-2"
                    onClick={updateIntenthandler}
                  >
                    {t("Save")}
                  </button>
                </PermissionWrapper>
              )}
              {!props.editMode && (
                <PermissionWrapper>
                  <button
                    className="btn btn-primary third-step"
                    onClick={createIntenthandler}
                  >
                    {props.editMode ? "Save" : buttonText}
                  </button>
                </PermissionWrapper>
              )}
            </div>
          )}
        </Container>
      </section>
      {!props.viewall && (
        <footer>
          <Container>
            <div className="d-flex align-items-center flex-wrap">
              {props.editMode && (
                <PermissionWrapper>
                  <button
                    className="btn btn-primary mr-3"
                    onClick={updateIntenthandler}
                  >
                    {t("Save")}
                  </button>
                </PermissionWrapper>
              )}
              {!props.editMode && (
                <PermissionWrapper>
                  <button
                    className="btn btn-primary third-step"
                    onClick={createIntenthandler}
                  >
                    {props.editMode ? "Save" : buttonText}
                  </button>
                </PermissionWrapper>
              )}
              {props.editMode && (
                <button
                  className="btn btn-outline-primary ml-3"
                  onClick={resetEditKnowledgehandler}
                >
                  {t("Cancel")}
                </button>
              )}
            </div>
          </Container>
        </footer>
      )}
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        maskClassName={"maskbg"}
        onRequestClose={() => setIsTourOpen(false)}
        showNumber={false}
        rounded={5}
        accentColor={"#F9B234"}
      />
    </>
  );
};

export default AddToKnowledge;
