import { useEffect, useState } from "react";
import { Button, Image, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useDispatch } from "react-redux";
import Select from "react-select";
import downarrowblk from "../../../../assets/images/chevron-down-blk.svg";
import closeimg from "../../../../assets/images/close-blk.svg";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import {
    linkACourseToClass
} from "../../../../store/knowledge/actions";

import { useTranslation } from "react-i18next";

const LinkCourseToAgent = (props: any) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const lmsListData = [
    {
      value: "moodle",
      label: "Moodle",
    },
    {
      value: "desire2learn",
      label: "D2L",
    },
    {
      value: "microsoft365",
      label: "Microsoft 365",
    },
    {
      value: "googleclassroom",
      label: "Google Classroom",
    },
    {
      value: "canvas",
      label: "Canvas",
    },
    {
      value: "BlackboardLearn",
      label: "Blackboard",
    },
    {
      value: "Wizlearn",
      label: "Wizlearn",
    }
  ];
  const [loading, setLoading] = useState(false);
  const [selectedButtonTarget, setSelectedButtonTarget] = useState({
    courseId: "",
    provider: {
      value: "moodle",
      label: "Moodle",
    },
  });

  useEffect(() => {
    if (!props.show) {
      return;
    }

    if ( props.courseLMS ) {
        const course = lmsListData.find((d: any) => d.value === props.courseLMS.provider)
        setSelectedButtonTarget({
            courseId: props.courseLMS?.course_id,
            provider: course ? course : {
                value: "moodle",
            label: "Moodle",
            },
        })
    } else {
        setSelectedButtonTarget({
            courseId: "",
            provider: {
                value: "moodle",
                label: "Moodle",
            },
        })
    }

  }, [props]);

  const linkCourseHandler = async () => {
    if (
        !selectedButtonTarget.courseId
            || 
        !selectedButtonTarget.provider
    ) {
        return
    }
    try {
      setLoading(true);
      await dispatch(
        linkACourseToClass(
          {
            course_id: selectedButtonTarget.courseId,
            provider: selectedButtonTarget.provider.value,
          },
          props.id
        )
      );
      props.confirmHandler();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const inputChangeHandler = (e: any) => {
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-400"
      onHide={props.onHide}
    >
      <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
        <ModalTitle
          className="h5 py-1 font-600"
          id="contained-modal-title-vcenter"
        >
          {t("Link Course")}
        </ModalTitle>
        <Button onClick={props.onHide} className="ml-auto">
          <Image src={closeimg} alt="" />
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="mb-12">
          <h3 className="mb-1">{t("Choose LMS")}</h3>
          <Select
            isSearchable={false}
            className="select-target radius-2"
            classNamePrefix="select-target-prefix"
            components={{
              //DropdownIndicator: () => null,
              DropdownIndicator: (props: any) => (
                <img src={downarrowblk} alt={t("dropdown arrow")} />
              ),
              IndicatorSeparator: () => null,
            }}
            defaultValue={selectedButtonTarget.provider || null }
            onChange={(e: any) => {
              setSelectedButtonTarget({
                ...selectedButtonTarget,
                provider: e,
              });
            }}
            placeholder={t("Choose LMS")}
            getOptionLabel={(option: any) => option["label"]}
            getOptionValue={(option: any) => option["value"]}
            options={lmsListData}
          />
        </div>

        <div className="mb-12">
          <h3 className="mb-1">{t("Course ID")}</h3>

          <input
            type="text"
            className="form-control he-35 radius-2"
            placeholder={t("Please enter course ID")}
            name="courseId"
            value={selectedButtonTarget.courseId || ""}
            onChange={inputChangeHandler}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <PermissionWrapper>
          <button
            disabled={loading}
            className="btn-outline-primary btn font-600 py-1"
            onClick={props.onHide}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary ml-3 font-600 py-1"
            onClick={linkCourseHandler}
            disabled={loading}
          >
            {t("Link Course")}
          </button>
        </PermissionWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default LinkCourseToAgent;
