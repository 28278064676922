import { subDays } from "date-fns";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import DateRangePickerComponent from "../../../../../components/UI/DateRangePicker/DateRangePicker";
import Pagination from "../../../../../components/UI/Pagination/Pagination";
import QuestionListSkeleton from "../../../../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import { ChartTableSortIcon } from "../../../../../routes/Knowledge/AllKnowledge";
import { getMatchedAndUnmatchedQuestions } from "../../../../../store/knowledge/actions";
import { isTopicsLoading } from "../../../../../store/knowledge/selector";
import { columns, customStyles } from "../MatchedUnmatchedUtils";
import AddToExistingIntent from "./AddToExisting";

import { useTranslation } from "react-i18next";
import ExportReportShared from "../../../../../components/UI/ExportReportShared/ExportReportShared";

export const UnMatchedDatatableComponent = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(30);
  const [existingRecord, setExistingRecord] = useState(null);
  const [matchedRecords, setMatchedRecords] = useState([]);
  const [matchedRecordsPagination, setMatchedRecordsPagination] =
    useState(null);
  //   const [unmatchedRecords, setUnMatchedRecords] = useState(30)
  //   const [unmatchedRecordsPagination, setUnMatchedRecordsPagination] = useState(30)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const topicsLoading = useSelector(isTopicsLoading);
  const tableWrapperRef = useRef(null);
  const [filters, setFilters] = useState({
    question_type: null,
    page: null,
    page_size: null,
    sort_field: null,
    sort_order: null,
    from_date: null,
    to_date: null,
  });
  const [scrollRight, setScrollRight] = useState(0);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [datePickerClicked, setDatePickerClicked] = useState(false);
  const [customDatePickerValue, setCustomDatePickerValue] = useState(false);
  const [dateParameters, setDateParameters] = useState(null);
  useEffect(() => {
    if (!topicsLoading) {
      // dispatch(getAllTopics())
      // dispatch(getAllUserGroupOptions()) // user groups
    }

    // Table wrapper reference to check scroll left value of an element
    if (
      tableWrapperRef &&
      tableWrapperRef.current &&
      tableWrapperRef.current.children[0]
    ) {
      setScrollRight(tableWrapperRef.current.children[0].scrollLeft);
    }

    return () => {
      setScrollRight(0);
    };
  }, []);

  useEffect(() => {
    if (loading || !dateParameters || !dateParameters.value) {
      return;
    }

    if (
      dateParameters &&
      dateParameters.value &&
      dateParameters.value === "custom"
    ) {
      return setCustomDatePickerValue(true);
    }

    setTimeout(async () => {
      const filter: any = {
        ...filters,
        from_date:
          dateParameters && dateParameters.value
            ? moment(subDays(new Date(), +dateParameters.value)).format(
                "YYYY-MM-DD"
              )
            : null,
        to_date:
          dateParameters && dateParameters.value
            ? moment(new Date()).format("YYYY-MM-DD")
            : null,
      };

      setCustomDatePickerValue(false);
      await setFilters(filter);
    }, 100);
  }, [dateParameters]);

  useEffect(() => {
    if (
      loading ||
      !props.filtersData.filter_from_date ||
      !props.filtersData.filter_to_date
    ) {
      return;
    }

    const fn = async () => {
      // console.log('[ filtersData ]', props.filtersData)
      const filter = {
        ...filters,
        from_date: props.filtersData.filter_from_date,
        to_date: props.filtersData.filter_to_date,
      };

      if (props.filtersData.dateParameters) {
        await setDateParameters(props.filtersData.dateParameters);
      }

      await setFilters(filter);
    };
    fn();
    setDatePickerClicked(false);
    return () => {};
  }, [props.filtersData]);

  useEffect(() => {
    getMatchedFunctions();
  }, [filters]);

  const getMatchedFunctions = async () => {
    if (loading || !filters.from_date || !filters.to_date) {
      return;
    }
    try {
      setLoading(true);
      const matchedQuestions: any = await dispatch(
        getMatchedAndUnmatchedQuestions({
          ...filters,
          page: currentPage,
          page_size: perPage,
          question_type: "unmatched",
        })
      );
      setMatchedRecords(matchedQuestions.rows);
      setMatchedRecordsPagination(matchedQuestions.pagination);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setCurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
    });
  };

  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    await setPerPage(newPerPage);
    await setCurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
      page_size: newPerPage,
    });
  };

  /**
   * Used to sort data using API
   * @param param0
   * @param direction
   */
  const onSortHandler = async ({ selector }: any, direction: string) => {
    await setFilters({
      ...filters,
      sort_field: selector === "_id" ? "question" : selector,
      sort_order: direction === "desc" ? "dsc" : "asc",
    });
  };

  /**
   * Custom sort function
   * @param rows
   * @param selector
   * @param direction
   * @returns
   */
  const customSort = (rows: any[], selector: string, direction: string) => {
    return rows;
  };

  const addToExistingIntent = (e: any) => {
    setExistingRecord(e);
    setShowTagsModal(!showTagsModal);
  };

  const confirmAddToExistingIntent = (e: any) => {
    setShowTagsModal(!showTagsModal);
    props.editKnowledgehandler({
      _id: e._id,
      existingRecord,
    });
  };

  const dateChangehandler = async (e: any) => {
    if (!e.filter_from_date || !e.filter_to_date) {
      return;
    }

    await setFilters({
      ...filters,
      from_date: e.filter_from_date,
      to_date: e.filter_to_date,
    });
  };

  const closeDateRangeHandler = () => {
    setCustomDatePickerValue(false);
  };

  return (
    <React.Fragment>
      <AddToExistingIntent
        onHide={addToExistingIntent}
        show={showTagsModal}
        confirmhandler={confirmAddToExistingIntent}
        selectedTags={[]}
      />

      <div className="graph-box px-0 py-3 position-relative">
        <div className="filters d-flex flex-wrap justify-content-between align-items-center border-0">
          <h3 className="mb-0">{t("Unanswered Questions")}</h3>
          <div className="d-flex flex-wrap sugested-next-ques"></div>
          {!loading && (
            <div className="d-flex flex-wrap sugested-next-ques">
              <Select
                className="select-sort select-target"
                classNamePrefix="select-target-prefix"
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={dateParameters ? { ...dateParameters } : null}
                onChange={(e: any) => {
                  setDatePickerClicked(e.value === "custom");
                  setDateParameters(e);
                }}
                options={[
                  { value: "60", label: t("Last 60 days") },
                  { value: "30", label: t("Last 30 days") },
                  { value: "7", label: t("Last 7 days") },
                  { value: "custom", label: t("Custom") },
                ]}
              />
              <ExportReportShared
                name={"question-list"}
                filtersData={{ ...filters, question_type: "unmatched" }}
              />
            </div>
          )}
        </div>
        {/* {loading && (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border spinner-border-lg text-primary'></div>
          </div>
        )} */}
        {/* {!loading && ( */}
        <React.Fragment>
          {customDatePickerValue && datePickerClicked && (
            <DateRangePickerComponent
              dateValue={{
                startDate:
                  filters && filters.from_date
                    ? new Date(filters.from_date)
                    : new Date(),
                endDate:
                  filters && filters.to_date
                    ? new Date(filters.to_date)
                    : new Date(),
              }}
              onDateChange={dateChangehandler}
              onCloseDateRangeHandler={closeDateRangeHandler}
            />
          )}
          <div className="px-4" ref={tableWrapperRef}>
            <DataTable
              className="table-wraper bot-exp-user-feedback-min-height"
              noContextMenu
              noHeader
              fixedHeader
              fixedHeaderScrollHeight="700px"
              columns={columns({
                editKnowledgehandler: props.editKnowledgehandler,
                addToExistingIntent: addToExistingIntent,
                unmatched: true,
              })}
              data={matchedRecords ? matchedRecords : []}
              customStyles={customStyles}
              sortIcon={<ChartTableSortIcon />}
              paginationPerPage={perPage}
              paginationDefaultPage={1}
              paginationResetDefaultPage={false}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={loading} //
              progressComponent={
                <div style={{ padding: 10, width: "100%" }}>
                  <QuestionListSkeleton count={12} />
                </div>
              }
              defaultSortAsc={false}
              // sortFunction={customSort}
              onSort={onSortHandler}
            />

            {/* -----------------  Pagination   --------------------------- */}
            {matchedRecords &&
              matchedRecords.length > 0 &&
              matchedRecordsPagination && (
                <Pagination
                  // showScrollButtons={true}
                  hideSuggestedQuestion={true}
                  //   scrollRightValue={scrollRight}
                  //   scrollWrapper={'table-wraper'}
                  currentPage={currentPage}
                  pagination={matchedRecordsPagination}
                  paginationPerPage={perPage}
                  pageChange={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              )}
          </div>
        </React.Fragment>
        {/* )} */}
      </div>
    </React.Fragment>
  );
};

export default UnMatchedDatatableComponent;
