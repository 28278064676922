import ApiUtil from "../utils/apiUtil";
import {
  getAgentFromStorage,
} from "../utils/appUtils";


const getIntegrations = async (): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/integrations?agent_id=${getAgentFromStorage()}`,
    method: "GET"
  });
  return res;
} 

const createIntegration = async (data: any): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/integrations?agent_id=${getAgentFromStorage()}`,
    method: "POST",
    data: data
  });
  return res;
}

const updateIntegration = async (id: string, data: any): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/integrations/${id}?agent_id=${getAgentFromStorage()}`,
    method: "PUT",
    data: data
  });
  return res;
} 

const getCanvasIntegrationFiles = async (fileType: string, course_id: string): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/canvas-integrations/current/courses/${course_id}/${fileType}?agent_id=${getAgentFromStorage()}`,
    method: "GET",
  });
  return res;
} 

const importCanvasIntegrationFiles = async (fileType: string, data: any): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/canvas-integrations/current/${fileType}?agent_id=${getAgentFromStorage()}`,
    method: "POST",
    data: data
  });
  return res.data.data;
} 

const getCanvasIntegration = async (): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/canvas-integrations/current?agent_id=${getAgentFromStorage()}`,
    method: "GET",
  });
  return res.data;
} 

const integrationService =  {
  getIntegrations,
  createIntegration,
  updateIntegration,
  getCanvasIntegrationFiles,
  importCanvasIntegrationFiles,
  getCanvasIntegration
} 

export default integrationService