import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import KnowledgeService from "../../../services/KnowledgeService";
import "./styles.scss";
import ModalCreate from "./ModalCreate";
import { HelperService } from "../../../services/helper.service";
import { KNOWLEDGE_ALL } from "../../../config";
import Loading from "../../../components/UI/Loading/Loading";
import backblue from "../../../assets/images/arrow-left.svg";


const helperService = new HelperService();

const UpdateLearningOutcome = () => {
  const { t } = useTranslation("translation");
  const history: any = useHistory();
  const [outcomes, setOutcomes] = useState([]);
  const [groupOutcomes, setGroupOutcomes] = useState([]);

  const [groupId, setGroupId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isShowAdd, setIsShowAdd] = useState(false);
  const knowledgeService = new KnowledgeService();

  const getGroupLearnings = async (groupId: string) => {
    const outcomeRes: any = await knowledgeService.getLearningOutcomesByGroup(
      groupId
    );
    setGroupOutcomes(outcomeRes?.data?.data?.learning_outcomes);
  };

  const getRecommendLearningOutcomes = async (groupId: any) => {
    const outcomeRes: any = await knowledgeService.getSuggestLearningOutcomes(
      groupId
    );
    setOutcomes(outcomeRes?.data?.data?.learning_outcomes);
  };

  const saveOutcomes = async () => {
    if (outcomes.length > 0) {
      setIsLoading(true);
      const learningOutcomes: any = outcomes.map((o) => ({
        text: o?.text,
      }));
      try {
        await knowledgeService.saveLearningOutcomesByGroup(
          groupId,
          learningOutcomes
        );
        setIsLoading(false);
        helperService.showToaster(t("Success"), "success");
        history.replace(KNOWLEDGE_ALL, {
          ...history.location.state,
          parent: history.location.state.selectedYear?._id
        });
      } catch (error) {
        setIsLoading(false);
        helperService.showToaster("Error when save learning outcomes",);
      }
    }
  };

  const getData = async (groupId: string) => {
    setIsLoading(true);
    await getGroupLearnings(groupId);
    await getRecommendLearningOutcomes(groupId);
    setIsLoading(false);

  }

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const groupId = query.get("group");
    setGroupId(groupId);
    if(groupId) {
      getData(groupId)
    } else {
      history.push(KNOWLEDGE_ALL)
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
       
      <div className="container">
        <section className="page-mid-wraper page-mid-wraper-build-v2 h-without-foter ">
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn p-0 btn-back"
              onClick={() => {
                history.replace(KNOWLEDGE_ALL, {
                  ...history.location.state,
                  parent: history.location.state.selectedYear?._id
                });
              }}
            >
              <Image src={backblue} />
              {t("Return to previous page")}
            </button>
          </div>
        </div>
          <div className="d-flex flex-wrap justify-content-center clr-primary">
            <h1 className="mb-5">{t("Update Learning Outcomes")}</h1>
          </div>
          <div className="row col-md-12">
            <div className="col-md-5 ">
              <div className="outcomes-wrapper justify-content-center h-100">
                <h2>{t("Previous Learning Outcomes")}</h2>
                <div className="outcome-list mt-3 ">
                  {groupOutcomes.map((o: any, i: any) => (
                     <div
                     key={i}
                     className="p-3 mb-1 d-flex justify-content-between align-items-center  outcome-item"
                   >
                     <div>{o.text}</div>
                     <div
                       className="px-3 clr-blue"
                       role="button"
                       onClick={() => {
                         setOutcomes((prev: any) => [
                           ...prev,
                           { text: o.text },
                         ]);
                       }}
                     >
                       +
                     </div>
                   </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-7 outcomes-wrapper recommened">
              <div className="recommened">
                <h2>{t("Suggested Learning Outcomes")}</h2>
                <div className="mt-3 outcome-list ">
                  {outcomes.map((o: any, i: number) => (
                    <div
                      key={i}
                      className="p-3 mb-1 d-flex justify-content-between align-items-center  outcome-item"
                    >
                      <div>{o.text}</div>
                      <div
                        className="px-3  clr-grey-text"
                        role="button"
                        onClick={() => {
                          const copy = [...outcomes];
                          copy.splice(i, 1);
                          setOutcomes((prev: any) => [...copy]);
                        }}
                      >
                        x
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-center p-1">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setIsShowAdd(true)}
                >
                  {t("+ Add")}
                </button>
              </div>
            </div>
          </div>
        </section>
        <div className="text-right">
          <button
            className="btn btn-primary mt-4"
            onClick={() => {
              saveOutcomes();
            }}
          >
            {t("Save")}
          </button>
        </div>
        <ModalCreate
          show={isShowAdd}
          onHide={() => setIsShowAdd(false)}
          addOutcome={(name: any) => {
            setIsShowAdd(false);
            setOutcomes((prev: any) => [...prev, { text: name }]);
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default UpdateLearningOutcome;
