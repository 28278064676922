import { useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import backblue from "../../../assets/images/arrow-left.svg";
import ArchiveKnowledgeDatatableView from "../../../components/UI/KnowledgeDatatableComponent/ArchiveKnowledgeDatatableView";

import { useTranslation } from "react-i18next";

export const ArchivedKnowledge = (props: any) => {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const [isDocument, SetIsDocument] = useState( false );
  const onTabChangesHandler = (key: string) => {
    SetIsDocument(key === 'viewDocument')
  }

  return (
    <section className="page-mid-wraper h-without-foter">
      <Container>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <button
                  className="btn p-0 btn-back"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <Image src={backblue} />{t("Return to previous page")}
                </button>
              </div>
              </div>
            <div className="mb-12 d-flex justify-content-between align-items-center">
              <h1 className="mb-0">{t("Archived Knowledge")}</h1>
            </div>
            <ArchiveKnowledgeDatatableView onTabChanges={onTabChangesHandler} />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ArchivedKnowledge;
