import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import backblue from "../../../assets/images/arrow-left.svg";
import ConfirmationModal from "../../../components/UI/ConfirmationModal/ConfirmationModal";
import UpdateModuleModal from "../../../components/UI/KnowledgeDatatableComponent/UpdateModuleModal";
import PermissionWrapper from "../../../components/UI/PermissionWrapper/PermissionWrapper";
import deleteDisable from "../../../assets/images/delete-black.svg";
import deleteRed from "../../../assets/images/delete-red.svg";

import collIcon from "../../../assets/images/caret-right-fill.svg";
import expandIcon from "../../../assets/images/caret-down-fill.svg";
import redCross from "../../../assets/images/red-cross.svg";
import singleGreenCheck from "../../../assets/images/single-green-check.svg";
import moveIcon from "../../../assets/images/move-icon.svg";
import blackMoveIcon from "../../../assets/images/disable-move-icon.svg";

import moveUpImg from "../../../assets/New-images/knowledge-group-move-up-img.svg";
import moveDownImg from "../../../assets/New-images/knowledge-group-move-down-img.svg";
import disableMoveUpImg from "../../../assets/New-images/disable-move-up-img.svg";
import disableMoveDownImg from "../../../assets/New-images/disable-move-down-img.svg";
import nestImg from "../../../assets/New-images/nest-group-img.svg";
import unnestImg from "../../../assets/New-images/unnest-group-img.svg";
import addKnowledgeGroupImg from "../../../assets/New-images/knowledge-group-blue-plus-img.svg";
import NestDisable from "../../../assets/New-images/nest-disable-img.svg";
import unnestDisable from "../../../assets/New-images/unnest-disable-img.svg";

import "./index.scss";
import {
  createKnowledgeGroup,
  deleteKnowledgeGroup,
  getAllModulesSubgroups,
  updateKnowledgeGroup,
} from "../../../store/knowledge/actions";

import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import * as _ from "lodash";
import TreeGroupsModal from "./TreeGroupsModal";
import { HelperService } from "../../../services/helper.service";

// import closeImg from "../../../assets/New-images/build-knowledge-close-img.svg";
import closeimg from "../../../assets/images/close-blk.svg";
import { object } from "yup";

const helperService = new HelperService();

export default function ManageKnowledgeGroup() {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const dispatch = useDispatch();
  const [flatGroups, setFlatGroups] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState([]);
  const [shownGroups, setShownGroups] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState<any>([]);
  const [editGroup, setEditGroup] = useState<any>({});
  const [isOpenMove, setIsOpenMove] = useState(false);

  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const [selectedParentId, setSelectedParentId] = React.useState(null);
  const [selectedModuleName, setSelectedModuleName] = React.useState("");
  const [moduleEditMode, setModuleEditMode] = React.useState(false);
  const [moduleDepth, setModuleDepth] = React.useState(0);

  const [moduleName, setModuleName] = useState(null);
  const [moveToIdUnnest, setMoveToIdUnnest] = useState(null);
  const [moveToIdNest, setMoveToIdNest] = useState(null);
  const [isUnnestDisable, setIsUnnestDisable] = useState(true);
  const [isNestDisable, setIsNestDisable] = useState(true);

  const [clickedModule, setClickedModule] = useState(null);
  const [newAddedGroup, setNewAddedGroup] = useState(null);
  const [addNewTopLevelModule, setAddNewTopLevelModule] = useState(false);

  const newGroupRef = useRef(null);

  useEffect(() => {
    getAllYearsFun();
  }, []);

  useEffect(() => {
    if (!selectedGroup) {
      setIsUnnestDisable(true);
      setIsNestDisable(true);
      return;
    } else {
      selectedGroupActions();
    }
  }, [selectedGroup]);

  const selectedGroupActions = () => {
    setIsUnnestDisable(true);
    setIsNestDisable(true);
    if (
      flatGroups &&
      flatGroups.length > 0 &&
      selectedGroup &&
      selectedGroup.length > 0
    ) {
      const groups = flatGroups.filter((e: any) => {
        return selectedGroup.some((item: any) => {
          return e._id === item;
        });
      });
      const group = _.groupBy(groups, "parent_id");
      const getGroup = (val: any) => {
        return flatGroups.find((e) => e._id === val);
      };
      const unnestFunc = (groupObj: any) => {
        if (Object.keys(groupObj).length > 1) {
          setIsUnnestDisable(true);
          return;
        } else {
          for (const key in groupObj) {
            const moveTo = getGroup(key);

            if (key === "null") {
              setIsUnnestDisable(true);
              return;
            } else {
              setIsUnnestDisable(false);
              setMoveToIdUnnest(moveTo && moveTo.parent_id);
              return;
            }
          }
        }
      };
      unnestFunc(group);
      const nestFunc = (groupObj: any) => {
        if (Object.keys(groupObj).length > 1) {
          setIsNestDisable(true);
          setMoveToIdNest(null);
          return;
        } else {
          const onSetNestBtn = (groups: any) => {
            let newParent: any = null;
            let i: number = 0;
            let selectedIndex = null;
            for (let index = 0; index < groups.length; index++) {
              const element = groups[index];
              const selected = selectedGroup.filter(
                (i: any) => i === element?._id
              );
              if (selectedIndex === 0) {
                newParent = null;
                break;
              } else if (selectedIndex >= 1 && selectedIndex !== null) {
                if (
                  groups[selectedIndex + 1]._id !== groups[index]._id &&
                  selected &&
                  selected.length > 0
                ) {
                  if (selectedGroup && selectedGroup.length > 1) {
                    newParent = null;
                  } else {
                    newParent = newParent;
                  }
                  break;
                } else {
                  if (selected && selected.length > 0) {
                    if (newParent && newParent !== null) {
                      if (i < index) {
                        newParent = groups[i - 1];
                        selectedIndex = index;
                      }
                    }
                  }
                }
              } else {
                if (selected && selected.length > 0) {
                  newParent = index - 1 < 0 ? null : groups[index - 1];
                  i = index;
                  selectedIndex = index;
                }
              }
            }
            if (newParent === null) {
              setIsNestDisable(true);
              setMoveToIdNest(null);
              return;
            } else {
              setMoveToIdNest(newParent?._id);
              setIsNestDisable(false);
              return;
            }
          };
          for (const Key in groupObj) {
            if (Key === "null") {
              const groups = flatGroups.filter(
                (i: any) => i.parent_id === null
              );
              if (groups && groups.length > 1) {
                onSetNestBtn(groups);
              }
            } else {
              const moveTo = getGroup(Key);
              if (moveTo && moveTo.children && moveTo.children.length > 1) {
                onSetNestBtn(moveTo.children);
              }
            }
          }
        }
      };
      nestFunc(group);
    } else {
      setMoveToIdNest(null);
      setMoveToIdUnnest(null);
    }

    // const group = flatGroups.find((e) => e._id === selectedGroup);
    // const Id = group && group.parent_id ? group.parent_id : null;
    // const moveTo = flatGroups.find((e) => e._id === Id);

    // setMoveToIdUnnest(
    //   moveTo && moveTo.parent_id
    //     ? moveTo.parent_id
    //     : group && group.parent_id && moveTo.depth !== 0
    //     ? group.parent_id
    //     : null
    // );
  };

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, []);

  // useEffect(() => {
  //   document.addEventListener("click", buttonPressed);
  //   return () => {
  //     document.removeEventListener("click", buttonPressed);
  //   };
  // }, []);

  // const buttonPressed = (e: any) => {
  //   const className = e.target.classname;
  //   console.log("class", e.target.classname);

  //   if (
  //     (!moduleName || moduleName?.trim().length === 0) &&
  //     !className?.includes("add-group")
  //   ) {
  //     console.log("here");

  //     setShowRenameModal(false);
  //     setAddNewTopLevelModule(false);
  //   }
  // };

  useEffect(() => {
    if (newAddedGroup && newAddedGroup.length > 0) {
      scroll();
    }
  }, [newAddedGroup]);

  const scroll = () => {
    newGroupRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleKeyPress = (e: any) => {
    const btn = document.getElementById("add-new-knowledge-btn");
    const removeBtn = document.getElementById("remove-add-knowledge-bar");
    if (btn && (e.key === "Enter" || e.keyCode === 13) && !e.shiftKey) {
      btn.click();
    }
    if (
      removeBtn &&
      (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27)
    ) {
      removeBtn.click();
    }
  };

  const getAllYearsFun = async (
    groupsUpdate: boolean = false,
    groupMoved: boolean = false
  ) => {
    try {
      const response: any = await dispatch(getAllModulesSubgroups());
      let Array = response.filter((g: any) => g.group_type !== "system");
      let groupIds = flatGroups.map((e) => e._id);
      let parents = flatGroups.filter((e) => e?.children?.length > 0);
      let newArray: any = null;
      let newId: any[] = [];
      if (Array && Array.length > 0) {
        setFlatGroups(_.uniqBy(Array, "_id"));
        if (groupsUpdate) {
          setShowRenameModal(true);
          newArray = Array.filter((e: any) => {
            return groupIds.indexOf(e._id) < 0;
          });
          setNewAddedGroup(newArray);
          if (newArray && newArray[0]?.parent_id === null) {
            setAddNewTopLevelModule(true);
          }
          newId.push(newArray[0]._id);
          setSelectedGroup(newId);
        }
        // if (groupMoved) {
        //   let parentGroups = Array.filter((e: any) => e?.children?.length > 0);
        //   if (parentGroups.length > 0 && parents && parents.length > 0) {
        //     for (let index = 0; index < parents.length; index++) {
        //       const element = parents[index];
        //       let childIds = element.children.map((c: any) => c._id);
        //       parentGroups.forEach((ele: any, index: any) => {
        //         if (element._id === ele._id) {
        //           setNewAddedGroup(
        //             ele.children.filter(
        //               (child: any) => childIds.indexOf(child._id) < 0
        //             )
        //           );
        //         }
        //       });
        //     }
        //   }
        // }
      }
    } catch (error: any) {
      setFlatGroups([]);
    }
  };

  const updateModalHandler = (id: string = "") => {
    setModuleName("");
    setShowRenameModal(!showRenameModal);
  };

  const updateParentHandler = (
    name?: string,
    parent_id?: string,
    isEdit: boolean = false,
    clickedModule: string = "",
    depth: number = 0
  ) => {
    setSelectedParentId(parent_id);
    setSelectedModuleName(name);
    setModuleEditMode(isEdit);
    setModuleDepth(depth);
    setClickedModule(clickedModule);
    if (parent_id === null) {
      setAddNewTopLevelModule(true);
    } else {
      setAddNewTopLevelModule(false);
    }
    setShowRenameModal(true);
    // updateModalHandler(clickedModule);
  };

  const confirmRenameHandler = async (data: any) => {
    setShowRenameModal(false);
    getAllYearsFun(true);
    helperService.showToaster("Success", "success");
    setModuleName("");
    setAddNewTopLevelModule(false);
    // updateModalHandler();
  };

  const [deleteModuleModalShow, setDeleteModuleModalShow] = useState(false);
  const [deletedModuleId, setDeletedModuleId] = useState([]);
  const deleteModuleModalHandler = () => {
    setDeleteModuleModalShow(!deleteModuleModalShow);
  };
  const confirmDeleteModuleHandler = async (e: any, deleteId: string[]) => {
    setDeleteModuleModalShow(false);
    if (deleteId.length === flatGroups.length) {
      helperService.showToaster(
        "You cannot delete the last knowledge group. Minimum of 1 knowledge group is required.",
        "error"
      );
      return;
    }
    try {
      for (let index = 0; index < deleteId.length; index++) {
        const element = deleteId[index];
        const response: any = await dispatch(deleteKnowledgeGroup(element));
      }
      helperService.showToaster("Knowledge Group has been deleted", "success");
      getAllYearsFun();
      deleteModuleModalHandler();
      setSelectedGroup([]);
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const addOrRemove = (groups: any, id: string) => {
    if (groups.find((gID: any) => gID === id)) {
      return groups.filter((gID: any) => gID !== id);
    } else {
      groups.push(id);
      return groups;
    }
  };

  const toggleGroup = (g: any) => {
    const copy = [...flatGroups];
    let copyExpaned = [...expandedGroups];

    copy.forEach((group: any) => {
      if (group._id === g._id) {
        g.isExpand = !g.isExpand;
        copyExpaned = addOrRemove(copyExpaned, g._id);
      }
    });
    setExpandedGroups(copyExpaned);

    setFlatGroups(copy);
  };

  const isShowExpand = (g: any) => {
    if (g?.children && g?.children.length > 0) {
      return true;
    }

    return false;
  };

  const isExpanded = (gId: any) => {
    return expandedGroups.find((id) => id === gId);
  };

  const isShown = (g: any) => {
    if (!g.parent_id) {
      return true;
    }
    const parent = flatGroups.find((group: any) => group._id === g.parent_id);
    if (
      expandedGroups.find((eg: any) => eg === parent._id) &&
      isShown(parent)
    ) {
      return true;
    }

    return false;
  };

  const moveGroup = async (groupId: any, moveTo: any) => {
    for (let index = 0; index < groupId.length; index++) {
      const element = groupId[index];
      await dispatch(updateKnowledgeGroup({ parent_id: moveTo }, element));
    }
    const index = flatGroups.findIndex((g: any) => g._id === groupId);
    getAllYearsFun(false, true);
    helperService.showToaster("Knowledge group has been moved", "success");
    setSelectedGroup([]);
  };

  const updateGroup = async (group: any, index: any) => {
    await dispatch(updateKnowledgeGroup({ name: group.name }, group._id));
    helperService.showToaster("Success", "success");
    const clonedGroups = [...flatGroups];
    clonedGroups[index].name = group.name;
    setFlatGroups(clonedGroups);
    setEditGroup({});
  };

  const changeHandler = (e: any) => {
    setModuleName(e.target.value);
  };

  const addKnowledgeGroup = async () => {
    try {
      let res = null;
      res = await dispatch(
        createKnowledgeGroup({
          name: moduleName,
          parent_id: selectedParentId,
        })
      );

      if (res) {
        confirmRenameHandler(res);
      }
    } catch (error) {}
  };

  const onSelectKnowledgeGroup = (e: any, id: any) => {
    let temp = [...selectedGroup];
    if (e.target.checked) {
      const isPresent = temp.indexOf(id);
      if (isPresent < 0) {
        temp.push(id);
      }
    } else {
      let data = [...selectedGroup];
      let index = data.indexOf(id);
      data.splice(index, 1);
      temp = [...data];
    }

    setSelectedGroup([..._.uniq(temp)]);
  };

  const onSelectAllGroups = (e: any) => {
    if (e.target.checked) {
      if (flatGroups && flatGroups.length > 0) {
        let data: any[] = [...flatGroups.map((e) => e._id)];
        setSelectedGroup([..._.uniq(data)]);
      }
    } else {
      setSelectedGroup([]);
    }
  };

  const renderFlatGroups = (groups: any) => {
    return (
      groups &&
      groups?.length > 0 && (
        <div>
          {groups.map(
            (g: any, index: any) =>
              (isShown(g) || g.depth === 0) && (
                <div key={g._id}>
                  {showRenameModal && addNewTopLevelModule && index === 0 && (
                    <div className="group-selected d-flex groups-item tree-p add-new-group-row">
                      <input
                        type="checkbox"
                        className="mr-2"
                        // checked={true}
                        defaultChecked
                        // onChange={(e) => onSelectKnowledgeGroup(e, g._id)}
                      />
                      <div className="input-edit-group d-flex flex-1 justify-content-between align-items-center bg-white">
                        <input
                          type="text"
                          className="w-100 pr-5"
                          // defaultValue={editGroup.name}
                          value={moduleName || ""}
                          onChange={changeHandler}
                          autoFocus
                          // onBlur={() => {
                          //   if (
                          //     !moduleName &&
                          //     moduleName?.trim().length === 0
                          //   ) {
                          //     updateModalHandler();
                          //   }
                          // }}
                        />
                        <div className="edit-actions">
                          <img
                            id="add-new-knowledge-btn"
                            src={singleGreenCheck}
                            alt="confirm"
                            onClick={() => {
                              if (moduleName && moduleName.trim().length > 0) {
                                addKnowledgeGroup();
                              }
                            }}
                            className="mr-2"
                          />
                          <img
                            id="remove-add-knowledge-bar"
                            src={redCross}
                            alt="discard"
                            onClick={() => updateModalHandler()}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={`groups-item d-flex justify-content-between align-items-center tree-p tree-p-${
                      g.depth
                    } ${
                      selectedGroup &&
                      selectedGroup.find((e: any) => e === g._id)
                        ? "group-selected"
                        : ""
                    }`}
                  >
                    <div>
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={
                          selectedGroup && selectedGroup.indexOf(g._id) >= 0
                        }
                        onChange={(e) => onSelectKnowledgeGroup(e, g._id)}
                      />
                    </div>
                    <div className="d-flex flex-1">
                      {isShowExpand(g) && (
                        <img
                          src={isExpanded(g._id) ? expandIcon : collIcon}
                          alt="collapse/expand"
                          onClick={() => toggleGroup(g)}
                        />
                      )}
                      <div
                        className="d-flex flex-1 pl-3"
                        onClick={(e: any) => {
                          if (e?.detail === 2) {
                            setEditGroup("");
                            setEditGroup(g);
                          }
                        }}
                      >
                        {g._id === editGroup._id ? (
                          <div className="input-edit-group d-flex flex-1 justify-content-between align-items-center bg-white">
                            <input
                              type="text"
                              className="w-100 pr-5"
                              defaultValue={editGroup.name}
                              onChange={(e) =>
                                setEditGroup({
                                  ...editGroup,
                                  name: e.target.value,
                                })
                              }
                            />
                            <div className="edit-actions">
                              <img
                                src={singleGreenCheck}
                                alt="confirm"
                                onClick={() => {
                                  updateGroup(editGroup, index);
                                }}
                                className="mr-2"
                              />
                              <img
                                src={redCross}
                                alt="discard"
                                onClick={() => setEditGroup({})}
                              />
                            </div>
                          </div>
                        ) : (
                          g.name
                        )}
                      </div>
                    </div>
                    <button
                      className="btn p-0 add-group"
                      onClick={(e: any) => {
                        setTimeout(() => {
                          if (isShowExpand(g) && !isExpanded(g._id)) {
                            toggleGroup(g);
                          }
                          updateParentHandler(
                            null,
                            g._id ? g._id : "",
                            false,
                            g._id,
                            -1
                          );
                        }, 200);
                      }}
                    >
                      <img
                        className="add-group"
                        src={addKnowledgeGroupImg}
                        alt="Add Knowledge"
                      />
                    </button>
                  </div>

                  {showRenameModal && g._id === clickedModule && (
                    <div className="group-selected d-flex groups-item tree-p add-new-group-row">
                      <input
                        type="checkbox"
                        className="mr-2"
                        // checked={true}
                        defaultChecked
                        // onChange={(e) => onSelectKnowledgeGroup(e, g._id)}
                      />
                      <div className="input-edit-group d-flex flex-1 justify-content-between align-items-center bg-white">
                        <input
                          type="text"
                          className="w-100 pr-5"
                          // defaultValue={editGroup.name}
                          value={moduleName || ""}
                          onChange={changeHandler}
                          autoFocus
                        />
                        <div className="edit-actions">
                          <img
                            id="add-new-knowledge-btn"
                            src={singleGreenCheck}
                            alt="confirm"
                            onClick={() => {
                              if (moduleName && moduleName.trim().length > 0) {
                                addKnowledgeGroup();
                              }
                            }}
                            className="mr-2"
                          />
                          <img
                            id="remove-add-knowledge-bar"
                            src={redCross}
                            alt="discard"
                            onClick={() => updateModalHandler()}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
          )}
          <div ref={newGroupRef} />
        </div>
      )
    );
  };

  return (
    <section className="page-mid-wraper h-without-foter">
      <ConfirmationModal
        message={t(
          "Are you sure you want to delete the selected knowledge group(s) and any nested knowledge group(s)? This action cannot be undone."
        )}
        size={"md"}
        show={deleteModuleModalShow}
        selectedRow={deletedModuleId}
        onHide={deleteModuleModalHandler}
        confirmHandler={confirmDeleteModuleHandler}
        headerMessage={t("Confirm Deletion?")}
      />

      {/* <UpdateModuleModal
        show={showRenameModal}
        onHide={updateModalHandler}
        name={selectedModuleName}
        parent_id={selectedParentId}
        editMode={moduleEditMode}
        confirmHandler={confirmRenameHandler}
        moduleDepth={moduleDepth}
        isLevel                          
      /> */}
      <TreeGroupsModal
        groups={flatGroups}
        isOpen={isOpenMove && flatGroups && flatGroups.length > 0}
        hide={() => setIsOpenMove(false)}
        disableGroup={selectedGroup}
        onSave={(moveToId: any) => {
          setIsOpenMove(false);
          moveGroup(selectedGroup, moveToId);
        }}
      />
      <Container>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn p-0 btn-back"
              onClick={() => {
                history.goBack();
              }}
            >
              <Image src={backblue} /> {t("Return to previous page")}
            </button>
            <h1 className="mb-2">{t("Manage Knowledge Group")}</h1>
            <h2 className="mb-32 font-weight-normal">
              {t("Add, edit, or remove your knowledge groups here")}
            </h2>
            <div className="knowledge-viewby">
              <PermissionWrapper>
                <div className="d-flex mb-2 justify-content-between mb-3">
                  <div>
                    <button
                      className={cx("btn btn-link  py-0 font-weight-normal", {
                        "clr-blue": selectedGroup && selectedGroup.length > 0,
                      })}
                      disabled={
                        !selectedGroup ||
                        (selectedGroup && selectedGroup.length <= 0)
                      }
                      onClick={() => setIsOpenMove(true)}
                    >
                      <img
                        src={
                          !selectedGroup ||
                          (selectedGroup && selectedGroup.length <= 0)
                            ? blackMoveIcon
                            : moveIcon
                        }
                        className="mr-2"
                        alt={t("Delete")}
                        width="16"
                        height="16"
                      />
                      {t("Move Selected")}
                    </button>
                    <button
                      className={cx("btn btn-link  py-0 font-weight-normal", {
                        "clr-blue":
                          !!selectedGroup &&
                          selectedGroup.length > 0 &&
                          !isNestDisable,
                      })}
                      disabled={
                        selectedGroup &&
                        (selectedGroup.length <= 0 || isNestDisable)
                      }
                      onClick={() => {
                        moveGroup(selectedGroup, moveToIdNest);
                      }}
                    >
                      <img
                        src={
                          selectedGroup &&
                          (selectedGroup.length <= 0 || isNestDisable)
                            ? NestDisable
                            : nestImg
                        }
                        className="mr-2"
                        alt={t("Move Down")}
                        width="16"
                        height="16"
                      />
                      {t("Nest Selected")}
                    </button>
                    <button
                      className={cx("btn btn-link  py-0 font-weight-normal", {
                        "clr-blue":
                          !!selectedGroup &&
                          selectedGroup.length > 0 &&
                          !isUnnestDisable,
                      })}
                      disabled={
                        selectedGroup &&
                        (selectedGroup.length <= 0 || isUnnestDisable)
                      }
                      onClick={() => {
                        moveGroup(selectedGroup, moveToIdUnnest);
                      }}
                    >
                      <img
                        src={
                          selectedGroup &&
                          (selectedGroup.length <= 0 || isUnnestDisable)
                            ? unnestDisable
                            : unnestImg
                        }
                        className="mr-2"
                        alt={t("Move Down")}
                        width="16"
                        height="16"
                      />
                      {t("Un-nest Selected")}
                    </button>
                    <button
                      className={cx("btn btn-link  py-0 font-weight-normal", {
                        "clr-red": selectedGroup && selectedGroup.length > 0,
                      })}
                      disabled={selectedGroup && selectedGroup.length <= 0}
                      onClick={() => {
                        setDeletedModuleId(selectedGroup);
                        setDeleteModuleModalShow(true);
                      }}
                    >
                      <img
                        src={
                          selectedGroup && selectedGroup.length <= 0
                            ? deleteDisable
                            : deleteRed
                        }
                        className="mr-2"
                        alt={t("Delete")}
                        width="16"
                        height="16"
                      />
                      {t("Delete Selected")}
                    </button>
                  </div>
                </div>
              </PermissionWrapper>

              <div className="p-0 mb-4">
                <div className="d-flex justify-content-between align-items-center px-3 py-3 bg-grey-head manage-knowledge-header">
                  <h3 className="mb-0 font-1">{t("Knowledge Group Name:")}</h3>
                  <button
                    className="btn p-0 add-group"
                    onClick={(e: any) => {
                      updateParentHandler(null, null, false, "", -1);
                    }}
                  >
                    <img src={addKnowledgeGroupImg} alt="Add Knowledge" />
                  </button>
                </div>
                <div>
                  <div className="groups-tree  scroll-wrap ">
                    <div className="groups-item tree-p">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={selectedGroup.length === flatGroups.length}
                        onChange={(e) => onSelectAllGroups(e)}
                      />
                      <label htmlFor="select-all" className="mb-0">
                        {t("Select all")}
                      </label>
                    </div>
                    {renderFlatGroups(flatGroups)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
