import { getAgentFromStorage } from "./../utils/appUtils";
import ApiUtil from "../utils/apiUtil";
import axios from "axios";

type Type = any;

export default class CommonService {
  /**
   * Used to fetch organization states including current agent details.
   *
   * @returns {Promise<Type>}
   * @memberof CommonService
   */
  async getOrganizationStates(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `organisation/states`, // Need token
      method: "GET",
    });
    return res;
  }

  /**
   * Used to update states including flow setup
   *
   * @returns {Promise<Type>}
   * @memberof CommonService
   */
  async updateOrganizationFlow(data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `organisation/states`, // Need token
      method: "PATCH",
      data: {
        ...data,
      },
    });
    return res;
  }

  /**
   * Api used to fetch all sticker sets at once
   *
   * @returns {Promise<Type>}
   * @memberof CommonService
   */
  async getStickerSets(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `media/stickersets`, // Need token
      method: "GET",
    });
    return res;
  }

  /**
   * Api used to fetch all stickers based on sticker set name
   *
   * @returns {Promise<Type>}
   * @memberof CommonService
   */
  async getStickerFromName(name: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `media/stickersets/${name}/stickers`, // Need token
      method: "GET",
    });
    return res;
  }

  async getPaymentInfo(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `payment/licenses?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getProds(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `payment/product-definitions?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  /**
   * API used to get current organization user details
   *
   * param {string} name
   * returns {Promise<Type>}
   * memberof CommonService
   */
  async getOrganizationCurrentUserDetails(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `organisation/users/current?agent_id=${getAgentFromStorage()}`, // Need token
      method: "GET",
    });
    return res;
  }

  async getAllNotifications(page_size = 1000): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `notification/notifications?mail=inbox&page_size=${page_size}`, // Need token
      method: "GET",
    });
    return res;
  }

  async getAllNotificationsSummary(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `notification/notifications/summary?agent_id=${getAgentFromStorage()}`, // Need token
      method: "GET",
    });
    return res;
  }

  async getSnackbarNotification(page_size = 100): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `notification/notifications?page_size=${page_size}&shows_portal_snackbar=true`,
      method: "GET",
    });
    return res;
  }

  async setNotificationSnackbar(
    notificationID: string,
    shows_portal_snackbar: boolean
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `notification/notifications/${notificationID}`, // Need token
      method: "PATCH",
      data: {
        shows_portal_snackbar: shows_portal_snackbar,
      },
    });
    return res;
  }

  async turnOffNewNotificationsStatus(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `notification/notifications/summary?agent_id=${getAgentFromStorage()}`, // Need token
      method: "PATCH",
    });
    return res;
  }

  async readNotification(
    notificationID: string,
    is_read: string
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `notification/notifications/${notificationID}`, // Need token
      method: "PATCH",
      data: {
        is_read: is_read === "true" ? false : true,
      },
    });
    return res;
  }

  async deleteNotification(notificationID: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `notification/notifications/${notificationID}`, // Need token
      method: "DELETE",
    });
    return res;
  }

  async getChatBotLink(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `widget/public-chat-urls?agent_id=${getAgentFromStorage()}`, // Need token
      method: "POST",
    });
    return res;
  }

  async getAgentFeatures(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `feature/agent-features?agent_id=${getAgentFromStorage()}`, // Need token
      method: "GET",
    });
    return res;
  }

  async getUsersRegistrationData(token: string, isIte: boolean): Promise<Type> {
    let res;
    const windowObj: any = window;
    const baseURL = `${windowObj.APP_API_URL}`;
    if (isIte) {
      res = await axios.get(`${baseURL}course/ite/registration/${token}`);
    } else {
      res = await axios.get(
        `${baseURL}organisation/users/registration/${token}`
      );
    }
    return res;
  }

  async generatePassword(
    email: string,
    password: string,
    token: string,
    isIte: boolean
  ): Promise<Type> {
    let res;
    const windowObj: any = window;
    const baseURL = `${windowObj.APP_API_URL}`;
    if (isIte) {
      res = await axios.put(`${baseURL}course/ite/registration/${token}`, {
        email,
        password,
      });
    } else {
      res = await axios.put(
        `${baseURL}organisation/users/registration/${token}`,
        {
          email,
          password,
        }
      );
    }
    return res;
  }

  async register(data: any, isAws: boolean = false): Promise<Type> {
    const windowObj: any = window;
    let res = {};
    let copiedData = { ...data };
    if(copiedData && (!copiedData.subscription_id || copiedData.subscription_id === null)) {
      delete copiedData.subscription_id
    }
    try {
      if(isAws) {
        res = await axios.post(
          `${
            windowObj.REACT_DEVELOPMENT_MODE === "production"
              ? "https://oneauth.noodlefactory.ai/awsmarketplace/signups"
              : "https://oneauth.staging.noodlefactory.ai/awsmarketplace/signups"
          }`,
          copiedData
        );
      } else {
        res = await axios.post(
         `${
           windowObj.REACT_DEVELOPMENT_MODE === "production"
             ? "https://oneauth.noodlefactory.ai/hub/signups"
             : "https://oneauth.staging.noodlefactory.ai/hub/signups"
         }`,
         copiedData
       );
      }
      return res;
    } catch (error) {
      throw error;
    }
  }

  async getLang(): Promise<Type> {
    const windowObj: any = window;
    const res = await ApiUtil.SendAsync<any>({
      url: `${
        windowObj.REACT_DEVELOPMENT_MODE === "production"
          ? "https://oneauth.noodlefactory.ai/"
          : "https://oneauth.staging.noodlefactory.ai/"
      }hub/recommended-language?agent_id=${getAgentFromStorage()}`, // Need token
      method: "GET",
    });
    return res;
  }

  async resendEmail(signupId: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `payment/signups/${signupId}/resend-email-verification`,
      method: "POST",
    });
    return res;
  }

  async resendCurentUserEmail(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `organisation/users/current/verifications?agent_id=${getAgentFromStorage()}`, // Need token
      method: "POST",
    });
    return res;
  }

  async checkIfEMailHasAlreadyRegistered(email: string): Promise<Type> {
    const windowObj: any = window;
    const res = await ApiUtil.SendAsync<any>({
      url: `${
        windowObj.REACT_DEVELOPMENT_MODE === "production"
          ? "https://oneauth.noodlefactory.ai/"
          : "https://oneauth.staging.noodlefactory.ai/"
      }hub/validate-signup?agent_id=${getAgentFromStorage()}`, // Need token
      method: "POST",
      data: {
        email,
      },
    });
    return res;
  }
}
