import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { MATCHED_UNMATCHED_QUESTIONS } from "../../../../config";
import { matchedAndUnmatchedSummary } from "../../../../store/dashboard/actions";
import history from "../../../../utils/history";
import InfoIconDetails from "../InfoIconDetails/InfoIconDetails";

import { useTranslation } from "react-i18next";

interface Props {
  filtersData?: {
    filter_from_date?: string;
    filter_to_date?: string;
    channel?: string;
  };
}

function MatchedQuestions({ filtersData }: Props): ReactElement {
  const { t } = useTranslation("translation");
  const [loading, setLoading] = useState(false);
  const [matchedData, setunmatchedData] = useState(null);
  const dispatch = useDispatch();
  const usehistory = useHistory();
  useEffect(() => {
    if (
      loading ||
      !filtersData.filter_from_date ||
      !filtersData.filter_to_date
    ) {
      return;
    }

    const fetchSessionData = async (filtersData: any) => {
      setLoading(true);
      try {
        const data: any = await dispatch(
          matchedAndUnmatchedSummary(filtersData)
        );
        await setunmatchedData(data);
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
      }
    };
    fetchSessionData(filtersData);
  }, [filtersData]);

  return (
    <div
      className="col-sm-6 col-md-4 col-lg-2"
      onClick={() =>
        usehistory.push(MATCHED_UNMATCHED_QUESTIONS, {
          activeTab: "matched",
        })
      }
    >
      <div className="graph-box">
        <h3>
          {t("Matched Questions")}
          <InfoIconDetails
            message={t(
              "Total number of times the chatbot could find the answer to the learner question."
            )}
          />
        </h3>
        <div className="row">
          <div className="col-4">
            {/* <h2>{weeklyUsersCount?.summary?.current_week_count}</h2> */}
            {!loading && (
              <h2>
                {
                  matchedData?.report_data?.rows.find(
                    (e: any) => e.question_type === "matched"
                  )?.count || 0
                }
              </h2>
            )}
            {loading && (
              <div className="spinner-border spinner-border-sm text-primary"></div>
            )}
            {/* {weeklyUsersCount && (
                <div
                  className={cx({
                    'growth-percent': true,
                    'growth-up':
                      Math.sign(weeklyUsersCount?.summary?.growth) > -1,
                    'growth-down':
                      Math.sign(weeklyUsersCount?.summary?.growth) < 0,
                  })}
                >
                  {weeklyUsersCount
                    ? weeklyUsersCount?.summary?.growth * 100
                    : 0}
                  %
                </div>
              )} */}
          </div>
          <div className="col-8">
            <div style={{ width: "100%", height: "50px" }}>
              {/* {matchedData &&
                matchedData.dailyReturningUsers &&
                matchedData &&
                matchedData.dailyReturningUsers.length && (
                  <LineChartCompact
                    data={
                      matchedData.dailyReturningUsers
                        ? matchedData.dailyReturningUsers
                        : []
                    }
                  />
                )} */}

              {/* {weeklyUsersCount &&
                  weeklyUsersCount.rows &&
                  weeklyUsersCount.rows.length > 0 && (
                    <LineChartCompact data={weeklyUsersCount.rows} />
                  )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchedQuestions;
