import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import backblue from "../../../assets/images/arrow-left.svg";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  getAllButtonOptions,
  getAllUserGroups,
} from "../../../store/questions/actions";
import {
  deleteQuizQuestionBankById,
  generateQuestionBank,
  getAllQuestionBank,
  getAllQuestionBankNoPaging,
  getQuizFeatures,
  getQuizQuestionBankById,
  getQuizQuestionBankGenerateTags,
  questionBankLinkDocumentSections,
  updateKeywordsOfQuestion,
  updateQuestionFromQuestionBank,
  updateQuiz,
} from "../../../store/quizzes/actions";
import { HelperService } from "../../../services/helper.service";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Container as DragContainer, Draggable } from "react-smooth-dnd";
import ConfirmationModal from "../../../components/UI/ConfirmationModal/ConfirmationModal";
import UpdateModuleModal from "../../../components/UI/KnowledgeDatatableComponent/UpdateModuleModal";
import { getButtonOptions } from "../../../store/questions/selector";
import QuizQuestionEditor from "../QuizQuestionEditor/QuizQuestionEditor";
import "../styles.scss";
import {
  EDIT_QUESTIONS_BANK,
  GET_QUIZ_BYID,
  IMPORTQUESTIONBANK_PREVIEWS_QUESTIONS,
  QUIZZES,
  QUIZZES_BETA,
} from "../../../config";
import {
  bulkAssignModules,
  getAllDocumentsNewFlow,
} from "../../../store/knowledge/actions";
import MoveSelectedModal from "../../../components/UI/KnowledgeDatatableComponent/MoveSelectedModal";
import { getQuizByQuizId } from "../../../store/dashboard/actions";
import AddTags from "../../../components/UI/AddTags/AddTags";

function CreateEditQuestionBank(props: any) {
  const { t } = useTranslation();
  const helper = new HelperService();
  const isMgs = process.env.REACT_APP_CISCO === "mgs";
  const useLocatio = useLocation();
  const emptyQuestion: any = {
    is_saved: false,
    type: "radio",
    text: "",
    score: 1,
    options: [
      {
        text: "",
        is_correct: false,
      },
      {
        text: "",
        is_correct: false,
      },
    ],
  };

  const mainButtonDropdownData: any = {
    "back-to-chat": {
      value: "back-to-chat",
      label: t("Go back to chat"),
    },
    "jump-to-question": {
      value: "jump-to-question",
      label: t("Go to a question"),
    },
  };

  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const buttonOptionsSelector = useSelector(getButtonOptions);
  const [IsLoading, setIsLoading] = useState(false);
  const [transformedQuestions, setTransformedQuestions] = useState<any>({});
  const [savedQuestions, setSavedQuestions] = useState([]);
  const [savedQuestionsBank, setSavedQuestionsBank] = useState([]);

  const [quizDetail, setQuizDetail] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [addNew, setAddNew] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [invalidQuestions, setInvalidQuestions] = useState([]);
  const [quizQuestionDropdownData, setQuizQuestionDropdownData] = useState([]);
  const [buttonOptions, setButtonOptions] = useState([]);
  const [topicDropdownData, setTopicDropdownData] = useState([]);
  const [questionIdLabelMap, setQuestionIdLabelMap] = useState({});
  const [showScore, setShowScore] = useState(true);
  const [quizTopic, setQuizTopic] = useState({});
  const [editQuestions, setEditQuestions] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [isMultiAct, setIsMultiAct] = useState(false);
  const [totalQuizScore, setTotalQuizScore] = useState(0);
  const [isWelcomeEnabled, setIsWelcomeEnabled] = useState(true);
  const [selectedQuizLabels, setSelectedQuizLabels] = useState([]);
  const [enableLearning, setEnableLearning] = useState(false);
  const [updatingQuizQuestionsFromParent, SetUpdatingQuizQuestionsFromParent] =
    useState(false);
  const [redirect, setRedirect] = useState("");
  const [extractQuestionClicked, SetExtractQuestionClicked] = useState(false);
  const [hasAnyError, setHasAnyError] = useState([]);
  const [componentHasError, setComponentHasError] = useState([]);
  const [componentTruthy, setComponentTruthy] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);
  const [openModal, SetOpenModal] = useState(false);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [addQuestionsQuizSubmitted, setAddQuestionsQuizSubmitted] =
    useState(false);

  const [selectedMove, setSelectedMove] = useState(null);
  const [showMoveSelectModal, setShowMoveSelectModal] = useState(false);
  const [allDocuments, setAllDocuments] = useState([]);
  const [showTagsModal, setShowTagsModal] = React.useState(false);
  const ref = useRef([]);
  const backupRef = useRef([]);
  const isMultiEdit =
    useLocatio.pathname === EDIT_QUESTIONS_BANK ||
    props.location.state?.createFromTestFile;
  const isImportForQuiz = !!props.location.state?.quizId;

  useEffect(() => {
    if (buttonOptionsSelector && buttonOptionsSelector.length) {
      setButtonOptions([...buttonOptionsSelector]);
    }
  }, [buttonOptionsSelector]);

  const getQuestions = async () => {
    const res: any = await dispatch(
      getAllQuestionBankNoPaging({
        ids: props.location.state?.questionList,
        sort_field: "created_at",
        sort_order: "asc",
      })
    );
    setEditQuestions(res);
    backupRef.current = res;
    transformAllQuestions(res);
    generateQuestionIdLabelMap(res);
    transformQuizDropdownData(res);
    setSavedQuestions(res);
  };

  useEffect(() => {
    getTopics();
    if (params.questionId && !isMultiEdit) {
      getQuestionOfQuestioBank(params.questionId);
    } else if (props.location.state?.questionList) {
      //multiple questions edit
      getQuestions();
    }
    dispatch(getAllButtonOptions(true));
    dispatch(getAllUserGroups());
    getLearningFeature();
    getAllDocumentsHandler();
    if (
      typeof props.location.state !== "undefined" &&
      props.location.state.selectedFileType &&
      props.location.state.quizArray &&
      props.location.state.quizArray.questions
    ) {
      backupRef.current = props.location.state.quizArray.questions;
      transformAllQuestions(props.location.state.quizArray.questions);
      generateQuestionIdLabelMap(props.location.state.quizArray.questions);
      transformQuizDropdownData(props.location.state.quizArray.questions);
      setSavedQuestions(props.location.state.quizArray.questions);
      if (!props.location.state?.createFromTestFile) {
        SetOpenModal(true);
      }
    }
  }, []);

  useEffect(() => {
    if (topicDropdownData && topicDropdownData.length > 0 && quizDetail) {
      if (quizDetail.topic_id) {
        setQuizTopic({
          value: quizDetail.topic_id,
          label: getLevelNames(topicDropdownData, quizDetail.topic_id),
        });
      }
    }
  }, [topicDropdownData, quizDetail]);

  const getLearningFeature = async () => {
    try {
      const response: any = await dispatch(getQuizFeatures());
      if (response.features && response.features.length !== 0) {
        for (let i = 0; i < response.features.length; i++) {
          if (response.features[i].name === "learning") {
            setEnableLearning(true);
            break;
          }
        }
      } else {
        setEnableLearning(false);
      }
    } catch (error) {
      setEnableLearning(false);
    }
  };

  const getTopics = async () => {
    try {
      // const response: any = await dispatch(getAllLevels());
    } catch (error) {}
  };

  const getQuestionOfQuestioBank = async (quizId: string) => {
    try {
      setIsLoading(true);
      const { quiz_question }: any = await dispatch(
        getQuizQuestionBankById(quizId)
      );
      let quizDetail: any = quiz_question;
      quizDetail = {
        ...quizDetail,
        questions: [quizDetail],
      };

      if (quizDetail.questions && quizDetail.questions.length > 0) {
        backupRef.current = quizDetail.questions;
        transformAllQuestions(quizDetail.questions);
        generateQuestionIdLabelMap(quizDetail.questions);
        transformQuizDropdownData(quizDetail.questions);
        setSavedQuestions(quizDetail.questions);
      } else {
        addNewQuestion();
      }

      if (quizDetail.questions) {
        computeTotalScore(quizDetail.questions);
      }

      if ("is_welcome_enabled" in quizDetail) {
        setIsWelcomeEnabled(quizDetail.is_welcome_enabled);
      }

      if (quizDetail.labels) {
        let temp = [];
        temp = quizDetail.labels.map((item: any) => ({
          label: item,
          value: item,
        }));
        setSelectedQuizLabels(temp);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  const getAllDocumentsHandler = async () => {
    const filterMgs = isMgs
      ? { type: "summary,ebook,other,lesson_slides" }
      : {};
    try {
      setIsLoading(true);
      const response: any = await dispatch(
        getAllDocumentsNewFlow(
          {
            page: 1,
            page_size: 100000,
            ...filterMgs,
          },
          false,
          false
        )
      );
      setAllDocuments(response.documents);
      setIsLoading(false);
    } catch (error) {}
  };

  // get module names
  const getLevelNames = (topicDropdownData: any, level: any) => {
    let moduleAsString = [];
    // levels.forEach(level => {
    const moduleObj = topicDropdownData.find((m: any) => m.value === level);
    if (moduleObj) {
      moduleAsString.push(moduleObj.label);
    }
    // })
    return moduleAsString.length === 0
      ? "Uncategorised"
      : moduleAsString.join(", ");
  };

  const transformAllQuestions = (questionList: any) => {
    let temp: any = {};

    // const subQuestions = questionList.filter((q: any) => !q.parent_id);
    // const mainQuestions = questionList.filter((q: any) => q.parent_id);
    // const questions = [...mainQuestions, ...subQuestions];
    questionList.forEach((question: any, index: number) => {
      question.subquestions = question?.sub_questions
        ? question?.sub_questions
        : [];
      if (question.parent_id && temp[question.parent_id]) {
        const isExist = temp[question.parent_id].subquestions.find(
          (s: any) => s.id === question.id
        );
        if (!isExist) {
          temp[question.parent_id] = {
            ...temp[question.parent_id],
            subquestions:
              "subquestions" in temp[question.parent_id]
                ? temp[question.parent_id].subquestions.concat([question])
                : [].concat([question]),
          };
        }
      } else {
        temp[question.id ? question.id : uuidv4()] = question;
      }
    });
    setTransformedQuestions(temp);
  };

  const computeTotalScore = (questions: any) => {
    let totalScore = 0;

    questions.forEach((question: any) => {
      if (question.score) {
        totalScore += question.score;
      }
    });

    setTotalQuizScore(totalScore);
  };

  const transformQuizDropdownData = (questions: any) => {
    let quizDropdown: any = [];

    questions.forEach((item: any, index: number) => {
      if (item && item.text) {
        quizDropdown.push({
          value: item.id,
          label: <div dangerouslySetInnerHTML={{ __html: item.text }}></div>,
        });
      }
    });

    setQuizQuestionDropdownData(quizDropdown);
  };

  const generateQuestionIdLabelMap = (questions: any) => {
    let tempMap: any = {};
    questions.forEach((question: any, index: number) => {
      tempMap[question.id] = {
        label: <div dangerouslySetInnerHTML={{ __html: question.text }}></div>,
        options: question.options,
        type: question.type,
      };
    });

    setQuestionIdLabelMap(tempMap);
  };

  ///////////////////// QUIZ SETTINGS ////////////////////
  const addIdToConditions = (data: any) => {
    let temp: any = [];
    data.forEach((item: any, index: number) => {
      if (!item.id) {
        temp[index] = {
          ...item,
          id: uuidv4(),
        };
      } else {
        temp[index] = { ...item };
      }
    });
    return temp;
  };
  ///////////////////// QUIZ SETTINGS ////////////////////

  ///////////////////// QUIZ LEVEL ////////////////////
  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const [newModuleAdded, setNewModuleAdded] = React.useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedParentId, setSelectedParentId] = React.useState(null);
  const [selectedModuleName, setSelectedModuleName] = React.useState("");
  const [moduleEditMode, setModuleEditMode] = React.useState(false);
  const [moduleDepth, setModuleDepth] = React.useState(0);

  const updateModalHandler = () => {
    setNewModuleAdded(false);
    setShowRenameModal(!showRenameModal);
  };

  const confirmRenameHandler = async (data: any) => {
    // getAllYearsFun(false);
    setNewModuleAdded(true);
    setModuleEditMode(false);
    updateModalHandler();
  };

  ///////////////////// QUIZ LEVEL ////////////////////

  ///////////////////// QUIZ EDITOR ////////////////////
  const setErrorIfAny = async (
    temp: any,
    questionId: any,
    isGenerateKeywords: any,
    isLinkSections: any,
    questionNumber: any
  ) => {
    const questions: any[] = [...hasAnyError];
    questions.push(temp);
    ref.current.push(temp);
    setHasAnyError(questions);
    const hasInvalidOption = _.uniqBy(ref.current, "id")
      .filter(function (el: any) {
        return el;
      })
      .filter(function (el: any) {
        return el.invalid;
      });

    // console.log("-------------------------------");
    // console.log(
    //   temp,
    //   questionId,
    //   questionNumber
    // );
    // console.log(
    //   "*********************",
    //   ref.current.length === Object.keys(transformedQuestions).length && hasInvalidOption.length === 0,
    //   ref.current.length
    // );

    if (
      ref.current.length === Object.keys(transformedQuestions).length &&
      hasInvalidOption.length === 0 &&
      !isMultiEdit
    ) {
      await updateQuizQuestions(
        _.uniqBy(ref.current, "id"),
        null,
        false, // isGenerateKeywords
        false, // isLinkSections,
        questionId
      );
    } else if (isMultiEdit && hasInvalidOption.length === 0) {
      await updateQuizQuestions(
        _.uniqBy(ref.current, "id"),
        null,
        false, // isGenerateKeywords
        false, // isLinkSections,
        questionId
      );
    }

    return;
  };

  const updateQuizQuestionsFromParent = async () => {
    ref.current = [];
    setInternalLoading(true);
    setQuizSubmitted(true);
    setHasAnyError([]);
    setComponentHasError([]);
    setComponentTruthy(true);
    SetUpdatingQuizQuestionsFromParent(true);
    SetExtractQuestionClicked(false);
    return;
  };

  const updateQuizQuestionFromParent = async (questions: any) => {
    if (extractQuestionClicked) {
      return;
    }
    let operation: any = "";
    let toUpdateQuestions: any = [];
    let tempSavedQuestions: any = [...questions];
    tempSavedQuestions.forEach((question: any) => {
      if (question !== null) {
        toUpdateQuestions.push(question);
      }
    });

    computeTotalScore(toUpdateQuestions);
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;
    setInternalLoading(true);
    setIsLoading(true);

    try {
      console.log("INNN");
      const response: any = await dispatch(
        generateQuestionBank({
          questions: toUpdateQuestions,
        })
      );
      setIsLoading(false);
      transformAllQuestions(response.quiz.questions);
      setComponentTruthy(false);
      setInternalLoading(false);

      if (isMgs) {
        history.push(QUIZZES);
      } else {
        history.push(QUIZZES_BETA + "?tab=bank");
      }
    } catch (error) {
      setInternalLoading(false);
      setComponentTruthy(false);
    }

    setInternalLoading(false);
    setIsLoading(false);
  };

  const updateQuizQuestions = async (
    toUpdateQuestions: any,
    operation: any = null,
    isGenerateKeywords: boolean = false,
    isLinkSections: boolean = false,
    qId: string = ""
  ) => {
    const invalidQuestionsPresent = invalidQuestions.filter(
      (d) => d.invalid
    ).length;

    if (invalidQuestionsPresent > 0) {
      return;
    }

    computeTotalScore(toUpdateQuestions);
    let questionId = params.questionId || (isMultiEdit && qId) || null;
    setIsLoading(true);
    try {
      if (questionId && isGenerateKeywords) {
        const keywords: any = await dispatch(
          getQuizQuestionBankGenerateTags(questionId)
        );
      }

      if (questionId && isLinkSections) {
        const keywords: any = await dispatch(
          questionBankLinkDocumentSections(questionId)
        );
      }

      if (!isLinkSections) {
        //!isGenerateKeywords &&
        let subQues: any = [];
        toUpdateQuestions.forEach((question: any) => {
          if (question?.type === "group") {
            subQues = [...subQues, ...question?.data];
          }
          delete question?.data;
        });
        toUpdateQuestions = _.uniqBy([...toUpdateQuestions, ...subQues], "id");
        const response: any = await dispatch(
          questionId
            ? updateQuestionFromQuestionBank(
                questionId,
                {
                  questions: toUpdateQuestions,
                },
                !props.location.state?.createFromTestFile
              )
            : !isMultiEdit &&
                generateQuestionBank({
                  questions: toUpdateQuestions,
                })
        );
        if (
          props.location.state?.createFromTestFile &&
          useLocatio.pathname === IMPORTQUESTIONBANK_PREVIEWS_QUESTIONS
        ) {
          // setSavedQuestionsBank([...savedQuestions, response.quiz_question])

          setSavedQuestionsBank((preSavedQuestions) => {
            if (
              !preSavedQuestions.find(
                (q) => q.id === response?.quiz_question.id
              )
            ) {
              return [...preSavedQuestions, response.quiz_question];
            }
          });

          // await saveQuestionsToQuiz([response.quiz_question])
        }
        if (!questionId && response) {
          const query = new URLSearchParams(useLocatio.search);
          const group: string = query.get("group");
          const nodes: string[] = response.quiz_questions.map((q: any) => q.id);
          group &&
            (await dispatch(bulkAssignModules(group, nodes, "question")));
        }

        if (props.location.state?.createFromTestFile) {
          if (redirect) {
            history.push(redirect);
            return;
          }
          if (useLocatio.pathname === IMPORTQUESTIONBANK_PREVIEWS_QUESTIONS) {
            // saveQuestionsToQuiz();
            // setTimeout(()=>{
            // const quizId = props.location.state?.quizId;
            // history.push(GET_QUIZ_BYID.replace(":quizId", quizId));
            // })
            return;
            // add to quizzz
          }

          history.push(IMPORTQUESTIONBANK_PREVIEWS_QUESTIONS, {
            questionList: savedQuestions.map((q: any) => q.id),
            selectedFileType: "customtemplates",
            createFromTestFile: true,
            quizId: props.location.state?.quizId,
            school: props.location.state?.school,
          });

          return;
        }
      }

      if (questionId && !isMultiEdit) {
        getQuestionOfQuestioBank(questionId);
      } else if (isMultiEdit) {
        getQuestions();
      }

      !questionId && isMgs && history.push(QUIZZES + "?tab=bank");
      !questionId && !isMgs && history.push(QUIZZES_BETA + "?tab=bank");
      //   setIsLoading(false);
      //   transformAllQuestions(response.quiz.quiz_questions);
    } catch (error) {}
  };

  const onQuizSave = async () => {
    let quizId = props.quizId ? props.quizId : props.match.params.quizId;
    let _conditions: any[] = [...conditions];
    if (_conditions) {
      _conditions.map((part: any) => {
        if (part.test.question_id) {
          part.test.question_id = part.test.question_id.toString();
        }
        if (part.test.option_id) {
          part.test.option_id = part.test.option_id.toString();
        }
        if (part.show.message === null) {
          part.show.message = "";
        }
        if (!part.test.min_score || part.test.min_score === null) {
          part.test.min_score = 0;
        }
        if (!part.test.max_score || part.test.max_score === null) {
          part.test.max_score = 0;
        }
        return part;
      });
    }

    let transformedLabels = [];

    transformedLabels = selectedQuizLabels
      ? selectedQuizLabels.map((item) => item.value)
      : [];
  };

  const onDelete = async () => {
    if (params.questionId) {
      try {
        await dispatch(deleteQuizQuestionBankById(params.questionId));
        setOpenDeleteConfirm(false);
        history.goBack();
      } catch (error) {
        setOpenDeleteConfirm(false);
      }
    } else {
      let tempQuestions = [...backupRef.current];
      tempQuestions.forEach((question, index) => {
        if (toBeDeleted === question.id) {
          tempQuestions.splice(index, 1);
        }
      });
      if (isMultiEdit) {
        if (isMultiAct) {
          selectedNodes.map(
            async (id: string) => await dispatch(deleteQuizQuestionBankById(id))
          );
          tempQuestions = tempQuestions.filter(
            (q) => !selectedNodes.includes(q.id)
          );
          setSelectedNodes([]);
        } else {
          await dispatch(deleteQuizQuestionBankById(toBeDeleted));
          setSelectedNodes(selectedNodes.filter((id) => id !== toBeDeleted));
        }
      }

      backupRef.current = tempQuestions;
      transformAllQuestions(tempQuestions);

      let tempSavedQuestions = [...savedQuestions];
      tempSavedQuestions.forEach((question, index) => {
        if (toBeDeleted === question.id) {
          tempSavedQuestions.splice(index, 1);
        }
      });

      setSavedQuestions(tempSavedQuestions);
      generateQuestionIdLabelMap(tempSavedQuestions);
      transformQuizDropdownData(tempSavedQuestions);
      let toUpdateQuestions: any = [];
      tempSavedQuestions.forEach((question) => {
        if (question !== null) {
          toUpdateQuestions.push(question);
        }
      });
      setOpenDeleteConfirm(false);
    }
  };

  const directQuestionDelete = (questionId: any) => {
    setAddNew(false);
    let tempQuestions = [...backupRef.current];
    tempQuestions.forEach((question, index) => {
      if (questionId === question.id) {
        tempQuestions.splice(index, 1);
      }
    });
    backupRef.current = tempQuestions;
    transformAllQuestions(tempQuestions);

    let tempSavedQuestions = [...savedQuestions];
    tempSavedQuestions.forEach((question, index) => {
      if (questionId === question.id) {
        tempSavedQuestions.splice(index, 1);
      }
    });
    setSavedQuestions(tempSavedQuestions);
    generateQuestionIdLabelMap(tempSavedQuestions);
    transformQuizDropdownData(tempSavedQuestions);

    let toUpdateQuestions: any[] = [];
    tempSavedQuestions.forEach((question) => {
      if (question !== null) {
        toUpdateQuestions.push(question);
      }
    });
    updateQuizQuestions(toUpdateQuestions, "directDelete");
  };

  const onQuestionSort = (data: any) => {
    backupRef.current = data;
    transformAllQuestions(data);

    let tempSavedQuestions = [...data];
    setSavedQuestions(tempSavedQuestions);
    generateQuestionIdLabelMap(tempSavedQuestions);
    transformQuizDropdownData(tempSavedQuestions);

    let toUpdateQuestions: any[] = [];
    tempSavedQuestions.forEach((question) => {
      if (question !== null) {
        toUpdateQuestions.push(question);
      }
    });
    updateQuizQuestions(toUpdateQuestions);
  };

  const onGroupQuestionSave = async (data: any, queIds: any) => {
    setAddNew(false);
    let tempQuestions = [...backupRef.current];
    data.forEach((questionToSave: any, mainIndex: any) => {
      tempQuestions.forEach((question, subIndex) => {
        if (queIds[mainIndex] === question.id) {
          tempQuestions[subIndex] = questionToSave;
        }
      });
    });

    backupRef.current = tempQuestions;
    transformAllQuestions(tempQuestions);

    let tempSavedQuestions = [...savedQuestions];
    data.forEach((questionToSave: any, mainIndex: any) => {
      tempSavedQuestions.forEach((question, subIndex) => {
        if (queIds[mainIndex] === question.id) {
          tempSavedQuestions[subIndex] = questionToSave;
        }
      });
    });
    setSavedQuestions(tempSavedQuestions);
    generateQuestionIdLabelMap(tempSavedQuestions);
    transformQuizDropdownData(tempSavedQuestions);

    let toUpdateQuestions: any[] = [];
    tempSavedQuestions.forEach((question) => {
      if (question !== null) {
        toUpdateQuestions.push(question);
      }
    });
    updateQuizQuestions(toUpdateQuestions);
  };

  const onSave = async (
    updatedQuestion: any,
    questionId: any,
    isGenerateKeywords: boolean = false,
    isLinkSections: boolean = false
  ) => {
    setAddNew(false);

    let tempQuestions = [...backupRef.current];
    tempQuestions.forEach((question, index) => {
      if (questionId === question.id) {
        tempQuestions[index] = updatedQuestion;
      }
    });
    backupRef.current = tempQuestions;
    transformAllQuestions(tempQuestions);

    let tempSavedQuestions = [...savedQuestions];
    tempSavedQuestions.forEach((question, index) => {
      if (question) {
        if (questionId === question.id) {
          tempSavedQuestions[index] = updatedQuestion;
        }
      }
    });
    setSavedQuestions(tempSavedQuestions);
    generateQuestionIdLabelMap(tempSavedQuestions);
    transformQuizDropdownData(tempSavedQuestions);

    let toUpdateQuestions: any[] = [];
    tempSavedQuestions.forEach((question) => {
      if (question !== null) {
        toUpdateQuestions.push(question);
      }
    });
    updateQuizQuestions(
      toUpdateQuestions,
      null,
      isGenerateKeywords,
      isLinkSections,
      questionId
    );
  };

  useEffect(() => {
    return () => {
      // cleanup
    };
  }, [ref]);

  const confirmDelete = (questionId: any) => {
    setToBeDeleted(questionId);
    setOpenDeleteConfirm(true);
  };

  const addNewQuestion = (type: any = null, parentId: any = null) => {
    let emptyOptions: any = [...emptyQuestion.options];
    emptyOptions.forEach((option: any, index: any) => {
      emptyOptions[index] = {
        ...option,
        id: uuidv4(),
      };
    });

    emptyQuestion.options = emptyOptions;

    let tempQuestions = [...backupRef.current];
    let newQ: any = { ...emptyQuestion };
    newQ.id = uuidv4();
    newQ.isNew = true;

    if (type === "sub") {
      newQ.parent_id = parentId;
    }
    tempQuestions.push(newQ);
    transformAllQuestions(tempQuestions);
    backupRef.current = tempQuestions;

    let tempSavedQuestions = [...savedQuestions];
    tempSavedQuestions.push(newQ);
    setSavedQuestions(tempSavedQuestions);
    // generateQuestionIdLabelMap(tempSavedQuestions);
    // transformQuizDropdownData(tempSavedQuestions);

    if (!type) {
      setAddNew(true);
    }
  };

  const transformQuestion = (question: any) => {
    let temp = {
      ...question,
      score: !question.score || question.score === null ? 1 : question.score,
    };
    if (question.options) {
      let opt: any[] = [];
      question.options.forEach((option: any, index: any) => {
        opt[index] = {
          ...option,
          id: option.id || uuidv4(),
          text: option.text,
          is_correct: option.is_correct,
        };
      });
      temp.options = opt;
    }
    return temp;
  };

  const transformButtonOptionsDropdown = (data: any) => {
    let dropdownData: any = [];
    data.forEach((item: any) => {
      item.value = item.reference;
      dropdownData.push(item);
    });
    return dropdownData;
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDrop = (dropResult: any) => {
    const { removedIndex, addedIndex, payload, element } = dropResult;
    if (addedIndex === undefined) {
      return;
    }
    const temp = reorder(backupRef.current, removedIndex, addedIndex);
    onQuestionSort(temp);
  };

  const getChildPayload = (index: any) => {
    return backupRef.current[index];
  };
  const addToSelectedRows = (event: any, id: string) => {
    let data: any[] = [...selectedNodes];
    if (event.target.checked) {
      const isPresent = data.indexOf(id);
      if (isPresent < 0) {
        data.push(id);
      }
    } else {
      const oldData = [...selectedNodes];
      const index = oldData.indexOf(id);
      oldData.splice(index, 1);
      data = [...oldData];
    }
    setSelectedNodes([..._.uniq(data)]);
  };

  const renderItem = (question: any, index: any, questions: any) => {
    return (
      <div className="sortable-item ">
        <QuizQuestionEditor
          isNew={question.isNew ? question.isNew : false}
          key={question.id || "que_" + index}
          questionNumber={index}
          questionId={question.id || index}
          data={transformQuestion(question)}
          enableLearning={enableLearning}
          activateEditAsProps={
            typeof props.location.state !== "undefined" &&
            props.location.state.selectedFileType &&
            (props.location.state?.tagReview ||
              !props.location.state?.createFromTestFile)
          }
          updatingQuizQuestionsFromParent={updatingQuizQuestionsFromParent}
          SetUpdatingQuizQuestionsFromParent={
            SetUpdatingQuizQuestionsFromParent
          }
          hasAnyError={hasAnyError}
          onSetErrorIfAny={setErrorIfAny}
          parentRef={ref}
          hasLastIndex={index + 1 === Object.keys(transformedQuestions).length}
          defaultEditable={!props.location.state?.createFromTestFile}
          topicDropdownData={topicDropdownData}
          showKeyword={props.location.state?.tagReview}
          hideActions={
            props.location.state?.tagReview ||
            props.location.state?.createFromTestFile
          }
          onQuestionSave={onSave}
          updateQuizQuestionsFromParent={updateQuizQuestionsFromParent}
          onQuestionDelete={confirmDelete}
          addNew={addNew}
          directQuestionDelete={directQuestionDelete}
          quizQuestionDropdownData={quizQuestionDropdownData}
          allQuestions={backupRef.current}
          questionIdLabelMap={questionIdLabelMap}
          addNewQuestion={addNewQuestion}
          onGroupQuestionSave={onGroupQuestionSave}
          isQuestionBank
          questionBankId={params.questionId}
          isQuestionBankEditable={params.questionId || isMultiEdit || false}
          questionBank={question}
          allDocuments={[...allDocuments]}
          enableDrag={!isMultiEdit}
          showCheckbox={isMultiEdit && !props.location.state?.tagReview}
          selectedNodes={selectedNodes}
          addToSelectedRows={addToSelectedRows}
          isQuestionBankMultiEdit={isMultiEdit}
          groupTagLabelClick={(q: any) => {
            setShowMoveSelectModal(true);
            setSelectedMove(q?.id);
          }}
        />
      </div>
    );
  };

  const getBuildQuizHtml = () => (
    <>
      {componentHasError.length > 0 &&
        typeof props.location.state !== "undefined" &&
        props.location.state.selectedFileType &&
        quizSubmitted && (
          <div className="card-error">
            {t(
              "The questions were imported successfully but there are some errors in few of the questions. Please resolve the errors in the highlighted questions before saving"
            )}
          </div>
        )}

      <ConfirmationModal
        message={t(
          'Questions imported successfully. You can now edit the questions/answers. Click on "Save Quiz" to save this quiz?'
        )}
        size={"md"}
        show={openModal}
        onHide={() => SetOpenModal(false)}
        confirmHandler={() => {
          SetOpenModal(false);
        }}
      />

      <div>
        {backupRef.current.length === 0 && (
          <div className="page-banner text-center">
            <p>
              {t(
                "You don't have any question yet. Click the button below to add a question."
              )}
            </p>
            <button
              onClick={addNewQuestion}
              className="btn btn-outline-primary"
            >
              + {t("Add Another Question")}
            </button>
          </div>
        )}

        {Object.keys(transformedQuestions).length > 0 && (
          <>
            <DragContainer
              onDrop={onDrop}
              getChildPayload={getChildPayload}
              dragHandleSelector=".drag-handle"
            >
              {Object.keys(transformedQuestions).map(
                (questionId: any, index: any) => {
                  return (
                    <Draggable
                      key={questionId}
                      // className={params.questionId ? "p-0" : ""}
                    >
                      {renderItem(
                        transformedQuestions[questionId],
                        index,
                        Object.values(transformedQuestions)
                      )}
                    </Draggable>
                  );
                }
              )}
            </DragContainer>
            {!params.questionId && !isMultiEdit && (
              <div className="add-btn-container text-center">
                <button
                  onClick={addNewQuestion}
                  className="btn btn-outline-primary"
                >
                  + {t("Create question from scratch")}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );

  const onSelectAllRows = (event: any) => {
    if (event.target.checked) {
      if (Object.keys(transformedQuestions).length > 0) {
        const quizzes: any = Object.keys(transformedQuestions);
        setSelectedNodes([..._.uniq(quizzes)]);
      }
    } else {
      setSelectedNodes([]);
    }
  };

  const confirmCategoryUpdateHandler = async (selectedOption: any) => {
    try {
      setShowMoveSelectModal(false);
      let moveQuestions = [];
      if (isMultiAct) {
        moveQuestions = selectedNodes.map(
          (id: any) => transformedQuestions[id]?.id
        );
      } else {
        moveQuestions = [selectedMove];
      }
      await dispatch(
        bulkAssignModules(
          selectedOption,
          // moveQuestions.map((qId: any) => transformedQuestions?.[qId]._id),
          moveQuestions,
          "question"
        )
      );
      getQuestions();
      if (isMultiAct) {
        setSelectedNodes([]);
        setIsMultiAct(false);
      }
      // getAllQuestionBankHandler();
    } catch (error: any) {
      if (isMultiAct) {
        setSelectedNodes([]);
        setIsMultiAct(false);
      }
    }
  };
  const maxQuestions: number = Object.keys(transformedQuestions).length;

  const getTitle = () => {
    if (props.location.state?.createFromTestFile) {
      return props.location.state?.tagReview
        ? t("Generate Keywords")
        : t("Review Question(s)");
    }

    if (params.questionId || isMultiEdit) {
      return t("Edit Question(s)");
    }

    return t("Create Questions");
  };

  const getSubTitle = () => {
    if (props.location.state?.createFromTestFile) {
      return props.location.state?.tagReview
        ? t(
            "Select and assign questions to be saved in specified knowledge group"
          )
        : t(
            "Select and assign questions to be saved in specified knowledge group"
          );
    }
    if (params.questionId) {
      return t("Manage and edit quiz questions");
    }
    if (isMultiEdit) {
      return t(
        "Manage your question-answer pairs, keywords, and save changes."
      );
    }
    return t("Convert the knowledge into quiz questions");
  };

  const saveQuestionsToQuiz = async (quizQuesstions: any[]) => {
    try {
      // console.log('saveddddddddd') updateQuizQuestionsFromParent
      const quizId = props.location.state?.quizId;
      const { quizDetail }: any = await dispatch(getQuizByQuizId(quizId));
      const quizSavedQuestions = quizQuesstions.map((q: any) => ({
        ...q,
        quiz_question_id: quizId,
      }));
      const response: any = await dispatch(
        updateQuiz(
          quizId,
          {
            questions: [...quizSavedQuestions, ...quizDetail.questions],
          },
          true
        )
      );

      history.push(GET_QUIZ_BYID.replace(":quizId", quizId));
    } catch (error) {
      console.log("[ error ]", error);
    }
  };

  const saveGotoPreviews = async () => {
    await updateQuizQuestionsFromParent();
  };

  const saveAndGotoQuestionsbanks = async () => {
    // setErrorIfAny();
    setRedirect(isMgs ? QUIZZES : QUIZZES_BETA + "?tab=bank");
    await updateQuizQuestionsFromParent();
  };

  const confirmedTagsHandler = async (e: any, tags: any[]) => {
    setShowTagsModal(false);
    const selectedQuestions = selectedNodes.map(
      (id: any) => transformedQuestions[id]
    );
    const res = await Promise.all(
      selectedQuestions.map(async (q: any) => {
        // q.keywords = [...q.keywords]
        await dispatch(
          updateKeywordsOfQuestion(q.id, [
            ...q.tags,
            ...tags.map((t: any) => t.value),
          ])
        );
      })
    );
    getQuestions();
  };

  useEffect(() => {
    const items: any = Object.keys(transformedQuestions);
    if (
      savedQuestionsBank &&
      items &&
      savedQuestionsBank?.length > 0 &&
      items?.length > 0 &&
      items.length === savedQuestionsBank.length
    ) {
      saveQuestionsToQuiz(savedQuestionsBank);
      setSavedQuestionsBank([]);
    }
  }, [savedQuestionsBank, transformedQuestions]);

  return (
    <>
      <section
        className={classNames({
          "page-mid-wraper": true,
          "h-without-foter": props.location.state?.createFromTestFile,
        })}
      >
        <UpdateModuleModal
          show={showRenameModal}
          onHide={updateModalHandler}
          name={selectedModuleName}
          parent_id={selectedParentId}
          editMode={moduleEditMode}
          confirmHandler={confirmRenameHandler}
          moduleDepth={moduleDepth}
        />

        <MoveSelectedModal
          show={showMoveSelectModal}
          onHide={() => {
            setShowMoveSelectModal(false);
          }}
          confirmCategoryUpdate={confirmCategoryUpdateHandler}
        />
        <AddTags
          onHide={() => setShowTagsModal(false)}
          show={showTagsModal}
          confirmhandler={confirmedTagsHandler}
          selectedTags={[]}
        />

        <Container>
          <Row>
            {!props.location.state?.createFromTestFile && (
              <Col md={12}>
                <button
                  className="btn py-0 btn-back"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <img src={backblue} alt="Back" />
                  {t("Return to previous page")}
                </button>
              </Col>
            )}
            <Col md={12}>
              <div className="pl-lg-3">
                <div className="d-flex flex-wrap justify-content-between mb-24 align-items-center">
                  <div>
                    <h1 className="mb-0">{getTitle()}</h1>
                    <div className="font-16">{getSubTitle()}</div>
                  </div>
                  {!params.questionId && !isMultiEdit && (
                    <button
                      className="btn btn-primary font-600 border-0 py-1"
                      onClick={updateQuizQuestionsFromParent}
                    >
                      {t("Add to Question Bank")}
                    </button>
                  )}
                  {props.location.state?.createFromTestFile &&
                    !props.location.state?.tagReview &&
                    !isImportForQuiz && (
                      <div>
                        <button
                          className="btn btn-primary font-600 py-1"
                          onClick={() => saveAndGotoQuestionsbanks()}
                        >
                          {t("View Question Bank")}
                        </button>
                        <button
                          className="btn btn-outline-primary font-600 py-1 mx-2"
                          disabled={selectedNodes && selectedNodes.length < 1}
                          onClick={() => {
                            setShowMoveSelectModal(true);
                            setIsMultiAct(true);
                          }}
                        >
                          {t("Move Selected")}
                        </button>
                      </div>
                    )}

                  {props.location.state?.createFromTestFile &&
                    props.location.state?.tagReview && (
                      <button
                        className="btn btn-primary font-600 py-1"
                        onClick={() => {
                          saveGotoPreviews();
                        }}
                      >
                        {t("Save and Review Question(s)")}
                      </button>
                    )}

                  {props.location.state?.createFromTestFile &&
                    !props.location.state?.tagReview &&
                    isImportForQuiz && (
                      <div>
                        <button
                          className="btn btn-outline-primary font-600 py-1 mx-2"
                          disabled={selectedNodes && selectedNodes.length < 1}
                          onClick={() => {
                            setShowMoveSelectModal(true);
                            setIsMultiAct(true);
                          }}
                        >
                          {t("Move Selected")}
                        </button>
                        <button
                          className="btn btn-primary font-600 py-1"
                          onClick={() => {
                            setSavedQuestionsBank([]);
                            updateQuizQuestionsFromParent();
                          }}
                        >
                          {t("Add All Questions to Quiz")}
                        </button>
                      </div>
                    )}
                </div>

                {isMultiEdit && !props.location.state?.tagReview && (
                  <div
                    className="d-flex flex-wrap align-items-center my-3"
                    style={{ minHeight: "40px" }}
                  >
                    {Object.keys(transformedQuestions).length !== 0 && (
                      <div className="custom-checkbox mr-3">
                        <input
                          type="checkbox"
                          className="mr-2"
                          id="select-all"
                          checked={
                            selectedNodes.length === maxQuestions &&
                            maxQuestions !== 0
                          }
                          onChange={onSelectAllRows}
                        />
                        <label htmlFor="select-all" className="mb-0">
                          {t("Select all")}
                        </label>
                      </div>
                    )}
                    {selectedNodes && selectedNodes.length > 0 && (
                      <>
                        {!props.location.state?.createFromTestFile && (
                          <button
                            className="btn btn-suggest py-0 font-sm"
                            onClick={() => {
                              setShowMoveSelectModal(true);
                              setIsMultiAct(true);
                            }}
                          >
                            {t("Move Selected")}
                          </button>
                        )}
                        <button
                          className="btn btn-suggest py-0 font-sm"
                          onClick={() => {
                            setShowTagsModal(true);
                            setIsMultiAct(true);
                          }}
                        >
                          {t("Assign Keywords")}
                        </button>
                        <button
                          className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                          onClick={() => {
                            setOpenDeleteConfirm(true);
                            setIsMultiAct(true);
                          }}
                        >
                          {t("Delete Selected")}
                        </button>
                      </>
                    )}
                  </div>
                )}
                {typeof props.location.state !== "undefined" &&
                  props.location.state.selectedFileType && (
                    <div className="extract-quiz-wrapper">
                      <div className="extract-quiz-questions">
                        {getBuildQuizHtml()}
                      </div>
                    </div>
                  )}

                {!(
                  typeof props.location.state !== "undefined" &&
                  props.location.state.selectedFileType
                ) && getBuildQuizHtml()}
                <div className="half-clearfix" />
                <div className="action-btn-container">
                  {typeof props.location.state !== "undefined" &&
                    props.location.state.selectedFileType &&
                    !props.location.state?.createFromTestFile && (
                      <button
                        onClick={
                          typeof props.location.state !== "undefined" &&
                          props.location.state.selectedFileType
                            ? updateQuizQuestionsFromParent
                            : onQuizSave
                        }
                        disabled={internalLoading}
                        className="btn btn-primary font-600"
                      >
                        {t("Save Quiz")}
                      </button>
                    )}
                </div>
              </div>
              <ConfirmationModal
                message={t("Are you sure you want to delete this question?")}
                size={"md"}
                show={openDeleteConfirm}
                onHide={() => setOpenDeleteConfirm(false)}
                confirmHandler={onDelete}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default CreateEditQuestionBank;
