import React, { useState, useEffect } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  getSubmissionsBySubmissionId,
  getSubmissionsReviewsByReviewId,
} from "../../../store/quizzes/actions";
import AuthorSubmission from "./AuthorSubmission";
import chevLeft from "../../../assets/New-images/chevron-left-blk.svg";
import backimg from "../../../assets/images/arrow-left.svg";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";

// import IconLabelButton from "../../../components/IconLabelButton";
// import AuthorSubmission from "./authorSubmission";

const QuizSumbmissionDetails = (props: any) => {
  const { t } = useTranslation();
  const params: any = useParams();
  const history = useHistory();
  const useLocatio = useLocation();
  const [submissions, setSubmissions] = useState([]);
  const [quizTotalScore, setQuizTotalScore] = useState(0);
  const [userScore, setUserScore] = useState(0);
  const [decimalPlaces, setDecimalPlaces] = useState(2);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const [reviewedMode, setReviewedMode] = useState( false );
  const dispatch = useDispatch();
  useEffect(() => {
    const query = new URLSearchParams(useLocatio.search);
    const reviewId = query.get("reviewId");
    setReviewedMode(query.get("reviewed") ? true : false)
    setReviewId( reviewId );
    getSubmissions();
  }, []);

  const getSubmissions = () => {
    if (props.isForReview) {
      getSubmissionById();
    } else {
      getSubmissionById();
    }
  };

  const getSubmissionById = async () => {
    if (
      (props.quizId || params.quizId) &&
      (props.submissionId || params.submissionId)
    ) {
      try {
        const response: any = await dispatch(
          getSubmissionsBySubmissionId(
            props.quizId || params.quizId,
            props.submissionId || params.submissionId
          )
        );
        
        const query = new URLSearchParams(useLocatio.search);
        const reviewId = query.get("reviewId");
        let reviewResponse: any;
        if (reviewId) {
          reviewResponse = await dispatch(
            getSubmissionsReviewsByReviewId(reviewId)
          );
          await setSelectedRecord(reviewResponse.submission_review);
        }

        if (response.quiz_submission && response.quiz_submission.quiz) {
          if (response.quiz_submission.quiz.questions.length) {
            setQuizTotalScore(
              response.quiz_submission.quiz.questions.reduce(
                (score: any, question: any) => {
                  return score + question.score;
                },
                0
              )
            );
            setUserScore(response.quiz_submission.score);
          }

          if (
            "score_decimal_number" in response.quiz_submission.quiz &&
            response.quiz_submission.quiz.score_decimal_number !== null
          ) {
            setDecimalPlaces(
              response.quiz_submission.quiz.score_decimal_number
            );
          } else {
            setDecimalPlaces(2);
          }
        }

        getTableData({
          questions: response.quiz_submission.quiz.questions || [],
          answers: response.quiz_submission.answers,
          score: response.quiz_submission.score,
          connection_id: response.quiz_submission.connection_id,
        }, reviewResponse && reviewResponse.hasOwnProperty('submission_review') ? reviewResponse.submission_review : [], response.quiz_submission || null);
      } catch (error) {
        console.log('error', error)
        getTableData({
          questions: [],
          answers: [],
          score: null,
          connection_id: null
        }, []);
      }
    }
  };

  
  const getTableData = (data: any, reviewResponse: any = [], fullData: any = null) => {

    let fetchedQuestions = [ ...data.questions ];
    const query = new URLSearchParams(useLocatio.search);
    const reviewId = query.get("reviewId");

    let tableData = [];
    if (props.isForReview || reviewId) {
      let questionIds = reviewResponse.to_review_answers.map(
        (item: any) => item.question_id
      );
      let comments = reviewResponse.to_review_answers.map(
        (item: any) => item.comment
      );
      let questions: any[] = [],
        answers: any[] = [];
      fetchedQuestions.forEach((question: any, index: any) => {
        if (questionIds.indexOf(question.id) !== -1) {
          questions.push(question);
          answers.push(data.answers[index]);
        }
      });

      // console.log('[ questions ]', questions)
      tableData.push({
        reviewId: reviewResponse._id,
        submissionId: reviewResponse.quiz_submission_id,
        answers: transformAnswers(questions, answers),
        questions: questions,
        score: data.score,
        connectionId: data.connection_id,
        comments: comments,
      });
    } else {
      if(!fullData) {
        return
      }
      [fullData].forEach((item, index) => {
        tableData.push({
          submissionId: item._id,
          answers: transformAnswers(item.quiz.questions, item.answers),
          questions: item.quiz.questions,
          score: item.score,
          connectionId: item.connection_id,
        });
      });
    }
    setSubmissions(tableData);
  };

  const getCorrectAnswer = (question: any) => {
    if (
      "ideal_answer_items" in question &&
      (question.type === "table" || question.type === "newbullet")
    ) {
      return [{ bullets: question.ideal_answer_items }];
    } else if ("ideal_answer" in question) {
      return [{ text: question.ideal_answer }];
    } else if ("options" in question && question.options.length > 0) {
      return question.options.filter((option: any) => option.is_correct);
    } else if ("matches" in question) {
      return [{ matches: question.matches }];
    } else {
      return ["-"];
    }
  };

  const getSubmittedAnswer = (item: any, question: any) => {
    if ("text" in item && item.text.length > 0) {
      return [item.text];
    } else if ("matches" in item) {
      return [{ matches: item.matches }];
    } else if ("options" in item) {
      return item.options.map((optionId: any, key: any) => {
        if (question.options[optionId]) {
          return question.options[optionId].text;
        } else {
          return question.options
            .map((item: any) => {
              if (item.id === optionId) {
                return item.text;
              }
            })
            .filter((item: any) => item !== undefined);
        }
      });
    }
  };

  const transformAnswers = (questions: any, answers: any) => {
    let transformedAnswers: any[] = [];
    answers.forEach((item: any, index: any) => {
      transformedAnswers.push({
        correctAnswer: getCorrectAnswer(questions[index]),
        submittedAnswer: getSubmittedAnswer(item, questions[index]),
        score:
          "score" in item
            ? decimalPlaces === 0
              ? Math.floor(item.score)
              : parseFloat(item.score).toFixed(decimalPlaces)
            : "Not scored",
        isCorrect: "is_correct" in item ? item.is_correct : "-",
        correctedScore: "corrected_score" in item ? item.corrected_score : "",
        questionId: "question_id" in item ? item.question_id : "",
        maxScore:
          "score" in questions[index]
            ? decimalPlaces === 0
              ? Math.floor(questions[index].score)
              : parseFloat(questions[index].score).toFixed(decimalPlaces)
            : "-",
        reviewer_comment:
          "reviewer_comment" in answers[index]
            ? answers[index].reviewer_comment
            : "-",
      });
    });
    return transformedAnswers;
  };


  return (
    <section className="page-mid-wraper h-without-foter">
      <Container>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn p-0 btn-back"
              onClick={() => history.goBack()}
            >
              <Image src={backimg} /> {t("Return to previous page")}
            </button>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-start mb-4">
          <div>
            <h2 className="mb-1">{t("View Quiz Submission")}</h2>
            <div className="clr-grey font-600">
              {t("Review the scores requested by the learners")}
            </div>
          </div>
        </div>
        <Row>
          <Col md={12}>
            <div className="create-quiz-submission-details-page-container">
              {!props.isForReview && reviewId && (
                <div className="d-flex justify-content-between mb-3 flex-wrap">
                  {/* <div>
                    <Button
                      onClick={props.onBack}
                      variant="default"
                      className="font-600 font-sm py-0 pl-0 clr-primary"
                    >
                      <img src={chevLeft} alt="" className="mr-1" />{" "}
                      {t("Go Back")}
                    </Button>
                  </div> */}

                  <div className="mb-1 d-flex align-items-center">
                    <h2 className="mb-0 mr-2">{t("Learner Email")}:</h2>
                    <div className="font-16 clr-primary font-weight-bold">
                      {props.selectedRecord
                        ? props.selectedRecord.email
                        : props.authorEmail
                        ? props.authorEmail
                        : params?.email}
                    </div>
                  </div>

                  <div className="mb-1 d-flex align-items-center">
                    <h2 className="mb-0 mr-2">{t("Total submissions")}:</h2>{" "}
                    <div className="font-16 clr-primary font-weight-bold">
                      {submissions.length}
                    </div>
                  </div>

                  <div className="mb-1 d-flex align-items-center">
                    <h2 className="mb-0 mr-2">{t("User Score")}:</h2>{" "}
                    <div className="font-16 clr-primary font-weight-bold">
                      {userScore.toFixed(decimalPlaces)}/
                      {quizTotalScore.toFixed(decimalPlaces)}
                    </div>
                  </div>
                </div>
              )}
              {(props.isForReview && !reviewId) && (
                <div className="mb-4">
                  <Button
                    onClick={props.onBack}
                    variant="default"
                    className="font-600 font-sm py-0 pl-0 clr-primary"
                  >
                    <img src={chevLeft} alt="" className="mr-1" />{" "}
                    {t("Go Back")}
                  </Button>
                </div>
              )}
              {submissions.map((submission, index) => {
                return (
                  <AuthorSubmission
                    key={uuidv4()}
                    markReviewed={false}
                    quizId={props.quizId || params.quizId}
                    getSubmissions={() => {
                      history.goBack()
                    }}
                    submission={submission}
                    isForReview={false}
                    actionLabel={reviewedMode ? t("Update Review"): t("Resolve Review")}
                    connectionIdClassMap={props.connectionIdClassMap}
                    enableLearning={props.enableLearning}
                  />
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default QuizSumbmissionDetails;
