/* eslint-disable jsx-a11y/anchor-is-valid */
import { default as classNames } from "classnames";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import emptyKnowledge from "../../assets/New-images/empty-knowledge.svg";
import exclamationpink from "../../assets/New-images/exclamation-pink.svg";
import folderImg from "../../assets/New-images/folder-imgblue.svg";
import greencheck from "../../assets/New-images/green-check.svg";
import plusIcon from "../../assets/New-images/plus-blue.svg";
import knowledgeImg from "../../assets/New-images/quick-start-knowledge.svg";
import sortIcon from "../../assets/New-images/sort-blue-icon.svg";
import ConfirmationModal from "../../components/UI/ConfirmationModal/ConfirmationModal";
import UpdateModuleModal from "../../components/UI/KnowledgeDatatableComponent/UpdateModuleModal";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";
import { TwoButtonModal } from "../../components/UI/Common";
import {
  EXPORT_QUESTION_BANK,
  IMPORTQUESTIONBANK,
  QUIZZALLREVIEWREQUESTSBYID,
  QUIZZDETAILSBYID,
  QUIZZES_ARCHIVE_BETA,
  QUIZZES_BETA_REVIEW,
} from "../../config";

import { GET_QUIZ_BYID, MANAGEKNOWLEDGEGROUP } from "../../config";
import {
  getQuizByQuizId,
  getSubmissionReviewCount,
} from "../../store/dashboard/actions";
import {
  bulkAssignModules,
  deleteKnowledgeGroup,
  getAllLevels,
  getAllModulesFromYear,
} from "../../store/knowledge/actions";
import {
  deleteQuiz,
  getAllQuizzes,
  restoreQuiz,
  updateQuiz,
} from "../../store/quizzes/actions";
import archivedImg from "../../assets/New-images/icon-archived.svg";
import CreateQuizModal from "./CreateQuizModal";
import QuizQuestionBank from "./QuizQuestionBank/QuizQuestionBank";
import exportImg from "../../assets/New-images/Export-image.svg";
import CreateQuizModalTeachersToolkit from "../TeachersToolkit/popups/createQuizModalTeachersToolkit";

function QuizzesGridView(props: any) {
  const isMgs = process.env.REACT_APP_CISCO === "mgs";
  const { t } = useTranslation();
  const history = useHistory();
  const useLocatio = useLocation();
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [suggestionMode, setSuggestionMode] = useState(false);
  const [unansweredCount, setUnansweredCount] = useState(0);
  const [activeKey, setActiveKey] = useState(null);
  const [recentUploadedDocuemnt, setRecentUploadedDocument] = useState(null);
  const [recentquestion, setRecentQuestion] = useState(null);
  const [activeTab, setActiveTab] = useState(isMgs ? "bank" : "quizzes");
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [modules, setModules] = useState([]);
  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const [selectedParentId, setSelectedParentId] = React.useState(null);
  const [selectedModuleName, setSelectedModuleName] = React.useState("");
  const [moduleEditMode, setModuleEditMode] = React.useState(false);
  const [moduleDepth, setModuleDepth] = React.useState(0);
  const [selectedParentModule, setSelectedParentModule] = useState<any>();
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [hasTopLevelApplied, setHasTopLevelApplied] = useState(true);
  const [submissionsReviewCount, setSubmissionsReviewCount] = useState(0);
  const { isArchive = false } = props;
  const [filters, setFilters] = useState({
    sort_field: "updated_at",
    sort_order: "dsc",
    topic_ids: null,
    knowledge_group_ids: null,
    q: null,
    includes_all_subgroups: null,
    isArchive: isArchive,
  });
  const [modalShow, setModalShow] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [deletedRowId, setDeletedRowId] = useState("");
  const [quizzes, setQuizzes] = useState([]);
  const [createQuizModal, setCreateQuizModal] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedQuizzes, setSelectedQuizzes] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const modalhandler = (value: boolean) => {
    setModalShow(value);
  };
  const deleteQuizzHandler = (id: string) => {
    setDeletedRowId(id);
    setIsShowDeleteModal(true);
  };

  useEffect(() => {
    getAllYearsFun();
    getSubmissionsReviewCount();
    const query = new URLSearchParams(useLocatio.search);
    const tab = query.get("tab");
    if (tab === "bank" || tab === "quizzes") {
      setActiveTab(tab);
    }
  }, []);

  useEffect(() => {
    if (!selectedGroupId || activeTab !== "quizzes") {
      return;
    }

    getAllQuizzesFun({
      ...filters,
      knowledge_group_ids: selectedGroupId,
      includes_all_subgroups: hasTopLevelApplied,
    });
  }, [filters, selectedGroupId, hasTopLevelApplied]);

  const getAllQuizzesFun = async (filters: any) => {
    try {
      const response: any = await dispatch(getAllQuizzes(filters));
      setQuizzes(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getSubmissionsReviewCount = async () => {
    try {
      const response: any = await dispatch(getSubmissionReviewCount());
      setSubmissionsReviewCount(response);
    } catch (error: any) {}
  };

  const isBranchUnderSelectedModule = (level: any) => {
    if (!selectedParentModule) {
      return false;
    }

    const findParent = (module: any) => {
      const parent = modules.find((m) => m._id === module.parent_id);
      return parent;
    };

    let parent = findParent(level);

    while (parent) {
      if (parent._id === selectedParentModule._id) {
        return true;
      }
      parent = findParent(parent);
    }
    return false;
  };

  const getAllYearsFun = async (loadSubModules: boolean = true) => {
    try {
      const response: any = await dispatch(getAllLevels());
      setYears([...response]);

      const query = new URLSearchParams(useLocatio.search);
      const parent = query.get("parent");
      if (parent) {
        const parentVal = response.find((par: any) => par._id === parent);
        if (parentVal) {
          getAllModulesFromYearFun(parentVal);
        } else {
          response &&
            response.length > 0 &&
            getAllModulesFromYearFun(selectedYear ? selectedYear : response[0]);
        }
      } else {
        response &&
          response.length > 0 &&
          getAllModulesFromYearFun(selectedYear ? selectedYear : response[0]);
      }
    } catch (error: any) {
      setYears([]);
    }
  };

  const getAllModulesFromYearFun = async (year: any) => {
    setSelectedYear(year);
    try {
      const query = new URLSearchParams(useLocatio.search);
      const group = query.get("group");
      const response: any = await dispatch(getAllModulesFromYear(year._id));
      setModules([...response]);
      if (group) {
        setSelectedGroupId(group);
      } else {
        if (response && response.length > 0) {
          onSelectGroupId(response[0].value);
          if (response[0].depth === 0) {
            setSelectedParentModule(response[0]);
          }
        } else {
          onSelectGroupId(year._id);
        }
      }
    } catch (error: any) {
      setModules([]);
    }
  };

  const confirmRenameHandler = async (data: any) => {
    getAllYearsFun(false);
    updateModalHandler();
  };

  const updateModalHandler = () => {
    setShowRenameModal(!showRenameModal);
  };

  const createQuizModalHandler = () => {
    setCreateQuizModal(!createQuizModal);
  };

  const updateParentHandler = (
    name?: string,
    parent_id?: string,
    isEdit: boolean = false,
    depth: number = 0
  ) => {
    setSelectedParentId(parent_id);
    setSelectedModuleName(name);
    setModuleEditMode(isEdit);
    setModuleDepth(depth);
    updateModalHandler();
  };

  const [deleteModuleModalShow, setDeleteModuleModalShow] = useState(false);
  const [deletedModuleId, setDeletedModuleId] = useState([]);
  const deleteModuleModalHandler = () => {
    setDeleteModuleModalShow(!deleteModuleModalShow);
  };
  const confirmDeleteModuleHandler = async (e: any, deleteId: string) => {
    try {
      await dispatch(deleteKnowledgeGroup(deleteId));
      getAllYearsFun(false);
      deleteModuleModalHandler();
    } catch (error: any) {
      console.log("error", error);
    }
  };

  /**
   * Set debounce time for while searching in any input
   */
  const [query, setQuery] = useState("");
  const [isTyping, setTyping] = useState(false);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isTyping) {
        setCurrentPage(1);
        setFilters({
          ...filters,
          q: query,
        });
        setTyping(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
    setTyping(true);
  };

  /**
   * Used to delete multiple knowledge based on IDS
   * @param e
   * @param selectedRowIds
   * @param isArchive
   */
  const deleteConfirmed = async (
    e: any,
    selectedRowIds: string,
    isArchive: boolean = false
  ) => {
    e?.preventDefault();
    setModalShow(false);
    await dispatch(deleteQuiz(selectedRowIds, isArchive));
    getAllQuizzesFun({
      ...filters,
      knowledge_group_ids: selectedGroupId,
      includes_all_subgroups: hasTopLevelApplied,
    });
    // setSelectedNodes([]);
  };

  const restoreQuizId = async (quizId: string) => {
    await dispatch(restoreQuiz(quizId));
    getAllQuizzesFun({
      ...filters,
      knowledge_group_ids: selectedGroupId,
      includes_all_subgroups: hasTopLevelApplied,
    });
    // setSelectedNodes([]);
  };

  const onSelectGroupId = (level: string) => {
    setSelectedGroupId(level);
  };

  const onSortHandler = (selector: any, direction: string) => {
    setFilters({
      ...filters,
      sort_field: selector,
      sort_order: direction,
    });
  };

  const addToSelectedRows = (event: any, id: string) => {
    let data: any[] = [...selectedQuizzes];
    if (event.target.checked) {
      const isPresent = data.map((e) => e.rowId).indexOf(id);
      if (isPresent < 0) {
        data.push({ rowId: id, yearId: selectedYear._id });
      }
    } else {
      const oldData = [...selectedQuizzes];
      const index = oldData.map((e) => e.rowId).indexOf(id);
      oldData.splice(index, 1);
      data = [...oldData];
    }
    setSelectedQuizzes([..._.uniq(data)]);
  };

  const onSelectAllRows = (event: any) => {
    if (event.target.checked) {
      if (quizzes && quizzes.length > 0) {
        let data: any[] = [
          ...quizzes.map((intent: any) => {
            return {
              rowId: intent._id,
              yearId: selectedYear._id,
            };
          }),
        ];
        setSelectedQuizzes([..._.uniq(data)]);
      }
    } else {
      setSelectedQuizzes([]);
    }
  };

  const quizHtml = (
    <>
      <div className="all-knowledge-search d-flex flex-wrap align-items-center mb-2">
        <Dropdown className="mr-2 sort-dropdown">
          <Dropdown.Toggle
            className="btn btn-outline-primary font-600 py-1 my-1 "
            variant="default"
            id="dropdown-basic"
          >
            <img src={sortIcon} alt="" className="mr-2" />
            {t("Sort")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("updated_at", "dsc");
              }}
            >
              {t("Most Recent")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("name", "asc");
              }}
            >
              {t("Ascending")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                onSortHandler("name", "dsc");
              }}
            >
              {t("Descending")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <div className="web-links my-0 mr-0">
          <input
            type="text"
            className="form-control h-auto"
            placeholder={t("Search for quiz")}
            onChange={onInputChangeHandler}
          />
        </div>

        {!isArchive && (
          <PermissionWrapper>
            <button
              className="btn btn-outline-primary font-600 border-0 py-1 ml-auto"
              onClick={createQuizModalHandler}
            >
              + {t("Create Quiz")}
            </button>
          </PermissionWrapper>
        )}
      </div>

      {/* <div className="custom-checkbox mx-1">
        <input
          type="checkbox"
          className="mr-2"
          id="select-all"
          checked={
            selectedQuizzes.length === quizzes.length && quizzes.length !== 0
          }
          onChange={onSelectAllRows}
        />
        <label htmlFor="select-all" className="mb-0">
          {t("Select all")}
        </label>
      </div> */}

      {/* <div className="d-flex flex-wrap justify-content-between align-items-center mt-1">
        <div className="d-flex align-items-center">
          <div className="custom-checkbox mr-3">
            <input
              type="checkbox"
              className="mr-2"
              id="select-all"
                checked={
                  allKnowledgeSuccess &&
                  allKnowledgeSuccess.intents &&
                  allKnowledgeSuccess.intents.length > 0 &&
                  selectedNodes.length === allKnowledgeSuccess.intents.length
                }
                onChange={onSelectAllRows}
            />
            <label htmlFor="select-all" className="mb-0">
              Select all
            </label>
          </div>
          {selectedNodes && selectedNodes.length > 0 && (
            <PermissionWrapper>
              <button
                className="btn btn-suggest py-0 font-sm"
                onClick={(e) => suggestBulkKnowledgeHandler(e, selectedNodes)}
              >
                Suggest Selected
              </button>

              <button
                className="btn btn-suggest py-0 font-sm"
                onClick={(e) =>
                  bulkAssignCategoryKnowledgeHandler(selectedRows)
                }
              >
                Move Selected
              </button>
              <button
                className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                onClick={() => deleteKnowledgeHandler(selectedNodes, true)}
              >
                Delete Selected
              </button>
            </PermissionWrapper>
          )}
        </div>
      </div> */}
      <div className="row mt-3 scroll-wrap">
        {quizzes.map((quiz: any) => {
          return (
            <div className="col-sm-6 col-lg-4 mb-4">
              <div
                className="quiz-box"
                role="button"
                onClick={() =>
                  !isArchive &&
                  history.push(GET_QUIZ_BYID.replace(":quizId", quiz._id))
                }
              >
                {quiz.reviews_count > 0 && (
                  <div
                    className="pending-review text-right mb-2"
                    onClick={(e) => {
                      e?.stopPropagation();
                      history.push(
                        QUIZZALLREVIEWREQUESTSBYID.replace(":quizId", quiz._id)
                      );
                    }}
                  >
                    <span>
                      {quiz.reviews_count} {t("pending review(s)")}{" "}
                    </span>
                  </div>
                )}
                <div className="quiz-ques-length mb-4 d-flex justify-content-between align-items-start checkbox-large">
                  <div>
                    <h2 className="font-32 clr-black font-600 mb-0">
                      {quiz.questions.length}
                    </h2>
                    <div
                      className={
                        quiz.questions.length === 0
                          ? "error quiz-indicator"
                          : "quiz-indicator"
                      }
                    ></div>
                    <p className="clr-grey-txt7 font-600">{t("Questions")}</p>
                  </div>

                  {/* <div
                    className="custom-checkbox"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      type="checkbox"
                      className="mr-2 mt-2"
                      onChange={(e) => {
                        console.log("event", e);
                        addToSelectedRows(e, quiz._id);
                      }}
                      checked={
                        selectedQuizzes.map((e) => e.rowId).indexOf(quiz._id) >=
                        0
                      }
                    />
                  </div> */}
                </div>
                <div className="quiz-name mb-4">
                  <h2 className="mb-1">{quiz.name}</h2>
                  <p className="clr-grey-txt7 font-600">
                    <Moment format="DD/MM/YYYY HH:mm" utc local>
                      {quiz.created_at}
                    </Moment>
                  </p>
                </div>
                <div className="">
                  <button
                    className="btn font-sm font-600 btn-outline-primary border-0 pl-0 pr-1 py-0 mr-2"
                    onClick={(e) => {
                      e?.stopPropagation();
                      history.push(
                        QUIZZDETAILSBYID.replace(":quizId", quiz._id)
                      );
                    }}
                  >
                    {t("View Activity")}
                  </button>
                  <button
                    className="btn font-sm font-600 btn-outline-primary border-0 pl-0 pr-1 py-0 mr-2"
                    onClick={(e) => {
                      e?.stopPropagation();
                      if (isArchive) {
                        restoreQuizId(quiz?._id);
                      } else {
                        history.push(
                          GET_QUIZ_BYID.replace(":quizId", quiz._id)
                        );
                      }
                    }}
                  >
                    {isArchive ? t("Restore") : t("Edit")}
                  </button>
                  <button
                    className="btn font-sm font-600 btn-outline-danger border-0 px-0 py-0"
                    onClick={(e) => {
                      e?.stopPropagation();
                      if (isArchive) {
                        setDeletedRowId(quiz._id);
                        setModalShow(true);
                      } else {
                        deleteQuizzHandler(quiz._id);
                      }
                    }}
                  >
                    {t("Delete")}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {quizzes && quizzes.length === 0 && (
        <div className="empty-knowledge text-center my-5">
          <h3 className="h6 clr-grey-txt7 mb-3">
            {t("You have yet to create quiz")}
          </h3>
          <figure className="h-auto">
            <img src={emptyKnowledge} alt="" />
          </figure>
          <div className="clr-primary font-weight-bold">
            {/* Drop a document here <span className="clr-grey-txt7">or</span>{" "} <br /> */}
            {/* min-height-for-unmatched */}
            <button
              className="btn btn-outline-primary font-600 border-0 py-1 ml-auto"
              onClick={isMgs ? () => {} : createQuizModalHandler}
            >
              {t("Click here to get started")}
            </button>
          </div>
        </div>
      )}
    </>
  );

  const getSelectedNodes = (i: any) => {
    setSelectedNodes([...i]);
  };

  const innerDeleteHtmlMsg = `<span>${t(
    "Are you sure you would like to delete? Deleted items would be "
  )} <b style="color:red;">${t("deleted permanently")}</b></span>`;
  return (
    <>
      <TwoButtonModal
        show={modalShow}
        title={t("Confirm Delete")}
        // message={t("Are you sure you would like to delete? Deleted items would be deleted permanently")}
        message={innerDeleteHtmlMsg}
        innerHtml={true}
        size={"lg"}
        leftButtonText={t("Delete")}
        leftButtonClassName="btn btn-outline-danger font-weight-normal mx-2"
        rightButtonText={t("Cancel")}
        rightButtonClassName="btn btn-primary font-weight-normal mx-2"
        onClickLeftButton={(e) => {
          setModalShow(true);
          deleteConfirmed(e, deletedRowId);
        }}
        onClickRightButton={(e) => {
          setModalShow(false);
        }}
        onClickClose={() => setModalShow(false)}
      />

      <TwoButtonModal
        show={isShowDeleteModal}
        title={t("Delete Quiz")}
        message={t("Would you like to archive or delete the quiz?")}
        size={"lg"}
        leftButtonText={t("Delete")}
        leftButtonClassName="btn btn-outline-danger font-weight-normal mx-2"
        rightButtonText={t("Archive")}
        rightButtonClassName="btn btn-primary font-weight-normal mx-2"
        onClickLeftButton={() => {
          setIsShowDeleteModal(false);
          setModalShow(true);
        }}
        onClickRightButton={(e) => {
          setIsShowDeleteModal(false);
          deleteConfirmed(e, deletedRowId, true);
        }}
        onClickClose={() => setIsShowDeleteModal(false)}
      />

      <ConfirmationModal
        message={t(
          "Knowledge Group would be permanently deleted. All knowledge items/Quizzes in knowledge group would be shifted to uncategorised"
        )}
        size={"md"}
        show={deleteModuleModalShow}
        selectedRow={deletedModuleId}
        onHide={deleteModuleModalHandler}
        confirmHandler={confirmDeleteModuleHandler}
      />

      <UpdateModuleModal
        show={showRenameModal}
        onHide={updateModalHandler}
        name={selectedModuleName}
        parent_id={selectedParentId}
        editMode={moduleEditMode}
        confirmHandler={confirmRenameHandler}
        moduleDepth={moduleDepth}
      />

      <CreateQuizModalTeachersToolkit
        show={createQuizModal}
        selectedGroupId={selectedGroupId}
        onHide={createQuizModalHandler}
        confirmHandler={() => {
          // getAllQuizzesFun(filters);
          createQuizModalHandler();
        }}
      />
      {!isArchive && !props.createFromQuestionBank && (
        <Row className={"mt-4"}>
          <Col md={4} className="mb-4">
            <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
              <img src={knowledgeImg} alt="" />
              <div
                className="pl-3"
                onClick={
                  isMgs
                    ? () => {
                        history.push(IMPORTQUESTIONBANK);
                      }
                    : createQuizModalHandler
                }
              >
                <h2 className="clr-grey-txt7 h6 mb-1">{t("Get Started")}</h2>
                <h2 className="mb-0 clr-primary">
                  {isMgs
                    ? t("Click to Upload a Test")
                    : t("Click to Build a Quiz")}{" "}
                </h2>
              </div>
            </div>
          </Col>
          {isMgs && (
            <Col md={4} className="mb-4">
              <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
                <img src={folderImg} alt="" />
                <div className="pl-3">
                  <h2 className="clr-grey-txt7 h6 mb-1">
                    {t("Recent Activity")}
                  </h2>
                  <h2 className="col-md-11 p-0 mb-0 clr-primary text-ellipsis">
                    {recentquestion}
                  </h2>
                </div>
              </div>
            </Col>
          )}
          {/* {quizzes && quizzes.length > 0 && (
            <Col md={4} className="mb-4">
              <div
                className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left"
                onClick={() => {
                  history.push(
                    GET_QUIZ_BYID.replace(":quizId", quizzes[0]._id)
                  );
                }}
              >
                <img src={folderImg} alt="" />
                <div className="pl-3">
                  <h2 className="clr-grey-txt7 h6 mb-1">
                    {t("Recent Activity")}
                  </h2>
                  <h2 className="mb-0 clr-primary">{quizzes[0].name}</h2>
                </div>
              </div>
            </Col>
          )} */}

          {isMgs && selectedNodes.length > 0 && (
            <Col md={4} className="mb-4">
              <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
                <img src={exportImg} alt="" />
                <div
                  className="pl-3"
                  onClick={() => {
                    history.push({
                      pathname: EXPORT_QUESTION_BANK,
                      state: {
                        rows: [...selectedNodes],
                        selectedYear: selectedYear._id,
                      },
                    });
                  }}
                >
                  <h2 className="clr-grey-txt7 h6 mb-1">
                    {t("Export Question Bank")}
                  </h2>
                  <h2 className="mb-0 clr-primary">
                    {t("Select the questions to export")}
                  </h2>
                </div>
              </div>
            </Col>
          )}

          {!isMgs && (
            <Col md={4} className="mb-4">
              <div
                className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left"
                onClick={() => {
                  history.push(QUIZZES_BETA_REVIEW);
                }}
              >
                {submissionsReviewCount > 0 ? (
                  <img src={exclamationpink} alt="" />
                ) : (
                  <img src={greencheck} alt="" />
                )}

                <div className="pl-3">
                  <h2 className="clr-grey-txt7 h6 mb-1">
                    {t("Quiz Submission Review")}
                  </h2>
                  <h2
                    className={
                      submissionsReviewCount > 0
                        ? "mb-0 clr-pink"
                        : "mb-0 clr-green"
                    }
                  >
                    {submissionsReviewCount > 0 ? (
                      <>
                        {submissionsReviewCount} {t("Quizzes Pending Review")}
                      </>
                    ) : (
                      <>0 {t("Quizzes Pending Review")}</>
                    )}
                  </h2>
                </div>
              </div>
            </Col>
          )}
          {!isMgs && (
            <Col md={4} className="mb-4">
              <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
                <img src={exportImg} alt="" />
                <div
                  className="pl-3"
                  onClick={() => {
                    history.push({
                      pathname: EXPORT_QUESTION_BANK,
                      state: {
                        rows: [...selectedNodes],
                        selectedYear: selectedYear._id,
                      },
                      search:
                        activeTab === "quizzes" ? "?tab=quizzes" : "?tab=bank",
                    });
                  }}
                >
                  <h2 className="clr-grey-txt7 h6 mb-1">
                    {t("Export Question Bank")}
                  </h2>
                  <h2 className="mb-0 clr-primary">
                    {t("Select the questions to export")}
                  </h2>
                </div>
              </div>
            </Col>
          )}
        </Row>
      )}

      <div>
        <div
          className={classNames({
            "levels-row mb-4 d-flex align-items-center flex-wrap justify-content-between":
              !props.createFromQuestionBank,
            "levels-row mb-4 d-flex": props.createFromQuestionBank,
          })}
        >
          <div
            className={classNames({
              "d-flex align-items-center flex-wrap": true,
              "levels-row-width": props.createFromQuestionBank,
            })}
          >
            <h2 className="mb-0 mr-3 font-1">
              {isMgs ? t("Subjects") : t("Level")}:
            </h2>
            {years.map((year: any) => {
              return (
                <button
                  className={classNames({
                    "btn btn-tabs ": true,
                    active: selectedYear && year._id === selectedYear._id,
                  })}
                  onClick={() => {
                    setHasTopLevelApplied(true);
                    getAllModulesFromYearFun(year);
                    setSelectedNodes([]);
                  }}
                >
                  {year?.name}
                </button>
              );
            })}
            {!isArchive &&
              selectedYear &&
              selectedYear._id &&
              selectedYear &&
              selectedYear.group_type !== "system" &&
              !props.createFromQuestionBank && (
                <button
                  className="btn btn-link"
                  onClick={() => {
                    history.push(
                      MANAGEKNOWLEDGEGROUP + "?level=" + selectedYear._id
                    );
                  }}
                >
                  + {t("Edit")}
                </button>
              )}
          </div>
          {props.createFromQuestionBank && (
            <div className="ml-auto">
              {" "}
              <button
                className="btn btn-primary font-400 font-16 p-1 px-3"
                onClick={() => {
                  //   history.push(
                  //     REVIEW_QUIZ_QUESTIONS +
                  //       `?document=${selectedDoc[0]}&quizId=${
                  //         props.isEditQuiz && props.quizId ? props.quizId : ""
                  //       }&queTypes=${props.queTypes}`,
                  //     {
                  //       createFromUploadedDocument:
                  //         props.isEditQuiz && props.quizId
                  //           ? !props.createFromUploadedDocument
                  //           : props.createFromUploadedDocument,
                  //       queryString: props.queryString,
                  //       isEditQuiz: props.isEditQuiz,
                  //       quizId: props.quizId,
                  //       toolkitSmartQuizAddMoreQue:
                  //         props.toolkitSmartQuizAddMoreQue,
                  //     }
                  //   );
                  // }}
                  props.addMoreQuestionToQuiz(selectedQuestions, props.quizId);
                }}
                disabled={selectedQuestions?.length === 0}
              >
                {t("Import to Quiz")}
              </button>
            </div>
          )}
        </div>
        <Row className="mb-4 position-relative">
          {selectedYear && selectedYear.group_type !== "system" && (
            <Col lg={3} md={4} className="knowledge-modules mb-4 mb-md-0">
              <div className="knowledge-box p-0 build-knowbox text-left d-flex flex-column min-height-for-unmatched">
                <div className="d-flex justify-content-between align-items-center px-3 py-2">
                  <h2 className="mb-0 font-1">
                    {isMgs ? t("Chapters") : t("Modules")}
                  </h2>
                  {!isArchive && !props.createFromQuestionBank && (
                    <button
                      className="btn btn-link font-sm px-0 py-1"
                      onClick={() => {
                        updateParentHandler(null, selectedYear._id, false, -1);
                      }}
                    >
                      + {t("Add")}
                    </button>
                  )}
                </div>
                {modules.length > 0 && (
                  <div className="modules-list flex-grow-1 scroll-wrap">
                    <ul className="list-unstyled">
                      {modules.map((level: any) => {
                        return (
                          <li
                            onClick={() => {
                              if (level.depth === 0) {
                                setSelectedParentModule(level);
                              }
                            }}
                            key={
                              "child_padding_dev_" +
                              level.value +
                              "_" +
                              Math.floor(Math.random() * 9999)
                            }
                            style={{
                              paddingLeft: `${
                                level.depth > 0 ? level.depth * 10 + 10 : 10
                              }px`,
                              display: `${
                                isBranchUnderSelectedModule(level) ||
                                level.depth === 0
                                  ? "block"
                                  : "none"
                              }`,
                            }}
                            className={classNames({
                              "clr-grey-txt": level.depth > 0,
                              active: selectedGroupId === level.value,
                            })}
                          >
                            <a
                              href="javascript:void(0);"
                              onClick={() => {
                                // setSelectedNodes([]);
                                setHasTopLevelApplied(false);
                                onSelectGroupId(level.value);
                              }}
                              className={classNames({
                                "clr-grey-txt": level.depth > 0,
                              })}
                            >
                              {level?.label}
                            </a>
                            <Dropdown className="ml-auto action-dropdown">
                              <Dropdown.Toggle
                                variant="default"
                                id="dropdown-basic"
                              >
                                <svg
                                  width="14"
                                  height="4"
                                  viewBox="0 0 14 4"
                                  fill="none"
                                >
                                  <path
                                    d="M2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5C2.82843 0.5 3.5 1.17157 3.5 2C3.5 2.82843 2.82843 3.5 2 3.5ZM7 3.5C6.17157 3.5 5.5 2.82843 5.5 2C5.5 1.17157 6.17157 0.5 7 0.5C7.82843 0.5 8.5 1.17157 8.5 2C8.5 2.82843 7.82843 3.5 7 3.5ZM12 3.5C11.1716 3.5 10.5 2.82843 10.5 2C10.5 1.17157 11.1716 0.5 12 0.5C12.8284 0.5 13.5 1.17157 13.5 2C13.5 2.82843 12.8284 3.5 12 3.5Z"
                                    fill="#6B7085"
                                  />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    updateParentHandler(
                                      level.name,
                                      level._id,
                                      true,
                                      level.depth === 0 ? -1 : level.depth
                                    );
                                  }}
                                >
                                  {t("Edit")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    setDeleteModuleModalShow(true);
                                    setDeletedModuleId(level._id);
                                  }}
                                >
                                  {t("Delete")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    updateParentHandler(
                                      "",
                                      level._id,
                                      false,
                                      level.depth
                                    );
                                  }}
                                >
                                  {t("Add")}{" "}
                                  {level.depth === 0 || !level.depth
                                    ? "Topic"
                                    : level.depth > 0
                                    ? "Subtopic"
                                    : "Module"}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {modules.length === 0 && (
                  <div className="empty-knowledge text-center my-auto">
                    <button
                      className="btn"
                      onClick={() => {
                        setSelectedParentId(selectedYear._id);
                        setSelectedModuleName("");
                        updateModalHandler();
                      }}
                    >
                      <img src={plusIcon} alt="" />
                    </button>
                    <h3 className="h6 clr-primary mb-0 font-weight-bold">
                      {t("Click on Add a Module")}
                    </h3>
                  </div>
                )}

                {!isArchive && !props.createFromQuestionBank && (
                  <div className="text-center px-3 py-2">
                    <Link to={QUIZZES_ARCHIVE_BETA}>
                      <button className="btn btn-link">
                        <img src={archivedImg} alt="" className="mr-1" />{" "}
                        {t("Archived")}
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </Col>
          )}
          <div
            className={classNames({
              "knowledge-viewby": true,
              "col-lg-9 col-md-8":
                selectedYear && selectedYear.group_type !== "system",
              "col-lg-12 col-md-12":
                selectedYear && selectedYear.group_type === "system",
            })}
          >
            {!props.createFromQuestionBank && (
              <div className="knowledge-box px-3 py-2 build-knowbox text-left">
                <h2 className="mb-0 mr-3 font-1 d-md-inline-block">
                  {t("View")}:{" "}
                </h2>
                <Tabs
                  defaultActiveKey={activeTab}
                  onSelect={(e: any) => {
                    if (props.onTabChanges) {
                      props.onTabChanges(e);
                    }
                    setActiveTab(e);
                  }}
                  activeKey={activeTab}
                >
                  {!isMgs && (
                    <Tab eventKey="quizzes" title={t("Quizzes")}>
                      {quizHtml}
                    </Tab>
                  )}
                  <Tab eventKey="bank" title={t("Question Bank")}>
                    {activeTab === "bank" && (
                      <QuizQuestionBank
                        knowledge_group_ids={selectedGroupId}
                        includes_all_subgroups={hasTopLevelApplied}
                        selectedYear={selectedYear}
                        getAllYearsFun={getAllYearsFun}
                        activeTab={activeTab}
                        setRecentQuestion={setRecentQuestion}
                        isArchive={isArchive}
                        isMgs={isMgs}
                        getSelectedNodes={getSelectedNodes}
                      />
                    )}
                  </Tab>
                </Tabs>
              </div>
            )}
            {props.createFromQuestionBank && (
              <div className="knowledge-box px-3 py-2 build-knowbox text-left">
                <QuizQuestionBank
                  knowledge_group_ids={selectedGroupId}
                  includes_all_subgroups={hasTopLevelApplied}
                  selectedYear={selectedYear}
                  getAllYearsFun={getAllYearsFun}
                  activeTab={activeTab}
                  setRecentQuestion={setRecentQuestion}
                  isArchive={isArchive}
                  isMgs={isMgs}
                  getSelectedNodes={getSelectedNodes}
                  createFromQuestionBank={props.createFromQuestionBank}
                  setSelectedQuestions={setSelectedQuestions}
                />
              </div>
            )}
          </div>
        </Row>
      </div>
    </>
  );
}

export default QuizzesGridView;
