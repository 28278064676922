import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import KnowledgeDatatableComponentGridView from "../../../components/UI/KnowledgeDatatableComponent/KnowledgeDatatableComponentGridView";
import { KNOWLEDGE_ALL_OLD, UPLOADEDDOCUMENTS } from "../../../config";
import "./AllKnowledge.scss";

import { useTranslation } from "react-i18next";

export const AllKnowledge = (props: any) => {
  const { t } = useTranslation('translation');
  const isMgs = process.env.REACT_APP_CISCO === 'mgs'
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDocument, SetIsDocument] = useState( isMgs );
  const onTabChangesHandler = (key: string) => {
    SetIsDocument(key === 'viewDocument')
  }

  return (
    <section className="page-mid-wraper h-without-foter">
      <Container>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-12 d-flex justify-content-between align-items-center">
              <h1 className="mb-0">
                {isMgs ? t("Syllabus"): t("Knowledge")}
                </h1>
              {!isMgs && <button
                className="btn btn-link py-0"
                onClick={() => {
                  isDocument ? history.push(UPLOADEDDOCUMENTS) : history.push(KNOWLEDGE_ALL_OLD)
                }}
              >
                {t("View All")}
              </button>}
            </div>
            <KnowledgeDatatableComponentGridView onTabChanges={onTabChangesHandler}/>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AllKnowledge;
