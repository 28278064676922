import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getConversationTurnSummary } from '../../../../store/dashboard/actions'
import BarCompact from '../../BarCompact'

import { useTranslation } from "react-i18next";

interface Props {
  filtersData?: {
    filter_from_date?: string
    filter_to_date?: string
    channel?: string
  }
}

function AverageConvsersationalTurns({ filtersData }: Props): ReactElement {
  const { t } = useTranslation('translation');
  const [loading, setLoading] = useState(false)
  const [convosData, setConvoTurnsData] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    // console.log('[ ConvoTurnss filtersData ]', filtersData)
    if (
      loading ||
      !filtersData.filter_from_date ||
      !filtersData.filter_to_date
    ) {
      return
    }

    const fetchConvoTurnsData = async (filtersData: any) => {
      setLoading(true)
      try {
        const convoData: any = await dispatch(
          getConversationTurnSummary(filtersData)
        )
        await setConvoTurnsData(convoData)
        setLoading(false)
      } catch (e: any) {
        setLoading(false)
      }
    }
    fetchConvoTurnsData(filtersData)
  }, [filtersData])

  return (
    <div className='col-sm-6 col-md-4 col-lg-2'>
      <div className='graph-box'>
        <h3>{t("Avg Conversational turns")}</h3>
        <div className='row'>
          <div className='col-4'>
            {!loading && (
              <h2>
                {convosData && convosData.conversationTurnSummary.length
                  ? convosData.conversationTurnSummary[0]
                      .conversation_turn_average.toFixed(2)
                  : 0}
              </h2>
            )}
            {loading && (
              <div className='spinner-border spinner-border-sm text-primary'></div>
            )}
            {/* <div className='growth-percent growth-up'>10%</div> */}
          </div>
          <div className='col-8'>
            <div style={{ width: '100%', height: '50px' }}>
              {convosData &&
                convosData.conversationTurns &&
                convosData &&
                convosData.conversationTurns.length > 0 && (
                  <BarCompact
                    data={
                      convosData && convosData.conversationTurns
                        ? convosData.conversationTurns
                        : []
                    }
                    indexBy='date'
                    keys='conversation_turn_count'
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AverageConvsersationalTurns
