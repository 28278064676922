import { useEffect, useState } from "react";
import { Button, Image, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Select from "react-select";
import downarrowblk from "../../../../assets/images/chevron-down-blk.svg";
import closeimg from "../../../../assets/images/close-blk.svg";
import closeRed from "../../../../assets/New-images/close-icon-red.svg";

import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import { getAllOrganisationUsers } from "../../../../store/agents/actions";
import {
  addAdminsToClasses,
  addRoleUserToAdmins,
  addUserAsAdmins,
} from "../../../../store/knowledge/actions";

import { useTranslation } from "react-i18next";

const AddMultipleAdmins = (props: any) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const { classId }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedButtonTarget, setSelectedButtonTarget] = useState({
    user_ids: [],
    permission: "",
  });

  useEffect(() => {
    if (!props.show) {
      return;
    }
    setSelectedButtonTarget({
      user_ids: [],
      permission: "",
    });
    getAllUsers();
  }, [props]);

  const getAllUsers = async () => {
    try {
      const response: any = await dispatch(getAllOrganisationUsers());
      const data: any = [];
      response.users.forEach((element: any) => {
        data.push({
          _id: element._id,
          label: element.email,
          value: element.email,
        });
      });
      setUsers(data);
    } catch (error) {
      setUsers([]);
    }
  };

  const createOrUpdateAgent = async () => {
    if (
      !selectedButtonTarget.user_ids.length ||
      (
        !selectedButtonTarget.permission
        && !props.classMode
      )
    ) {
      return;
    }

    try {
      setLoading(true);
      const users: any = selectedButtonTarget.user_ids.map((d: any) => d._id);

      if ( props.classMode ) {
        const reponse: any = await dispatch(
          addAdminsToClasses(classId, users)
        );
      } else {
        await dispatch(addUserAsAdmins(props.agentId, users));
      }


      if (selectedButtonTarget.permission && !props.classMode) {
        await dispatch(
          addRoleUserToAdmins(
            props.agentId,
            users,
            selectedButtonTarget.permission,
            props.editData ? props.editData.is_superadmin : false
          )
        );
      }

      props.confirmHandler();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const removeUsersOfAUser = (classIndex: number) => {
    const oldData = [...selectedButtonTarget.user_ids];
    const deletedData: any = oldData.splice(classIndex, 1);
    // if (props.editMode) {
    //   dispatch(
    //     deleteLearnerToClasses(
    //       deletedData[0].value,
    //       props.editData._id,
    //       "students"
    //     )
    //   );
    // }
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      user_ids: [...oldData],
    });
  };

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-400"
      onHide={props.onHide}
    >
      <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
        <ModalTitle
          className="h5 py-1 font-600"
          id="contained-modal-title-vcenter"
        >
          {t("Add Learner")}
        </ModalTitle>
        <Button onClick={props.onHide} className="ml-auto">
          <Image src={closeimg} alt="" />
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="mb-12 mt-3">
          <h3 className="mb-1">{t("Add by user email")}</h3>
          <Select
            isSearchable
            isMulti
            isClearable={false}
            controlShouldRenderValue={false}
            className="select-target radius-2"
            classNamePrefix="select-target-prefix"
            components={{
              DropdownIndicator: (props: any) => (
                <img src={downarrowblk} alt={t("dropdown arrow")} />
              ),
              IndicatorSeparator: () => null,
            }}
            onChange={(e: any) => {
              setSelectedButtonTarget({
                ...selectedButtonTarget,
                user_ids: e, // .map((d: any) => d._id)
              });
            }}
            value={selectedButtonTarget.user_ids || []}
            placeholder={t("Select Users")}
            options={users}
          />
        </div>

        {selectedButtonTarget.user_ids.map(
          (classes: any, classIndex: number) => {
            return (
              <div
                className=" group-cross-tags d-flex align-items-center font-sm pl-2"
                key={"_class_" + classIndex}
              >
                <div className="font-600 font-16">{classes?.label}</div>
                <button
                  type="button"
                  className="btn ml-auto py-0"
                  onClick={() => {
                    removeUsersOfAUser(classIndex);
                  }}
                >
                  <img src={closeRed} alt="" />
                </button>
              </div>
            );
          }
        )}

        {!props.classMode && (
          <div className="mb-12 mt-2">
            <h3 className="mb-1">{t("Permissions")}</h3>
            <Select
              isSearchable={false}
              className="select-target radius-2"
              classNamePrefix="select-target-prefix"
              components={{
                //DropdownIndicator: () => null,
                DropdownIndicator: (props: any) => (
                  <img src={downarrowblk} alt={t("dropdown arrow")} />
                ),
                IndicatorSeparator: () => null,
              }}
              onChange={(e: any) => {
                setSelectedButtonTarget({
                  ...selectedButtonTarget,
                  permission: e.value,
                });
              }}
              placeholder={t("Permission")}
              getOptionLabel={(option: any) => option["label"]}
              getOptionValue={(option: any) => option["value"]}
              options={[
                { value: "admins", label: t("Can Edit") },
                { value: "readonlyadmins", label: t("View Only") },
              ]}
              defaultValue={
                props.editData
                  ? props.editData.role === "readonlyadmin"
                    ? { value: "readonlyadmins", label: t("View Only") }
                    : props.editData.role === "admin" ||
                      props.editData.is_superadmin
                    ? { value: "admins", label: t("Can Edit") }
                    : null
                  : null
              }
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <PermissionWrapper>
          <button
            disabled={loading}
            className="btn-outline-primary btn font-600 py-1"
            onClick={props.onHide}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary ml-3 font-600 py-1"
            onClick={createOrUpdateAgent}
            disabled={loading}
          >
            {props.editMode ? t("Update") : t("Add")}
          </button>
        </PermissionWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default AddMultipleAdmins;
