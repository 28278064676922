import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router";

import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CHAT_CUSTOMISATION, SUPPORT, USER_MANAGEMENT } from "../../../config";
import {
  getStickerSets
} from "../../../store/common/actions";
import {
  getFaqResponsesFromDocument
} from "../../../store/knowledge/actions";
import {
  getUserDocuments,
  iskeyWordsLoading,
} from "../../../store/knowledge/selector";

import chatImg1 from "../../../assets/New-images/chatbotImg1.svg";
import chatImg2 from "../../../assets/New-images/chatbotImg2.svg";
import docsummaryImg from "../../../assets/New-images/doc-summary-img.svg";
import learningPathImg from "../../../assets/New-images/learning-path.svg";


export const BuildKnowledgeReviewFaq = () => {
  const history = useHistory();
  const { t } = useTranslation("translation");
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [keywordGenerationProcessing, SetKeywordGenerationProcessing] =
    useState(false);
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [updatedFaqQuestions, setUpdatedFaqQuestions] = useState([]);
  const dispatch = useDispatch();
  const userDocuments = useSelector(getUserDocuments);
  const keyWordsLoading = useSelector(iskeyWordsLoading);
  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const params = query.get("question").split(",");
    if (params.length === 0) {
      history.push("/knowledge/knowledge-create-spreadsheet");
      return;
    }
    dispatch({
      type: "INIT_GENERATE_ANSWERS_SCREEN",
      payload: {
        keyWordsLoading: true,
      },
    });

    // ASK user before leave and then update
    // dispatch(
    //   updateOrganizationFlow({
    //     ...organizationFlowObject,
    //     prevRoute: '/knowledge/knowledge-create-spreadsheet',
    //     currentRoute: `${history.location.pathname}${history.location.search}`,
    //     nextRoute: `/knowledge/all`,
    //     setup_completed: 'false',
    //   })
    // )
    if (!keyWordsLoading) {
    }
    dispatch(getFaqResponsesFromDocument(userDocuments, params));
    dispatch(getStickerSets());
  }, []);

  useEffect(() => {
    setGeneratedQuestions([...userDocuments]);
    setUpdatedFaqQuestions([...userDocuments]);
    const keywordsLoading = userDocuments.filter(
      (document: any) => document.keywordsLoading
    );
    SetKeywordGenerationProcessing(keywordsLoading.length > 0 ? true : false);
  }, [userDocuments]);

//   const generateAnswers = async (e: any) => {
//     e.preventDefault();
//     const filteredDocuments = updatedFaqQuestions.filter(
//       (d: any) => d.qas.length > 0
//     );
//     if (filteredDocuments.length === 0) {
//       helper.showToaster(
//         t("None of documents has questions, Please add questions before proceeding.")
//       );
//       return;
//     }

//     const remainingQuestions: any = [];
//     const remainingResponses: any = [];
//     userDocuments.forEach((uD: any) => {
//       uD.qas.forEach((uDElement: any) => {
//         const qas = uDElement.questions.filter(
//           (qE: any) => qE.text.length === 0
//         );
//         const responses = uDElement.responses.filter(
//           (qE: any) => qE.text.length === 0
//         );
//         remainingQuestions.push(qas);
//         remainingResponses.push(responses);
//       });
//     });

//     if (
//       remainingQuestions.filter((d: any) => d.length > 0).length > 0 ||
//       remainingResponses.filter((d: any) => d.length > 0).length > 0
//     ) {
//       helper.showToaster(t("Please fill all inputs before proceeding."));
//       return;
//     }

//     try {
//       await dispatch(updateFaqResponsesFromDocument(filteredDocuments));
//       dispatch(
//         updateOrganizationFlow({
//           ...organizationFlowObject,
//           prevRoute: "/knowledge/knowledge-create-spreadsheet",
//           currentRoute: `${history.location.pathname}${history.location.search}`,
//           nextRoute: `/knowledge/all`,
//           setup_completed: "true",
//         })
//       );
//       history.push(`/knowledge/all`);
//     } catch (error: any) {}
//   };

  const updatedFaqsHandler = (data: any) => {
    const oldQuestions = [...generatedQuestions];
    const document = oldQuestions.find((d: any) => d._id === data.documentId);
    document.qas = [...data.qas];
    document.keywordsLoading = false;
    setUpdatedFaqQuestions([...oldQuestions]);
  };


  return (
    <>
    {/* {keywordGenerationProcessing && <Loading />}
      <section className="page-mid-wraper h-without-foter">
        <Container>
          <div className="row">
            <div className="col-sm-12">
              <button
                className="btn p-0 btn-back"
                onClick={() => {
                  history.goBack();
                }}
              >
                <Image src={backblue} /> {t("Return to previous page")}
              </button>
              <div className="text-center mb-32">
                <div className="d-flex flex-wrap justify-content-center">
                  <h1 className="clr-primary first-step mb-2">
                    {t("Import Predefined Q&As")}
                  </h1>
                </div>
                <h2 className="font-600">
                  {t(
                    "Upload Your Predefined Question and Answer Sets into your knowledge base"
                  )}
                </h2>
              </div>
            </div>
          </div>
          {!keyWordsLoading &&
            generatedQuestions.map((document, index) => {
              return (
                <QuestionList
                  key={document._id}
                  index={index}
                  documentName={document.name}
                  documentId={document._id}
                  documentQas={document.qas ? [...document.qas] : []}
                  documentLength={userDocuments.length}
                  updatedFaqsHandler={updatedFaqsHandler}
                />
              );
            })}
        </Container>
      </section> */}

      <React.Fragment>
      <section className="page-mid-wraper h-without-foter">
        <Container>
        <div className="text-center mb-32">
                <div className="d-flex flex-wrap justify-content-center">
                  <h1 className="clr-primary first-step mb-2">
                    {t("Import Predefined Q&As")}
                  </h1>
                </div>
                <h2 className="font-600">
                  {t(
                    "Upload Your Predefined Question and Answer Sets into your knowledge base"
                  )}
                </h2>
              </div>

          <div className="pdf-section-wrapper">
            <>
              <div className="document-section-wrapper row">
                <div className="col-md-12 d-flex flex-column text-center">
                  <div className="doc-processing d-flex flex-wrap align-items-center p-0">
                    {/* <h1 className="col-msg font-600">
                      {t("Congratulations! You have successfully built your knowledge base.")}
                    </h1> */}
                    {/* <h2 className="col-msg font-1 font-600">
                      {t(
                        "Your document has been saved and will be used to set up the context for your chatbot."
                      )}
                    </h2> */}
                  </div>
                  <div className="my-4">
                    <img src={docsummaryImg} alt="" style={{
                        maxHeight: '250px' 
                    }}/>
                    <div className="font-16 mt-3">
                      {t(
                        "You will receive a notification when the context setup has been completed."
                      )}
                      <br />
                      {t(
                        "Please ensure that your notifications are on so you don’t miss out on the update."
                      )}{" "}
                      <span className="clr-primary font-weight-bold cursor-pointer" onClick={() =>
                          history.push("/notification", {
                            activetab: "preference",
                          })
                        }>
                        {t("Check notification settings")}
                      </span>
                    </div>
                  </div>
                  <div className="p-3 radius-8 mt-auto text-left">
                    <h2 className="font-16 mb-3">
                      {t("While you wait, you can choose to do the following")}
                    </h2>
                    <Row>
                      <Col>
                        <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center" onClick={() => {
                            history.push(USER_MANAGEMENT)
                        }}>
                          <figure className="h-auto mb-0">
                            <img src={learningPathImg} alt="" />
                          </figure>
                          <div className="text-left pl-2">
                            <h3 className="font-weight-bold font-sm clr-primary mb-1">
                              {t("Create Learning Pathway")}
                            </h3>
                            <p className="clr-grey-txt7">
                              {t("Craft Customized Learning Pathways for Students")}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center" onClick={() => history.push(CHAT_CUSTOMISATION)}>
                          <figure className="h-auto mb-0">
                            <img src={chatImg1} alt="" />
                          </figure>
                          <div className="text-left pl-2">
                            <h3 className="font-weight-bold font-sm clr-primary mb-1">
                              {t("Manage your chatbot")}
                            </h3>
                            <p className="clr-grey-txt7">
                              {t("To match your your brand")}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="knowledge-box know-box-new radius-8 p-3 not-hover d-flex align-items-center"  onClick={() => history.push(SUPPORT)}>
                          <figure className="h-auto mb-0">
                            <img src={chatImg2} alt="" />
                          </figure>
                          <div className="text-left pl-2">
                            <h3 className="font-weight-bold font-sm clr-primary mb-1">
                              {t("Learn how to setup")}
                            </h3>
                            <p className="clr-grey-txt7">
                              {t("View our video guides")}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* <div className="font-600 font-sm mt-3">
                      {t(
                        "Please ensure that your notifications are on so that you don’t miss out the update."
                      )}{" "}
                      <span className="clr-primary font-weight-bold">
                        {t("Check notification settings")}
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </>
          </div>
        </Container>
      </section>
    </React.Fragment>
    </>
  );
};

export default BuildKnowledgeReviewFaq;
