import React from "react";
import SummariseDocuments from "../../../BuildKnowledgeV2/Step3/SummariseDocuments";
import { TOOLKIT_SETUP_CONTEXTUALISED_CHAT_WAITING } from "../../../../config";

export const SetupContextualisedSummarise = (props: any) => {
  return (
    <SummariseDocuments
      hideModalSummariseIndividual
      redirectURI={TOOLKIT_SETUP_CONTEXTUALISED_CHAT_WAITING}
      summarise
      routeDocumentIds
      summaryType="single"
      target_flow={"contextualised_setup"}
    />
  );
};

export default SetupContextualisedSummarise;
