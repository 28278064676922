import React from 'react'
import { Image } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import DragDropimg from "../../../assets/New-images/build-drag-drop.svg";

import { useTranslation } from "react-i18next";

interface props {
  getFileDocumentsHandler: (documents: any) => void,
  supportedTypes?: string;
  maxFiles?: number;
  message?: string;
  fileSizeMessage?: string;
  fileImage?: any;
}

export const DragAndDrop: React.FunctionComponent<props> = (props: props) => {
  const { t } = useTranslation('translation');
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: files =>  props.getFileDocumentsHandler(files),
    accept: props.supportedTypes ? props.supportedTypes : '.doc, .docx, .ppt, .pptx, .pdf, .txt',
    maxFiles: props.maxFiles ? props.maxFiles : 5,
  })

  const defaultMessage = t("<><b>Drop</b> your image here or click to <b>browse.</b></>")
  return (
    <div className='custom-file-wrap' {...getRootProps()}>
      <input {...getInputProps()} />
      <Image src={props.fileImage ? props.fileImage : DragDropimg} />
      <b className='pb-2 d-block mt-3'>
        <span dangerouslySetInnerHTML={{ __html: props.message ? props.message : defaultMessage }}>
        </span>
        {!props.fileSizeMessage && !props.hasOwnProperty('fileSizeMessage') && (<><br />{t("You can upload upto")} {props.maxFiles ? props.maxFiles : 5} {t("files.")}</>)}
        {(props.fileSizeMessage || props.hasOwnProperty('fileSizeMessage')) && (<><br /> {props.fileSizeMessage}</>)}
      </b>
      {/* <label>{t("Supported File types: .docx, pdf, pptx")} </label> */}
    </div>
  )
}

export default DragAndDrop
