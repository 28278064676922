import {
  Button,
  ButtonGroup,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Select from "react-select";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import { getCommonState } from "../../../../store/common/selector";
import unansweredFlagicon from "../../../../assets/New-images/flag-outline-red.svg";

import { useTranslation } from "react-i18next";

export const conditionalRowStyles = [
  {
    when: (row: any) => row.is_hiccup === true,
    style: {
      borderLeft: "3px solid rgba(255, 0, 158, 0.7)",
    },
  },
];

export const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#0923E6",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        img: {
          width: "10px",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "0px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#0923E6",
      width: "250px",
      padding: "20px 20px",
      fontSize: "14px",
      color: "#fff",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#fff",
      },
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      "&:nth-child(1)": {
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
      "&:nth-child(2)": {
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
    },
  },
};

export const PopoverComponent = (data: any) => {
  return (
    <Popover id="popover-basic-" {...data.popoverComponentProps}>
      {/* <Popover.Title as='h3'>Popover right</Popover.Title> */}
      <Popover.Content
        dangerouslySetInnerHTML={{
          __html: data.entity ? data.entity : "",
        }}
      ></Popover.Content>
    </Popover>
  );
};

export const Answer = ({ row }: any) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement="auto"
    overlay={(d) => (
      <PopoverComponent
        popoverComponentProps={d}
        row={row}
        entity={row.reply_text}
      />
    )}
  >
    <div
      data-tag="allowRowEvents"
      style={{
        color: "#07032B",
        overflow: "hidden",
        textOverflow: "ellipses",
      }}
    >
      {row.reply_text ? row.reply_text : ""}
    </div>
  </OverlayTrigger>
);

export const Question = ({ row }: any) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement="auto"
    overlay={(d) => (
      <PopoverComponent
        popoverComponentProps={d}
        row={row}
        entity={row.question_text}
      />
    )}
  >
    <div
      data-tag="allowRowEvents"
      style={{
        color: "#07032B",
        overflow: "hidden",
        textOverflow: "ellipses",
      }}
    >
      {row.question_text ? row.question_text : ""}
    </div>
  </OverlayTrigger>
);

export const TotalAsked = ({ row }: any) => <div>{row.total_asked}</div>;
export const UserName = ({ row }: any) => <div>{row.author_email}</div>;
export const FeedBack = ({ row }: any) => {
  return (
    <>
      {row.feedback_type === 1 && <i className="icon-thumbs-up" />}
      {row.feedback_type === 0 && <i className="icon-thumbs-down" />}
      {row.feedback_type === 3 && (
        <img className="" src={unansweredFlagicon} alt="" />
      )}
      <div className="ml-2">{row.comment}</div>
    </>
  );
};

export const DateComponent = ({ row }: any) => (
  <div>
    <Moment format="DD/MM/YYYY" utc local>
      {row.created_at}
    </Moment>
  </div>
);

export const Acknowledge = ({ row, acknowledgeConfirmed }: any) => {
  const commonState = useSelector(getCommonState);
  return (
    <div>
      <div className="pref-check">
        <input
          type="checkbox"
          disabled={
            commonState &&
            commonState.user &&
            commonState.user.role === "readonlyadmin"
          }
          defaultChecked={!!row.is_solved}
          onChange={() => {
            acknowledgeConfirmed(row._id);
          }}
        />
      </div>
    </div>
  );
};

export const ActionColumn = ({
  row,
  editKnowledgehandler,
  addToExistingIntent,
  unmatched,
}: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  return (
    <div className="d-flex flex-wrap">
      <PermissionWrapper>
        <Select
          isSearchable={false}
          className="select-sort select-target"
          classNamePrefix="select-target-prefix"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e: any) => {
            if (e.value === "edit" && editKnowledgehandler && !unmatched) {
              editKnowledgehandler({
                _id: row.predicted_intent_id,
              });
            }
            if (e.value === "new") {
              history.push("/knowledge/knowledge-create-from-scratch");
            }
            if (e.value === "existing") {
              addToExistingIntent(row);
            }
          }}
          placeholder={t("Add to Knowledge")}
          controlShouldRenderValue={false}
          options={
            !unmatched
              ? [
                  { value: "edit", label: t("Edit this question") },
                  // { value: "new", label: t("Add new") },
                  { value: "existing", label: t("Add to existing knowledge") },
                ]
              : [
                  { value: "new", label: t("Add new") },
                  { value: "existing", label: t("Add to existing knowledge") },
                ]
          }
        />
      </PermissionWrapper>
    </div>
  );
};

export const userFeedbackColumns = ({
  editKnowledgehandler,
  addToExistingIntent,
  acknowledgeConfirmed,
  unmatched,
}: any) => {
  return [
    {
      name: "Question",
      selector: "question_text",
      sortable: true,
      cell: (row: any) => <Question row={row} />,
    },
    {
      name: "Answer",
      selector: "reply_text",
      sortable: true,
      cell: (row: any) => <Answer row={row} />,
    },
    {
      name: "Feedback",
      selector: "comment",
      sortable: true,
      cell: (row: any) => <FeedBack row={row} />,
    },
    {
      name: "Email",
      selector: "author_email",
      sortable: true,
      cell: (row: any) => <UserName row={row} />,
    },
    {
      name: "Date (DD/MM/YYYY)",
      selector: "created_at",
      sortable: true,
      cell: (row: any) => <DateComponent row={row} />,
    },
    {
      name: "Ack",
      selector: "created_at",
      sortable: false,
      cell: (row: any) => (
        <Acknowledge row={row} acknowledgeConfirmed={acknowledgeConfirmed} />
      ),
    },
    {
      name: "Action",
      selector: "",
      sortable: false,
      cell: (row: any) => (
        <ActionColumn
          row={row}
          unmatched={unmatched}
          editKnowledgehandler={editKnowledgehandler}
          addToExistingIntent={addToExistingIntent}
        />
      ),
    },
  ];
};
