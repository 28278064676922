import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { HelperService } from "../../../services/helper.service";
import {
  updateReviews,
  updateSubmissionsAcknowledge,
} from "../../../store/quizzes/actions";
import OverrideUserScorePopup from "./popup/OverrideUserScorePopup";

// import TextInput from "../../../components/TextInput";
// import Button from "../../../components/Button";
// import Checkbox from "../../../components/Checkbox";
const helper: any = new HelperService();
const AuthorSubmission = ({
  quizId,
  submission,
  actionLabel,
  markReviewed,
  getSubmissions,
  connectionIdClassMap,
  enableLearning,
  isForReview,
}: any) => {
  // let makeApiCall = useFetch();
  const { t } = useTranslation();
  const [comment, setComment] = useState<any>({});
  const [manualScoreInputs, setManualScoreInputs] = useState<any>({});
  const [shouldOverride, setShouldOverride] = useState(false);

  const [showOverrideScoreModal, setShowOverrideScoreModal] = useState(false);
  // const { setSnackbar } = useAuth();
  const dispatch = useDispatch();
  const useLocatio = useLocation();

  useEffect(() => {
    let tempManualScores: any[] = [];
    submission.answers.forEach((answer: any) => {
      tempManualScores[answer.questionId] = {
        question_id: answer.questionId,
        score:
          "correctedScore" in answer ? answer.correctedScore.toString() : "",
      };
    });
    setManualScoreInputs(tempManualScores);

    let tempComment: any = [];
    submission.answers.forEach((answer: any) => {
      tempComment[answer.questionId] = {
        question_id: answer.questionId,
        reviewer_comment:
          "reviewer_comment" in answer ? answer.reviewer_comment : "",
      };
    });
    setComment(tempComment);
  }, []);

  const submitReview = async (value: boolean = false) => {
    let transformedScores: any = [];
    transformedScores = Object.keys(manualScoreInputs).map((questionId) => ({
      ...manualScoreInputs[questionId],
      score: manualScoreInputs[questionId].score
        ? Number(manualScoreInputs[questionId].score)
        : null,
      reviewer_comment:
        comment[questionId].reviewer_comment !== "-"
          ? comment[questionId].reviewer_comment
          : "",
    }));

    try {
      await dispatch(
        updateSubmissionsAcknowledge(quizId, submission.submissionId, {
          answers: transformedScores,
          is_overriding: value,
        })
      );
      const query = new URLSearchParams(useLocatio.search);
      const reviewId = query.get("reviewId");
      if (reviewId) {
        await dispatch(updateReviews(reviewId));
      }

      getSubmissions();
      markReviewed && markReviewed(submission.reviewId);
      await setShowOverrideScoreModal(false);
      helper.showToaster("Correct scores submitted successfully", "success");
    } catch (error) {
      await setShowOverrideScoreModal(false);
      helper.showToaster(
        "Oops! Could not submit scores. Please try again.",
        "success"
      );
    }
  };

  const getTableDisplay = (
    data: any,
    answerKeySettings: any,
    noHeader: any,
    prop: any = null
  ) => {
    let temp = data && typeof data === "string" ? JSON.parse(data) : data;
    if (
      answerKeySettings &&
      answerKeySettings.column_headers &&
      answerKeySettings.column_headers.length
    ) {
      temp &&
        temp.map((row: any, index: any) =>
          row.unshift(answerKeySettings.column_headers[index])
        );
    }

    return (
      <table className="quiz-table-answer-table">
        {!noHeader && (
          <thead>
            <tr>
              {answerKeySettings &&
                answerKeySettings.headers &&
                answerKeySettings.headers.map((column: any) => (
                  <th className="quiz-table-answer-cell">{column}</th>
                ))}
            </tr>
          </thead>
        )}
        <tbody>
          {temp &&
            temp.map((row: any) => (
              <tr>
                {row.map((column: any) => (
                  <td className="quiz-table-answer-cell">{column}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const getMatchDisplay = (data: any, answerKeySettings: any) => {
    return (
      <table className="quiz-table-answer-table">
        <thead>
          <tr>
            {answerKeySettings &&
              answerKeySettings.headers &&
              answerKeySettings.headers.map((column: any) => (
                <th className="quiz-table-answer-cell">{column}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row: any) => (
            <tr>
              <td className="quiz-table-answer-cell">{row.term}</td>
              <td className="quiz-table-answer-cell">{row.definition}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const divideArray = (data: any, controlColumnCount: any) => {
    let temp = [...data];
    let result: any = [];
    if (temp.length > 0) {
      result = new Array(Math.ceil(temp.length / controlColumnCount))
        .fill(null)
        .map((_) => temp.splice(0, controlColumnCount));
    }
    return result;
  };

  const getNewTableDisplay = (data: any, answerKeySettings: any) => {
    let temp: any = [];
    if (
      answerKeySettings &&
      answerKeySettings.headers &&
      "column_count" in answerKeySettings &&
      answerKeySettings.headers.length === answerKeySettings.column_count
    ) {
      temp = [...answerKeySettings.headers];
      temp.unshift("");
    }
    return (
      <table className="quiz-table-answer-table">
        <thead>
          <tr>
            {answerKeySettings &&
              answerKeySettings.headers &&
              temp.map((column: any) => (
                <th className="quiz-table-answer-cell">{column}</th>
              ))}
          </tr>
        </thead>

        <tbody>
          {divideArray(data, answerKeySettings.column_count).map(
            (row: any, index: any) => (
              <tr>
                {answerKeySettings && answerKeySettings.column_headers && (
                  <th className="quiz-table-header-cell">
                    {answerKeySettings.column_headers[index]}
                  </th>
                )}
                {row.map((column: any) => (
                  <td className="quiz-table-answer-cell">{column.text}</td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
    );
  };

  const getBulletDisplay = (data: any) => {
    if (data.split("\n").length > 0) {
      const itemsMapped = data.split("\n").map((point: any, index: number) => {
        if (point && point.split("- ").length > 1) {
          return <li>{point.split("- ")[1]}</li>;
        } else if (point && point.split(/^\d. /).length > 1) {
          return <li>{point.split(/^\d. /)[1]}</li>;
        } else {
          return <li>{point}</li>;
        }
      });
      return <ul>{itemsMapped}</ul>;
    } else return data;
  };

  const getNewBulletDisplay = (data: any) => {
    return (
      <ul>
        {data.map((item: any) => (
          <li>{item.text}</li>
        ))}
      </ul>
    );
  };

  const transformTableData = (data: any) => {
    let rows: any = [];
    data.answers.forEach((prop: any, index: any) => {
      rows.push({
        questionId: prop.questionId,
        parentId: data.questions[index].parent_id,
        type: data.questions[index].type,
        id: index.toString(),
        question: (
          <div
            dangerouslySetInnerHTML={{ __html: data.questions[index].text }}
          />
        ),
        correctAnswer: prop.correctAnswer.map(
          (answer: any, localIndex: any) => {
            if (data.questions[index].type === "description") {
              return getTableDisplay(
                "text" in answer ? answer.text : "",
                data.questions[index].answer_key_settings,
                data.questions[index].type === "description",
                prop
              );
            } else if (
              data.questions[index].type === "table" &&
              "bullets" in answer
            ) {
              return getNewTableDisplay(
                "bullets" in answer ? answer.bullets : [],
                data.questions[index].answer_key_settings
              );
            } else if (data.questions[index].type === "table") {
              return getTableDisplay(
                "text" in answer ? answer.text : "",
                data.questions[index].answer_key_settings,
                false
              );
            } else if (data.questions[index].type === "bullet") {
              return getBulletDisplay(
                answer.text !== null ? answer.text : answer
              );
            } else if (data.questions[index].type === "newbullet") {
              return getNewBulletDisplay(answer.bullets);
            } else if (data.questions[index].type === "matching") {
              return getMatchDisplay(
                "matches" in answer ? answer.matches : [],
                data.questions[index].answer_key_settings
              );
            } else {
              return (
                <div
                  key={localIndex}
                  dangerouslySetInnerHTML={{
                    __html: answer.text !== null ? answer.text : answer,
                  }}
                />
              );
            }
          }
        ),
        submittedAnswer:
          prop.submittedAnswer &&
          prop.submittedAnswer.map((answer: any, localIndex: any) => {
            if (
              data.questions[index].type === "table" ||
              data.questions[index].type === "description"
            ) {
              return getTableDisplay(
                answer,
                data.questions[index].answer_key_settings,
                data.questions[index].type === "description"
              );
            } else if (
              data.questions[index].type === "bullet" ||
              data.questions[index].type === "newbullet"
            ) {
              return getBulletDisplay(answer);
            } else if (data.questions[index].type === "matching") {
              return getMatchDisplay(
                answer && answer.matches ? answer.matches : [],
                data.questions[index].answer_key_settings
              );
            } else {
              return (
                <div
                  key={localIndex}
                  dangerouslySetInnerHTML={{ __html: answer }}
                />
              );
            }
          }),
        submissionClass:
          data.connectionId &&
          connectionIdClassMap &&
          data.connectionId in connectionIdClassMap
            ? connectionIdClassMap[data.connectionId]
            : "-",
        botScore:
          prop.maxScore > 0 ? (
            <span
              className={
                prop.isCorrect === "-"
                  ? "score-cell"
                  : prop.isCorrect
                  ? "score-cell green-score"
                  : "score-cell red-score"
              }
            >
              {prop.correctedScore
                ? prop.correctedScore.toFixed(2)
                : prop.score}{" "}
              / {prop.maxScore}
            </span>
          ) : (
            "-"
          ),
        userComment:
          data.comments && data.comments[index] ? data.comments[index] : "",
        manualScore:
          prop.maxScore > 0 ? (
            <input
              type="text"
              key={`${index.toString()}${data.questions[index].id}`}
              onChange={(event: any) => {
                if (event.target.value.match(/^[0-9]*(\.[0-9]{0,2})?$/)) {
                  onManualScoreChange(
                    event.target.value,
                    data.questions[index].id,
                    index
                  );
                }
              }}
              className="no-spin-arrows form-control"
              value={
                prop.questionId in manualScoreInputs
                  ? manualScoreInputs[prop.questionId].score
                  : ""
              }
            />
          ) : (
            "-"
          ),
        comment: (
          <input
            type="text"
            key={`${index.toString()}${data.questions[index].id}`}
            onChange={(event: any) =>
              onCommentChange(
                event.target.value,
                data.questions[index].id,
                index
              )
            }
            className="no-spin-arrows form-control"
            value={
              prop.questionId in comment
                ? comment[prop.questionId].reviewer_comment
                : ""
            }
          />
        ),
      });
    });

    let temp: any = {};
    rows.forEach((row: any) => {
      if (row.parentId && temp[row.parentId]) {
        temp[row.parentId] = {
          ...temp[row.parentId],
          subquestions:
            "subquestions" in temp[row.parentId]
              ? temp[row.parentId].subquestions.concat([row])
              : [].concat([row]),
        };
      } else {
        temp[row.questionId] = row;
      }
    });

    return Object.values(temp);
  };

  const onManualScoreChange = (value: any, questionId: any, index: any) => {
    let temp: any = { ...manualScoreInputs };
    temp[questionId] = {
      question_id: questionId,
      score: value,
    };
    setManualScoreInputs(temp);
  };

  const onCommentChange = (value: any, questionId: any, index: any) => {
    let temp: any = { ...comment };
    temp[questionId] = {
      question_id: questionId,
      reviewer_comment: value,
    };
    setComment(temp);
  };

  const getItemHTML = (item: any, index: any, type: any) => {
    return (
      <React.Fragment>
        {type !== "sub" && <div className="review-number">{index + 1}</div>}
        <div className="flex-container">
          <div className="flex-one">
            <p className="submission-data-table-label">{t("Question")}</p>
            {item.question}
          </div>
          {item.type !== "group" && (
            <React.Fragment>
              <div className="flex-one">
                <p className="submission-data-table-label">
                  {t("Recommended Answer")}
                </p>
                {item.correctAnswer}
              </div>
              <div className="flex-one">
                <p className="submission-data-table-label">
                  {t("Learner Answer")}
                </p>
                {item.submittedAnswer}
              </div>
            </React.Fragment>
          )}
        </div>
        {item.type !== "group" && (
          <React.Fragment>
            <div className="clearfix" />
            <div className="flex-container">
              {enableLearning && (
                <div className="flex-one">
                  <p className="submission-data-table-label">{t("Class")}</p>
                  {item.submissionClass}
                </div>
              )}
              <div className="flex-one">
                <p className="submission-data-table-label">
                  {t("Learner Score/Max Score")}
                </p>
                {item.botScore}
              </div>
              {/* {isForReview && ( */}
                <div className="flex-one">
                  <p className="submission-data-table-label">
                    {t("Learner Comment/Concern")}
                  </p>
                  {item.userComment}
                </div>
              {/* )} */}
              <div className="flex-one">
                <p className="submission-data-table-label">
                  {t("Correct Score (if applicable)")}
                </p>
                {item.manualScore}
              </div>
              <div className="flex-one">
                <p className="submission-data-table-label">
                  {t("Your Comments")}
                </p>
                {item.comment}
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="clearfix" />
        {item.type === "group" && "subquestions" in item && (
          <div>
            {item.subquestions.map((question: any, localIndex: any) => (
              <div>
                <div className="dashed-separator" />
                <div className="sub-question-number">
                  {t("Question")} {index}.{localIndex + 1}
                </div>
                {getItemHTML(question, localIndex, "sub")}
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="submission-data-wrapper">
      <OverrideUserScorePopup
        show={showOverrideScoreModal}
        onHide={() => setShowOverrideScoreModal(false)}
        onProceed={(value: any) => {
          submitReview(value);
        }}
      />
      {/* {!isSingleSubmission && (
        <div className="submission-data-header">
          Submission {submissionIndex + 1} (User score: {submission.score})
        </div>
      )} */}
      {transformTableData(submission).map((item, index) => {
        return (
          <div className="table-row">
            {getItemHTML(item, index, null)}
            <div className="clearfix" />
          </div>
        );
      })}
      <div className="footer-action-btn-container">
        <Button
          className="btn btn-outline-primary font-600 mt-3"
          onClick={() => {
            setShowOverrideScoreModal(true);
          }}
          variant="default"
        >
          {actionLabel}
        </Button>
      </div>
    </div>
  );
};

export default AuthorSubmission;
