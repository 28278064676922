import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import alarm from "../../assets/images/alarm.svg";
import logo from "../../assets/images/logo-head.svg";
import lab from "../../assets/images/noun_lab.svg";
import * as CONFIG from "../../config";
import { HelperService } from "../../services/helper.service";
import { logoutUser } from "../../store/auth/actions";
import { turnOffNewNotificationsStatus } from "../../store/common/actions";
import {
  getCommonUserDetails,
  getIsSuperAdmin,
  getNewNotificationStatus,
} from "../../store/common/selector";
import { useAuth0 } from "../../utils/react-auth0-wrapper";
import { Notifications } from "../UI/Notifications/Notifications";
import { TestBot } from "../UI/TestBot/TestBot";
import Select from "react-select";
import ChangeLanguage from "./ChangeLanguage";
import OnlySupperAdmin from "../UI/OnlySupperAdmin/OnlySupperAdmin";

import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../context/GlobalContext";
import EmailVerifyReminder from "../UI/EmailVerifyReminder/EmailVerifyReminder";
import CommonService from "../../services/CommonService";

type Props = {};

const helperService = new HelperService();
const commonService = new CommonService();

export const Header = React.memo((props: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const { logout, getIdTokenClaims } = useAuth0();
  const {
    selectedAgentName,
    setSelectedAgentName,
    selectedAgentEmail,
    setSelectedAgentEmail,
  } = useGlobalContext();
  const history = useHistory();
  const IsSuperAdmin = useSelector(getIsSuperAdmin);
  const hasNewNotification = useSelector(getNewNotificationStatus);
  const [showNotifications, setshowNotifications] = useState(false);
  const [showChangeLanguage, setShowChangeLanguage] = useState(false);
  const [showPayment, setShowPayment] = useState(false);

  const [hasNewNotificationStatus, setHasNewNotificationStatus] =
    useState(false);
  const [botView, setbotView] = useState(false);
  const windowObj: any = window;

  const userDetails = useSelector(getCommonUserDetails);
  const [selectedAgent, setSelectedAgent] = useState(
    localStorage.getItem("selectedAgent")
  );

  const navBarItems = [
    {
      route: "/knowledge/all",
      name: t("Manage Knowledge"),
    },
    // {
    //   route: '/knowledge',
    //   name: 'Build Knowledge',
    // },
    // {
    //   route: CONFIG.MANAGEDOCUMENTS,
    //   name: 'Manage Documents',
    // },
    // {
    //   route: CONFIG.UPLOADEDDOCUMENTS,
    //   name: 'Manage Documents',
    // },
    // {
    //   route: CONFIG.KNOWLEDGE_MANAGEUNANSWERED,
    //   name: 'Manage Unanswered',
    // },
  ];

  // useEffect(() => {
  //   return () => {}
  // }, [])

  const getPaymentInfo = async () => {
    const res: any = await commonService.getPaymentInfo();
    if (res?.data?.data.licenses && res?.data?.data.licenses.length > 0) {
      setShowPayment(true);
    }
  };

  useEffect(() => {
    getPaymentInfo();
    if (hasNewNotification) {
      // helperService.showNotificationToaster('You have a new notification.');
    }
    setHasNewNotificationStatus(hasNewNotification);
    return () => {};
  }, [hasNewNotification]);

  const logoutHandler = () => {
    localStorage.clear();
    // logout({
    //   returnTo: window.location.origin,
    // })
    if (windowObj.REACT_APP_SSO_PROVIDER === "oneauth") {
      dispatch(logoutUser());
    } else {
      logout({
        returnTo: window.location.origin,
      });
    }
  };

  const navItemHandler = (e: any) => {
    e.preventDefault();
    history.push(e.target.dataset.route);
  };

  const toggleNotificationsView = (e: any) => {
    e.preventDefault();
    // dispatch({
    //   type: 'ALLNOTIFICATIONS',
    //   payload: {
    //     hasNewNotification: false
    //   }
    // });
    if (hasNewNotificationStatus) {
      dispatch(turnOffNewNotificationsStatus());
    }
    setshowNotifications(!showNotifications);
    setbotView(false);
  };

  const toggleBotView = (e: any) => {
    e.preventDefault();
    setbotView(!botView);
    setshowNotifications(false);
  };

  // const selectedAgentName = localStorage.getItem("selectedAgentName");
  // const selectedAgentEmail = localStorage.getItem("selectedAgentEmail");

  const renderMgsHeaderOptions = () => (
    <Nav className="mr-auto">
      <NavLink activeClassName="active" to={""} exact className="nav-link">
        {t("Syllabus")}
      </NavLink>

      <NavLink
        activeClassName="active"
        to={CONFIG.QUIZZES}
        className="nav-link"
      >
        {t("Assessments")}
      </NavLink>
      <NavLink
        activeClassName="active"
        to={CONFIG.SUPPORT}
        className="nav-link"
      >
        {t("Support")}
      </NavLink>
    </Nav>
  );

  return (
    <>
      <header
        className={cx({
          __super: IsSuperAdmin,
          organisation_header: props.hideElementsForOrganization,
        })}
      >
        <Container>
          <Navbar expand="lg">
            {!props.hideElementsForOrganization && (
              <NavLink to={""} className="navbar-brand mr-lg-2">
                <Image src={logo} />
              </NavLink>
            )}

            {process.env && process.env.REACT_APP_CISCO === "cisco" && (
              <div className="ml-auto user-dropdown">
                <div className="text-elipse">{selectedAgentName}</div>
                <NavDropdown
                  title={
                    selectedAgentName || selectedAgentEmail
                      ? selectedAgentEmail
                        ? selectedAgentEmail.slice(0, 2).toUpperCase()
                        : selectedAgentName.slice(0, 2).toUpperCase()
                      : t("NF")
                  }
                  id="basic-nav-dropdown"
                  className="user-img"
                >
                  <NavDropdown.Item onClick={logoutHandler}>
                    {t("Logout")}
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            )}
            {process.env && process.env.REACT_APP_CISCO !== "cisco" && (
              <>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  {!props.hideElementsForOrganization && (
                    <>
                      {process.env.REACT_APP_CISCO === "mgs" ? (
                        renderMgsHeaderOptions()
                      ) : (
                        <Nav className="mr-auto">
                          <NavLink
                            activeClassName="active"
                            to={"/dashboard"}
                            exact
                            className="nav-link"
                          >
                            {t("Dashboard")}
                          </NavLink>
                          {/* <NavLink
                          activeClassName='active'
                          to={'/greeting'}
                          className='nav-link'
                        >
                          Greeting
                        </NavLink> */}

                          <NavLink
                            activeClassName="active"
                            to={"/knowledge/all"}
                            className="nav-link"
                          >
                            {t("Knowledge")}
                          </NavLink>

                          {/* <NavDropdown
                          title='Knowledge'
                          id='basic-nav-dropdown'
                          active={
                            history.location.pathname === '/knowledge/all' ||
                            history.location.pathname === '/knowledge' ||
                            history.location.pathname === '/uploaded-documents' ||
                            history.location.pathname === '/manage-documents'
                          }
                        >
                          {navBarItems.map((item: any, index: number) => {
                            return (
                              <NavDropdown.Item
                                onClick={navItemHandler}
                                data-route={item.route}
                                className={cx({
                                  active:
                                    history.location.pathname === item.route,
                                })}
                              >
                                {item.name}
                              </NavDropdown.Item>
                            )
                          })}
                        </NavDropdown> */}
                          {/* <NavLink
                          activeClassName='active'
                          to={'/fallback'}
                          className='nav-link'
                        >
                          Fallback
                        </NavLink> */}

                          <NavLink
                            activeClassName="active"
                            to={"/conversation-flow"}
                            className="nav-link"
                          >
                            {t("Conversation Flow")}
                          </NavLink>

                          <NavLink
                            activeClassName="active"
                            to={"/forms?viewMode=true"}
                            className="nav-link"
                          >
                            {t("Forms")}
                          </NavLink>
                          <NavLink
                            activeClassName="active"
                            to={CONFIG.QUIZZES_BETA}
                            className="nav-link"
                          >
                            {t("Quizzes")}
                          </NavLink>

                          {/* <NavDropdown
                            title={t("Quizzes")}
                            id="basic-nav-dropdown"
                            active={
                              history.location.pathname ===
                                "/quizzes?viewMode=true" ||
                              history.location.pathname === CONFIG.QUIZZES_BETA
                            }
                          >
                            <NavDropdown.Item
                              onClick={navItemHandler}
                              data-route={"/quizzes?viewMode=true"}
                              className={cx({
                                active:
                                  history.location.pathname ===
                                  "/quizzes?viewMode=true",
                              })}
                            >
                              {t("Quizzes")}
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              onClick={navItemHandler}
                              data-route={CONFIG.QUIZZES_BETA}
                              className={cx({
                                active:
                                  history.location.pathname ===
                                  CONFIG.QUIZZES_BETA,
                              })}
                            >
                              {t("Quizzes ( Beta )")}
                            </NavDropdown.Item>
                          </NavDropdown> */}

                          <NavLink
                            activeClassName="active"
                            to={"/chat-customisation?viewMode=true"}
                            className="nav-link"
                          >
                            {t("Channels")}
                          </NavLink>

                          <NavLink
                            activeClassName="active"
                            to={"/user-management?viewMode=true"}
                            className="nav-link"
                          >
                            {t("Users")}
                          </NavLink>
                          <NavLink
                            activeClassName="active"
                            to={"/support"}
                            className="nav-link"
                          >
                            {t("Support")}
                          </NavLink>
                          {/* <Link to={'conversation-flow'} className='nav-link'>
                  Conversation Flow
                  <Nav.Link href='#home'>Fallback</Nav.Link>
                </Link>
                <Nav.Link href='#home'>Testing</Nav.Link> */}
                        </Nav>
                      )}

                      <div
                        className={cx({
                          notification: true,
                          hasNewNofitication: hasNewNotificationStatus,
                        })}
                      >
                        {process.env.REACT_APP_CISCO !== "mgs" && (
                          <Image
                            src={lab}
                            className="mr-3"
                            onClick={toggleBotView}
                          />
                        )}
                        <span className="mr-1 notify-indicator">
                          <Image
                            src={alarm}
                            onClick={toggleNotificationsView}
                          />
                        </span>
                      </div>
                    </>
                  )}
                  <div className="user-dropdown">
                    <div className="text-elipse">{selectedAgentName}</div>
                    <NavDropdown
                      title={
                        selectedAgentName || selectedAgentEmail
                          ? selectedAgentEmail
                            ? selectedAgentEmail.slice(0, 2).toUpperCase()
                            : selectedAgentName.slice(0, 2).toUpperCase()
                          : t("NF")
                      }
                      id="basic-nav-dropdown"
                      className="user-img"
                    >
                      {/* <NavDropdown.Item href='#'>Switch Agent</NavDropdown.Item> */}
                      {!props.noAgentFound && (
                        <>
                          <div className="d-flex align-items-center dropdown-head">
                            <div className="header-avatar d-flex align-items-center justify-content-center">
                              {selectedAgentName || selectedAgentEmail
                                ? selectedAgentEmail
                                  ? selectedAgentEmail.slice(0, 2).toUpperCase()
                                  : selectedAgentName.slice(0, 2).toUpperCase()
                                : t("NF")}
                            </div>
                            <div className="pl-2">
                              <div>{userDetails.full_name}</div>
                              <span>{selectedAgentName}</span>
                            </div>
                          </div>
                          {(userDetails.role === "superadmin" ||
                            userDetails.role === "admin") && (
                            <NavDropdown.Item
                              href={`${windowObj.CHATBOT_URL}?agent=${selectedAgent}&through=admin-portal`}
                              target="_blank"
                            >
                              <span className="menu-icons">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <path
                                    d="M2.25 11.0877V13.9122L6.95775 11.0877H10.5C11.3272 11.0877 12 10.415 12 9.58774V3.58774C12 2.76049 11.3272 2.08774 10.5 2.08774H1.5C0.67275 2.08774 0 2.76049 0 3.58774V9.58774C0 10.415 0.67275 11.0877 1.5 11.0877H2.25ZM1.5 3.58774H10.5V9.58774H6.54225L3.75 11.2632V9.58774H1.5V3.58774Z"
                                    fill="#6B7085"
                                  />
                                  <path
                                    d="M12.5 0.087738H3.5C2.67275 0.087738 2 0.760488 2 1.58774H11C11.8272 1.58774 12.5 2.26049 12.5 3.08774V9.08774C13.3272 9.08774 14 8.41499 14 7.58774V1.58774C14 0.760488 13.3272 0.087738 12.5 0.087738Z"
                                    fill="#6B7085"
                                  />
                                </svg>
                              </span>
                              <span>{t("View as Student")}</span>
                            </NavDropdown.Item>
                          )}
                          <hr />
                          <NavLink
                            activeClassName="active"
                            to={CONFIG.SWITCHAGENT}
                            className="dropdown-item"
                          >
                            <span className="menu-icons">
                              <svg
                                width="10"
                                height="12"
                                viewBox="0 0 10 12"
                                fill="none"
                              >
                                <path
                                  d="M6.43313 0.175782C6.55033 0.0632287 6.70927 0 6.875 0C7.04073 0 7.19967 0.0632287 7.31687 0.175782L9.81688 2.57728C9.93404 2.68987 9.99987 2.84255 9.99987 3.00175C9.99987 3.16095 9.93404 3.31363 9.81688 3.42621L7.31687 5.82771C7.199 5.93708 7.04112 5.99759 6.87725 5.99622C6.71338 5.99486 6.55662 5.93172 6.44074 5.8204C6.32486 5.70909 6.25913 5.5585 6.2577 5.40109C6.25628 5.24367 6.31928 5.09202 6.43313 4.97878L7.86625 3.60212H0.625C0.45924 3.60212 0.300269 3.53887 0.183058 3.42628C0.0658481 3.31368 0 3.16098 0 3.00175C0 2.84252 0.0658481 2.68981 0.183058 2.57722C0.300269 2.46463 0.45924 2.40137 0.625 2.40137H7.86625L6.43313 1.02471C6.31596 0.912125 6.25013 0.759445 6.25013 0.600247C6.25013 0.441049 6.31596 0.288369 6.43313 0.175782ZM3.56687 6.17953C3.68404 6.29212 3.74987 6.4448 3.74987 6.604C3.74987 6.7632 3.68404 6.91588 3.56687 7.02846L2.13375 8.40512H9.375C9.54076 8.40512 9.69973 8.46838 9.81694 8.58097C9.93415 8.69356 10 8.84627 10 9.0055C10 9.16473 9.93415 9.31744 9.81694 9.43003C9.69973 9.54262 9.54076 9.60588 9.375 9.60588H2.13375L3.56687 10.9825C3.68072 11.0958 3.74372 11.2474 3.7423 11.4048C3.74087 11.5623 3.67514 11.7128 3.55926 11.8242C3.44338 11.9355 3.28662 11.9986 3.12275 12C2.95888 12.0013 2.801 11.9408 2.68313 11.8315L0.183125 9.42997C0.0659557 9.31738 0.000133514 9.1647 0.000133514 9.0055C0.000133514 8.8463 0.0659557 8.69362 0.183125 8.58103L2.68313 6.17953C2.80033 6.06698 2.95927 6.00375 3.125 6.00375C3.29073 6.00375 3.44967 6.06698 3.56687 6.17953Z"
                                  fill="#6B7085"
                                />
                              </svg>
                            </span>
                            <span>{t("Switch Agent")}</span>
                          </NavLink>
                          <OnlySupperAdmin>
                            {process.env.REACT_APP_CISCO !== "mgs" && (
                              <NavLink
                                to={CONFIG.INTEGRATION}
                                className="dropdown-item"
                              >
                                <span className="menu-icons">
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                  >
                                    <path
                                      d="M8.09091 10.5455H10.5455M10.5455 10.5455H13M10.5455 10.5455V8.09091M10.5455 10.5455V13M2.63636 5.90909H4.27273C4.70672 5.90909 5.12293 5.73669 5.42981 5.42981C5.73669 5.12293 5.90909 4.70672 5.90909 4.27273V2.63636C5.90909 2.20237 5.73669 1.78616 5.42981 1.47928C5.12293 1.1724 4.70672 1 4.27273 1H2.63636C2.20237 1 1.78616 1.1724 1.47928 1.47928C1.1724 1.78616 1 2.20237 1 2.63636V4.27273C1 4.70672 1.1724 5.12293 1.47928 5.42981C1.78616 5.73669 2.20237 5.90909 2.63636 5.90909ZM2.63636 13H4.27273C4.70672 13 5.12293 12.8276 5.42981 12.5207C5.73669 12.2138 5.90909 11.7976 5.90909 11.3636V9.72727C5.90909 9.29328 5.73669 8.87707 5.42981 8.57019C5.12293 8.26331 4.70672 8.09091 4.27273 8.09091H2.63636C2.20237 8.09091 1.78616 8.26331 1.47928 8.57019C1.1724 8.87707 1 9.29328 1 9.72727V11.3636C1 11.7976 1.1724 12.2138 1.47928 12.5207C1.78616 12.8276 2.20237 13 2.63636 13ZM9.72727 5.90909H11.3636C11.7976 5.90909 12.2138 5.73669 12.5207 5.42981C12.8276 5.12293 13 4.70672 13 4.27273V2.63636C13 2.20237 12.8276 1.78616 12.5207 1.47928C12.2138 1.1724 11.7976 1 11.3636 1H9.72727C9.29328 1 8.87707 1.1724 8.57019 1.47928C8.26331 1.78616 8.09091 2.20237 8.09091 2.63636V4.27273C8.09091 4.70672 8.26331 5.12293 8.57019 5.42981C8.87707 5.73669 9.29328 5.90909 9.72727 5.90909Z"
                                      stroke="#6B7085"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span>{t("Integration")}</span>
                              </NavLink>
                            )}
                          </OnlySupperAdmin>
                          {/* <NavLink
                            activeClassName='active'
                            to={'/class-management?viewMode=true'}
                            className='dropdown-item'
                          >
                            Manage Classes
                          </NavLink> */}

                          {/* <NavLink
                            exact
                            activeClassName='active'
                            to={'/organisation/users?viewMode=true'}
                            className='dropdown-item'
                          >
                            Manage All Users
                          </NavLink> */}
                          <NavDropdown.Item
                            href="#"
                            target="_blank"
                            onClick={() => setShowChangeLanguage(true)}
                          >
                            <span className="menu-icons">
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M5.994 0C2.682 0 0 2.688 0 6C0 9.312 2.682 12 5.994 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 5.994 0ZM10.152 3.6H8.382C8.19421 2.85687 7.91614 2.13954 7.554 1.464C8.64847 1.8408 9.5707 2.59903 10.152 3.6ZM6 1.224C6.498 1.944 6.888 2.742 7.146 3.6H4.854C5.112 2.742 5.502 1.944 6 1.224ZM1.356 7.2C1.26 6.816 1.2 6.414 1.2 6C1.2 5.586 1.26 5.184 1.356 4.8H3.384C3.336 5.196 3.3 5.592 3.3 6C3.3 6.408 3.336 6.804 3.384 7.2H1.356ZM1.848 8.4H3.618C3.81 9.15 4.086 9.87 4.446 10.536C3.35038 10.1613 2.4275 9.40249 1.848 8.4ZM3.618 3.6H1.848C2.4275 2.59751 3.35038 1.83874 4.446 1.464C4.08386 2.13954 3.80579 2.85687 3.618 3.6ZM6 10.776C5.502 10.056 5.112 9.258 4.854 8.4H7.146C6.888 9.258 6.498 10.056 6 10.776ZM7.404 7.2H4.596C4.542 6.804 4.5 6.408 4.5 6C4.5 5.592 4.542 5.19 4.596 4.8H7.404C7.458 5.19 7.5 5.592 7.5 6C7.5 6.408 7.458 6.804 7.404 7.2ZM7.554 10.536C7.914 9.87 8.19 9.15 8.382 8.4H10.152C9.5707 9.40097 8.64847 10.1592 7.554 10.536ZM8.616 7.2C8.664 6.804 8.7 6.408 8.7 6C8.7 5.592 8.664 5.196 8.616 4.8H10.644C10.74 5.184 10.8 5.586 10.8 6C10.8 6.414 10.74 6.816 10.644 7.2H8.616Z"
                                  fill="#6B7085"
                                />
                              </svg>
                            </span>
                            <span>{t("Change Language")}</span>
                          </NavDropdown.Item>
                          {showPayment && (
                            <NavLink
                              to={CONFIG.PLANS}
                              className="dropdown-item"
                            >
                              <span className="menu-icons">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <path
                                    d="M2.25 11.0877V13.9122L6.95775 11.0877H10.5C11.3272 11.0877 12 10.415 12 9.58774V3.58774C12 2.76049 11.3272 2.08774 10.5 2.08774H1.5C0.67275 2.08774 0 2.76049 0 3.58774V9.58774C0 10.415 0.67275 11.0877 1.5 11.0877H2.25ZM1.5 3.58774H10.5V9.58774H6.54225L3.75 11.2632V9.58774H1.5V3.58774Z"
                                    fill="#6B7085"
                                  />
                                  <path
                                    d="M12.5 0.087738H3.5C2.67275 0.087738 2 0.760488 2 1.58774H11C11.8272 1.58774 12.5 2.26049 12.5 3.08774V9.08774C13.3272 9.08774 14 8.41499 14 7.58774V1.58774C14 0.760488 13.3272 0.087738 12.5 0.087738Z"
                                    fill="#6B7085"
                                  />
                                </svg>
                              </span>
                              <span>{t("Manage Subscription")}</span>
                            </NavLink>
                          )}
                          <hr />
                          <NavDropdown.Item
                            href="https://www.noodlefactory.ai/privacy-policy/"
                            target="_blank"
                          >
                            <span className="menu-icons">
                              <svg
                                width="13"
                                height="14"
                                viewBox="0 0 13 14"
                                fill="none"
                              >
                                <path
                                  d="M10.1429 6.71429V3.14229C10.1429 3.09718 10.134 3.05251 10.1168 3.01084C10.0995 2.96916 10.0742 2.9313 10.0423 2.89943L8.24343 1.10057C8.17919 1.03625 8.09204 1.00008 8.00114 1H1.34286C1.25193 1 1.16472 1.03612 1.10042 1.10042C1.03612 1.16472 1 1.25193 1 1.34286V12.0857C1 12.1766 1.03612 12.2639 1.10042 12.3282C1.16472 12.3924 1.25193 12.4286 1.34286 12.4286H6.14286M3.28571 5.57143H7.85714M3.28571 3.28571H5.57143M3.28571 7.85714H5M7.85714 1V2.94286C7.85714 3.03379 7.89327 3.121 7.95756 3.18529C8.02186 3.24959 8.10907 3.28571 8.2 3.28571H10.1429M10.1383 8.5L11.5989 8.87086C11.7509 8.90971 11.8577 9.048 11.8531 9.20457C11.7549 12.4949 9.85714 13 9.85714 13C9.85714 13 7.95943 12.4949 7.86114 9.20457C7.85938 9.12835 7.88359 9.05379 7.9298 8.99315C7.97601 8.9325 8.04147 8.88938 8.11543 8.87086L9.576 8.5C9.76057 8.45314 9.95372 8.45314 10.1383 8.5Z"
                                  stroke="#6B7085"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                            <span>{t("Privacy Policy")}</span>
                          </NavDropdown.Item>
                          {/* <NavDropdown.Item
                            href={windowObj.OLD_PORTAL_URI}
                            target='_self'
                          >
                            Switch to old portal
                          </NavDropdown.Item> */}
                        </>
                      )}
                      <NavDropdown.Item onClick={logoutHandler}>
                        <span className="menu-icons">
                          <svg
                            width="14"
                            height="12"
                            viewBox="0 0 14 12"
                            fill="none"
                          >
                            <path
                              d="M8.33333 3.33334V2.00001C8.33333 1.64638 8.19286 1.30724 7.94281 1.0572C7.69276 0.807147 7.35362 0.666672 7 0.666672H2.33333C1.97971 0.666672 1.64057 0.807147 1.39052 1.0572C1.14048 1.30724 1 1.64638 1 2.00001V10C1 10.3536 1.14048 10.6928 1.39052 10.9428C1.64057 11.1929 1.97971 11.3333 2.33333 11.3333H7C7.35362 11.3333 7.69276 11.1929 7.94281 10.9428C8.19286 10.6928 8.33333 10.3536 8.33333 10V8.66667"
                              stroke="#0E0748"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5 6H13M13 6L11 4M13 6L11 8"
                              stroke="#0E0748"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <span>{t("Logout")}</span>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </Navbar.Collapse>
              </>
            )}
          </Navbar>
          {!props.hideElementsForOrganization && <EmailVerifyReminder />}

          <ChangeLanguage
            show={showChangeLanguage}
            onHide={() => setShowChangeLanguage(false)}
          />
        </Container>

        {!props.hideElementsForOrganization &&
          !props.noAgentFound &&
          process.env &&
          process.env.REACT_APP_CISCO !== "cisco" && (
            <>
              <aside
                className={cx({
                  "notification-wrap": true,
                  open: showNotifications,
                })}
              >
                <Notifications
                  toggleNotificationsView={toggleNotificationsView}
                />
              </aside>
              <aside
                className={cx({
                  "notification-wrap": true,
                  open: botView,
                })}
              >
                <TestBot toggleBotView={toggleBotView} />
              </aside>
            </>
          )}
      </header>
      {props.hideElementsForOrganization && <EmailVerifyReminder />}
    </>
  );
});

export default Header;
