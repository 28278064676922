import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getAllQuizSubmissions,
  updateSubmissionsAcknowledge,
} from "../../../store/quizzes/actions";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { getClasses } from "../../../store/dashboard/actions";
import { Button } from "react-bootstrap";
import QuizSumbmissionDetails from "./QuizSubmissionDetails";
import { useTranslation } from "react-i18next";
import { QUIZZSUBMISSIONBYSUBMISSIONID } from "../../../config";
import { DashboardFilters } from "../../../components/UI/Dashboard";

// import QuizSumbmissionDetails from "./quizSubmissionDetails";
// import IconLabelButton from "../../../components/IconLabelButton";
// import Checkbox from "../../../components/Checkbox";
// import Dropdown from "../../../components/Dropdown";

const QuizSubmissionList = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [channels, setChannels] = useState([]);
  const [quizSubmissions, setQuizSubmissions] = useState([]);
  const [showSubmissionDetailScreen, setShowSubmissionDetailsScreen] =
    useState(false);
  const [authorEmail, setAuthorEmail] = useState("");
  const [submissionId, setSubmissionId] = useState("");
  const [pending, setPending] = useState(false);
  const [classDropdownData, setClassDropdownData] = useState<any[]>([]);
  const [selectedClass, setSelectedClass] = useState<any>({
    value: "",
    label: "All Classes",
    connectionIds: null,
  });
  const [connectionIdClassMap, setConnectionIdClassMap] = useState<any>({});
  const [filtersData, setFiltersData] = useState({
    connection_id: null,
    channel: null,
  });

  useEffect(() => {
    const func = async () => {
      const { classes }: any = await dispatch(getClasses());

      getClassesForUser(classes);
      //   const data: any[] = [...classes];
      //   data.unshift({
      //     value: "",
      //     label: "All Classes",
      //   });
      //   let temp: any = {};
      //   data.forEach((record) => {
      //     classes.forEach((connection: any) => {
      //       temp[connection.connection_id] = record.name;
      //     });
      //   });
      //   setConnectionIdClassMap(temp);
      //   setChannels(data);
    };
    // func();
  }, []);

  const dateSort = (rowA: any, rowB: any) => {
    let temp =
      rowA.submittedOn > rowB.submittedOn
        ? 1
        : rowB.submittedOn > rowA.submittedOn
        ? -1
        : 0;
    return temp;
  };

  const learningQuizDetailsColumns = [
    {
      name: t("SNo"),
      selector: "serial",
      sortable: false,
      maxWidth: "60px",
      minWidth: "60px",
    },
    {
      name: t("Name"),
      selector: "name",
      sortable: true,
    },
    {
      name: t("Email/Session"),
      selector: "email",
      sortable: true,
      grow: 2,
    },
    {
      name: t("Score"),
      selector: "maxScore",
      sortable: true,
      wrap: true,
    },
    {
      name: t("Submitted at"),
      selector: "submittedOn",
      sortable: true,
      wrap: true,
      sortFunction: dateSort,
      format: (row: any, index: any) => {
        return (
          <Moment format="DD/MM/YYYY HH:mm" utc local>
            {row.submittedOn}
          </Moment>
        );
      },
    },
    {
      name: t("Class"),
      selector: "submissionClass",
      sortable: true,
      wrap: true,
    },
    {
      name: t("Submitted from"),
      selector: "submittedFrom",
      sortable: true,
      wrap: true,
    },
    {
      name: t("Ack"),
      selector: "acknowledge",
      sortable: true,
      wrap: true,
    },
    {
      name: t("Action"),
      selector: "action",
      sortable: true,
      wrap: true,
    },
  ];

  const quizDetailsColumns = [
    {
      name: t("SNo"),
      selector: "serial",
      sortable: false,
      maxWidth: "60px",
      minWidth: "60px",
    },
    {
      name: t("Name"),
      selector: "name",
      sortable: true,
    },
    {
      name: t("Email/Session"),
      selector: "email",
      sortable: true,
      grow: 2,
    },
    {
      name: t("Score"),
      selector: "maxScore",
      sortable: true,
      wrap: true,
    },
    {
      name: t("Submitted on"),
      selector: "submittedOn",
      sortable: true,
      wrap: true,
      sortFunction: dateSort,
      format: (row: any, index: any) => {
        return (
          <Moment format="DD/MM/YYYY HH:mm" utc local>
            {row.submittedOn}
          </Moment>
        );
      },
    },
    {
      name: t("Submitted from"),
      selector: "submittedFrom",
      sortable: true,
      wrap: true,
    },
    {
      name: t("Ack"),
      selector: "acknowledge",
      sortable: true,
      wrap: true,
    },
    {
      name: t("Action"),
      selector: "action",
      sortable: true,
      wrap: true,
    },
  ];

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderRadius: "10px",
        backgroundColor: "#0923E6",
        borderBottomWidth: "0",
      },
    },
    expanderRow: {
      style: {
        // backgroundColor: '#0923E6',
      },
    },
    expanderCell: {
      style: {
        "&:hover": {
          backgroundColor: "transparent",
        },
        button: {
          backgroundColor: "transparent !important",
          img: {
            width: "10px",
          },
        },
      },
    },
    expanderButton: {
      style: {
        backgroundColor: "transparent",
        alignItems: "center",
      },
    },
    headCells: {
      style: {
        "&:first-child": {
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "0px",
        },
        "&:last-child": {
          borderTopRightRadius: "5px",
        },
        "&.chart--table-sort-icon": {
          marginLeft: "10px",
        },
        'input[type="checkbox"]': {
          width: "16px",
          height: "16px",
        },
        backgroundColor: "#0923E6",
        //width: "250px",
        padding: "10px 10px",
        fontSize: "14px",
        color: "#fff",
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: "#FFFFFF",
        },
        div: {
          color: "#fff",
        },
      },
    },
    cells: {
      style: {
        color: "#07032B",
        fontSize: "14px",
        padding: "10px 10px",
        'input[type="checkbox"]': {
          width: "16px",
          height: "16px",
        },
        // "&:nth-child(1)": {
        //   div: {
        //     textOverflow: "ellipsis",
        //     display: " -webkit-box",
        //     WebkitLineClamp: "2",
        //     WebkitBoxOrient: "vertical",
        //   },
        // },
        // "&:nth-child(2)": {
        //   div: {
        //     textOverflow: "ellipsis",
        //     display: " -webkit-box",
        //     WebkitLineClamp: "2",
        //     WebkitBoxOrient: "vertical",
        //   },
        // },
      },
    },
  };

  // useEffect(() => {
  //   setPending(true);
  //   getQuizSubmissions(
  //     selectedClass.connectionIds ? selectedClass.connectionIds : ""
  //   );
  // }, [showSubmissionDetailScreen === false]);

  useEffect(() => {
    if (pending) {
      return;
    }
    console.log('[ filtersData ]', filtersData, pending)
    setPending(true)
    getQuizSubmissions(
      selectedClass.connectionIds ? selectedClass.connectionIds : ""
    );
  }, [ filtersData ]);

  const getQuizSubmissions = async (connectionIds: any) => {
    let quizId = props.quizId ? props.quizId : "";

    try {
      await setPending(true);
      const response: any = await dispatch(
        getAllQuizSubmissions(quizId, filtersData)
      );
      setQuizSubmissions(response.quiz_submissions);
      await setPending(false);
    } catch (error) {
      await setPending(false);
      setQuizSubmissions([]);
    }
  };

  const getClassesForUser = (classes: any) => {
    setClassDropdownData(transformClassDropdownData(classes));
    let temp: any = {};
    classes.forEach((record: any) => {
      record.connections.forEach((connection: any) => {
        temp[connection.connection_id] = record.name;
      });
    });
    setConnectionIdClassMap(temp);
  };

  const transformClassDropdownData = (rawData: any) => {
    let temp: any[] = [
      {
        value: "",
        label: "All Classes",
      },
    ];

    rawData.forEach((record: any) => {
      temp.push({
        value: record._id,
        label: record.name,
        connectionIds: getConnectionIds(record),
      });
    });

    return temp;
  };

  const getConnectionIds = (classRecord: any) => {
    let connectionIds = "";
    if ("connections" in classRecord) {
      classRecord.connections.forEach((record: any) => {
        connectionIds = connectionIds + "," + record.connection_id;
      });
    }
    return connectionIds.substring(1);
  };

  const acknowlegeSubmission = async (
    quiz_id: any,
    submission_id: any,
    value: any
  ) => {
    try {
      setPending(true);
      await dispatch(
        updateSubmissionsAcknowledge(quiz_id, submission_id, {
          is_acknowledged: value,
        })
      );
      getQuizSubmissions(
        selectedClass.connectionIds ? selectedClass.connectionIds : ""
      );
      setPending(false);
    } catch (error) {}
  };

  const transformTableData = (data: any) => {
    function capitalizeFirstLetter(string: any) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    let details: any = [];
    let quizId = props.quizId ? props.quizId : "";
    data.forEach((prop: any, key: any) => {
      details.push({
        id: key,
        serial: key + 1,
        name:
          prop.author_first_name || prop.author_last_name
            ? `${prop.author_first_name} ${prop.author_last_name}`
            : "-",
        email: prop.author_email ? prop.author_email : "User",
        avgScore: prop.average_score
          ? parseFloat(prop.average_score).toFixed(2)
          : 0.0,
        // maxScore: Math.round((prop.score + Number.EPSILON) * 100) / 100,
        maxScore:
          props.decimalPlaces === "0"
            ? Math.floor(prop.score) + "/" + prop.quiz_score
            : parseFloat(prop.score).toFixed(props.decimalPlaces) +
              "/" +
              parseFloat(prop.quiz_score).toFixed(props.decimalPlaces),
        totalSubmissions: prop.submission_count,
        submittedFrom: prop.channel ? capitalizeFirstLetter(prop.channel) : "-",
        submittedOn: prop.created_at ? prop.created_at : "-",
        submissionClass:
          prop.connection_id in connectionIdClassMap
            ? connectionIdClassMap[prop.connection_id]
            : "-",
        acknowledge: (
          <>
            {" "}
            <div className="custom-checkbox mr-3 font-16">
              <input
                type="checkbox"
                className="mr-2"
                id="quiz-required"
                checked={prop.is_acknowledged}
                onChange={(e: any) => {
                  acknowlegeSubmission(quizId, prop._id, e.target.checked);
                }}
              />
              {/* <label htmlFor="quiz-required" className="mb-0 clr-grey-text">
                Mark quiz as required
              </label> */}
            </div>
          </>
        ),
        action: (
          <Button
            onClick={() => {
              setAuthorEmail(prop.author_email);
              setSubmissionId(prop._id);
              setShowSubmissionDetailsScreen(true);
              history.push(
                QUIZZSUBMISSIONBYSUBMISSIONID.replace(":quizId", quizId)
                  .replace(":submissionId", prop._id)
                  .replace(":email", prop.author_email)
              );
            }}
            variant="default"
            className="btn clr-primary font-sm font-600 p-0"
          >
            {t("View")}
          </Button>
        ),
      });
    });
    return details;
  };

  const handleClassFilterChange = (value: any) => {
    setSelectedClass(value);
    getQuizSubmissions(value.connectionIds);
  };

  const conditionalRowStyles = [
    {
      when: (row: any) => row,
      style: {
        borderLeft: "4px solid #f9b234",
      },
    },
  ];

  const hasFilterUpdated = async (e: any) => {
    await setFiltersData({
      ...filtersData,
      connection_id: e.connection_ids,
      channel: e.channel,
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-start mb-4">
        <div>
          <h2 className="mb-1">{t("View all Submission")}</h2>
          <div className="clr-grey font-600">
            {t("View all the submissions for the quiz by users.")}
          </div>
        </div>

        {/* {classDropdownData.length > 1 &&
          props.enableLearning &&
          !showSubmissionDetailScreen && (
            <div className="class-filter-dropdown-container">
              <Select
                className="select-sort select-target"
                classNamePrefix="select-target-prefix"
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder={t("All Channels")}
                options={classDropdownData}
                data={classDropdownData}
                value={selectedClass}
                onChange={handleClassFilterChange}
              />
            </div>
          )} */}
      </div>
      <div className="blue-filters">
        <DashboardFilters hasFilterUpdated={hasFilterUpdated} hideDateOptions />
      </div>

      {!showSubmissionDetailScreen && (
        <DataTable
          noHeader
          columns={
            props.enableLearning
              ? learningQuizDetailsColumns
              : quizDetailsColumns
          }
          data={transformTableData(quizSubmissions)}
          customStyles={customStyles}
        />
      )}
      {showSubmissionDetailScreen && (
        <QuizSumbmissionDetails
          isForReview={false}
          quizId={props.quizId}
          authorEmail={authorEmail}
          submissionId={submissionId}
          onBack={() => setShowSubmissionDetailsScreen(false)}
          actionLabel={t("Update Scores")}
          decimalPlaces={props.decimalPlaces}
          connectionIdClassMap={connectionIdClassMap}
          enableLearning={props.enableLearning}
        />
      )}
    </div>
  );
};

export default QuizSubmissionList;
