import cx from "classnames";
import * as _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MANAGECATEGORY, UPLOADEDDOCUMENTS } from "../../../config";
import "../../../routes/Knowledge/AllKnowledge/AllKnowledge.scss";
import KnowledgeCreateFromScratch from "../../../routes/Knowledge/KnowledgeCreateFromScratch/KnowledgeCreateFromScratch";
import ManageSuggestions from "../../../routes/Knowledge/Suggestions/ManageSuggestions";
import {
  bulkAssignModules,
  bulkSuggestKnowledgeByIds,
  deleteKnowledgeById,
  downloadKnowledgeAsCSV,
  getAllKnowledge,
  getAllTopics,
  suggestKnowledgeById,
} from "../../../store/knowledge/actions";
import {
  isAllKnowledgeError,
  isAllKnowledgeLoading,
  isAllKnowledgeSuccess,
  isTopicsLoading,
  getAllTopicsSelector,
} from "../../../store/knowledge/selector";
import { getAllUserGroupOptions } from "../../../store/notifications/actions";
import { getAllIntents } from "../../../store/questions/actions";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Tags from "../Tags/Tags";
import MoveSelectedModal from "./MoveSelectedModal";

import { useTranslation } from "react-i18next";

import IntentDataTable from "../IntentDataTable";

export const KnowledgeDataTable = (props: any) => {
  const { t } = useTranslation("translation");
  const allTopics = useSelector(getAllTopicsSelector);
  const [selectedTopic, setSelectedTopic] = useState({
    value: "",
    label: t("All Years"),
  });
  const [selectedSort, setSelectedSort] = useState({
    field: "",
    direction: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [topicIds, setTopicIds] = useState("");
  const allKnowledgeSuccess = useSelector(isAllKnowledgeSuccess);
  const allKnowledgeLoading = useSelector(isAllKnowledgeLoading);
  const topicsLoading = useSelector(isTopicsLoading);
  const allKnowledgeError = useSelector(isAllKnowledgeError);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [suggestionMode, setSuggestionMode] = useState(false);
  const tableWrapperRef = useRef(null);
  const [scrollRight, setScrollRight] = useState(0);
  /**
   * Edit mode activate or de activate
   */
  const [editMode, setEditMode] = useState(false);
  const [selectedIntent, setSelectedIntent] = useState(null);
  const editKnowledgehandler = (intent: any) => {
    const editModeCheck = !editMode;
    setSelectedIntent(intent);
    setEditMode(editModeCheck);
  };

  useEffect(() => {
    dispatch(
      getAllKnowledge(
        3,
        currentPage,
        perPage,
        query,
        topicIds,
        {
          sort_field: selectedSort.field,
          sort_order: selectedSort.direction,
        },
        selectedNodes.length > 0,
        props.documentId,
        selectedTopic?.value || "",
        true
      )
    );
  }, [selectedSort.field, selectedSort.direction]);

  const resetEditKnowledgehandler = (action: any) => {
    const editModeCheck = !editMode;
    setSelectedIntent(null);
    setEditMode(editModeCheck);
    // From edit mode if any intent gets deleted then we have to reload data
    if (action === "reload") {
      dispatch(
        getAllKnowledge(
          3,
          currentPage,
          perPage,
          query,
          topicIds,
          {},
          selectedNodes.length > 0,
          props.documentId
        )
      );
    }
  };

  // Show delete modal
  const [modalShow, setModalShow] = useState(false);
  const modalhandler = (value: boolean) => {
    setModalShow(value);
  };
  const [deletedRowId, setDeletedRowId] = useState([]);
  const deleteKnowledgeHandler = (
    id: string[],
    isMultipleDelete: boolean = false
  ) => {
    const msg = isMultipleDelete
      ? t(
          "You are deleting multiple questions. Note that all the children of these questions will also be deleted. Are you sure you want to delete?"
        )
      : t(
          "You are deleting a question. Note that all the children of this question will also be deleted. Are you sure you want to delete?"
        );
    setConfirmationMessage(msg);
    setDeletedRowId(id);
    setModalShow(true);
  };

  useEffect(() => {
    if (!topicsLoading) {
      dispatch(getAllTopics());
      dispatch(getAllIntents());
      dispatch(getAllUserGroupOptions()); // user groups
    }

    // Table wrapper reference to check scroll left value of an element
    if (
      tableWrapperRef &&
      tableWrapperRef.current &&
      tableWrapperRef.current.children[0]
    ) {
      setScrollRight(tableWrapperRef.current.children[0].scrollLeft);
    }

    return () => {
      setScrollRight(0);
    };
  }, []);

  useEffect(() => {
    setTotalRows(allKnowledgeSuccess?.pagination?.total_items);
  }, [allKnowledgeSuccess]);
  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = (page: number) => {
    // e.preventDefault();
    // console.log('[[[[[ DISPATCHED ]]]]]', currentPage, page)
    setCurrentPage(page);
    dispatch(
      getAllKnowledge(
        3,
        page,
        perPage,
        query,
        topicIds,
        {
          sort_field: selectedSort.field,
          sort_order: selectedSort.direction,
        },
        selectedNodes.length > 0,
        props.documentId,
        selectedTopic?.value || "",
        true
      )
    );
  };
  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    dispatch(
      getAllKnowledge(
        3,
        page,
        newPerPage,
        query,
        topicIds,
        {
          sort_field: selectedSort.field,
          sort_order: selectedSort.direction,
        },
        selectedNodes.length > 0,
        props.documentId,
        selectedTopic?.value || "",
        true
      )
    );
  };

  /**
   * Set debounce time for while searching in any input
   */
  const [query, setQuery] = useState("");
  const [isTyping, setTyping] = useState(false);
  useEffect(() => {
    // Added 500 as debounce time will wait for 500 miliseconds and then we will fetch intents based on query
    const delayDebounceFn = setTimeout(() => {
      if (isTyping) {
        setCurrentPage(1);
        dispatch(
          getAllKnowledge(
            3,
            1,
            perPage,
            query,
            topicIds,
            {
              sort_field: selectedSort.field,
              sort_order: selectedSort.direction,
            },
            selectedNodes.length > 0,
            props.documentId,
            selectedTopic?.value || "",
            true
          )
        );
        setTyping(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
    setTyping(true);
  };
  /**
   * State to manage tags | categories as Nodes | Tags whatever we can say
   */
  const [selectedNodes, setSelectedNodes] = useState([]);
  useEffect(() => {
    const topicIds = selectedNodes.map((topic) => topic.value).join(",");
    setTopicIds(topicIds);
    if (!allKnowledgeLoading) {
      setCurrentPage(1);
      dispatch(
        getAllKnowledge(
          3,
          1,
          perPage > 0 ? perPage : 10,
          query,
          topicIds,
          {
            sort_field: selectedSort.field,
            sort_order: selectedSort.direction,
          },
          selectedNodes.length > 0,
          props.documentId,
          selectedTopic?.value || "",
          true
        )
      );
    }
  }, [selectedNodes]);

  // Remove tags
  const removeTagHandler = (e: any, value: any) => {
    e.preventDefault();
    const oldNodes = [...selectedNodes];
    const selectedNodeTopicIndex = oldNodes.findIndex(
      (selectedNodeTopic: any) => selectedNodeTopic.value === value
    );
    if (selectedNodeTopicIndex >= 0) {
      oldNodes.splice(selectedNodeTopicIndex, 1);
      setSelectedNodes([...oldNodes]);
    }
  };
  /**
   * Used to suggest knowledge based on ID
   * @param e
   * @param selectedRow
   */
  const suggestKnowledgeHandler = (
    e: any,
    selectedRow: string,
    suggested: boolean
  ) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(suggestKnowledgeById(selectedRow, suggested));
  };
  /**
   * Used to dowload csv
   * @param e
   */
  const downloadCsvHandler = (e: { value: string; label: string }) => {
    if (e.value === "csv") {
      dispatch(
        downloadKnowledgeAsCSV(3, props.documentId, {
          knowledge_group_ids: selectedTopic?.value,
          hasTopLevelApplied: true,
        })
      );
    } else if (e.value === "managesuggestions") {
      resetSuggestionView();
      return;
    } else if (e.value === "managedocuments") {
      history.push(UPLOADEDDOCUMENTS);
      return;
    } else if (e.value === "generatequestions") {
      history.push("/knowledge/knowledge-create-from-scratch");
    } else if (e.value === "managecategories") {
      history.push(MANAGECATEGORY);
    }
  };
  /**
   * Used to delete multiple knowledge based on IDS
   * @param e
   * @param selectedRowIds
   */
  const deleteConfirmed = async (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedRowIds: string[]
  ) => {
    e.preventDefault();
    setModalShow(false);
    await dispatch(deleteKnowledgeById(selectedRowIds, false));
    dispatch(
      getAllKnowledge(
        3,
        currentPage,
        perPage,
        query,
        topicIds,
        {
          sort_field: selectedSort.field,
          sort_order: selectedSort.direction,
        },
        selectedNodes.length > 0,
        props.documentId,
        selectedTopic?.value || "",
        true
      )
    );
  };

  const resetSuggestionView = () => {
    setSuggestionMode(!suggestionMode);
  };

  // Show bulk assign category modal
  const [bulkAssignCategoryShow, setBulkAssignCategoryShow] = useState(false);
  const [bulkCategoryIds, setBulkCategoryIds] = useState([]);
  const BulkAssignCategoryhandler = (value: boolean) => {
    setBulkAssignCategoryShow(value);
  };

  const confirmCategoryUpdateHandler = async (selectedOption: any) => {
    try {
      BulkAssignCategoryhandler(false);
      await dispatch(bulkAssignModules(selectedOption, selectedRows));
      dispatch(
        getAllKnowledge(
          3,
          currentPage,
          perPage,
          query,
          topicIds,
          {
            sort_field: selectedSort.field,
            sort_order: selectedSort.direction,
          },
          selectedNodes.length > 0,
          props.documentId,
          selectedTopic?.value || "",
          true
        )
      );
      setSelectedRows([]);
    } catch (error: any) {
      setSelectedRows([]);
    }
  };

  const handleTopicChange = (topic: any, direction?: string) => {
    // For now we are allowing server side sorting on above two fields
    setCurrentPage(1);
    dispatch(
      getAllKnowledge(
        3,
        1,
        perPage,
        query,
        topicIds,
        null,
        selectedNodes.length > 0,
        props.documentId,
        topic?.value || "",
        true
      )
    );
    setSelectedTopic({
      value: topic.value,
      label: topic.label,
    });
  };

  /**
   * Used to suggest knowledge based on ID
   * @param e
   * @param selectedRow
   */
  const suggestBulkKnowledgeHandler = async (e: any, suggestedRows: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (suggestedRows.length > 0) {
      await dispatch(bulkSuggestKnowledgeByIds(suggestedRows));
      setSelectedRows([]);
    }
  };

  return (
    <React.Fragment>
      <MoveSelectedModal
        show={bulkAssignCategoryShow}
        onHide={() => BulkAssignCategoryhandler(false)}
        bulkCategoryIds={bulkCategoryIds}
        confirmCategoryUpdate={confirmCategoryUpdateHandler}
      />
      <div
        className={cx("right-animate", {
          show: editMode || suggestionMode,
        })}
      >
        {editMode && (
          <KnowledgeCreateFromScratch
            resetEditKnowledgehandler={resetEditKnowledgehandler}
            selectedIntent={selectedIntent}
            editMode={editMode}
          />
        )}
        {suggestionMode && (
          <ManageSuggestions resetSuggestionView={resetSuggestionView} />
        )}
      </div>

      {/* ------------------------------ Confirmation Modal for deleting intents ( Edit Mode ) -------------------------------------- */}
      <ConfirmationModal
        message={confirmationMessage}
        size={"md"}
        show={modalShow}
        selectedRow={deletedRowId}
        onHide={() => modalhandler(false)}
        confirmHandler={deleteConfirmed}
      />
      <div className="">
        {/* ------------------------------------------------------------------------ */}
        <div className="knowledge-tags d-flex flex-wrap align-items-center">
          {selectedNodes.length > 0 &&
            selectedNodes.map((value, index) => {
              return (
                <Tags
                  key={value + "_" + index}
                  value={value.value}
                  label={value.label}
                  removeTagHandler={removeTagHandler}
                />
              );
            })}
        </div>
        {/* -------------------------------- Datatable Component ------------------------- */}
        <div className="all-knowledge-table" ref={tableWrapperRef}>
          <IntentDataTable
            search={{
              placeHolder: t("Search by question, answer or topic"),
              handleInput: onInputChangeHandler,
              value: query,
            }}
            columns={[]}
            data={
              allKnowledgeSuccess && allKnowledgeSuccess.intents
                ? allKnowledgeSuccess.intents
                : []
            }
            paginationPerPage={perPage}
            progressPending={allKnowledgeLoading && !allKnowledgeError}
            onExportTableClick={() =>
              downloadCsvHandler({
                value: "csv",
                label: t("Export table (CSV)"),
              })
            }
            pagingProps={{
              pagination: allKnowledgeSuccess.pagination,
              showScrollButtons: false,
              scrollRightValue: scrollRight,
              currentPage: currentPage,
              pageChange: handlePerRowsChange,
              onChangePage: handlePageChange,
            }}
            editRow={editKnowledgehandler}
            deleteRow={deleteKnowledgeHandler}
            suggestRow={suggestKnowledgeHandler}
            topics={allTopics.map((t: any) => ({
              ...t,
              label: t?.name,
              value: t?._id,
            }))}
            handleTopicChange={(e) => handleTopicChange(e)}
            selectedTopic={selectedTopic}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
            BulkAssignCategoryhandler={BulkAssignCategoryhandler}
            suggestBulkKnowledgeHandler={suggestBulkKnowledgeHandler}
          />
          {/* -----------------  Pagination   --------------------------- */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default KnowledgeDataTable;
