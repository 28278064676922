import cx from "classnames";
import React, { ReactElement } from "react";
import { Dropdown, Image } from "react-bootstrap";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import dotImg from "../../../assets/images/dot-vertical.svg";
import { useSelector } from "react-redux";
import { getIsSuperAdmin } from "../../../store/common/selector";
import PermissionWrapper from "../PermissionWrapper/PermissionWrapper";
import agentLangIcon from "../../../assets/New-images/agent-lang-icon.svg";
import agentCalendarIcon from "../../../assets/New-images/agent-calendar-icon.svg";

import { useTranslation } from "react-i18next";

interface Props {
  _id: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  selectedAgent: string;
  language_code?: string;
  editAgentHandler?: (e: any) => void;
  deleteAgentHandler?: (e: any) => void;
  chooseCurrentAgentHandler?: (e: any) => void;
  goToCurrentAgentHandler?: () => void;
  hideOptions?: boolean;
  isMultiAgentFeatureEnabled?: boolean;
}

function ChooseAgentListItem({
  _id,
  name,
  description,
  created_at,
  updated_at,
  selectedAgent,
  editAgentHandler,
  deleteAgentHandler,
  chooseCurrentAgentHandler,
  goToCurrentAgentHandler,
  hideOptions,
  isMultiAgentFeatureEnabled,
  language_code,
}: Props): ReactElement {
  const { t } = useTranslation("translation");
  const IsSuperAdmin = useSelector(getIsSuperAdmin);

  const dropdownMenu = (
    <Dropdown>
      <Dropdown.Toggle className="p-0" variant="default" id="agent-dropdown">
        <Image src={dotImg} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          data-agentid={_id}
          data-name={name}
          data-description={description}
          onClick={editAgentHandler}
        >
          {t("Edit")}
        </Dropdown.Item>
        {/* {IsSuperAdmin && ( */}
        {/* <NavLink
            activeClassName='active'
            to={`/agent/${_id}/admins?viewMode=true`}
            className='dropdown-item'
          >
            Manage Admins
          </NavLink> */}
        {/* )} */}
        {/* <NavLink
          to={'/class-management?viewMode=true'}
          className='dropdown-item'
        >
          Manage Classes
        </NavLink> */}
        {IsSuperAdmin && isMultiAgentFeatureEnabled && (
          <Dropdown.Item
            className="delete"
            data-agentid={_id}
            data-name={name}
            data-description={description}
            onClick={deleteAgentHandler}
          >
            {t("Delete")}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
  return (
    <div className="col-md-4 col-sm-6 col-xl-3 mb-3">
      <div
        className={cx({
          "knowledge-box switch-agents-box": true,
          active: _id === selectedAgent,
        })}
      >
        <div className="mb-24 d-flex justify-content-between align-items-center">
          <h2 className="mb-0">{name}</h2>
          <PermissionWrapper>{!hideOptions && dropdownMenu}</PermissionWrapper>
        </div>
        <div>
          <div className="d-flex align-items-center mb-2">
            <div className="d-flex align-items-center mr-3 text-nowrap">
              <img src={agentLangIcon} alt="language" className="mr-1" />
              <span className="font-12 clr-grey-txt7">
                {language_code ? language_code.toLocaleUpperCase() : ""}
              </span>
            </div>
            <div className="d-flex align-items-center text-nowrap">
              <img src={agentCalendarIcon} alt="calendar" className="mr-1" />
              <span className="font-12 clr-grey-txt7">
                <Moment date={updated_at} format="DD-MM-YYYY hh:mm A">
                  {updated_at}
                </Moment>
              </span>
            </div>
          </div>
          <p className="mb-4">{description}</p>
          <p>
            {t("Created:")}{" "}
            <Moment date={created_at} format="DD-MM-YYYY hh:mm A">
              {created_at}
            </Moment>
          </p>
          <p>
            {t("Updated:")}{" "}
            <Moment date={updated_at} format="DD-MM-YYYY hh:mm A">
              {updated_at}
            </Moment>
          </p>
          {_id === selectedAgent && (
            <button className="btn btn-primary mt-5">{t("Selected")}</button>
          )}
          {_id === selectedAgent && (
            <button
              className="btn btn-link mt-5 ml-3"
              onClick={goToCurrentAgentHandler}
            >
              {t("Go to")}
            </button>
          )}
          {_id !== selectedAgent && (
            // <PermissionWrapper>
            <button
              className="btn btn-primary mt-5"
              onClick={chooseCurrentAgentHandler}
              data-agentid={_id}
              data-name={name}
            >
              {t("Choose")}
            </button>
            // </PermissionWrapper>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChooseAgentListItem;
