import integrationService from "../../../../../services/IntegrationService";

const service = integrationService;

export const getIsCanvasIntegration = async () => {
  try {
    const response: any = await service.getCanvasIntegration();
    if (response && response.status && response.status.code === 400) {
      return { isIntegrated: false, integrationData: null };
    } else if (
      response &&
      response.status &&
      response.status.code === 200 &&
      response.data
    ) {
      return { isIntegrated: true, integrationData: response.data };
    }
  } catch (error) {
    return { isIntegrated: false, integrationData: null };
  }
};

export const checkIntegration = async (props: any) => {
  try {
    const response = await getIsCanvasIntegration();
    if (response && response.isIntegrated) {
      props.setIsCanvasIntegrated({
        ...props.isCanvasIntegrated,
        intregate: true,
        data: response.integrationData,
      });
    } else {
      props.setIsCanvasIntegrated({
        intregate: false,
        data: null,
        show: false,
      });
    }
  } catch (error) {
    props.setIsCanvasIntegrated({ intregate: false, data: null, show: false });
  }
};
