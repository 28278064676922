/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import MainLayout from '../components/MainLayout/MainLayout'
import Loading from '../components/UI/Loading/Loading'
import { useGlobalContext } from '../context/GlobalContext'
import { getUrlParameter } from '../utils/appUtils'
import { useAuth0 } from '../utils/react-auth0-wrapper'
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("t");

const Auth0Wrapper = ({ Component, ...rest }: any) => {
  const { history } = rest
  const { isAuthenticated, loading, loginWithRedirect, getTokenSilently } = useAuth0()
  const { setLoadingFromApp, setAccessDeniedMode } = useGlobalContext()
  useEffect(() => {

    if (isAuthenticated || loading) {
      return
    }

    const fn = async () => {
      let allParams = getUrlParameter(history.location.search.split('?')[1])
      if (
        allParams &&
        allParams.error &&
        (allParams.error === 'unauthorized' ||
          allParams.error === 'access_denied')
      ) {
        // setHideLogout(true);
        setAccessDeniedMode(true)
      } else {
        await loginWithRedirect({
          appState: { targetUrl: window.location.pathname },
        })
      }
    }

    fn()
  }, [history.location.search, isAuthenticated, loading, loginWithRedirect])

  return (
    <Route
      exact
      {...rest}
      render={(matchProps) =>
        (isAuthenticated || token) ? (
          <MainLayout isHeader={rest.isHeader}>
            <Component history={history} {...matchProps} />
          </MainLayout>
        ) : (
          <Loading />
        )
      }
    />
  )
}

export default Auth0Wrapper
