import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { Button, Image, Modal, ModalFooter, Container } from "react-bootstrap";
import inteApis from "../../services/IntegrationService";
import arrowLeft from "../../assets/images/arrow-left.svg";
import canvas from "../../assets/images/canvas.svg";
import closeimg from "../../assets/images/close-blk.svg";
import tick from "../../assets/images/tick.svg";
import check from "../../assets/images/check.svg";
import OnlySupperAdmin from "../../components/UI/OnlySupperAdmin/OnlySupperAdmin";
import "./integration.scss";

export const Integration = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const [isEditModel, setIsEditModel] = useState(false);
  const [popUpState, setPopUpState] = useState("");
  const [integrationInputs, setIntegrationInputs] = useState({
    provider: "Canvas",
    client_id: "",
    client_secret: "",
    base_url: "",
    _id: "",
  });

  const { client_id, client_secret, base_url, _id } = integrationInputs;
  const isEdit = !!_id;

  const getAllIntegrations = async () => {
    const { status, data } = await inteApis.getIntegrations();
    if (status === 200) {
      const canvas = data?.data?.integrations?.find(
        (i: any) => i.provider === "canvas"
      );
      if (canvas) {
        setIntegrationInputs((prevState) => ({
          ...prevState,
          ...canvas?.params,
          _id: canvas?._id,
        }));
      }
    }
  };

  const inputChangeHandler = (e: any) => {
    if (e?.target) {
      setIntegrationInputs((prevState) => ({
        ...prevState,
        [e.target.name]: e?.target?.value,
      }));
    }
  };

  useEffect(() => {
    getAllIntegrations();
  }, []);

  const renderModalBody = () => {
    switch (popUpState) {
      case "loading":
        return (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="spinner-border spinner-border-lg text-primary"></div>
            <div className="pt-1">{t("Connecting... Please wait")}</div>
          </div>
        );

      case "success":
        return (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Image src={tick} />
            <div className="pt-1">{t("Connected Successfully")}</div>
          </div>
        );

      default:
        return (
          <>
            <div>
              <h3>{t("Provider")}</h3>
              <input
                type="text"
                className="form-control he-35 radius-2"
                placeholder={t("Enter Provider")}
                name="provider"
                onChange={inputChangeHandler}
                disabled
                value={integrationInputs.provider}
              />
            </div>
            <div>
              <h3>{t("Canvas LMS URL")}</h3>
              <input
                type="text"
                className="form-control he-35 radius-2"
                placeholder={t("Enter Canvas LMS URL")}
                name="base_url"
                onChange={inputChangeHandler}
                value={integrationInputs.base_url}
              />
            </div>
            <div>
              <h3>{t("Client ID")}</h3>
              <input
                type="text"
                className="form-control he-35 radius-2"
                placeholder={t("Enter client ID")}
                name="client_id"
                onChange={inputChangeHandler}
                value={integrationInputs.client_id}
              />
            </div>
            <div>
              <h3>{t("Client Secret")}</h3>
              <input
                type="text"
                className="form-control he-35 radius-2"
                placeholder={t("Enter client Secret")}
                name="client_secret"
                onChange={inputChangeHandler}
                value={integrationInputs.client_secret}
              />
            </div>
          </>
        );
    }
  };

  const connectClick = async () => {
    setPopUpState("loading");
    let res : any= null;
    if (isEdit) {
      res = await inteApis.updateIntegration(_id, {
        params: {
          base_url: base_url,
          client_id: client_id,
          client_secret: client_secret,
        },
      });
    } else {
      res = await inteApis.createIntegration({
        provider: "canvas",
        params: {
          base_url: base_url,
          client_id: client_id,
          client_secret: client_secret,
        },
      });
    }

    if (res.status === 200) {
      if(res?.data?.data?.integration?._id)
      setIntegrationInputs((prevState) => ({
        ...prevState,
        _id: res?.data?.data?.integration?._id,
      })); 
      setPopUpState("success");
    }
  };

  const renderEditModal = () => {
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={isEditModel}
        onHide={() => {}}
        centered
      >
        <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
          <ModalTitle className="container-fluid d-inline-flex justify-content-between align-items-center p-0">
            <div>{t("Connect to Canvas")}</div>
            <Button
              onClick={() => {
                setIsEditModel(false);
                setPopUpState("");
              }}
            >
              <Image src={closeimg} />
            </Button>
          </ModalTitle>
        </ModalHeader>
        <ModalBody className="integration-modal-body">
          {renderModalBody()}
        </ModalBody>

        {popUpState === "" && (
          <ModalFooter>
            <Button
              variant="outline-primary"
              onClick={() => {
                setIsEditModel(false);
                setPopUpState("");
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="primary"
              disabled={!base_url || !client_id || !client_secret}
              onClick={() => connectClick()}
            >
              {t("Connect")}
            </Button>
          </ModalFooter>
        )}
      </Modal>
    );
  };

  return (
    <OnlySupperAdmin>
      <Container className="integration">
        {renderEditModal()}
        <button
          className="btn p-0 btn-back mt-2"
          onClick={() => {
            history.goBack();
          }}
        >
          <Image src={arrowLeft} /> {t("Return to previous page")}
        </button>

        <section
          role="button"
          className="integration-item col-sm-6 col-md-4 col-lg-4"
          onClick={(e: any) => {
            e.stopPropagation();
            isEdit && setIsEditModel(true);
          }}
        >
          <div className="container-fluid d-inline-flex justify-content-between align-items-center p-0">
            <Image src={canvas} />
            {isEdit ? (
              <button
                className="btn btn-success"
                onClick={() => setIsEditModel(true)}
              >
                <Image src={check} className="pr-1" />
                {t("Connected")}
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => setIsEditModel(true)}
              >
                {t("Connect")}
              </button>
            )}
          </div>
          <div>
            <h4>{t("Canvas")}</h4>
          </div>
          <div>
            {t(
              "Canvas LMS simplifies course management, communication, and student connection."
            )}
          </div>
        </section>
      </Container>
    </OnlySupperAdmin>
  );
};

export default Integration;
