import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Header from '../../components/Header'
import ChooseAgentListItem from '../../components/UI/ChooseAgentListItem/ChooseAgentListItem'
import { updateSelectedAgent } from '../../store/agents/actions'
import { getOrganizationCurrentUserDetails } from '../../store/common/actions'

import { useTranslation } from "react-i18next";

interface Props {}

export const ChooseAgent = ({ allAgents, agentSelected }: any) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch()
  const [fetchedAgents, setFetchedAgents] = useState([])
  const [selectedAgent, setSelectedAgent] = useState(
    localStorage.getItem('selectedAgent')
  )

  useEffect(() => {
    setFetchedAgents(allAgents ? [...allAgents] : [])
  }, [allAgents])

  const chooseCurrentAgentHandler = async (e: any) => {
    e.preventDefault()
    try {
      const response: any = await dispatch(
        updateSelectedAgent({
          states: {
            selectedAgent: e.target.dataset.agentid,
            selectedAgentName: e.target.dataset.name,
          },
        })
      )
      await setSelectedAgent(response.selectedAgent)
      await localStorage.setItem('selectedAgent', response.selectedAgent)
      await localStorage.setItem(
        'selectedAgentName',
        response.selectedAgentName
      )
      await dispatch(getOrganizationCurrentUserDetails());
      agentSelected();
    } catch (error: any) {}
  }

  return (
    <>
      <Header hideElementsForOrganization={true} noAgentFound={true} />
      <section className='organisation-wraper h-100 w-100'>
        <div className='container'>
          <h1 className='mb-32 font-32'>{t("Choose Agent")}</h1>
          {fetchedAgents.length === 0 && (
            <h1 className='mb-32'>
              {t("Looks like you do not have access to any agent. Please contact your administrator")}
            </h1>
          )}
          <div className='row mt-3 '>
            {fetchedAgents.map(
              (
                { _id, name, description, created_at, updated_at }: any,
                index: number
              ) => {
                return (
                  <ChooseAgentListItem
                    key={_id + '_' + index}
                    hideOptions={true}
                    selectedAgent={selectedAgent}
                    _id={_id}
                    name={name}
                    description={description}
                    created_at={created_at}
                    updated_at={updated_at}
                    editAgentHandler={(e: any) => {}}
                    deleteAgentHandler={(e: any) => {}}
                    chooseCurrentAgentHandler={chooseCurrentAgentHandler}
                  />
                )
              }
            )}
          </div>
        </div>
      </section>
      <footer></footer>
    </>
  )
}

export default ChooseAgent
