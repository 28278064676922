import React, { useEffect, useState } from "react";
import cx from "classnames";
import {
  Button,
  ButtonGroup,
  Dropdown,
  Image,
  Tab,
  Tabs,
} from "react-bootstrap";
import DataTable from "react-data-table-component";

import { useTranslation } from "react-i18next";

import { ChartTableSortIcon } from "../Knowledge/AllKnowledge";
import { DashboardFilters } from "../../components/UI/Dashboard";
import { useDispatch } from "react-redux";
import unansweredthumpsUpicon from "../../assets/New-images/unansweredthumpsUpicon.svg";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";
import editIcon from "../../assets/New-images/responses-edit-icon.svg";
import deleteIcon from "../../assets/New-images/deleteIcon.svg";
import editedIcon from "../../assets/New-images/editedIcon.svg";
import Pagination from "../../components/UI/Pagination/Pagination";
import { HelperService } from "../../services/helper.service";
import {
  conditionalRowStyles,
  conditionalRowStylesOpenAi,
  customStyles,
  openAiCustomStyles,
} from ".";
import AddAnswerFromKnowledgeBase from "./AddAnswerFromKnowledgeBase";
import { getReportsAnswersFromOutsideSource } from "../../store/knowledge/actions";
import ConfirmationModal from "../../components/UI/ConfirmationModal/ConfirmationModal";
import { deleteAnswersFromOutsideSource } from "../../store/questions/actions";
import unansweredthumpsDownicon from "../../assets/New-images/thumb-down-outline-blue.svg";
import unansweredFlagicon from "../../assets/New-images/flag-outline-red.svg";
import Moment from "react-moment";
import ExportReportShared from "../../components/UI/ExportReportShared/ExportReportShared";
import DashboardNavigation from "../Dashboard/pages/DashboardNavigation";
import UsersAskedModal from "./popups/UsersAskedModal";
import { getAllOrganisationUsers } from "../../store/agents/actions";
import { getClasses } from "../../store/dashboard/actions";
import QuestionListSkeleton from "../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import plusIcon from "../../assets/New-images/plus-white.svg";

const ManageAnswersByOpenAI = (props: any) => {
  const { t } = useTranslation("translation");
  const helperService = new HelperService();
  const [filters, setFilters] = useState({
    filter_connection_ids: null,
    page: null,
    status: null,
    page_size: null,
    sort_field: null,
    sort_order: null,
    from_date: null,
    to_date: null,
    filter_question_text: null,
    filter_category: null,
    filter_status: null,
  });
  const [activeKey, setActiveKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [perPage, setperPage] = useState(25);
  const [paginationState, setpaginationState] = useState(null);
  const [questionAnswerData, setQuestionAnswerData] = useState(null);
  const [showUpdateAnswerModal, setShowUpdateAnswerModal] = useState(false);
  const [isIntentId, setIsIntentId] = useState(null);
  const [sessionUsersShow, setSessionUsersShow] = useState(false);
  const [modalData, setModalData] = useState({
    users_asked: null,
    date: null,
  });
  const [userList, setUserList] = useState([]);
  const [classes, setClasses] = useState([]);
  const [activeTab, setActiveTab] = useState("");

  const dispatch = useDispatch();

  const ActionColumn = ({ row }: any) => {
    const isEdit = row.hasOwnProperty("intent_id") && row.intent_id !== null;
    return (
      <div className="responses-action-btn">
        {!isEdit && (
          <button
            className={
              "btn btn-primary font-14 font-600 text-nowrap radius-8 py-1 px-2"
            }
            onClick={() => questionAnswerHandler(row)}
          >
            <img src={plusIcon} alt="edit" className="mr-2" />
            {"Add"}
          </button>
        )}
        {isEdit && (
          <button
            className={
              "btn btn-outline-primary font-14 font-600 text-nowrap radius-8 py-1 px-2"
            }
            onClick={() => questionAnswerHandler(row)}
          >
            <img src={editIcon} alt="edit" className="mr-2" />
            {"Edit"}
          </button>
        )}
      </div>
    );
  };

  useEffect(() => {
    getClassesFun();
    getAllUsers();
  }, []);

  useEffect(() => {
    if (filters.from_date !== null && filters.to_date !== null) {
      getQuestionAnswerList();
    }
  }, [filters]);

  const getAllUsers = async () => {
    try {
      const response: any = await dispatch(getAllOrganisationUsers());
      const data: any = [];
      response.users.forEach((element: any) => {
        data.push({
          _id: element._id,
          email: element.email,
          name: element.first_name + " " + element.last_name,
        });
      });
      setUserList(data);
    } catch (error) {
      setUserList([]);
    }
  };

  const getClassesFun = async () => {
    try {
      const { classes }: any = await dispatch(getClasses());
      setClasses(classes);
    } catch (error: any) {
      setClasses([]);
    }
  };

  const getQuestionAnswerList = async () => {
    try {
      setLoading(true);
      const queAnsList: any = await dispatch(
        getReportsAnswersFromOutsideSource({
          ...filters,
          page_size: perPage,
          page: currentPage,
          filter_question_text: query,
        })
      );
      let allRows = queAnsList.rows.map((row: any) => {
        let askedByUsers = [];
        if (row.asked_by_users && row.asked_by_users.length > 0) {
          askedByUsers = row.asked_by_users.map((user: any) => {
            return {
              ...user,
              user_email: userList.find((u: any) => u._id === user.user_id)
                ?.email,
            };
          });
        }
        return {
          ...row,
          asked_by_users: askedByUsers,
        };
      });
      setData(allRows);
      setpaginationState(queAnsList.pagination);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const askedByHandler = (row: any) => {
    let user = null;
    let uniqueUsers: any;
    const containsDuplicate = (nums: any[]) => {
      uniqueUsers = new Set(nums);
      return uniqueUsers.size === 1;
    };
    let isAskedMulti =
      row &&
      row.asked_by_users &&
      row.asked_by_users.length >= 1 &&
      containsDuplicate(row.asked_by_users.map((e: any) => e.user_id));

    if (row.ask_count >= 1 && isAskedMulti) {
      user = getDetailByNameOrEmail(uniqueUsers.values().next().value);
    }

    return (
      <div className="font-weight-normal font-sm">
        {row.ask_count >= 1 ? (
          <button
            onClick={(e) => {
              setSessionUsersShow(true);
              setModalData({
                users_asked: row.asked_by_users,
                date: row.updated_at,
              });
            }}
            className="btn btn-link btn-underline p-0 font-weight-normal text-left"
          >
            {user && isAskedMulti
              ? `${user.name} (${uniqueUsers.size})`
              : `Multiple Users (${uniqueUsers.size})`}
          </button>
        ) : (
          ""
        )}
      </div>
    );
  };

  const getDetailByNameOrEmail = (userId: string) => {
    const user = userList.find((u: any) => u._id === userId);
    return user || {};
  };

  const columns = [
    {
      name: t("Question"),
      selector: "text",
      sortable: true,
      cell: (row: any) => <div>{row.text}</div>,
    },
    {
      name: t("Answer"),
      selector: "reply_text",
      sortable: true,
      cell: (row: any) => <div>{row.reply_text}</div>,
    },
    {
      name: t("Asked by"),
      selector: "asked_by_users",
      sortable: false,
      cell: (row: any) => <div>{askedByHandler(row)}</div>,
    },
    {
      name: t("Action"),
      selector: "intent_id",
      sortable: false,
      cell: (row: any) => (
        <div>
          <ActionColumn row={row} />
        </div>
      ),
    },
  ];

  const hasFilterUpdated = async (e: any) => {
    await setperPage(25);
    await setcurrentPage(1);
    await setFilters({
      ...filters,
      from_date: e.filter_from_date,
      to_date: e.filter_to_date,
      filter_connection_ids: e.connection_ids,
      page_size: 25,
      page: 1,
      filter_status: e.status,
    });
  };

  const onSortHandler = async ({ selector }: any, direction: string) => {
    await setFilters({
      ...filters,
      sort_field: selector,
      sort_order: direction === "desc" ? "dsc" : "asc",
    });
  };

  const customSort = (rows: any[], selector: string, direction: string) => {
    return rows;
  };

  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    await setperPage(newPerPage);
    await setcurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
      page_size: newPerPage,
    });
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setcurrentPage(page);
    await setFilters({
      ...filters,
      page: page,
    });
  };

  const questionAnswerHandler = (row: any) => {
    if (row && row.text) {
      setQuestionAnswerData({
        question: row.text,
        answer: row.reply_text,
        intent_id: row.intent_id || null,
        conversation_log_id: row._id,
      });
    }
    setShowUpdateAnswerModal(!showUpdateAnswerModal);
  };

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState([]);

  const deleteModalhandler = (value: any) => {
    setDeleteModalShow(value);
  };

  const deleteQuestionAnswerHandler = (row: any) => {
    setDeleteModalShow(true);
    setDeleteModalId(row._id);
  };

  const confirmDeleteKnowledgeHandler = async (e: any, data: string) => {
    setDeleteModalShow(false);
    try {
      await setLoading(true);
      await dispatch(deleteAnswersFromOutsideSource(data));
      getQuestionAnswerList();
      await setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const [query, setQuery] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isTyping) {
        setcurrentPage(1);
        setFilters({
          ...filters,
          filter_question_text: query,
          page: currentPage,
        });
        setIsTyping(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
    setIsTyping(true);
  };

  const resetEditKnowledgehandler = () => {
    setShowUpdateAnswerModal(!showUpdateAnswerModal);
    getQuestionAnswerList();
  };

  return (
    <section
      className={cx({
        "page-mid-wraper dashborad-wraper h-without-foter position-relative":
          true,
      })}
    >
      <div className="all-knowledge-search responses-by-ai justify-content-between d-flex flex-wrap align-items-center py-2">
        <div className="container">
          {/*--------- top-navigation -----------*/}
          <DashboardNavigation />
          {/*-------- welcome text ------------*/}
          <div className="pt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h2>{t("Responses")}</h2>
                {/* <div>
                  {t(
                    "Questions asked by students in which the AI is unable to answer them. Questions answered would be saved into knowledge"
                  )}
                </div> */}
              </div>
              <div className="">
                <ExportReportShared
                  name={"conversation-logs"}
                  exportName={
                    activeTab === "document_searching"
                      ? "answered_questions"
                      : activeTab === "improvising"
                      ? "other_questions"
                      : "all_questions"
                  }
                  filtersData={filters}
                  removedKeyFromObject="filter_connection_ids, page, page_size, filter_category, filter_status "
                  exportFont={true}
                />
              </div>
            </div>

            <div className="chat-responses-tab mt-0">
              <Tabs
                defaultActiveKey="all"
                onSelect={(k) => {
                  setFilters({
                    ...filters,
                    filter_category: k === "all" ? null : k,
                  });
                  setActiveTab(k);
                  // setSelectedRows([]);
                }}
              >
                <Tab eventKey="all" title={t("All Responses")}></Tab>
                <Tab
                  eventKey={"document_searching"}
                  title={t("Contextualised Chat Responses")}
                ></Tab>

                <Tab
                  eventKey="improvising"
                  title={t("Responses from External Sources")}
                ></Tab>
              </Tabs>
            </div>

            <div className=" ml-auto d-flex flex-wrap doc-select-dropdown justify-content-between">
              {/* <div className="web-links mw-100 w-50">
                <input
                  type="text"
                  className="form-control h-auto"
                  placeholder="Search Questions or Users"
                  onChange={onInputChangeHandler}
                />
              </div> */}
              <DashboardFilters
                hasFilterUpdated={hasFilterUpdated}
                hideChannels
                showAnsweredStatus={true}
                options={[
                  {
                    value: null,
                    label: t("All Responses"),
                  },
                  {
                    value: "unanswered",
                    label: t("Not Added to Knowledge"),
                  },
                  {
                    value: "answered",
                    label: t("Added to Knowledge Base"),
                  },
                ]}
                defaultStatus={{ value: null, label: t("All Responses") }}
              />
            </div>

            {showUpdateAnswerModal && (
              <div className="edit-knowledge-sidewrap edit-knowledge-sidewrap-openAI">
                <AddAnswerFromKnowledgeBase
                  resetEditKnowledgehandler={resetEditKnowledgehandler}
                  questionAnswerData={questionAnswerData}
                  editMode={questionAnswerData.intent_id ? true : false}
                  viewall
                  openAi
                />
              </div>
            )}

            <UsersAskedModal
              modalData={modalData}
              show={sessionUsersShow}
              onHide={() => setSessionUsersShow(false)}
              users={userList}
              classes={classes}
              modalTitle={t("Asked By")}
              showCloseBtn={true}
              // attempted={modalData.filter_is_attempted}
            />

            <ConfirmationModal
              message={t("Are you sure you want to delete this question?")}
              size={"md"}
              show={deleteModalShow}
              selectedRow={deleteModalId}
              onHide={() => deleteModalhandler(false)}
              confirmHandler={confirmDeleteKnowledgeHandler}
            />

            <DataTable
              className="table-wraper-trending-questions"
              noContextMenu
              noHeader
              // fixedHeader
              // fixedHeaderScrollHeight="1500px"
              columns={columns}
              data={data}
              customStyles={openAiCustomStyles}
              sortIcon={<ChartTableSortIcon />}
              // conditionalRowStyles={conditionalRowStylesOpenAi}
              sortFunction={customSort}
              onSort={onSortHandler}
              progressPending={loading} //
              progressComponent={
                <div style={{ padding: 10, width: "100%" }}>
                  <QuestionListSkeleton count={12} />
                </div>
              }
            />

            {data && data.length > 0 && paginationState && (
              <Pagination
                hideSuggestedQuestion
                pagination={paginationState}
                currentPage={currentPage}
                paginationPerPage={perPage}
                pageChange={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManageAnswersByOpenAI;
