import AuthService from "../../services/AuthService";
import Auth0 from "../../utils/auth-spa";
import * as type from "../actionType";

type AppDispatchType = any;
const authService = new AuthService();
const windowObj: any = window;
export const resetState = () => async (
    dispatch: AppDispatchType
) => {
    if (windowObj.REACT_APP_SSO_PROVIDER === 'oneauth') {
        const token = await localStorage.getItem('token')
        dispatch({
            type: type.LOGIN_PROCESS_STARTED,
            payload: {
                loginProcessStarted: false,
                isLoggedIn: token ? true: false,
                token: token,
            }
        });
        return Promise.resolve(true); // Intentionally return true, false will be handled by wrapper
    } else {
        const isAuthenticated = await Auth0.isAuthenticated();
        dispatch({
            type: type.LOGIN_PROCESS_STARTED,
            payload: {
                loginProcessStarted: false,
                isLoggedIn: isAuthenticated,
                token: null,
            }
        });
        return Promise.resolve(true); // Intentionally return true, false will be handled by wrapper
    }
};

export const processAuthState = (code: string) => async (
    dispatch: AppDispatchType
) => {
    try {
        dispatch({
            type: type.LOGIN_PROCESS_STARTED,
            loginProcessStarted: true,
            isLoggedIn: false,
            token: null,
        });
        const response = await authService.getTokenFromOneAuth(code);
        dispatch({
            type: type.LOGIN_PROCESS_COMPLETED,
            payload: {
                token: response.data.access_token,
                isLoggedIn: true
            },
        });
        return Promise.resolve(response.data.access_token);
    } catch (error: any) {
        console.log('error', error);
        dispatch({
            type: type.LOGIN_PROCESS_FAIL,
            payload: {
                token: null,
                isLoggedIn: false
            },
        });
        return Promise.resolve(null);
    }
};


export const logoutUser = () => async (
    dispatch: AppDispatchType
) => {
    await localStorage.clear()
    dispatch({
        type: type.LOGIN_PROCESS_STARTED,
        loginProcessStarted: false,
        isLoggedIn: false,
        token: null,
    })
    if ( windowObj.REACT_APP_SSO_PROVIDER === 'oneauth' ) {
        window.location.href =
            windowObj.REACT_APP_ONE_AUTH_DOMAIN +
            '/logout?redirect_uri=' +
            window.location.origin
    } else {
        Auth0.logout({
            returnTo: window.location.origin
        })
    }
};