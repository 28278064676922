import axios from "axios";
import cx from "classnames";
import { useEffect, useState } from "react";
import { Container, Image, Dropdown, Form } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import backblue from "../../../assets/images/arrow-left.svg";
import { getManageAccessRight } from "../../../store/knowledge/selector";
import "./ManageAccessRights.scss";
import { HelperService } from "../../../services/helper.service";
import {
  getAllLevels,
  getAllModulesFromYear,
  getCourseClassesById,
  getUserGroupById,
  updateUserGroupById,
  updateCourseClassesById,
} from "../../../store/knowledge/actions";
import classNames from "classnames";
import * as _ from "lodash";

import { useTranslation } from "react-i18next";

const helperService = new HelperService();

export const ManageAccessRights = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();
  const [loading, setLoading] = useState(false);
  const manageAccessData = useSelector(getManageAccessRight);
  const [entity, setEntity] = useState<any>(null);
  const [multipleClasses, setMultipleClasses] = useState<any>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<any>([]);

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [modules, setModules] = useState([]);
  const [accessAllKnowledge, setAccessAllKnowledge] = useState(false);

  // Get data on page load
  // We are setting the group first Ex: Level: Year 1 Year 2
  // Then, the first group is set as the selected one
  // Finally, we make the call to get that modules
  useEffect(() => {
    // const handle = async () => {
    //   if (manageAccessData) {
    //     setSelectedGroupId(manageAccessData.knowledge_group_ids)
    //     await axios(
    //       `${process.env.REACT_APP_API_URL}knowledge/groups?agent_id=${manageAccessData.agent_id}`,
    //       {
    //         headers: {
    //           Accept: "application/json",
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    //         },
    //       }
    //     )
    //       .then((res) => {
    //         setLoading(true);
    //         setGroups(res.data.data.groups);
    //         setActiveGroup(res.data.data.groups[0].name);
    //         handleModulesCall(res.data.data.groups[0]._id);
    //         setLoading(false);
    //       })
    //       .catch((err) => console.log(err.message));
    //   }
    // };
    // handle();
    setSelectedGroupId([]);
    getAllYearsFun();
    handleModulesCall(params);
  }, []);

  const getAllYearsFun = async (loadSubModules: boolean = true) => {
    try {
      const response: any = await dispatch(getAllLevels());
      setYears([...response.filter((res: any) => res.group_type !== "system")]);
      response &&
        response.length > 0 &&
        getAllModulesFromYearFun(selectedYear ? selectedYear : response[0]);
    } catch (error: any) {
      setYears([]);
    }
  };

  const getAllModulesFromYearFun = async (year: any) => {
    setSelectedYear(year);
    try {
      const response: any = await dispatch(getAllModulesFromYear(year._id));
      setModules([...response]);
    } catch (error: any) {
      setModules([]);
    }
  };

  const handleModulesCall = async ({
    id,
    type,
  }: {
    id?: string;
    type?: string;
  }) => {
    setLoading(true);
    if (type === "learner") {
      try {
        const response: any = await dispatch(getUserGroupById(id));
        setAccessAllKnowledge(response.access_all_knowledge);
        setSelectedGroupId(
          response.knowledge_groups
            ? [...response.knowledge_groups.map((g: any) => g._id)]
            : []
        );
        setEntity(response);
      } catch (error: any) {
        setEntity(null);
      }
    } else {
      const query = new URLSearchParams(history.location.search);
      let classes = query.get("classes")
        ? query.get("classes").split(",")
        : null;

      try {
        if (classes) {
          let entities: any = [];
          for await (const iterator of classes) {
            const response: any = await dispatch(
              getCourseClassesById(iterator)
            );
            entities.push(response);
          }
          setMultipleClasses(entities);
        } else {
          const response: any = await dispatch(getCourseClassesById(id));
          setAccessAllKnowledge(response.access_all_knowledge);
          setSelectedGroupId(
            response.knowledge_group_ids
              ? [...response.knowledge_group_ids]
              : []
          );
          setEntity(response);
        }
      } catch (error: any) {
        setEntity(null);
      }
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    if (params.type === "learner") {
      try {
        const response: any = await dispatch(
          updateUserGroupById(entity._id, selectedGroupId, accessAllKnowledge)
        );
        setEntity(response);
        setLoading(false);
      } catch (error: any) {
        setEntity(null);
        setLoading(false);
      }
    } else {
      try {
        if (multipleClasses.length) {
          let enetities: any = [];
          let count = 1;
          for await (const iterator of multipleClasses) {
            const response: any = await dispatch(
              updateCourseClassesById(
                iterator._id,
                selectedGroupId,
                accessAllKnowledge,
                count === multipleClasses.length
              )
            );
            enetities.push(response);
            ++count;
          }
          setMultipleClasses(enetities);
          setLoading(false);
        } else {
          const response: any = await dispatch(
            updateCourseClassesById(
              entity._id,
              selectedGroupId,
              accessAllKnowledge
            )
          );
          setEntity(response);
          setLoading(false);
        }
      } catch (error: any) {
        setEntity(null);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const chooseCategoryHandler = (e: any) => {
    const isPresentIndex = selectedGroupId.indexOf(e.target.value);
    const oldSelectedOptions = [...selectedGroupId];
    const childrensArray: string[] = [];
    const func = (value: string) => {
      const childrens = modules.filter((p: any) => p.parent_id === value);
      if (childrens.length) {
        childrens.forEach((d) => {
          func(d.value);
        });
      }
      childrensArray.push(value);
    };
    func(e.target.value);

    if (e.target.checked && isPresentIndex === -1) {
      oldSelectedOptions.push(e.target.value);
      return setSelectedGroupId(
        _.uniq(oldSelectedOptions.concat(childrensArray))
      );
    }

    if (!e.target.checked && isPresentIndex >= 0) {
      oldSelectedOptions.splice(isPresentIndex, 1);
      childrensArray.forEach((id: string) => {
        const isPresentIndex = _.uniq(oldSelectedOptions).indexOf(id);
        if (isPresentIndex >= 0) {
          oldSelectedOptions.splice(isPresentIndex, 1);
        }
      });
      return setSelectedGroupId(_.uniq(oldSelectedOptions));
    }
  };

  const accessAllKnowledgeChangedHandler = (e: any) => {
    setAccessAllKnowledge(e.target.checked);
  };

  return (
    <section className={cx({ "page-mid-wraper user-groups pb-0": true, hidden: loading })}>
      <Container>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn p-0 btn-back"
              onClick={() => {
                history.goBack();
              }}
            >
              <Image src={backblue} /> {t("Return to previous page")}
            </button>
            <div className='col-md-12'>
              <div className='row'>
                <div className='col-lg-8' >
                  <h1 className="first-step mb-12 d-flex flex-wrap">
                    {t("Manage Access Rights")}
                    
                  </h1>
                  <h2 className="mb-32 font-weight-normal">
                    {t(
                      "Manage users and grant access and decide which of the content they can access"
                    )}
                    
                  </h2>
                </div>
                <div className='col-lg-4' >
                  <div className="flexButton">
                    <button
                      className="btn btn-primary ml-auto"
                      onClick={() => handleUpdate()}
                      disabled={loading}
                    >
                      {t("Update")}
                    </button>
                  </div>
                </div>
              </div>
            </div>  
            

            

          </div>
        </div>
        <div className="classHeader">
          <div>
            <h3>
              {params && params.type === "learner"
                ? "Module Name"
                : "Class Name"}
            </h3>
            <h2>
              {multipleClasses.length
                ? multipleClasses.map((cl: any) => cl.name).join(", ")
                : entity && entity?.name}
            </h2>
          </div>
        </div>

        <div className="pref-check mt-4 notify-onOff switch-blue pl-3">
          <Form>
            <Form.Check
              className="pl-1"
              type="switch"
              // defaultValue={channel.name}
              label={t("Allow Access to all knowledge?")}
              id={"all_knowledge"}
              checked={accessAllKnowledge}
              defaultChecked={accessAllKnowledge}
              onChange={accessAllKnowledgeChangedHandler}
            />
          </Form>
        </div>

        <div className="levels-row mb-4 d-flex align-items-center flex-wrap classFlex">
          <h2 className="mb-0 mr-3 font-1">{t("Level:")}</h2>
          {years &&
            years.map((year: any, i: number) => {
              return (
                <button
                  key={i}
                  className={classNames({
                    "btn btn-tabs ": true,
                    active: selectedYear && year._id === selectedYear._id,
                  })}
                  onClick={() => {
                    getAllModulesFromYearFun(year);
                  }}
                >
                  {year.name}
                </button>
              );
            })}
        </div>
        <div className="modulesContainer">
          <div className="header">
            <h3>{t("Modules")}</h3>
            <h4>{t("Access")}</h4>
          </div>
          <div className="modules-list flex-grow-1">
            <ul className="list-unstyled content-scroll">
              {modules &&
                modules.map((mod: any, i: number) => {
                  return (
                    <li
                      key={
                        "child_padding_dev_" +
                        mod.value +
                        "_" +
                        Math.floor(Math.random() * 9999)
                      }
                      style={{
                        paddingLeft: `${
                          mod.depth > 0 ? mod.depth * 10 + 10 : 10
                        }px`,
                      }}
                      className={cx({
                        "clr-grey-txt": mod.depth > 0,
                        disabled:
                          accessAllKnowledge ||
                          selectedGroupId.indexOf(mod.parent_id) >= 0,
                        // active: selectedGroupId === mod.value,
                      })}
                    >
                      <div className="moduleContainer">
                        <h3>{mod.label}</h3>
                        <input
                          type="checkbox"
                          value={mod.value}
                          disabled={
                            accessAllKnowledge ||
                            selectedGroupId.indexOf(mod.parent_id) >= 0
                          }
                          defaultChecked={
                            selectedGroupId.includes(mod.value) ||
                            selectedGroupId.indexOf(mod.parent_id) >= 0
                          }
                          checked={
                            selectedGroupId.indexOf(mod.value) >= 0 ||
                            accessAllKnowledge ||
                            selectedGroupId.indexOf(mod.parent_id) >= 0
                          }
                          onChange={chooseCategoryHandler}
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        
      </Container>
    </section>
  );
};
export default ManageAccessRights;
