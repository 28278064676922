import cx from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import Select from "react-select";
// import "../../index.scss";
import CommentImg from "../../assets/images/comments.svg";
import ChevronRight from "../../assets/images/chevRight.svg";
import AnwseredQuesIcon from "../../assets/images/answered-ques-icon.svg";

import RemoveImg from "../../assets/images/red-cross.svg";
import ConfirmImg from "../../assets/images/single-green-check.svg";
import CloseImg from "../../assets/images/close-blk.svg";
// import PlanBG from "../../assets/images/plan-bg.png";
import DateRangePickerComponent from "../../components/UI/DateRangePicker/DateRangePicker";
import { useTranslation } from "react-i18next";
import { getClasses } from "../../store/dashboard/actions";
import DashboardService from "../../services/DashboardService";
import Moment from "react-moment";
import DataTable from "react-data-table-component";
import Pagination from "../../components/UI/Pagination/Pagination";
import { HelperService } from "../../services/helper.service";
import { Image, Tab, Tabs } from "react-bootstrap";
import LearnerQuestionDetails from "./LearnerQuestionDetails";
import "./style.scss";
import moment from "moment";
import AddAnswerFromKnowledgeBase from "../ManageUnansweredKnowledge/AddAnswerFromKnowledgeBase";
import AddToKnowledge from "./AddToKnowledge";
import ManageAnswersByOpenAI from "../ManageUnansweredKnowledge/ManageAnswersByOpenAI";

interface Props {}
const service = new DashboardService();
const helperService = new HelperService();

const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#0923E6",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        img: {
          width: "10px",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "0px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#0923E6",
      padding: "20px 20px",
      fontSize: "14px",
      color: "#fff",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#fff",
      },
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      "&:nth-child(4)": {
        div: {
          textOverflow: "ellipsis",
          display: " -webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        },
      },
    },
  },
};

export const SORTS = [
  { value: "recent", label: "Most Recent" },
  { value: "old", label: "Oldest" },
  { value: "res", label: "Most Responses" },
];

export const QUESTION_TYPES = [
  { value: "", label: "All Questions" },
  { value: "unanswered", label: "Not added to Knowledge" },
  { value: "answered", label: "Added to Knowledge" },
];

const defaultClass = { label: "All Classes", value: "" };

const PerPage = 10;
export const ManageLearnerQuestions = (props: any) => {
  const { t } = useTranslation("translation");
  const [paginationState, setPaginationState] = useState(null);
  const [perPage, setPerPage] = useState(PerPage);
  const [discussions, setDiscussions] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([defaultClass]);
  const [post, setPost] = useState(null);
  // const [classes, setClasses] = useState([]);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  // const [q, setQ] = useState('');

  const [currentRow, setCurrentRow] = useState(null);
  const [detailsDicusstion, setDetailsDicusstion] = useState<any>({});
  const [editId, setEditId] = useState<any>({});
  const [isCreating, setIsCreating] = useState(false);
  const [newDiscussion, setNewDiscussion] = useState<any>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>();
  const searchRef = useRef<HTMLInputElement>();
  const [dateParameters, setDateParameters] = useState({
    value: "30",
    label: t("Last 30 days"),
  });
  const dispatch = useDispatch();

  const fetchDiscussion = async (skipPage = true) => {
    let filter: any = {
      answer_status: filters?.question_type?.value || null,
      class_id: filters?.class_id?.value || null,
      page: skipPage ? null: (filters?.page || 1),
      page_size: filters?.page_size || PerPage,
      q: searchRef?.current?.value?.trim() || null,
      filter_from_date: filters?.from_date || null,
      filter_to_date: filters?.to_date || null,
    };
    switch (filters?.sort.value) {
      case "recent":
        // fitler = fitler + `&sort_field=created_at&sort_order=dsc`;
        filter.sort_field = "created_at";
        filter.sort_order = "dsc";
        break;
      case "old":
        filter.sort_field = "created_at";
        filter.sort_order = "asc";
        break;
      case "res":
        filter.sort_field = "post_count";
        filter.sort_order = "dsc";
        break;
      default:
    }
    const res = await service.getLearnerQuestions(filter);
    // const res = await service.getDiscussion(filter);

    if (res.status === 200) {
      setDiscussions(res.data.data.threads);
      setPaginationState(res.data.data.pagination);
      setLoading(false);
      setCurrentRow(null);
    }
  };
  const debouncedFetchDiscussion = debounce(() => fetchDiscussion(true), 300);

  const [filters, setFilters] = useState({
    question_type: QUESTION_TYPES[0],
    class_id: defaultClass,
    page: 1,
    page_size: PerPage,
    sort_field: null,
    sort_order: null,
    sort: SORTS[0],
    q: null,
    to_date: moment(new Date()).format("YYYY-MM-DD"),
    from_date: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
  });

  const getClass = async () => {
    const { classes }: any = await dispatch(getClasses());
    setClasses(classes);
  };

  useEffect(() => {
    getClass();
    fetchDiscussion();
  }, []);

  useEffect(() => {
    if (dateParameters.value !== "custom") {
      setFilters({
        ...filters,
        to_date: moment(new Date()).format("YYYY-MM-DD"),
        from_date: moment(new Date())
          .subtract(dateParameters.value, "days")
          .format("YYYY-MM-DD"),
      });
    }
  }, [dateParameters.value]);

  useEffect(() => {
    if (currentRow?._id && !detailsDicusstion[currentRow?._id]) {
      // fetchDiscussionDetails(currentRow?._id);
    }
  }, [currentRow?._id]);

  const saveDiscussion = async () => {
    if (textAreaRef?.current) {
      const value = textAreaRef?.current?.value;
      await service.saveDiscussion(editId, {
        text: value,
      });

      // Update the matching item in discussions
      setDiscussions((prevState) => {
        return prevState.map((item) => {
          if (item._id === editId) {
            return {
              ...item,
              text: value,
            };
          }
          return item;
        });
      });

      helperService.showToasterIcon(t("Successful Update"), "success");
    }
  };

  const removeDiscusstion = async (id: string) => {
    await service.deleteDiscussion(id);
    helperService.showToasterIcon(t("Successful remove"), "success");
    fetchDiscussion();
  };

  const columns: any = [
    {
      name: t("Question"),
      selector: "text",
      cell: (row: any) => {
        return (
          <div>
            {
              row?.answer_status === "answered" && (
                <div className="flex pb-1">
                  <Image src={AnwseredQuesIcon} />{" "}
                  <span className="clr-green px-1">
                    {t("Answer Added to Knowledge Base")}
                  </span>
                </div>
              )
              // : (
              //   <div className="clr-grey pb-1">{t("Question")}</div>
              // )
            }
            {row.text}
          </div>
        );
      },
      width: "470px",
    },
    {
      name: t("Asked by"),
      selector: "text",
      cell: (row: any) => (
        <div className="d-flex justify-content-center align-items-center">
          <Image src={row?.author_profile_image} className="author-ava" />
          <span className="px-2">
            {row?.author_nickname ||
              row?.author_first_name + " " + row?.author_last_name}
          </span>
        </div>
      ),
    },
    {
      name: t("Date Asked"),
      selector: "created_at",
      width: "150px",
      cell: (row: any) => (
        <div>
          <Moment date={row.created_at} format="DD-MM-YYYY">
            {row.created_at}
          </Moment>
        </div>
      ),
    },
    {
      name: t("Learners Activity"),
      selector: "post_count",
      with: "120px",
      cell: (row: any) => (
        <div
          className="w-100 d-flex align-items-center justify-content-between"
          role="button"
          onClick={() => {
            if (currentRow?._id === row?._id) {
              setCurrentRow(null);
            } else {
              setCurrentRow(row);
            }
          }}
        >
          <div>
            <Image src={CommentImg} className="mr-3" />
            {row.post_count}
          </div>
          <div>
            <Image
              className={cx("mx-2", {
                "action-button chev expanded": currentRow?._id === row?._id,
              })}
              role="button"
              src={ChevronRight}
              onClick={() => {
                if (currentRow?._id === row?._id) {
                  setCurrentRow(null);
                } else {
                  setCurrentRow(row);
                }
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const createDicusstion = () => async () => {
    const classIds = newDiscussion?.classes?.map((c: any) => c.value) || [];
    const res = await service.createDiscussion({
      text: newDiscussion?.text,
      class_ids: classIds,
      thread_type: "discussion",
    });

    if (res.status === 200) {
      helperService.showToasterIcon(t("Successful create"), "success");
      fetchDiscussion();
      setIsCreating(false);
      setNewDiscussion({});
    }
  };

  useEffect(() => {
    fetchDiscussion();
  }, [
    filters.page,
    filters.page_size,
    filters?.question_type?.value,
    filters?.class_id?.value,
    filters?.sort?.value,
    filters?.from_date,
    filters?.to_date,
  ]);

  useEffect(() => {
    fetchDiscussion(false);
  }, [
    filters.page,
  ]);

  useEffect(() => {
    // paginate(discussions)
    // return () => {}
    setFilters({
      ...filters,
      page: currentPage,
      page_size: perPage,
    });
  }, [currentPage, perPage]);

  return (
    <>
      <section
        className={cx({
          "page-mid-wraper dashborad-wraper h-without-foter discussion position-relative":
            true,
        })}
      >
        <div className="container">
          {/*--------- top-navigation -----------*/}
          {/*-------- welcome text ------------*/}
          <div className="pt-3">
            <h2>{t("Manage Learners Questions")}</h2>
            <div>
              {t(
                "Questions asked by students in which the AI is unable to answer them. Questions answered would be saved into knowledge"
              )}
            </div>
            <div className="mt-2">
              <div className="learner-question-tab position-relative">
                <div className="py-4 d-flex justify-content-between">
                  <input
                    type="text"
                    className="form-control edit h-auto"
                    placeholder="Search for Questions"
                    ref={searchRef}
                    onChange={(e) =>
                      debouncedFetchDiscussion()
                    }
                  />
                  <div className="d-flex shadow-none radius-8 position-relative">
                    <Select
                      className="select-sort select-target select-blue shadow-none radius-8"
                      classNamePrefix="select-target-prefix"
                      placeholder={t("All Questions")}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={filters.question_type}
                      onChange={(newValue: any) => {
                        setFilters({
                          ...filters,
                          question_type: newValue,
                        });
                      }}
                      options={QUESTION_TYPES}
                    />
                    <Select
                      className="select-sort select-target select-blue  shadow-none radius-8"
                      classNamePrefix="select-target-prefix"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={filters?.class_id}
                      onChange={(newValue: any) => {
                        setFilters({
                          ...filters,
                          class_id: newValue,
                        });
                      }}
                      options={[
                        defaultClass,
                        ...classes.map((c: any) => ({
                          value: c._id,
                          label: c.name,
                        })),
                      ]}
                    />
                    <Select
                      className="select-sort select-target select-blue shadow-none radius-8"
                      classNamePrefix="select-target-prefix"
                      isSearchable={false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      value={dateParameters ? { ...dateParameters } : null}
                      onChange={(e: any) => {
                        // setClickedCustomDatePickerValue(false);
                        setDateParameters(e);
                        if (e.value === "custom") {
                          setOpenDatePicker(true);
                        }
                      }}
                      options={[
                        { value: "60", label: t("Last 60 days") },
                        { value: "30", label: t("Last 30 days") },
                        { value: "7", label: t("Last 7 days") },
                        { value: "custom", label: t("Custom") },
                      ]}
                    />
                    {openDatePicker && (
                      <DateRangePickerComponent
                        dateValue={{
                          startDate:
                            filters && filters.from_date
                              ? new Date(filters.from_date)
                              : new Date(),
                          endDate:
                            filters && filters.to_date
                              ? new Date(filters.to_date)
                              : new Date(),
                        }}
                        onDateChange={(e) => {
                          setFilters({
                            ...filters,
                            from_date: e.filter_from_date,
                            to_date: e.filter_to_date,
                          });
                        }}
                        onCloseDateRangeHandler={() => setOpenDatePicker(false)}
                      />
                    )}
                  </div>
                </div>
                {discussions.length === 0 ? (
                  <div className="text-center p-5">
                    {t("There are no records to display")}
                  </div>
                ) : (
                  <>
                    <div className="">
                      <DataTable
                        className="table-wraper-trending-questions"
                        noContextMenu
                        noHeader
                        columns={columns}
                        data={discussions}
                        customStyles={customStyles}
                        expandableRows={true}
                        expandableRowsComponent={
                          <LearnerQuestionDetails setPost={setPost} />
                        }
                        expandableRowExpanded={(row) => row === currentRow}
                        expandableRowsHideExpander={true}
                        noDataComponent={t("There are no records to display")}
                      />

                      {discussions &&
                        discussions.length > 0 &&
                        paginationState && (
                          <Pagination
                            currentPage={currentPage}
                            pagination={{
                              ...paginationState,
                            }}
                            paginationPerPage={perPage}
                            pageChange={(newPerPage: number, page: number) => {
                              setPerPage(newPerPage);
                              setCurrentPage(1);
                            }}
                            onChangePage={(page) => setCurrentPage(page)}
                            hideSuggestedQuestion
                          />
                        )}
                    </div>
                  </>
                )}
                {post && (
                  <div className="edit-knowledge-sidewrap edit-learner-question ">
                    <AddToKnowledge
                      resetEditKnowledgehandler={() => setPost(null)}
                      questionAnswerData={{ ...currentRow, post: post }}
                      editMode={post?.approval_status === "accepted"}
                      fetchDiscussion={fetchDiscussion}
                      addAnswerFromPopup
                      viewall
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {isCreating && (
          <div className="w-full h-full creating-bg">
            <div className="content-page">
              <div className="d-flex align-items-center justify-content-between py-5">
                <b>{t("Create Discussion Question")}</b>
                <Image
                  role="button"
                  src={CloseImg}
                  onClick={() => {
                    setIsCreating(false);
                    setNewDiscussion(null);
                  }}
                />
              </div>
              <div className="w-100 position-relative">
                <div className="py-3">{t("Assign to Classes")}</div>
                <input
                  className="form-control"
                  placeholder={t("Enter your discussion question")}
                  value={newDiscussion?.text}
                  onChange={(e) =>
                    setNewDiscussion({ ...newDiscussion, text: e.target.value })
                  }
                />
                <Image
                  role="button"
                  className="clear-input"
                  src={CloseImg}
                  onClick={() =>
                    setNewDiscussion({ ...newDiscussion, text: "" })
                  }
                />
              </div>
              <div>
                <div className="pt-5 pb-3">{t("Assign to Classes")}</div>
                <Select
                  className="select-sort select-target"
                  classNamePrefix="select-target-prefix"
                  isMulti
                  placeholder={t("Select Class")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value={newDiscussion?.classes}
                  onChange={(newValue: any) => {
                    setNewDiscussion({
                      ...newDiscussion,
                      classes: [...newValue],
                    });
                  }}
                  options={classes.map((c: any) => ({
                    value: c._id,
                    label: c.name,
                  }))}
                />
              </div>
              <div className="d-flex mt-4 bottom-btns">
                <button
                  className="btn btn-outline-primary mx-2"
                  onClick={() => {
                    setIsCreating(false);
                    setNewDiscussion(null);
                  }}
                >
                  {t("Cancel")}
                </button>
                <button
                  className="btn btn-primary mx-2"
                  disabled={!newDiscussion?.text}
                  onClick={createDicusstion()}
                >
                  {" "}
                  {t("Create Discussion")}
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default ManageLearnerQuestions;
