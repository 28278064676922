import React from 'react'
import {
  Container, Image
} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import backblue from "../../../assets/images/arrow-left.svg";
import './AllKnowledge.scss'
import KnowledgeDataTable from '../../../components/UI/KnowledgeDatatableComponent/KnowledgeDataTable';

import { useTranslation } from "react-i18next";

export const AllKnowledgeFullTable = (props: any) => {
  const { t } = useTranslation('translation');
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <section className='page-mid-wraper h-without-foter'>
      <Container>
        <div className='row'>
          <div className='col-md-12'>
            <button
              className="btn p-0 btn-back"
              onClick={() => {
                history.goBack();
              }}
            >
              <Image src={backblue} />{t("Return to previous page")}
            </button>
            <div className='mb-2 d-flex justify-content-between align-items-center'>
              <h1 className='mb-0'>{t("Knowledge")}</h1>
              {/* <button className="btn btn-link py-0">View All</button> */}
            </div>
            <KnowledgeDataTable />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default AllKnowledgeFullTable
