import React, { useEffect, useState } from "react";
import { Container, Image, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Tour from "reactour";
import { organizationFlowObject } from "../../../app.constant";
import backimg from "../../../assets/images/arrow-left.svg";
import ConfirmationModal from "../../../components/UI/ConfirmationModal/ConfirmationModal";
import Loading from "../../../components/UI/Loading/Loading";
import {
  getStickerSets,
  updateOrganizationFlow,
} from "../../../store/common/actions";
import {
  addEntityToKnowledgeGroup,
  deleteKnowledgeById,
} from "../../../store/knowledge/actions";
import {
  createIntent,
  getAllButtonOptions,
  getAllIntents,
  getAllUserGroups,
  getParaPhrasesBasedOnIntent,
  getParaPhrasesWithoutIntent,
  updateIntent,
  updateIntentTags,
} from "../../../store/questions/actions";
import {
  AllUserGroups,
  getAllIntents as getAllIntentSelector,
  getIntent,
  isQuestionLoading,
} from "../../../store/questions/selector";
import { steps } from "./KnowledgeCreateFromScratchTour";
import ButtonsTab from "./partials/ButtonsTab";
import SettingsTab from "./partials/SettingsTab";
import UserSaysTab from "./partials/UserSaysTab";
import VirtualAssistantTab from "./partials/VirtualAssistantTab";

import AddTags from "../../../components/UI/AddTags/AddTags";
import UpdateModuleModal from "../../../components/UI/KnowledgeDatatableComponent/UpdateModuleModal";
import ManageLevelCompact from "../../../components/UI/ManageLevelCompact/ManageLevelCompact";
import PermissionWrapper from "../../../components/UI/PermissionWrapper/PermissionWrapper";
import Tags from "../../../components/UI/Tags/Tags";
import { getAllUserGroupOptions } from "../../../store/notifications/actions";
import {
  getKnowledgeById,
  localUserQuestionUpdate,
} from "../../../store/questions/actions";

import { useTranslation } from "react-i18next";
import { HelperService } from "../../../services/helper.service";

interface Props {}

export const KnowledgeCreateFromScratch = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const dispatch = useDispatch();
  const useLocatio = useLocation();
  const questionLoading = useSelector(isQuestionLoading);
  const allUserGroupsData = useSelector(AllUserGroups);
  const intent = useSelector(getIntent);
  const allFetchedIntents: any = useSelector(getAllIntentSelector);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isIntentUpdating, setIsIntentUpdating] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [activeTab, setActiveTab] = useState("usersays");
  const [selectedButtonTarget, setSelectedButtonTarget] = useState(null);
  const [targetButtonsList, setTargetOptionsList] = useState([]);
  const [hasAddedQuestion, setHasAddedQuestion] = useState(false);
  const [userSaysTarget, setUserSaysTarget] = useState(null);
  const [hasAddedResponse, setHasAddedResponse] = useState(false);
  const [selectedResponseTarget, setSelectedResponseTarget] = useState(null);
  const [allTags, setAllTags] = useState([]);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [AllIntents, setAllIntents] = useState([]);
  const [paraPhrasesLoading, setParaPhrasesLoading] = useState(false);
  const [qas, setQas] = useState({
    que: "",
    ans: "",
  });
  const [generateVariations, setGenerateVariations] = useState(false);
  useEffect(() => {
    if (allFetchedIntents) {
      setAllIntents([...allFetchedIntents]);
    }
  }, [allFetchedIntents]);

  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      (props.location.state.que || props.location.state.ans)
    ) {
      if (props.location.state.que) {
        const oldList = [];
        oldList.unshift({
          text: props.location.state.que,
        });
        dispatch(localUserQuestionUpdate("questions", oldList));
      }

      if (props.location.state.ans) {
        const oldResponses = [];
        oldResponses.unshift({
          text: props.location.state.ans,
          group_ids: [],
        });
        dispatch(localUserQuestionUpdate("responses", oldResponses));
      }
    }
  }, [props.location]);

  useEffect(() => {
    dispatch(
      updateOrganizationFlow({
        ...organizationFlowObject,
        prevRoute: "/knowledge",
        currentRoute: "/knowledge-create-from-scratch",
        nextRoute: "/knowledge/generate-questions",
        setup_completed: false,
      })
    );

    dispatch(getAllUserGroups());
    dispatch(getStickerSets());
    dispatch(getAllUserGroupOptions()); // user groups

    setUserSaysTarget(null);
    setSelectedResponseTarget(null);
    setHasAddedQuestion(false);
    setHasAddedResponse(false);

    const query = new URLSearchParams(useLocatio.search);
    const group = query.get("group");
    const viewAll = query.get("view-all");
    setSelectedGroupId(group ? group : null);
    setGenerateVariations(viewAll === "true" ? true : false);
    if (group) {
      dispatch(localUserQuestionUpdate("knowledge_group_id", group));
    }
    return () => {
      console.log("CLEANED UP");
      dispatch({
        type: "FETCH_KNOWLEDGE_BY_ID_SUCCESS",
        payload: {
          intent: null,
        },
      });
    };
  }, []);

  useEffect(() => {
    // Check if intent is already present dispatch locally
    const getSelectedIntent = async (selectedIntent: any) => {
      if (selectedIntent && selectedIntent.hasOwnProperty("_id")) {
        await dispatch(getKnowledgeById(selectedIntent._id));
      }
    };

    getSelectedIntent(props.selectedIntent);
    // Fetch all button options in background
    dispatch(getAllButtonOptions(true));
    dispatch(getAllIntents(true));
  }, [props.selectedIntent]);

  // Levels

  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const [selectedParentId, setSelectedParentId] = React.useState(null);
  const [selectedModuleName, setSelectedModuleName] = React.useState("");
  const [moduleEditMode, setModuleEditMode] = React.useState(false);
  const [moduleDepth, setModuleDepth] = React.useState(0);
  /**
   * Used to sort data using API
   * @param param
   * @param direction
   */
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const onSelectGroupId = (level: string) => {
    // For now we are allowing server side sorting on above two fields
    setSelectedGroupId(level);

    if (!props.editMode) {
      dispatch(localUserQuestionUpdate("knowledge_group_id", level));
    }
  };

  const updateParentHandler = (
    name?: string,
    parent_id?: string,
    isEdit: boolean = false,
    depth: number = 0
  ) => {
    setSelectedParentId(parent_id);
    setSelectedModuleName(name);
    setModuleEditMode(isEdit);
    setModuleDepth(depth);
    updateModalHandler();
  };

  const updateModalHandler = () => {
    setShowRenameModal(!showRenameModal);
  };

  const confirmRenameHandler = async (data: any) => {
    // getAllYearsFun(false);
    setModuleEditMode(false);
    updateModalHandler();
  };

  // Levels

  useEffect(() => {
    if (
      !questionLoading &&
      intent &&
      props.selectedIntent &&
      props.selectedIntent.existingRecord
    ) {
      const oldList = intent.questions ? [...intent.questions] : [];
      oldList.unshift({ text: props.selectedIntent.existingRecord.question });
      dispatch(localUserQuestionUpdate("questions", oldList));

      const oldResponses = intent.responses ? [...intent.responses] : [];
      oldResponses.unshift({
        text: props.selectedIntent.existingRecord.answer,
        group_ids: [],
      });
      dispatch(localUserQuestionUpdate("responses", oldResponses));
    }
  }, [questionLoading]);

  //
  useEffect(() => {
    buttonTextHandler();

    // If intent found updated selected state
    if (intent) {
      if (intent && intent.tags) {
        setAllTags(
          intent.tags.map((value: string) => {
            return {
              label: value,
              value,
            };
          })
        );
      }
    }
  }, [intent]);

  // Create intent
  const createIntenthandler = async () => {
    // For creation
    if (
      !props.editMode &&
      activeTab === "usersays" &&
      (!intent || !intent.questions || intent.questions.length === 0)
    ) {
      try {
        if (selectedButtonTarget) {
          const oldButtonsList = [...targetButtonsList];
          oldButtonsList.push({
            ...selectedButtonTarget,
            name: selectedButtonTarget.name,
            type: selectedButtonTarget.type,
          });
          await dispatch(localUserQuestionUpdate("buttons", oldButtonsList));
          setSelectedButtonTarget(null);
        }

        if (userSaysTarget) {
          const oldList = [];
          await oldList.unshift(userSaysTarget);
          await dispatch(localUserQuestionUpdate("questions", oldList));
          setUserSaysTarget(null);
          if (!intent || !intent.responses) {
            setHasAddedResponse(true);
            tabsChangeHandler("virtualassistant");
            return;
          }
        }

        if (selectedResponseTarget) {
          const oldList = [];
          await oldList.unshift(selectedResponseTarget);
          await dispatch(localUserQuestionUpdate("responses", oldList));
        }

        await setHasAddedResponse(true);
        await setHasAddedQuestion(true);
        dispatch(createIntent());
      } catch (e) {}
      return;
    }

    // If questions but no responses
    if (
      !props.editMode &&
      activeTab === "usersays" &&
      intent &&
      intent.questions &&
      intent.questions.length > 0
    ) {
      tabsChangeHandler("virtualassistant");
      return;
    }

    // All good proceed
    setIsIntentUpdating(true);
    try {
      if (selectedButtonTarget) {
        const oldButtonsList = [...targetButtonsList];
        oldButtonsList.push({
          ...selectedButtonTarget,
          name: selectedButtonTarget.name,
          type: selectedButtonTarget.type,
        });
        await dispatch(localUserQuestionUpdate("buttons", oldButtonsList));
      }

      if (
        userSaysTarget &&
        userSaysTarget.text &&
        userSaysTarget.text.trim().length > 0
      ) {
        let oldList = [];
        oldList = intent && intent.questions ? [...intent.questions] : [];
        await oldList.unshift(userSaysTarget);
        await dispatch(localUserQuestionUpdate("questions", oldList));
      }

      if (selectedResponseTarget) {
        const oldList = [];
        await oldList.unshift(selectedResponseTarget);
        await dispatch(localUserQuestionUpdate("responses", oldList));
      }

      await setHasAddedResponse(true);
      await setHasAddedQuestion(true);
      dispatch(getAllButtonOptions(true));
      await dispatch(createIntent());
      history.push("/knowledge/all" + useLocatio.search);
    } catch (error: any) {}
    setIsIntentUpdating(false);
  };

  const isUserSaysActivityDetected = (e: any) => {
    setUserSaysTarget({
      text: e,
    });
  };

  const isResponseActivityDetected = (e: any) => {
    setSelectedResponseTarget(e);
  };

  const isActivityDetected = (e: any, buttonsList: any[]) => {
    setSelectedButtonTarget(e);
    setTargetOptionsList([...buttonsList]);
  };

  const tabsChangeHandler = (e: any) => {
    setActiveTab(e);
    buttonTextHandler(e);
  };

  const buttonTextHandler = (tab = activeTab) => {
    if (!props.editMode) {
      if (tab === "usersays") {
        setButtonText("Next");
      } else if (tab === "virtualassistant") {
        setButtonText("Save");
      }
    }
  };

  // Show delete modal
  const [modalShow, setModalShow] = useState(false);
  const modalhandler = (value: boolean) => {
    setModalShow(value);
  };

  const deleteConfirmed = async (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedRowIds: string[]
  ) => {
    e.preventDefault();
    setModalShow(false);
    setIsIntentUpdating(true);
    await dispatch(deleteKnowledgeById(selectedRowIds, false));
    props.resetEditKnowledgehandler("reload");
    setIsIntentUpdating(false);
  };

  const updateIntenthandler = async () => {
    if (activeTab === "level") {
      if (selectedGroupId) {
        setIsIntentUpdating(true);
        dispatch(
          addEntityToKnowledgeGroup(selectedGroupId, "intent", [intent._id])
        );
        setIsIntentUpdating(false);
      }
    } else {
      setIsIntentUpdating(true);
      if (selectedButtonTarget) {
        const oldButtonsList = [...targetButtonsList];
        oldButtonsList.push({
          ...selectedButtonTarget,
          name: selectedButtonTarget.name,
          type: selectedButtonTarget.type,
        });
        await dispatch(localUserQuestionUpdate("buttons", oldButtonsList));
      }

      await dispatch(updateIntent());
      setIsIntentUpdating(false);
    }
  };

  const resetEditKnowledgehandler = () => {
    dispatch({
      type: "FETCH_KNOWLEDGE_BY_ID_SUCCESS",
      payload: {
        intent: null,
      },
    });
    props.editMode ? props.resetEditKnowledgehandler() : history.goBack();
  };

  const getParaPhrasesBasedOnIntentHandler = async () => {
    try {
      setParaPhrasesLoading(true);
      const { questions, is_paraphrased }: any = await dispatch(
        getParaPhrasesBasedOnIntent(intent?._id)
      );
      setParaPhrasesLoading(false);

      let oldQuestions: any[] = [];
      if (questions.length) {
        oldQuestions = [...questions];
      }
      await dispatch(localUserQuestionUpdate("questions", oldQuestions));
      await dispatch(localUserQuestionUpdate("is_paraphrased", is_paraphrased));
    } catch (error) {
      setParaPhrasesLoading(false);
      console.log("[ error ]", error);
    }
  };

  const getParaPhrasesWithoutIntentHandler = async () => {
    try {
      setParaPhrasesLoading(true);
      let oldList: any[] = [];
      let list: any = [];
      if (intent && intent.questions) {
        oldList = [...intent.questions];
      }
      if (userSaysTarget) {
        await oldList.unshift(userSaysTarget);
      }

      if (oldList && oldList.length > 0) {
        for (let index = 0; index < oldList.length; index++) {
          const element = oldList[index];
          if (element?.text?.trim().length > 0) {
            list.push(element.text);
          }
        }
      } else {
        return;
      }

      const { paraphrased_questions }: any = await dispatch(
        getParaPhrasesWithoutIntent(list)
      );
      setParaPhrasesLoading(false);

      let oldQuestions: any[] = [];
      if (paraphrased_questions && paraphrased_questions.length > 0) {
        oldQuestions = [...paraphrased_questions];
      }
      oldQuestions = oldQuestions.map((e: any) => {
        return { text: e };
      });

      await dispatch(localUserQuestionUpdate("questions", oldQuestions));
      await dispatch(localUserQuestionUpdate("is_paraphrased", true));
    } catch (error) {
      setParaPhrasesLoading(false);
      console.log("[ error ]", error);
    }
  };

  const showAddTagHandler = (e: any) => {
    setShowTagsModal(!showTagsModal);
  };

  const confirmedTagsHandler = async (e: any, tags: any[]) => {
    e.preventDefault();
    // setQuestionUpdating(true)
    setShowTagsModal(false);
    if (props.editMode) {
      await dispatch(updateIntentTags(tags.map((t: any) => t.value)));
    } else {
      dispatch(
        localUserQuestionUpdate(
          "tags",
          tags.map((t: any) => t.value)
        )
      );
    }
    // setQuestionUpdating(false)
  };

  const removeTagsHandler = async (e: any, value: any) => {
    const tags = [...allTags];
    const hasTagPresent = tags.findIndex(
      (tag) => tag.value.toLowerCase() === value.toLowerCase()
    );
    if (hasTagPresent >= 0) {
      tags.splice(hasTagPresent, 1);
      setAllTags(tags);

      if (props.editMode) {
        await dispatch(updateIntentTags(tags.map((t: any) => t.value)));
      } else {
        dispatch(
          localUserQuestionUpdate(
            "tags",
            tags.map((t: any) => t.value)
          )
        );
      }
    }
  };

  // questionLoading
  if (isIntentUpdating) {
    return <Loading />;
  }

  return (
    <>
      <section className="page-mid-wraper pb-0">
        {intent && intent._id && (
          <ConfirmationModal
            message={t(
              "You are deleting a question. Note that all the children of this question will also be deleted. Are you sure you want to delete?"
            )}
            size={"md"}
            show={modalShow}
            selectedRow={[intent._id]}
            onHide={() => modalhandler(false)}
            confirmHandler={deleteConfirmed}
          />
        )}

        <UpdateModuleModal
          show={showRenameModal}
          onHide={updateModalHandler}
          name={selectedModuleName}
          parent_id={selectedParentId}
          editMode={moduleEditMode}
          confirmHandler={confirmRenameHandler}
          moduleDepth={moduleDepth}
        />

        <AddTags
          onHide={showAddTagHandler}
          show={showTagsModal}
          confirmhandler={confirmedTagsHandler}
          selectedTags={allTags}
        />

        <Container>
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn p-0 btn-back"
                onClick={resetEditKnowledgehandler}
              >
                <Image src={backimg} /> {t("Return to previous page")}
              </button>
              <h1 className="first-step mb-2 d-flex flex-wrap">
                {props.editMode
                  ? intent && intent.questions && intent.questions.length > 0
                    ? intent.questions[0].text
                    : intent?.name
                  : "Create from scratch"}
                {props.editMode && (
                  <button
                    className="btn btn-danger ml-auto"
                    onClick={() => modalhandler(true)}
                  >
                    {t("Delete Question")}
                  </button>
                )}
              </h1>
              <h2 className="font-weight-normal">
                {t(
                  "This is where you create knowledge for your virtual assistant."
                )}
                {/* <button className='btn btn-link font-sm py-0'>
                  Tell Me More
                </button> */}
              </h2>
              <div className="mt-4">
                <Tabs
                  defaultActiveKey="usersays"
                  onSelect={tabsChangeHandler}
                  activeKey={activeTab}
                >
                  <Tab eventKey="usersays" title={t("Questions")}>
                    <UserSaysTab
                      activeTab={activeTab}
                      editMode={props.editMode}
                      isActivityDetected={isUserSaysActivityDetected}
                      hasAdded={hasAddedQuestion}
                    />
                  </Tab>
                  <Tab
                    eventKey="virtualassistant"
                    title={t("Answers")}
                    disabled={
                      !props.editMode &&
                      intent &&
                      ((intent.questions &&
                      intent.questions.length === 0) || !intent.questions) &&
                      allUserGroupsData.length > 0
                    }
                  >
                    <VirtualAssistantTab
                      allUserGroups={[...allUserGroupsData]}
                      isActivityDetected={isResponseActivityDetected}
                      hasAdded={hasAddedResponse}
                      createFromScratch={
                        generateVariations === true ? true : false
                      }
                    />
                  </Tab>
                  <Tab eventKey="buttons" title={t("Buttons")}>
                    <ButtonsTab
                      editMode={props.editMode}
                      isActivityDetected={isActivityDetected}
                    />
                  </Tab>
                  <Tab eventKey="keywords" title={t("Keywords")}>
                    <div className="sugested-next-ques">
                      <h3 className="mb-3">
                        {t("Manage keywords for the knowledge.")}
                      </h3>
                      <div className="knowledge-tags d-flex flex-wrap align-items-center">
                        {allTags.length > 0 &&
                          allTags.map((value, index) => {
                            return (
                              <Tags
                                key={value + "_" + index}
                                value={value.value}
                                label={value.label}
                                removeTagHandler={removeTagsHandler}
                              />
                            );
                          })}

                        <PermissionWrapper>
                          <button
                            className="btn btn-outline-primary"
                            onClick={showAddTagHandler}
                          >
                            {t("+ Add keywords")}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="level" title={t("Level")}>
                    <ManageLevelCompact
                      updateParentHandler={updateParentHandler}
                      onSelectGroupId={onSelectGroupId}
                      selectedGroupId={selectedGroupId}
                      setSelectedParentId={setSelectedParentId}
                      setSelectedModuleName={setSelectedModuleName}
                      intent={intent}
                      editMode={props.editMode}
                    />
                  </Tab>
                  <Tab eventKey="settings" title={t("Settings")}>
                    <SettingsTab editMode={props.editMode} />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          {(props.viewall || generateVariations) && (
            <div className="d-flex align-items-center flex-wrap justify-content-end">
              {paraPhrasesLoading && (
                <div className="spinner-border spinner-width-20 spinner-border-lg clr-primary mr-2"></div>
              )}

              {paraPhrasesLoading && (
                <div className="mr-auto font-16">
                  {t("Generating training phrases...  ")}
                </div>
              )}

              {intent &&
                intent._id &&
                !paraPhrasesLoading &&
                !intent.is_paraphrased &&
                activeTab === "usersays" && (
                  <div className="mr-auto font-16">
                    {t("Looking to expand your question set?  ")}
                    <button
                      className="btn btn-link py-0"
                      disabled={paraPhrasesLoading}
                      onClick={getParaPhrasesBasedOnIntentHandler}
                    >
                      {t("Generate Variations")}
                    </button>
                  </div>
                )}
              {generateVariations &&
                intent &&
                !intent._id &&
                !paraPhrasesLoading &&
                !intent.is_paraphrased &&
                activeTab === "usersays" && (
                  <div className="mr-auto font-16">
                    {t("Looking to expand your question set?  ")}
                    <button
                      className="btn btn-link py-0"
                      disabled={
                        paraPhrasesLoading ||
                        (!userSaysTarget && intent && !intent.questions) ||
                        (userSaysTarget?.text?.trim().length === 0 &&
                          intent &&
                          !intent.questions)
                        // intent?.questions?.length <= 0
                      }
                      onClick={getParaPhrasesWithoutIntentHandler}
                    >
                      {t("Generate Variations")}
                    </button>
                  </div>
                )}

              {props.editMode && (
                <PermissionWrapper>
                  <button
                    className="btn btn-primary mr-2"
                    onClick={updateIntenthandler}
                  >
                    {t("Save")}
                  </button>
                </PermissionWrapper>
              )}
              {!props.editMode && (
                <PermissionWrapper>
                  <button
                    className="btn btn-primary third-step"
                    onClick={createIntenthandler}
                  >
                    {props.editMode ? "Save" : buttonText}
                  </button>
                </PermissionWrapper>
              )}
              {props.editMode && (
                <button
                  className="btn btn-outline-primary"
                  onClick={resetEditKnowledgehandler}
                >
                  {t("Cancel")}
                </button>
              )}
              {/* <PermissionWrapper>
                  <button
                    className="btn btn-outline-primary ml-auto"
                    onClick={() => {
                      history.push("/knowledge/knowledge-create-spreadsheet");
                    }}
                  >
                    Bulk Upload
                  </button>
                </PermissionWrapper> */}
            </div>
          )}
        </Container>
      </section>
      {!props.viewall && !generateVariations && (
        <footer>
          <Container>
            <div className="d-flex align-items-center flex-wrap">
              {props.editMode && (
                <PermissionWrapper>
                  <button
                    className="btn btn-primary mr-3"
                    onClick={updateIntenthandler}
                  >
                    {t("Save")}
                  </button>
                </PermissionWrapper>
              )}
              {!props.editMode && (
                <PermissionWrapper>
                  <button
                    className="btn btn-primary third-step"
                    onClick={createIntenthandler}
                  >
                    {props.editMode ? "Save" : buttonText}
                  </button>
                </PermissionWrapper>
              )}
              {props.editMode && (
                <button
                  className="btn btn-outline-primary ml-3"
                  onClick={resetEditKnowledgehandler}
                >
                  {t("Cancel")}
                </button>
              )}
              {/* <PermissionWrapper>
                  <button
                    className="btn btn-outline-primary ml-auto"
                    onClick={() => {
                      history.push("/knowledge/knowledge-create-spreadsheet");
                    }}
                  >
                    Bulk Upload
                  </button>
                </PermissionWrapper> */}
            </div>
          </Container>
        </footer>
      )}
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        maskClassName={"maskbg"}
        onRequestClose={() => setIsTourOpen(false)}
        showNumber={false}
        rounded={5}
        accentColor={"#F9B234"}
      />
    </>
  );
};

export default KnowledgeCreateFromScratch;
