import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import BarCompact from '../../BarCompact'
import { getDashboardUserSessionReports } from '../../../../store/dashboard/actions'
import { useHistory } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import infoimg from '../../../../assets/images/help-info.svg'
import InfoIconDetails from '../InfoIconDetails/InfoIconDetails'

import { useTranslation } from "react-i18next";

interface Props {
  filtersData?: {
    filter_from_date?: string
    filter_to_date?: string
    channel?: string
  }
  redirectPath?: string
}

function Sessions({ filtersData, redirectPath }: Props): ReactElement {
  const { t } = useTranslation('translation');
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [sessionData, setSessionData] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    // console.log('[ Sessions filtersData ]', filtersData)
    if (
      loading ||
      !filtersData.filter_from_date ||
      !filtersData.filter_to_date
    ) {
      return
    }

    const fetchSessionData = async (filtersData: any) => {
      setLoading(true)
      // console.log('filtersData', filtersData)
      try {
        const sessionsData: any = await dispatch(
          getDashboardUserSessionReports(filtersData)
        )
        setSessionData(sessionsData)
        setLoading(false)
      } catch (e: any) {
        setLoading(false)
        console.log('[[[ e ]]]', e)
      }
    }
    fetchSessionData(filtersData)
  }, [filtersData])

  return (
    <div
      className='col-sm-6 col-md-4 col-lg-2'
      onClick={() => {
        redirectPath && history.push(redirectPath)
      }}
    >
      <div className='graph-box'>
        <h3>
          {t("Chat Sessions")}
          <InfoIconDetails
            message={
              t('Chat session is a group of interactions between the learner and the chatbot that take place within a given time frame.')
            }
          />
        </h3>
        <div className='row'>
          <div className='col-4'>
            {/* <h2>{weeklySessionsCount?.summary?.current_week_count}</h2> */}
            {!loading && <h2>{sessionData?.sessionsCount}</h2>}
            {loading && (
              <div className='spinner-border spinner-border-sm text-primary'></div>
            )}
            {/* {weeklySessionsCount.summary?.growth > 0 && (
                  <div
                    className={cx({
                      'growth-percent': true,
                      'growth-up':
                        Math.sign(weeklySessionsCount?.summary?.growth) >
                        -1,
                      'growth-down':
                        Math.sign(weeklySessionsCount?.summary?.growth) < 0,
                    })}
                  >
                    {weeklySessionsCount
                      ? weeklySessionsCount?.summary?.growth * 100
                      : 0}
                    %
                  </div>
                )} */}
          </div>
          <div className='col-8'>
            <div style={{ width: '100%', height: '50px' }}>
              {sessionData &&
                sessionData.activeUsersSessions &&
                sessionData &&
                sessionData.activeUsersSessions.length && (
                  <BarCompact
                    data={
                      sessionData && sessionData.activeUsersSessions
                        ? sessionData.activeUsersSessions
                        : []
                    }
                    indexBy='date'
                    keys='session_count'
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sessions
