import { default as classNames, default as cx } from "classnames";
import React, { useEffect, useState } from "react";
import { Container, Image, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  supportedFilesKendra,
  supportedFilesKnowledgeForFaQs,
} from "../../../app.constant";
import closeimg from "../../../assets/images/close-red.svg";
import docximg from "../../../assets/images/docx.svg";
import pptimg from "../../../assets/images/pptx.svg";
import pdfimg from "../../../assets/images/pdf.svg";

// import SpreadSheetDocument from '../../../assets/sample/sample-faq.xlsx';
import { BUILD_KNOWLEDGE_REVIEW_FAQ } from "../../../config";

import {
  removeDocumentByDocumentId,
  resetDocuments,
  uploadFaqUrlAndDocument,
  uploadFilesToStorage,
} from "../../../store/knowledge/actions";
import { getUserDocuments } from "../../../store/knowledge/selector";
import { ValidateUrl } from "../../../utils/appUtils";

import { useTranslation } from "react-i18next";
import buildDragDropImg from "../../../assets/New-images/build-drag-drop.svg";
import backblue from "../../../assets/images/arrow-left.svg";
import DragAndDrop from "../../../components/UI/DragAndDrop";
import { HelperService } from "../../../services/helper.service";

interface Props {}

export const UploadBuildFaqDocument = (props: any) => {
  const { t } = useTranslation("translation");
  const helper = new HelperService();
  let re = /(?:\.([^.]+))?$/;
  const dispatch = useDispatch();
  const useLocatio = useLocation();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isFileInValid, setIsFileInValid] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [hasFileUploaded, setHasFileUploaded] = useState(false);
  const userDocuments = useSelector(getUserDocuments);
  const history = useHistory();
  const [documents, setDocuments] = React.useState([]);
  const [faqOption, setFaqOption] = React.useState("upload");
  const [isFileValidating, setIsFileValidating] = useState(false);
  const [uploadUrl, setUploadURL] = React.useState({
    url: "",
    valid: false,
  });
  const [typeOfDocument, setTypeOfDocument] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const qnaType = query.get("type");
    setTypeOfDocument(qnaType);
  }, []);

  useEffect(() => {
    dispatch(resetDocuments());
    setDocuments([]);
  }, []);

  /**
   * Function is used to check which option user has Choosen, Faq or Faq/URL
   */
  const faqExtractOptionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFaqOption(e.target.value);
    setIsFileInValid(false);
    setIsFileUploading(false);
    setDocuments([]);
  };

  /**
   * Function is used to check which option user has Choosen, Faq or Faq/URL
   */
  const onTabChangesHandler = (e: string) => {
    setIsFileInValid(false);
    setIsFileUploading(false);
    setFaqOption(e);
    if (e === "weburl") {
      setDocuments([]);
    } else {
      setUploadURL({
        url: "",
        valid: false,
      });
    }
  };

  const handleLinkChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const valid = ValidateUrl(value);
    const prevUrl = {
      ...uploadUrl,
      url: value,
      valid,
    };
    setIsFileUploading(false);
    await setUploadURL(prevUrl);
  };

  const getFileDocumentsHandler = async (d: File[]) => {
    setIsFileInValid(false);
    setIsFileUploading(false);
    let totalDocuments = 0;
    totalDocuments = documents.length + d.length;
    if (totalDocuments > 1) {
      helper.showToaster(t("You can upload only single file."));
      return;
    }
    const oldDocuments = [...documents];
    await setDocuments([
      ...oldDocuments,
      ...d.map((doc: any) => {
        doc.sizeError = doc.size / (1024 * 1024) > 10;
        return doc;
      }),
    ]);
    setHasFileUploaded(false);
    // dispatch(actions.setDocuments(d))
  };

  /**
   *
   * KNOWLEDGE_DOCUMENT_FAQ
   */
  const processUrl = async () => {
    if (documents.length === 0 && (!uploadUrl || !uploadUrl.valid)) {
      setIsFileInValid(true);
      helper.showToaster(
        t("Please choose documents or add url before proceeding.")
      );
      return;
    } else {
      setIsFileUploading(true);

      const oldDocuments = [...documents];
      let document: any;
      const query = new URLSearchParams(useLocatio.search);
      const group = query.get("group");

      if (documents.length > 0) {
        document = oldDocuments[0];
        document.isUploading = true;
      }

      const uploadedDocument: any = await dispatch(
        uploadFaqUrlAndDocument(
          {
            file: documents ? documents[0] : null,
            file_name:
              documents && documents.length > 0
                ? documents[0].name
                : uploadUrl.url,
            faqType: "other",
            group,
            flow: "v1",
          },
          (event: any) => {
            if (documents.length > 0) {
              document.progress = Math.round(
                (100 * event.loaded) / event.total
              );
              setDocuments([...oldDocuments]);
            }
          }
        )
      );

      if (uploadedDocument && uploadedDocument.status === 200) {
        if (typeOfDocument === "qna") {
          history.push(
            `${BUILD_KNOWLEDGE_REVIEW_FAQ.replace(
              ":documentId",
              uploadedDocument.document._id
            )}?question=${uploadedDocument.document._id}&type=1`
          );
        }
        // return history.push(
        //   `${KNOWLEDGE_DOCUMENT_FAQ.replace(
        //     ":documentId",
        //     uploadedDocument.document._id
        //   )}?question=${uploadedDocument.document._id}&type=2`
        // );
      }

      if (documents.length > 0) {
        if (uploadedDocument && uploadedDocument.status.code !== 200) {
          document.isUploaded = false;
          document.isError = true;
          document.isUploading = false;
          document.message = uploadedDocument.status.message;
          setDocuments([...oldDocuments]);
        }
      }
    }
  };

  /**
   *
   * KNOWLEDGE_REVIEW_FAQ
   */
  const processDocuments = async () => {
    if (hasFileUploaded) {
      const ID = userDocuments.map((d: any) => d._id).join(",");
      if (typeOfDocument === "qna") {
        history.push(
          `${BUILD_KNOWLEDGE_REVIEW_FAQ.replace(
            ":documentId",
            ID
          )}?question=${ID}&type=1`
        );
      }
      // history.push(
      //   `${KNOWLEDGE_REVIEW_FAQ.replace(
      //     ":documentId",
      //     ID
      //   )}?question=${ID}&type=1`
      // );
      return;
    }

    if (documents.length === 0) {
      setIsFileInValid(true);
      helper.showToaster(t("Please choose documents before proceeding."));
      return;
    } else if (documents && documents[0].sizeError) {
      helper.showToaster(t("File not uploaded. File size is more than 10 MB."));
      return;
    } else {
      setIsFileUploading(true);
      setHasFileUploaded(false);
      const query = new URLSearchParams(useLocatio.search);
      const group = query.get("group");
      const oldDocuments = [...documents];
      let document: any;
      if (documents.length > 0) {
        document = oldDocuments[0];
        document.isUploading = true;
      }
      setIsFileValidating(true);
      const uploadedDocument: any = await dispatch(
        uploadFilesToStorage(
          document,
          (event: any) => {
            const prevResult = document;
            prevResult.progress = Math.round(
              (100 * event.loaded) / event.total
            );
            setDocuments([...documents]);
          },
          "qna",
          "newupload",
          group
        )
      );
      setIsFileValidating(false);
      if (
        uploadedDocument &&
        uploadedDocument.status &&
        !uploadedDocument._id &&
        uploadedDocument.status.code !== 200
      ) {
        document.isUploaded = false;
        document.isError = true;
        document.isUploading = false;
        document.message = uploadedDocument.status.message;
        setDocuments([...oldDocuments]);
        setIsFileUploading(false);
        setHasFileUploaded(true);
      } else {
        document.isUploaded = true;
        document.isUploading = false;
        document._id = uploadedDocument._id;
        if (typeOfDocument === "qna") {
          return history.push(
            `${BUILD_KNOWLEDGE_REVIEW_FAQ.replace(
              ":documentId",
              uploadedDocument._id
            )}?question=${uploadedDocument._id}&type=1`
          );
        }
        // else {
        //   history.push(
        //     `${DOCUMENT_SECTIONS}?documents=${uploadedDocument._id}`
        //   );
        // }
      }
    }
  };

  const handleDocRemove = (e: React.MouseEvent<HTMLElement>, index: number) => {
    const newDocuments = [...documents];
    const slicedDoc = newDocuments.splice(index, 1);
    if (slicedDoc && slicedDoc[0] && slicedDoc[0].isUploaded) {
      dispatch(removeDocumentByDocumentId(slicedDoc[0]._id));
    }
    setDocuments(newDocuments);
  };

  const RenderSelectedDocuments =
    documents &&
    documents.map((doc: any, index: number) => {
      let img = pptimg;
      if (doc.name.split(".").pop().includes("ppt")) {
        img = pptimg;
      }
      if (doc.name.split(".").pop().includes("doc")) {
        img = docximg;
      }
      if (doc.name.split(".").pop().includes("pdf")) {
        img = pdfimg;
      }
      return (
        <div
          className={`${doc.sizeError ? "f-error " : ""}${
            doc.isLangError ? "info-doc-msg " : ""
          }files d-flex align-items-center border-grey radius-4 px-3 py-2`}
          key={index}
        >
          <div className="d-flex align-items-center w-auto">
            <Image src={img} />
            <div className="mx-3 font-sm">
              <strong>{doc.name}</strong>
              {!doc.sizeError && (
                <span className="font-12 clr-black font-600">
                  {helper.formatBytes(doc.size)}
                </span>
              )}

              {/* <ProgressBar now={doc.progress} /> */}
              {doc.isError && <span>{doc.message}</span>}
            </div>
          </div>

          <div className="ml-auto w-auto d-flex align-items-center">
            {
              // doc.progress < 100 ||
              isFileValidating && (
                <div className="doc-checking-msg d-flex align-items-center font-600 clr-black font-sm">
                  <span className="doc-loader spinner-width-20 mr-2"></span>
                  {t("Checking document")}
                </div>
              )
            }

            {!doc.isUploading && !isFileUploading && !isFileValidating && (
              <button
                className="btn p-0 ml-3 btn-red-close"
                onClick={(e) => handleDocRemove(e, index)}
              >
                <Image src={closeimg} />
              </button>
            )}
          </div>
        </div>
      );
    });

  const dragDropClass = classNames({
    "invalid-file": isFileInValid,
  });

  const uploadDocumentHtml = (
    <>
      <div>
        <div className="row page-mid-wraper-build-v2">
          <div className="col-md-5">
            <div className={dragDropClass}>
              <div className="custom-file-wrap new-custom-file mw-100 border-0">
                <DragAndDrop
                  getFileDocumentsHandler={getFileDocumentsHandler}
                  supportedTypes={
                    typeOfDocument === "qna"
                      ? supportedFilesKnowledgeForFaQs.join(", ")
                      : supportedFilesKendra.join(", ")
                  }
                  message={`<h2 className='mb-1'>Drop your files here or click to browse.</h2><div>Supported File types: ${
                    typeOfDocument === "qna"
                      ? supportedFilesKnowledgeForFaQs.join(", ")
                      : supportedFilesKendra.join(", ")
                  }</div>`}
                  fileSizeMessage=""
                  fileImage={buildDragDropImg}
                  maxFiles={1}
                  {...props}
                />
              </div>
            </div>
          </div>
          <div className="col-md-7 mt-3 mt-md-0">
            <>
              <p className="text-left clr-grey-txt7 font-16 mb-3">
                {t(
                  "To get started, simply upload your preset question and answer pairs here. "
                )}
                <br />
                {t("You can upload ")}
                <span className="clr-primary font-weight-bold">{t("1 ")}</span>
                {t("document with a maximum file size of ")}
                <span className="clr-primary font-weight-bold">
                  {t("10MB ")}
                </span>
                {/* {t("If you need assistance with formatting, ")}
                <span className="clr-primary font-weight-bold" onClick={() => {
                  window.open('https://d1ei8oh01ba18r.cloudfront.net/static/files/sample-faq.xlsx', '_blank')
                }}>
                  {t("download ")}
                </span>
                {t("our sample spreadsheet template for guidance. ")} */}
              </p>
            </>
            <div className="selected-files mt-3 w-100">
              {RenderSelectedDocuments}
            </div>
          </div>
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn btn-primary third-step mt-4"
          onClick={processDocuments}
          disabled={isFileUploading || !documents.length}
        >
          {t("Upload Document(s)")}
        </button>
      </div>
    </>
  );

  return (
    <>
      <section className="page-mid-wraper h-without-foter">
        <Container>
          <div className="row">
            <div className="col-sm-12">
              <button
                className="btn p-0 btn-back"
                onClick={() => {
                  history.goBack();
                }}
              >
                <Image src={backblue} /> {t("Return to previous page")}
              </button>
              <div className="text-center mb-32">
                <div className="d-flex flex-wrap justify-content-center">
                  <h1 className="clr-primary first-step mb-2">
                    {t("Import Predefined Q&As")}
                  </h1>
                </div>
                <h2 className="font-600">
                  {t(
                    "Upload Your Predefined Question and Answer Sets into your knowledge base"
                  )}
                </h2>
              </div>
            </div>
          </div>
          <div>
            <div className="text-center mb-32">
              <div className="d-flex flex-wrap justify-content-center">
                <Tabs
                  defaultActiveKey="upload"
                  onSelect={onTabChangesHandler}
                  // className="justify-content-center"
                >
                  <Tab eventKey="upload" title={t("Upload Document")}>
                    {uploadDocumentHtml}
                  </Tab>
                  <Tab eventKey="weburl" title={t("Website Address(URL)")}>
                    <div className="file-input-url page-mid-wraper-build-v2">
                      <p className="clr-grey-txt7 font-16 mb-3">
                        {t(
                          "To get started, simply enter the website address (URL) from which you would like to extract the predefined questions and answers."
                        )}
                      </p>
                      <input
                        type="text"
                        className={cx({
                          "form-control input-v2": true,
                          confirm: uploadUrl && uploadUrl.valid,
                        })}
                        placeholder="https://"
                        value={uploadUrl.url}
                        onChange={handleLinkChange}
                      />
                    </div>
                    <button
                      className="btn btn-primary third-step mt-4"
                      onClick={processUrl}
                      disabled={
                        isFileUploading || uploadUrl.url.trim().length === 0
                      }
                    >
                      {t("Extract Q&A")}
                    </button>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};
export default UploadBuildFaqDocument;
