import React, { useState, useEffect, useRef } from "react";

//-------------------------SUN---------------------------------//
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { sunConfig, sunIcons } from "./data";

import "./style.scss";

import { useTranslation } from "react-i18next";
import ApiUtil from "../../../utils/apiUtil";
import { UploadBeforeHandler, UploadBeforeReturn } from "suneditor-react/dist/types/upload";

const SunEditorUI = ({
  name,
  value,
  onChange,
  config,
  editorType,
  mode,
  id,
  autoFocus,
  maxCharCount,
  buttonList,
  useValue,
  showInline,
  isToogleToolBar = false,
  height = null,
  placeholder = null,
}: any) => {
  // const { t } = useTranslation("translation");
  //   const makeApiCall = useFetch();

  const [initialValue, setInitialValue] = useState(value);
  const editor: any = useRef();

  // if(isToogleToolBar) {
  //   editor.toolbar.hide()
  // }

  useEffect(() => {
    setInitialValue(value);
  }, [value]);

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
    editor.current.toolbar.hide();
    editor.current.onFocus = () => {
      editor.current.toolbar.show();
    };

    editor.current.onBlur = () => {
      editor.current.toolbar.hide();
    };
  };

  function handleImageUploadBefore (files: any, info: any, uploadHandler: any): any {
    const file_data = files[0];
    const formData = new FormData();
    formData.append("media", file_data);
    ApiUtil.SendAsync<any>({
      url: `media/images/upload`,
      method: "POST",
      data: formData,
    }).then((json: any) => {
      if (json.status === 200) {
        let response = {
          errorMessage: "Image could not be uploaded",
          result: [
            {
              url: json.data.data.media.url,
              name: json.data.data.media.filename,
              size: file_data.size,
            },
          ],
        };
        uploadHandler(response);
      }
      return undefined;
    });
  }

  const htmlDecode = (input: any) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  };

  const handlePaste = (e: any, cleanData: any, maxCharCount: any) => {
    let parser = new DOMParser();
    let newHTML = parser.parseFromString(cleanData, "text/html");

    if (editor && editor.current) {
      let newData = editor.current.getContents().concat(htmlDecode(cleanData));
      editor.current.setContents(newData);
    }
  };

  // placeholder={placeholder ? placeholder : t("Please type here...")}
  return (
    <>
      {editorType === "sun" && (
        <SunEditor
          getSunEditorInstance={getSunEditorInstance}
          showInline={showInline}
          height={height ? height : "180"}
          autoFocus={true}
          // key={id}
          name={name}
          placeholder={placeholder ? placeholder : "Please type here..."}
          setContents={initialValue}
          // onBlur={(e, content) => onChange(content)}
          onChange={onChange}
          setOptions={{
            showPathLabel: false,
            mode: mode ? mode : "classic",
            buttonList: buttonList ? buttonList : sunConfig,
            icons: sunIcons,
            maxCharCount: maxCharCount ? maxCharCount : null,
            charCounter: true,
            charCounterLabel: "Charaters:",
          }}
          onImageUploadBefore={ handleImageUploadBefore as any}
          onImageUploadError={(message: any, result: any) => {
            console.log("[ message, result ]", message, result);
          }}
          // onPaste={handlePaste}
        />
      )}
    </>
  );
};

export default SunEditorUI;
