import cx from "classnames";
import React, { useEffect, useState } from "react";

import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select, { components } from "react-select";
import { v4 as uuidv4 } from "uuid";
import { DocOptions } from "../../../app.constant";
// import backblue from "../../assets/images/arrow-left.svg";
import chevLeft from "../../../assets/New-images/chevron-left-blk.svg";
import closeimg from "../../../assets/New-images/close-icon-red.svg";
import downarrowblue from "../../../assets/New-images/dropdown-arow-blue.svg";
import pinkWarning from "../../../assets/images/pink-warning.svg";

import infoImg from "../../../assets/New-images/info-file-img.svg";
import infoCircleImg from "../../../assets/New-images/info-pink-circle.svg";
import noSectionRequired from "../../../assets/New-images/no_section_required.svg";

import PDFViewer from "../../../components/UI/PDFViewer/PDFViewer";
import PermissionWrapper from "../../../components/UI/PermissionWrapper/PermissionWrapper";
import {
  bulkAssignModules,
  getDocumentFromID,
  getDocumentsValidationsV3,
  getSectionsBasedOnDocumentId,
  recentlyUsedDocuments,
  sendBuildDocToSumRequest,
} from "../../../store/knowledge/actions";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Loading from "../../../components/UI/Loading/Loading";
import { BUILD_DOCUMENT_PROCESS } from "../../../config";

import MoveSelectedModal from "../../../components/UI/KnowledgeDatatableComponent/MoveSelectedModal";
import ProgressSteps from "../../../components/UI/ProgressSteps/ProgressSteps";
import { HelperService } from "../../../services/helper.service";
import { getAgentFeatures } from "../../../store/common/actions";
import LengthOfSummaryPopup from "./popups/LengthOfSummaryPopup";
import SelectQuestionTypeModal from "./popups/SelectQuestionTypeModal";
import { getCommonState } from "../../../store/common/selector";
const helper = new HelperService();

export const SummariseDocuments = (props: any) => {
  const { Option, SingleValue } = components;
  const { t } = useTranslation("translation");
  const history: any = useHistory();
  const query = new URLSearchParams(history.location.search);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState([]);
  const [documentSectionData, setDocumentSectionData] = useState(null);
  const [documentSmartQuizData, setDocumentSmartQuizData] = useState({});
  const [documents, setDocuments] = useState([]);
  const [localSections, setLocalSections] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [notReadyToSummarised, setNotReadyToSummarised] = useState(false);
  const [urlMap, setUrlMap] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const params: any = useParams();
  const [showLengthSummaryModal, setShowLengthSummaryModal] = useState(false);
  const [fileSize, setFileSize] = useState({
    generic_files: null,
    pptx_files: null,
  });
  const [groupId, setGroupId] = useState(query.get("group") || "");
  const [newDocAdded, setNewDocAdded] = useState(false);
  const [showQueType, setShowQueType] = useState(false);
  const [type, setType] = useState("");
  const url = window.location.href;
  const commonState = useSelector(getCommonState);

  useEffect(() => {
    setGroupId(
      history &&
        history.location &&
        history.location.state &&
        history.location.state.group
        ? history.location.state.group
        : query.get("group")
    );
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const question = query.get("documents") || query.get("question");
    const paramsQ = question.split(",");
    // setSummaryType(query.get("summaryType"));
    const type = query.get("type");
    setType(type);
    setUploadedDocumentIds(paramsQ);
    if (!paramsQ.length) {
      history.goBack();
    }

    const fn = async () => {
      const agentFeatures: any = await dispatch(getAgentFeatures());
      const feature = agentFeatures.filter(
        (e: any) => e.name === "document_summarisation"
      );
      setFileSize({
        generic_files:
          feature.length > 0 ? feature[0]?.params?.max_generic_pages : 20,
        pptx_files:
          feature.length > 0 ? feature[0]?.params?.max_pptx_pages : 50,
      });
    };
    fn();
    getDocumentFromIDHandler(paramsQ);
  }, []);

  const getDocumentFromIDHandler = async (documentIds: string[]) => {
    const documents = [];
    let index = 0;
    for await (const document of documentIds) {
      const fetchedDocument: any = await dispatch(getDocumentFromID(document));
      const option: any = DocOptions.find(
        (option: any) => option.value === fetchedDocument.type
      );
      setSelectedDocumentType(option);
      documents.push({
        ...fetchedDocument,
        documentIndex: index,
        label: fetchedDocument.name,
        value: fetchedDocument._id,
      });
      index++;
    }

    // let temp: { [id: string]: any } = {};
    // for (const question of documentIds) {
    //   let tempSectionsArray = new Array(4);
    //   for (let i = 0; i < 9; i++) {
    //     const id = uuidv4();
    //     tempSectionsArray[i] = {
    //       id: id,
    //       name: "",
    //       from_page: "",
    //       to_page: "",
    //     };
    //   }
    //   temp[question] = { ...temp[question], sections: [] };
    // }
    // await setDocumentSectionData(temp);
    await setDocuments([...documents]);
    await paginateDocuments(documents, 0);
  };

  const paginateDocuments = async (documents: any[] = [], index: number) => {
    setSelectedDocument(documents[index]);
    setSelectedDocumentIndex(index);
    await getDocumentSections(documents[index]._id);
    getDocumentValidationsHandler(documents[index]._id);
  };

  const setDocumentSection = async (
    fetchedSections: any[],
    questionId: string
  ) => {
    const temp: { [id: string]: any } = {};
    // let tempSectionsArray = new Array(4);
    // for (let i = 0; i < 4; i++) {
    //   const id = uuidv4();
    //   tempSectionsArray[i] = {
    //     id: id,
    //     name: "",
    //     from_page: "",
    //     to_page: "",
    //   };
    // }
    temp[questionId] = {
      ...temp[questionId],
      sections: [],
    };

    if (fetchedSections.length > 0) {
      let sections: any = [...temp[questionId].sections];
      fetchedSections.reverse().forEach((section: any) => {
        sections.unshift(section);
      });
      temp[questionId].sections = [...sections];
      temp[questionId].hasApiCalled = true;
      temp[questionId].compact_ratio = null;
      await setDocumentSectionData({ ...documentSectionData, ...temp });
      return;
    } else {
      let sections: any = [...temp[questionId].sections];
      const newId = uuidv4();
      sections.push({
        id: newId,
        name: "",
        from_page: "",
        to_page: "",
      });
      temp[questionId].sections = [...sections];
      await setDocumentSectionData(temp);
      return;
    }
  };

  const getDocumentSections = async (questionId: any) => {
    let docuData: any = { ...documentSectionData };
    if (
      !documentSectionData ||
      (docuData && !docuData[questionId]) ||
      (docuData &&
        docuData[questionId] &&
        !docuData[questionId].hasOwnProperty("hasApiCalled"))
    ) {
      try {
        const sectionResponse: any = await dispatch(
          getSectionsBasedOnDocumentId([questionId])
        );
        setLocalSections([...sectionResponse]);
        setDocumentSection(sectionResponse, questionId);
      } catch (error) {
        setLocalSections([]);
      }
    }
  };

  const getDocumentValidationsHandler = async (questionId: string) => {
    try {
      let oldDocumentSmartQuizData = {
        ...documentSmartQuizData,
      };
      let data: any = {};
      data[questionId] = [];
      let updateSectiondata: any = {};
      updateSectiondata[questionId] = [];
      let newSections: any[] = [];
      let updateSections: any[] = [];
      documentSectionData[questionId].sections.forEach((section: any) => {
        if (section.name || (section.to_page && section.from_page)) {
          delete section.id;
          if (!section._id) {
            newSections.push({
              ...section,
              from_page: Number(section.from_page),
              to_page: Number(section.to_page),
            });
          }

          if (section._id) {
            updateSections.push({
              ...section,
              from_page: Number(section.from_page),
              to_page: Number(section.to_page),
            });
          }
        }

        updateSectiondata[questionId] = updateSections;
        data[questionId] = newSections;
      });

      // for the "length of summary dialog to appear"
      let pages = [];
      for (
        let i = 0;
        i < documentSectionData[questionId].sections.length;
        i++
      ) {
        let s = documentSectionData[questionId].sections[i];
        if (Number(s.to_page) && Number(s.from_page)) {
          pages.push({
            from_page: Number(s.from_page),
            to_page: Number(s.to_page),
          });
        }
      }

      Object.assign(oldDocumentSmartQuizData, {
        [questionId]: {
          sections: [...pages],
          compact_ratio: documentSectionData[questionId]?.compact_ratio
            ? documentSectionData[questionId]?.compact_ratio
            : null,
        },
      });
      await setDocumentSmartQuizData(oldDocumentSmartQuizData);

      const validationResponse: any = await dispatch(
        getDocumentsValidationsV3({
          // document_ids: [questionId],
          documents: [
            {
              _id: questionId,
              sections: pages,
              // documentSectionData[questionId].sections.map(
              //   (section: any) => {
              //     return {
              //       from_page: section.from_page,
              //       to_page: section.to_page,
              //     };
              //   }
              // ),
            },
          ],
        })
      );

      let valid: boolean = true;
      if (validationResponse.length && !validationResponse[0][0]) {
        let noOfPagesToBeValidated: number = getPageSize();
        // selectedDocument.label.includes(".ppt") ||
        // selectedDocument.label.includes(".pptx")
        //   ? 50
        //   : 20;
        await helper.showToaster(
          t(
            `Current document does not meet requirement. Please ensure that total page count does not exceed ${noOfPagesToBeValidated} pages`
          )
        );
        valid = false;
        setNotReadyToSummarised(true);
      } else {
        valid = true;
        setNotReadyToSummarised(false);
      }
      return { oldDocumentSmartQuizData, valid };
    } catch (error) {
      return { oldDocumentSmartQuizData: [], valid: false };
    }
  };

  const handleInputChange = (
    documentId: string,
    sectionId: any,
    value: string,
    type: string,
    index: number
  ) => {
    const temp = { ...documentSectionData };

    if (type === "section_name") {
      let sections: any = documentSectionData[documentId].sections;
      sections[index].name = value;
      temp[documentId].sections = [...sections];
      setDocumentSectionData(temp);
    } else if (type === "from_page") {
      let sections: any = documentSectionData[documentId].sections;
      sections[index].from_page = +value;
      temp[documentId].sections = [...sections];
      setDocumentSectionData(temp);
    } else if (type === "to_page") {
      let sections: any = documentSectionData[documentId].sections;
      sections[index].to_page = +value;
      temp[documentId].sections = [...sections];
      setDocumentSectionData(temp);
    }
  };

  const saveSectionDetails = async (
    generateTags = false,
    knowledgeMode = false,
    index: number,
    sendDocumentForSummarisation: boolean = false
  ) => {
    if (!selectedDocument || !selectedDocument.value) {
      return;
    }

    let data: any = {};
    data[selectedDocument.value] = [];
    let updateSectiondata: any = {};
    updateSectiondata[selectedDocument.value] = [];
    let newSections: any[] = [];
    let updateSections: any[] = [];
    documentSectionData[selectedDocument.value].sections.forEach(
      (section: any) => {
        if (section.name || (section.to_page && section.from_page)) {
          delete section.id;
          if (!section._id) {
            newSections.push({
              ...section,
              from_page: Number(section.from_page),
              to_page: Number(section.to_page),
            });
          }

          if (section._id) {
            updateSections.push({
              ...section,
              from_page: Number(section.from_page),
              to_page: Number(section.to_page),
            });
          }
        }

        updateSectiondata[selectedDocument.value] = updateSections;
        data[selectedDocument.value] = newSections;
      }
    );

    try {
      setLoading(true);
      const validationResponse: any = await getDocumentValidationsHandler(
        selectedDocument.value
      );
      if (!validationResponse.valid) {
        setLoading(false);
        return;
      }

      if (sendDocumentForSummarisation) {
        // if (props.smartQuiz || props.processToolkitDocument) {
        //   await dispatch(
        //     bulkAssignModules(
        //       groupId,
        //       Object.keys(validationResponse.oldDocumentSmartQuizData),
        //       "document",
        //       false
        //     )
        //   );
        // }
        if (
          props.smartQuiz ||
          (props.processToolkitDocument &&
            !props.hideModalSummariseIndividual) ||
          (url.includes("build") && type === "automated")
        ) {
          if (props.showModal) {
            setNewDocAdded(true);
            return;
          } else {
            if (
              (props.questionTypes && props.questionTypes.length === 0) ||
              !props.questionTypes
            ) {
              setShowQueType(true);
              return;
            } else {
              singleSummaryDocumentHandler(
                validationResponse.oldDocumentSmartQuizData,
                0.7
              );
              return;
            }
          }
        }
        if (props.summarise && props.summaryType === "single" && !props.generateKnowledgeFlow) {
          singleSummaryDocumentHandler(
            validationResponse.oldDocumentSmartQuizData,
            0.7
          );
          return;
        }
        summariseDocumentHandler(validationResponse.oldDocumentSmartQuizData);
      } else {
        paginateDocuments(documents, index);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  const confirmCategoryUpdate = async (
    selectedOption: any,
    createButtons: boolean = false
  ) => {
    setNewDocAdded(false);
    await setGroupId(selectedOption);
    await dispatch(
      bulkAssignModules(
        selectedOption,
        Object.keys(documentSmartQuizData),
        "document",
        false
      )
    );

    if (props.summarise && props.summaryType === "individual") {
      await summariseDocumentHandler(documentSmartQuizData);
      return;
    }

    if (props.processToolkitDocument && props.processToolkitDocumentQna) {
      await summariseDocumentHandler(
        documentSmartQuizData,
        0.7,
        selectedOption
      );
      return;
    }

    singleSummaryDocumentHandler(documentSmartQuizData, 0.7, selectedOption);
  };

  const singleSummaryDocumentHandler = async (
    updatedDocumentSmartQuizData: any = [],
    compactRatio: any,
    selectedGroupId: string = null,
    queType: any[] = [],
    questionCount: number = 0
  ) => {
    try {
      setLoading(true);
      let docSum: any = {
        document_ids: [],
        documents: [],
        builds_summarised_file: false,
        compact_ratio: null,
      };
      const docuData =
        // props.smartQuiz || props.processToolkitDocument
        // ?
        { ...updatedDocumentSmartQuizData };
      // : { ...documentSectionData };
      let documents: any = [];
      let ids: any = [];
      for (const key in docuData) {
        // only include valid pages
        let Sections = [];
        for (let i = 0; i < docuData[key].sections.length; i++) {
          let s = docuData[key].sections[i];
          if (Number(s.to_page) && Number(s.from_page)) {
            Sections.push({
              from_page: Number(s.from_page),
              to_page: Number(s.to_page),
            });
          }
        }

        documents.push({
          _id: key,
          sections: Sections,
        });
        ids.push(key);
        docSum.document_ids = [...ids];
        docSum.documents = [...documents];
        docSum.compact_ratio =
          uploadedDocumentIds && uploadedDocumentIds.length > 1
            ? compactRatio
            : docuData[key]?.compact_ratio
            ? docuData[key]?.compact_ratio
            : compactRatio;
      }

      docSum =
        type === "automated"
          ? { ...docSum, is_auto_setup: true, target_flow: props.target_flow }
          : { ...docSum, target_flow: props.target_flow };

      const response: any = await dispatch(
        sendBuildDocToSumRequest(
          docSum,
          false,
          props.smartQuiz ||
            props.genQna ||
            (url.includes("build") && type === "automated")
        )
      );
      setShowLengthSummaryModal(false);

      if (response) {
        if (response.summary_document_id && (selectedGroupId || groupId)) {
          await dispatch(
            bulkAssignModules(
              selectedGroupId ? selectedGroupId : groupId,
              [response.summary_document_id],
              "document",
              false
            )
          );
        }

        await dispatch(
          recentlyUsedDocuments(
            commonState.selectedAgent,
            ids,
            commonState.recentlyUsedDocuments
              ? JSON.parse(commonState.recentlyUsedDocuments)
              : {}
          )
        );
        const sendDocument = props.routeDocumentIds
          ? `&document=${docSum.document_ids.join(",")}`
          : "";

        const through = query.get("through") === "questionBank" ? `&through=questionBank` : "";
        const fileType = query.get("fileType") ? `&fileType=${query.get("fileType")}` : ""

        history.push(
          (props.redirectURI ? props.redirectURI : BUILD_DOCUMENT_PROCESS) +
            // props.smartQuiz || props.processToolkitDocument
            // ?
            `?docsum=${response._id}&summary_document_id=${
              response.summary_document_id
            }&quizId=${props.quizId}&group=${
              selectedGroupId ? selectedGroupId : groupId
            }&queTypes=${
              props.questionTypes && props.questionTypes.length > 0
                ? props.questionTypes
                : queType
                ? queType
                : null
            }&type=${type}&queCount=${questionCount}&first=${
              response.summary_document_id
            }${sendDocument}${through}${fileType}`,
          {
            group: selectedGroupId ? selectedGroupId : groupId,
            learning: history.location.state?.learning,
            docIds: history.location.state?.docIds,
          }
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const summariseDocumentHandler = async (
    updatedDocumentSmartQuizData: any = [],
    compactRatio: any = 0.7,
    selectedGroupId: string = null
  ) => {
    try {
      setLoading(true);

      if (params.subStep === "1") {
        setShowLengthSummaryModal(true);
      } else if (
        params.subStep === "2" ||
        props.smartQuiz ||
        props.processToolkitDocument
      ) {
        const docuData =
          // props.smartQuiz || props.processToolkitDocument
          // ?
          { ...updatedDocumentSmartQuizData };
        // : { ...documentSectionData };
        const summary_Ids: any = [];
        const docsumIds: string[] = [];
        for (const key in docuData) {
          const response: any = await dispatch(
            sendBuildDocToSumRequest(
              {
                document_ids: [key],
                documents: [
                  {
                    _id: key,
                    sections: docuData[key].sections.map((section: any) => {
                      return {
                        from_page: section.from_page,
                        to_page: section.to_page,
                      };
                    }),
                  },
                ],
                compact_ratio:
                  "compact_ratio" in docuData[key]
                    ? docuData[key].compact_ratio
                    : null,
                builds_summarised_file: false,
                target_flow: props.target_flow,
              },
              false,
              // props.genQna ||
                props.generateLearningOutcomes ||
                (url.includes("build") && type !== "automated")
                ? true
                : false
            )
          );
          docsumIds.push(response._id);
          summary_Ids.push(response.summary_document_id);
          if (selectedGroupId || groupId) {
            dispatch(
              bulkAssignModules(
                selectedGroupId ? selectedGroupId : groupId,
                [response.summary_document_id],
                "document",
                false
              )
            );
          }
        }

        await dispatch(
          recentlyUsedDocuments(
            commonState.selectedAgent,
            [...Object.keys(docuData)],
            commonState.recentlyUsedDocuments
              ? JSON.parse(commonState.recentlyUsedDocuments)
              : {}
          )
        );

        history.push(
          (props.redirectURI ? props.redirectURI : BUILD_DOCUMENT_PROCESS) +
            // (props.processToolkitDocumentQna
            // ?
            `?docsum=${docsumIds.join(
              ","
            )}&summary_document_id=${summary_Ids.join(",")}&group=${
              selectedGroupId ? selectedGroupId : groupId
            }&type=${type ? type : ""}&first=${summary_Ids[0]}`,
          // : `?documents=${summary_Ids.join(
          //     ","
          //   )}&group=${groupId}&type=${type}`),
          {
            group: groupId,
            learning: history.location.state?.learning,
            docIds: history.location.state?.docIds,
          }
        );
      } else {
        return;
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const addSectionInputRow = (documentId: string) => {
    const temp = { ...documentSectionData };
    let newSectionData = [...documentSectionData[documentId].sections];
    const newId = uuidv4();
    newSectionData.push({
      id: newId,
      name: "",
      from_page: "",
      to_page: "",
    });
    temp[documentId].sections = newSectionData;
    setDocumentSectionData(temp);
  };

  const removeSectionHandler = (documentId: string, index: number) => {
    const temp = { ...documentSectionData };
    let newSectionData: any[] = [...documentSectionData[documentId].sections];
    newSectionData.splice(index, 1);
    temp[documentId].sections = newSectionData;
    setDocumentSectionData(temp);
  };

  const renderInputFields = (
    index: any,
    sectionDetails: any,
    documentId: string,
    isDisabled: boolean = false
  ) => {
    return (
      <React.Fragment>
        <div>
          {index === 0 && <h6>{t("Section Name")}</h6>}
          <input
            className="input-field form-control"
            value={sectionDetails.name}
            readOnly={props.hideHeaders || isDisabled}
            onChange={(e) =>
              handleInputChange(
                documentId,
                sectionDetails.id,
                e.target.value,
                "section_name",
                index
              )
            }
          />
        </div>
        <div>
          {index === 0 && <h6>{t("From Page")}</h6>}
          <input
            className="input-field form-control page-number-input"
            type="number"
            value={sectionDetails.from_page}
            readOnly={props.hideHeaders || isDisabled}
            onChange={(e) =>
              handleInputChange(
                documentId,
                sectionDetails.id,
                e.target.value,
                "from_page",
                index
              )
            }
          ></input>
        </div>
        <span
          className={`page-number-separator page-top-margin ${
            index === 0 && "page-separator-extra-margin"
          }`}
        >
          -
        </span>
        <div>
          {index === 0 && <h6>{t("To Page")}</h6>}
          <input
            className="input-field form-control page-number-input"
            type="number"
            value={sectionDetails.to_page}
            readOnly={props.hideHeaders || isDisabled}
            onChange={(e) =>
              handleInputChange(
                documentId,
                sectionDetails.id,
                e.target.value,
                "to_page",
                index
              )
            }
          ></input>
        </div>
        {/* {index > 0 && ( */}
        <button
          className={`btn-red-close border-0 ml-1 ${index === 0 && "mt-36"}`}
        >
          <Image
            src={closeimg}
            onClick={(e) => removeSectionHandler(documentId, index)}
          />
        </button>
        {/* )} */}
      </React.Fragment>
    );
  };

  const getNoOfPagesHandler = (e: any) => {
    let docuData: any = { ...documentSectionData };
    if (
      "sections" in docuData[selectedDocument.value] &&
      docuData[selectedDocument.value].sections.filter(
        (sec: any) =>
          sec.name.trim().length === 0 &&
          sec.to_page.toString().trim().length === 0 &&
          sec.from_page.toString().trim().length === 0
      ).length === docuData[selectedDocument.value].sections.length
    ) {
      const temp = { ...documentSectionData };
      let newSectionData = [
        ...documentSectionData[selectedDocument.value].sections,
      ];
      const newId = uuidv4();
      newSectionData.unshift({
        id: newId,
        name: "Default",
        from_page: 1,
        to_page: e,
      });
      temp[selectedDocument.value].sections = newSectionData;
      setDocumentSectionData(temp);
    }
    setDocumentSectionData({ ...docuData });
  };

  const setUrlMapHandler = (e: any) => {
    setUrlMap({ ...e[selectedDocument.value] });
    // let docuData: any = { ...documentSectionData };
    // docuData[selectedDocument.value].urlMap = { ...e[selectedDocument.value] };
    // setDocumentSectionData({ ...docuData });
  };

  const getTotalPageCountBasedOnDocument = () => {
    let docuData: any = { ...documentSectionData };

    let pages = new Set();
    if (
      docuData &&
      selectedDocument.value &&
      docuData[selectedDocument.value] &&
      docuData[selectedDocument.value].sections
    ) {
      docuData[selectedDocument.value].sections.forEach((section: any) => {
        if (section.to_page && section.from_page) {
          if (section.to_page >= section.from_page) {
            for (
              let index = section.from_page;
              index <= section.to_page;
              index++
            ) {
              pages.add(+index);
            }
          }
        }
      });
    }
    return pages.size;
  };

  const setCompactRatioHandler = (l: any, documentId: any) => {
    const temp = { ...documentSectionData };
    temp[documentId].compact_ratio = l;
    setDocumentSectionData(temp);
    let oldSelectedDocument = { ...selectedDocument };
    oldSelectedDocument.compact_ratio = l;
    setSelectedDocument(oldSelectedDocument);
  };

  const getPageSize = () => {
    if (
      selectedDocument.label.includes(".ppt") ||
      selectedDocument.label.includes(".pptx")
    ) {
      return fileSize.pptx_files ? fileSize.pptx_files : 50;
    } else {
      return fileSize.generic_files ? fileSize.generic_files : 20;
    }
  };

  const isMaxPageError = (document: any) => {
    let total = 0;
    if (documents && document?.sections && document.sections.length > 0) {
      document.sections.forEach((section: any) => {
        if (section.to_page && section.from_page) {
          if (section.to_page >= section.from_page) {
            total = total + (section.to_page - section.from_page + 1);
          }
        }
      });

      const maxPage =
        document.type === "pptx" || document.type === "ppt"
          ? fileSize.pptx_files
          : fileSize.generic_files;
      return total > maxPage;
    }

    return false;
  };

  const IconOption = (props: any) => {
    const document = documentSectionData[props.data._id];
    return (
      <Option {...props}>
        <div className="d-flex justify-content-between  align-items-center">
          {props.data.label}
          {isMaxPageError(document) && <img src={pinkWarning} alt="" />}
        </div>
      </Option>
    );
  };

  const IconValue = (props: any) => {
    const document =
      documentSectionData && documentSectionData?.[props.data._id];
    return (
      <SingleValue {...props}>
        <div className="d-flex justify-content-between  align-items-center">
          {props.data.label}
          {isMaxPageError(document) && <img src={pinkWarning} alt="" />}
        </div>
      </SingleValue>
    );
  };

  const getDocCountError = () => {
    let count = 0;
    documents.forEach((d: any) => {
      if (documentSectionData) {
        const document = documentSectionData?.[d._id];
        if (isMaxPageError(document)) {
          count = count + 1;
        }
      }
    });

    return count;
  };

  if (loading) {
    <Loading />;
  }

  return (
    <React.Fragment>
      {/* {params.subStep && showModal && documents && (
        <Modal show centered>
          <ModalHeader className="py-3 justify-content-between border-bottom">
            <ModalTitle id="contained-modal-title-vcenter">
              <h2 className="font-600 mb-0 font-1">
                {t("Summarise Document(s)")}
              </h2>
            </ModalTitle>
            <Button onClick={() => setShowModal(false)}>
              <Image src={closeBlk} />
            </Button>
          </ModalHeader>
          <ModalBody>
            {documents?.length > 1
              ? t(
                  "Before summarising your document(s), you will need to select the pages that would be used for the summary. Omit irrelevant pages by excluding them from sections."
                )
              : t(
                  "In order to generate learning outcomes, please summarise your document first. Choose the specific pages you wish to include in the document summary, and exclude any irrelevant pages by removing them from the sections."
                )}
          </ModalBody>
          <ModalFooter>
            <button
              className="btn-primary btn ml-3 mr-0"
              onClick={() => setShowModal(false)}
            >
              {t("Proceed")}
            </button>
          </ModalFooter>
        </Modal>
      )} */}

      <SelectQuestionTypeModal
        show={showQueType}
        onProceed={(d: any, hide: boolean, queCount: number) => {
          const types = d.map((i: any) => i.type);
          if (!hide) {
            singleSummaryDocumentHandler(
              documentSmartQuizData,
              0.7,
              "",
              types,
              queCount
            );
          } else {
            setShowQueType(false);
          }
        }}
      />

      {newDocAdded && (
        <MoveSelectedModal
          show={newDocAdded}
          onHide={() => setNewDocAdded(false)}
          confirmCategoryUpdate={confirmCategoryUpdate}
          isBuildKnowledge
          groupId={groupId}
        />
      )}

      {showLengthSummaryModal && (
        <LengthOfSummaryPopup
          show={showLengthSummaryModal}
          onHide={() => {
            setShowLengthSummaryModal(false);
          }}
          singleSummariseDoc={singleSummaryDocumentHandler}
        />
      )}
      <section className="page-mid-wraper page-mid-wraper-build-v2">
        <Container>
          {!props.hideHeaders && (
            <>
              <div className="d-flex flex-wrap justify-content-center clr-primary position-relative">
                <button
                  className="btn py-0 mr-2 position-absolute position-l-0 "
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <img src={chevLeft} alt="" />
                </button>
                <h1 className="mb-2">
                  {props.smartQuiz || props.processToolkitDocument
                    ? t("Select Pages")
                    : params.subStep
                    ? t("Select Pages to Summarise")
                    : t("Section Your Document(s)")}
                </h1>
              </div>
              <div className="mb-24 font-16 text-center">
                {props.smartQuiz || props.processToolkitDocument
                  ? props.smartQuiz && !props.processToolkitDocument
                    ? "Select the pages of your documents to be used to create quiz questions"
                    : props.generateKnowledgeFlow
                    ? t(
                        "Select the pages of your documents to be summarised to generate knowledge"
                      )
                    : t(
                        "Select the pages of your documents to be used to generate learning outcomes"
                      )
                  : params.subStep
                  ? t(
                      "Select the sections of your document(s) that you want to use for summarisation."
                    )
                  : t(
                      "Select the sections of your document(s) that you want to use for summarisation."
                    )}
              </div>
            </>
          )}
          <div className="pdf-section-wrapper">
            {/* {uploadedDocumentIds.map((documentId, index) => ( */}
            <>
              {selectedDocument && selectedDocument.value && (
                <div
                  key={selectedDocument.value}
                  //eventKey={index.toString()}
                  title={
                    urlMap && urlMap[selectedDocument.value]
                      ? urlMap[selectedDocument.value].name
                      : `Document ${selectedDocument.value}`
                  }
                >
                  {!props.generateKnowledgeFlow && (
                    <div className="document-section-wrapper">
                      <Row className="row-12 mb-4 align-items-center">
                        <Col md={5}>
                          <Select
                            className="select-target px-0 select-blue shadow-none"
                            classNamePrefix="select-target-prefix-summarise"
                            value={selectedDocument}
                            // isDisabled
                            components={{
                              DropdownIndicator: () => (
                                <img
                                  className="mr-2"
                                  src={downarrowblue}
                                  alt=""
                                />
                              ),
                              IndicatorSeparator: () => null,
                              ClearIndicator: () => null,
                              Option: IconOption,
                              SingleValue: IconValue,
                            }}
                            onChange={(e) => {
                              saveSectionDetails(false, false, e.documentIndex);
                            }}
                            placeholder={t("Document")}
                            options={documents}
                          />
                        </Col>
                        <Col md={7} className="mt-3 mt-md-0">
                          {!props.summarise && (
                            <div className="d-flex align-items-center font-16 info-doc-msg radius-8 py-2 px-3">
                              <Image src={infoImg} alt="" className="mr-3" />

                              <div>
                                {t(
                                  `Kindly ensure your document adheres to the specified page limit.  `
                                )}
                                <br />
                                {!props.processToolkitDocument &&
                                  !(props.summarise || props.smartQuiz) && (
                                    <span>
                                      <span
                                        className="clr-primary font-weight-bold cursor-pointer"
                                        // onClick={() => {
                                        //   window.open(
                                        //     "http://3359170.hs-sites.com/en/knowledge/supported-languages",
                                        //     "_blank"
                                        //   );
                                        // }}
                                      >
                                        {t("click here")}
                                      </span>
                                      {t(
                                        " to check the page limit for each plan."
                                      )}
                                    </span>
                                  )}
                              </div>
                              {/* )  */}
                              {/* : ( */}
                              {/* // <div> */}
                              {/* //   {t( */}
                              {/* //     "Please ensure you do not exceed the page limit." */}
                              {/* //   )} */}
                              {/* //   <br></br> */}
                              {/* //   {t( */}
                              {/* //     "The page limit is __ for docx/pdf files and ___ for pptx files." */}
                              {/* //   ) */}
                              {/* //     .replace( */}
                              {/* //       "__", */}
                              {/* //       fileSize.generic_files */}
                              {/* //         ? fileSize.generic_files */}
                              {/* //         : 20 */}
                              {/* //     ) */}
                              {/* //     .replace( */}
                              {/* //       "___", */}
                              {/* //       fileSize.pptx_files */}
                              {/* //         ? fileSize.pptx_files */}
                              {/* //         : 50 */}
                              {/* //     )} */}
                              {/* // </div> */}
                              {/* // )} */}
                            </div>
                          )}
                          {props.target_flow &&
                            props.target_flow === "contextualised_setup" && (
                              <div className="d-flex align-items-center font-16 info-doc-msg radius-8 py-2 px-3">
                                <Image src={infoImg} alt="" className="mr-3" />

                                <div>
                                  {t(
                                    `Kindly ensure your document adheres to the specified page limit.  `
                                  )}
                                </div>
                              </div>
                            )}
                          {selectedDocument &&
                            props.processToolkitDocument &&
                            props.summarise && (
                              <div className="d-flex align-items-center flex-wrap flex-sm-nowrap">
                                <h2 className="font-600 font-16 mb-sm-0 clr-primary mr-3 text-nowrap">
                                  {t("Length of the summary:")}
                                </h2>
                                <ButtonGroup className="tab-btns-group w-100">
                                  <Button
                                    variant="default"
                                    className={
                                      !selectedDocument.compact_ratio
                                        ? "btn-primary active"
                                        : "btn-outline-primary"
                                    }
                                    onClick={() => {
                                      setCompactRatioHandler(
                                        null,
                                        selectedDocument.value
                                      );
                                    }}
                                  >
                                    {t("Brief")}
                                  </Button>
                                  <Button
                                    variant="default"
                                    className={
                                      selectedDocument.compact_ratio === 0.7
                                        ? "active btn-primary"
                                        : "btn-outline-primary"
                                    }
                                    onClick={() => {
                                      setCompactRatioHandler(
                                        0.7,
                                        selectedDocument.value
                                      );
                                    }}
                                  >
                                    {t("Balanced")}
                                  </Button>
                                  <Button
                                    variant="default"
                                    className={
                                      selectedDocument.compact_ratio === 0.85
                                        ? "active btn-primary"
                                        : "btn-outline-primary"
                                    }
                                    onClick={() => {
                                      setCompactRatioHandler(
                                        0.85,
                                        selectedDocument.value
                                      );
                                    }}
                                  >
                                    {t("Detailed")}
                                  </Button>
                                </ButtonGroup>
                              </div>
                            )}
                        </Col>
                      </Row>
                      <Row className="row-12">
                        <Col md={5}>
                          {documentSectionData &&
                            documentSectionData[selectedDocument.value] && (
                              <PDFViewer
                                setUrlMapForParent={setUrlMapHandler}
                                getNoOfPages={getNoOfPagesHandler}
                                documentId={selectedDocument.value}
                                multiDoc
                              />
                            )}
                        </Col>
                        <Col md={7} className="mt-3 mt-md-0">
                          {selectedDocument &&
                            props.processToolkitDocument &&
                            props.summarise && (
                              <div className="kindly-msg-inside">
                                {selectedDocument && selectedDocument.label && (
                                  <div className="d-flex align-items-center font-16 info-doc-msg radius-8 py-2 px-3 mb-3">
                                    <Image
                                      src={infoImg}
                                      alt=""
                                      className="mr-3"
                                    />

                                    {props.smartQuiz ||
                                    props.processToolkitDocument ? (
                                      <div>
                                        {t(
                                          `Kindly ensure your document adheres to the specified page limit.  `
                                        )}
                                        <br />
                                        {/* <span
                                  className="clr-primary font-weight-bold cursor-pointer"
                                  // onClick={() => {
                                  //   window.open(
                                  //     "http://3359170.hs-sites.com/en/knowledge/supported-languages",
                                  //     "_blank"
                                  //   );
                                  // }}
                                >
                                  {t("click here")}
                                </span>
                                {t(" to check the page limit for each plan.")} */}
                                      </div>
                                    ) : (
                                      <div>
                                        {t(
                                          "Please ensure you do not exceed the page limit."
                                        )}
                                        <br></br>
                                        {t(
                                          "The page limit is __ for docx/pdf files and ___ for pptx files."
                                        )
                                          .replace(
                                            "__",
                                            fileSize.generic_files
                                              ? fileSize.generic_files
                                              : 20
                                          )
                                          .replace(
                                            "___",
                                            fileSize.pptx_files
                                              ? fileSize.pptx_files
                                              : 50
                                          )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          <div className="section-pages-data">
                            {/* urlMap[documentId].type &&
                            urlMap[documentId].type === "pdf" && */}
                            {selectedDocument &&
                              (selectedDocument.name.includes(".html") ||
                                selectedDocument.name.includes(".txt")) && (
                                <div className="empty-doc-msg d-flex align-items-center justify-content-center">
                                  <div className="text-center">
                                    <h3>
                                      {t(
                                        "You are not required to section this document"
                                      )}
                                    </h3>
                                    <figure className="mt-3 mb-0 empty-img-wrap">
                                      <img src={noSectionRequired} alt="" />
                                    </figure>
                                  </div>
                                </div>
                              )}

                            {documentSectionData &&
                              documentSectionData[selectedDocument.value] &&
                              !(
                                selectedDocument.name.includes(".html") ||
                                selectedDocument.name.includes(".txt")
                              ) &&
                              ("urlMap" in
                                documentSectionData[selectedDocument.value] ||
                                "sections" in
                                  documentSectionData[
                                    selectedDocument.value
                                  ]) && (
                                <React.Fragment>
                                  <div className="section-pages-input-container">
                                    {"urlMap" in
                                      documentSectionData[
                                        selectedDocument.value
                                      ] &&
                                      documentSectionData[
                                        selectedDocument.value
                                      ].urlMap.type &&
                                      documentSectionData[
                                        selectedDocument.value
                                      ].urlMap.type !== "pdf" &&
                                      documentSectionData[
                                        selectedDocument.value
                                      ].urlMap.type !== "docx" && (
                                        <div className="section-pages-no-input-container mb-3">
                                          {t(
                                            "Dividing documents into sections is currently"
                                          )}{" "}
                                          <strong>
                                            {t(
                                              "only supported for PDF type documents"
                                            )}
                                          </strong>
                                          {/* . If you have uploaded multiple documents,
                                        you can add sections for PDF type documents
                                        or click on "Save Document(s)" to proceed to
                                        next step. */}
                                        </div>
                                      )}

                                    <h6>
                                      {t("Total Page Count")}:{" "}
                                      <span
                                        // className={
                                        //   notReadyToSummarised ? "clr-error" : ""
                                        // }
                                        className={cx({
                                          "clr-error":
                                            notReadyToSummarised ||
                                            isMaxPageError(
                                              documentSectionData[
                                                selectedDocument.value
                                              ]
                                            ),
                                        })}
                                      >
                                        {getTotalPageCountBasedOnDocument()}/
                                        {getPageSize()}
                                        {/* {selectedDocument.label.includes(".ppt") ||
                                    selectedDocument.label.includes(".pptx")
                                      ? 50
                                      : 20} */}
                                      </span>
                                    </h6>

                                    {"sections" in
                                      documentSectionData[
                                        selectedDocument.value
                                      ] &&
                                      documentSectionData[
                                        selectedDocument.value
                                      ]?.sections.map(
                                        (sectionDetails: any, index: any) => (
                                          <div
                                            key={sectionDetails.id}
                                            className="section-pages-input-row"
                                          >
                                            <div>
                                              {index === 0 && <h6>#</h6>}
                                              <span
                                                className={`page-number page-top-margin`}
                                              >
                                                {index + 1}.
                                              </span>
                                            </div>
                                            {renderInputFields(
                                              index,
                                              sectionDetails,
                                              selectedDocument.value,
                                              documentSectionData &&
                                                documentSectionData[
                                                  selectedDocument.value
                                                ] &&
                                                documentSectionData[
                                                  selectedDocument.value
                                                ].type &&
                                                documentSectionData[
                                                  selectedDocument.value
                                                ].type !== "pdf" &&
                                                documentSectionData[
                                                  selectedDocument.value
                                                ].type !== "docx"
                                            )}
                                          </div>
                                        )
                                      )}

                                    {selectedDocument &&
                                      (selectedDocument.label.includes(
                                        ".pdf"
                                      ) ||
                                        selectedDocument.label.includes(
                                          "docx"
                                        ) ||
                                        selectedDocument.label.includes(
                                          "pptx"
                                        )) && (
                                        <PermissionWrapper>
                                          <div className="text-center">
                                            <button
                                              onClick={() =>
                                                addSectionInputRow(
                                                  selectedDocument.value
                                                )
                                              }
                                              className="btn btn-link genurl-btn"
                                            >
                                              {t("Add More pages")}
                                            </button>
                                          </div>
                                        </PermissionWrapper>
                                      )}
                                  </div>
                                </React.Fragment>
                              )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {props.generateKnowledgeFlow && (
                    <div className="document-section-wrapper generate-knowledge-section-documents">
                      <Row className="row-12 mb-4 ">
                        <Col md={5}>
                          <div className="document-preview-wrapper">
                            <h2 className="font-weight-bold font-16 mr-3 text-nowrap mb-2">
                              {t("Document Preview")}
                            </h2>
                            <Select
                              className="select-target px-0 select-blue shadow-none"
                              classNamePrefix="select-target-prefix-summarise"
                              value={selectedDocument}
                              // isDisabled
                              components={{
                                DropdownIndicator: () => (
                                  <img
                                    className="mr-2"
                                    src={downarrowblue}
                                    alt=""
                                  />
                                ),
                                IndicatorSeparator: () => null,
                                ClearIndicator: () => null,
                                Option: IconOption,
                                SingleValue: IconValue,
                              }}
                              onChange={(e) => {
                                saveSectionDetails(
                                  false,
                                  false,
                                  e.documentIndex
                                );
                              }}
                              placeholder={t("Document")}
                              options={documents}
                            />

                            {documentSectionData &&
                              documentSectionData[selectedDocument.value] && (
                                <PDFViewer
                                  setUrlMapForParent={setUrlMapHandler}
                                  getNoOfPages={getNoOfPagesHandler}
                                  documentId={selectedDocument.value}
                                  multiDoc
                                />
                              )}
                          </div>
                        </Col>
                        <Col md={7} className="mt-3 mt-md-0">
                          {selectedDocument && (
                            <div className="section-pages-data">
                              <h2 className="font-weight-bold font-16 mb-sm-0 mr-3 text-nowrap">
                                {t("Select document summary length")}
                              </h2>
                              <div className="d-flex align-items-center flex-wrap flex-sm-nowrap mt-2">
                                <ButtonGroup className="tab-btns-group w-100">
                                  <Button
                                    variant="default"
                                    className={
                                      !selectedDocument.compact_ratio
                                        ? "btn-primary active"
                                        : "btn-outline-primary"
                                    }
                                    onClick={() => {
                                      setCompactRatioHandler(
                                        null,
                                        selectedDocument.value
                                      );
                                    }}
                                  >
                                    {t("Brief")}
                                  </Button>
                                  <Button
                                    variant="default"
                                    className={
                                      selectedDocument.compact_ratio === 0.7
                                        ? "active btn-primary"
                                        : "btn-outline-primary"
                                    }
                                    onClick={() => {
                                      setCompactRatioHandler(
                                        0.7,
                                        selectedDocument.value
                                      );
                                    }}
                                  >
                                    {t("Balanced")}
                                  </Button>
                                  <Button
                                    variant="default"
                                    className={
                                      selectedDocument.compact_ratio === 0.85
                                        ? "active btn-primary"
                                        : "btn-outline-primary"
                                    }
                                    onClick={() => {
                                      setCompactRatioHandler(
                                        0.85,
                                        selectedDocument.value
                                      );
                                    }}
                                  >
                                    {t("Detailed")}
                                  </Button>
                                </ButtonGroup>
                              </div>
                              <h2 className="font-weight-bold font-14 mb-sm-0 mr-3 text-nowrap mt-2">
                                <span>
                                  {t("Summarises document to ")}
                                  <span className="clr-primary">
                                    {selectedDocument.compact_ratio === 0.85
                                      ? t("85% ")
                                      : selectedDocument.compact_ratio === 0.7
                                      ? t("70% ")
                                      : t("50% ")}
                                  </span>
                                  {t("of the length")}
                                </span>
                              </h2>
                              <div className="d-flex align-items-center justify-content-between mt-3">
                                <h2 className="font-weight-bold font-16 mb-sm-0 mr-3 text-nowrap">
                                  {t("Select pages to use")}
                                </h2>

                                {documentSectionData &&
                                  documentSectionData[
                                    selectedDocument.value
                                  ] && (
                                    <h2 className="font-600 font-16 mb-0">
                                      {t("Page Limit")}:{" "}
                                      <span
                                        className={cx({
                                          "clr-primary font-weight-bold": true,
                                          "clr-error":
                                            notReadyToSummarised ||
                                            isMaxPageError(
                                              documentSectionData[
                                                selectedDocument.value
                                              ]
                                            ),
                                        })}
                                      >
                                        {getTotalPageCountBasedOnDocument()}/
                                        {getPageSize()}
                                      </span>
                                    </h2>
                                  )}
                              </div>

                              <div className="d-flex align-items-center font-16 info-doc-msg py-2 px-3 my-3">
                                <Image src={infoImg} alt="" className="mr-3" />
                                <div>
                                  {t(
                                    `Kindly ensure your document adheres to the specified page limit.  `
                                  )}
                                  <br />
                                </div>
                              </div>

                              {/* urlMap[documentId].type &&
                                urlMap[documentId].type === "pdf" && */}
                              {selectedDocument &&
                                (selectedDocument.name.includes(".html") ||
                                  selectedDocument.name.includes(".txt")) && (
                                  <div className="empty-doc-msg d-flex align-items-center justify-content-center">
                                    <div className="text-center">
                                      <h3>
                                        {t(
                                          "You are not required to section this document"
                                        )}
                                      </h3>
                                      <figure className="mt-3 mb-0 empty-img-wrap">
                                        <img src={noSectionRequired} alt="" />
                                      </figure>
                                    </div>
                                  </div>
                                )}

                              {documentSectionData &&
                                documentSectionData[selectedDocument.value] &&
                                !(
                                  selectedDocument.name.includes(".html") ||
                                  selectedDocument.name.includes(".txt")
                                ) &&
                                ("urlMap" in
                                  documentSectionData[selectedDocument.value] ||
                                  "sections" in
                                    documentSectionData[
                                      selectedDocument.value
                                    ]) && (
                                  <React.Fragment>
                                    <div className="section-pages-input-container">
                                      {"urlMap" in
                                        documentSectionData[
                                          selectedDocument.value
                                        ] &&
                                        documentSectionData[
                                          selectedDocument.value
                                        ].urlMap.type &&
                                        documentSectionData[
                                          selectedDocument.value
                                        ].urlMap.type !== "pdf" &&
                                        documentSectionData[
                                          selectedDocument.value
                                        ].urlMap.type !== "docx" && (
                                          <div className="section-pages-no-input-container mb-3">
                                            {t(
                                              "Dividing documents into sections is currently"
                                            )}{" "}
                                            <strong>
                                              {t(
                                                "only supported for PDF type documents"
                                              )}
                                            </strong>
                                            {/* . If you have uploaded multiple documents,
                                        you can add sections for PDF type documents
                                        or click on "Save Document(s)" to proceed to
                                        next step. */}
                                          </div>
                                        )}

                                      {"sections" in
                                        documentSectionData[
                                          selectedDocument.value
                                        ] &&
                                        documentSectionData[
                                          selectedDocument.value
                                        ]?.sections.map(
                                          (sectionDetails: any, index: any) => (
                                            <div
                                              key={sectionDetails.id}
                                              className="section-pages-input-row"
                                            >
                                              <div>
                                                {index === 0 && <h6>#</h6>}
                                                <span
                                                  className={`page-number page-top-margin`}
                                                >
                                                  {index + 1}.
                                                </span>
                                              </div>
                                              {renderInputFields(
                                                index,
                                                sectionDetails,
                                                selectedDocument.value,
                                                documentSectionData &&
                                                  documentSectionData[
                                                    selectedDocument.value
                                                  ] &&
                                                  documentSectionData[
                                                    selectedDocument.value
                                                  ].type &&
                                                  documentSectionData[
                                                    selectedDocument.value
                                                  ].type !== "pdf" &&
                                                  documentSectionData[
                                                    selectedDocument.value
                                                  ].type !== "docx"
                                              )}
                                            </div>
                                          )
                                        )}

                                      {selectedDocument &&
                                        (selectedDocument.label.includes(
                                          ".pdf"
                                        ) ||
                                          selectedDocument.label.includes(
                                            "docx"
                                          ) ||
                                          selectedDocument.label.includes(
                                            "pptx"
                                          )) && (
                                          <PermissionWrapper>
                                            <div className="text-center">
                                              <button
                                                onClick={() =>
                                                  addSectionInputRow(
                                                    selectedDocument.value
                                                  )
                                                }
                                                className="btn btn-link genurl-btn"
                                              >
                                                {t("Add More pages")}
                                              </button>
                                            </div>
                                          </PermissionWrapper>
                                        )}
                                    </div>
                                  </React.Fragment>
                                )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              )}
            </>
          </div>
        </Container>
      </section>

      <footer>
        <Container>
          <div className="d-flex align-items-center flex-wrap justify-content-between">
            <div
              className={classNames({
                "d-flex align-items-center font-16 font-600 radius-8 py-2 px-3":
                  true,
                "info-moredoc-msg": notReadyToSummarised,
                "max-page-error": getDocCountError() > 0,
              })}
            >
              {notReadyToSummarised && (
                <div>
                  <Image src={infoCircleImg} alt="" className="mr-3" />
                  {t("Current document does not meet requirement.")}
                  <br />
                  {t(
                    `Please ensure that total page count does not exceed ${
                      getPageSize()
                      // selectedDocument.label.includes(".ppt") ||
                      // selectedDocument.label.includes(".pptx")
                      //   ? 50
                      //   : 20
                    } pages`
                  )}
                </div>
              )}
              {getDocCountError() > 0 && (
                <div className="clr-pink">
                  <Image src={pinkWarning} alt="" className="mr-3" />
                  {t(
                    " You have 1 or more document that exceeds the page count limit"
                  )}
                </div>
              )}
            </div>
            <PermissionWrapper>
              <div>
                <button
                  className={classNames({
                    "btn mr-3 font-weight-bold": true,
                    "btn-light": !documents[selectedDocumentIndex - 1],
                    "btn-outline-primary": documents[selectedDocumentIndex - 1],
                  })}
                  onClick={() => {
                    if (documents[selectedDocumentIndex - 1]) {
                      saveSectionDetails(
                        false,
                        false,
                        selectedDocumentIndex - 1
                      );
                    }
                  }}
                >
                  {t("Previous Document")}
                </button>
                {documents[selectedDocumentIndex + 1] && (
                  <button
                    className="btn btn-primary mr-3"
                    disabled={!documents[selectedDocumentIndex + 1]}
                    onClick={() => {
                      saveSectionDetails(
                        false,
                        false,
                        selectedDocumentIndex + 1
                      );
                    }}
                  >
                    {t("Next Document")}
                  </button>
                )}
                {!documents[selectedDocumentIndex + 1] &&
                  !showLengthSummaryModal && (
                    <button
                      className="btn btn-primary mr-3"
                      disabled={getDocCountError() > 0}
                      onClick={() => {
                        saveSectionDetails(
                          false,
                          false,
                          selectedDocumentIndex + 1,
                          true
                        );
                      }}
                    >
                      {params.subStep
                        ? t("Generate Learning Outcomes")
                        : props.generateKnowledgeFlow
                        ? t("Generate Knowledge")
                        : t("Save and Proceed")}
                    </button>
                  )}
              </div>
            </PermissionWrapper>
          </div>
        </Container>

        <ProgressSteps currentStep={2} steps={props.summarise ? 3 : 4} />
      </footer>
    </React.Fragment>
  );
};

export default SummariseDocuments;