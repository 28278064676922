import classNames from "classnames";
import { useEffect, useState } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// import { ChartTableSortIcon } from "../../../Knowledge/AllKnowledge";
// import { getAllGroupUsersModalDashboard } from "../../../../store/dashboard/actions";
// import Pagination from "../../../../components/UI/Pagination/Pagination";
import Moment from "react-moment";
import { ChartTableSortIcon } from "../../Knowledge/AllKnowledge";
import closeImg from "../../../assets/New-images/build-knowledge-close-img.svg";

interface Props {}

const userAskedModalTableStyles = {
  header: {
    style: {
      height: "70px",
    },
  },
  headRow: {
    style: {
      borderRadius: "10px",
      backgroundColor: "#0923E6",
      borderBottomWidth: "0",
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: "#0923E6",
    },
  },
  expanderCell: {
    style: {
      "&:hover": {
        backgroundColor: "transparent",
      },
      button: {
        backgroundColor: "transparent !important",
        img: {
          width: "10px",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "transparent",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      "&:first-child": {
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "0px",
      },
      "&.chart--table-sort-icon": {
        marginLeft: "10px",
      },
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      backgroundColor: "#0923E6",
      // width: '300px',
      padding: "20px 20px",
      fontSize: "14px",
      color: "#fff",
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: "#FFFFFF",
      },
      div: {
        color: "#fff",
      },
    },
  },
  cells: {
    style: {
      color: "#07032B",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      'input[type="checkbox"]': {
        width: "16px",
        height: "16px",
      },
      flexWrap: "wrap",
      a: {
        color: "#0923E6",
        textDecoration: "underline",
      },
    },
  },
  rows: {
    style: {
      minHeight: "auto",
    },
  },
};

const UsersAskedModal = (props: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    let users: any = [];
    users = props.modalData.users_asked
      ? props.modalData.users_asked.map((user: any) => {
          return {
            name: getDetailByNameOrEmail(user.user_id)?.name || "",
            email: getDetailByNameOrEmail(user.user_id)?.email || "",
            classNam: getDetailByClass(user.connection_id)?.name || "",
            date: props.modalData.date || null,
          };
        })
      : [];

    //
    setData([...users]);
  }, [props.modalData]);

  const getDetailByNameOrEmail = (userId: string) => {
    const user = props.users.find((u: any) => u._id === userId);
    return user || {};
  };

  const getDetailByClass = (classId: string) => {
    const classDetail = props.classes.find(
      (u: any) => u.connectionIds.split(",").indexOf(classId) >= 0
    );
    return classDetail || {};
  };

  const columns = [
    {
      name: t("User"),
      selector: "name",
      sortable: true,
      cell: (row: any) => <div>{row.name}</div>,
    },
    {
      name: t("Email"),
      selector: "email",
      sortable: true,
      cell: ({ email }: any) => <div>{email}</div>,
    },
    {
      name: t("Class"),
      selector: "class",
      sortable: true,
      cell: ({ classNam }: any) => <div>{classNam}</div>,
    },
    {
      name: t("Date Asked"),
      selector: "date",
      sortable: true,
      cell: ({ date }: any) => (
        <div>
          {date ? (
            <>
              <div>
                <Moment date={date} utc local format="DD/MM/YYYY">
                  {date}
                </Moment>
              </div>
              <div>
                <Moment date={date} utc local format="HH:MM A">
                  {date}
                </Moment>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // scrollable
      dialogClassName="move-selected-modal"
      onHide={props.onHide}
    >
      <ModalHeader className="clr-black pt-4 px-4 justify-content-between">
        <ModalTitle id="contained-modal-title-vcenter">
          <h2 className="mb-0 font-1">
            {props.modalTitle ? props.modalTitle : t("Users who asked")}
          </h2>
        </ModalTitle>
        {props.showCloseBtn && (
          <button className="btn" onClick={props.onHide}>
            <img src={closeImg} alt="Close" />
          </button>
        )}
      </ModalHeader>
      <ModalBody className="px-4">
        <div className="level-tab-wraper">
          <div className="levels-row mb-4 d-flex align-items-center flex-wrap">
            <DataTable
              className="table-wraper"
              noContextMenu
              noHeader
              fixedHeader
              fixedHeaderScrollHeight="400px"
              columns={columns}
              selectableRows={false}
              sortIcon={<ChartTableSortIcon />}
              customStyles={userAskedModalTableStyles}
              progressComponent={
                <div style={{ padding: 10, width: "100%" }}></div>
              }
              data={data}
            />
            {/* {paginationState && (
              <Pagination
                currentPage={currentPage}
                pagination={{
                  ...paginationState,
                }}
                paginationPerPage={perPage}
                pageChange={(newPerPage: number, page: number) => {
                  setPerPage(newPerPage);
                  setCurrentPage(page);
                }}
                onChangePage={handlePageChange}
                hideSuggestedQuestion
              />
            )} */}
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="border-0 px-4 mb-3">
        {
          <button
            className={classNames({
              "btn btn-outline-primary font-600 m-0": true,
            })}
            onClick={props.onHide}
          >
            {t("Close")}
          </button>
        }
      </ModalFooter>
    </Modal>
  );
};

export default UsersAskedModal;
