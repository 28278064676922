import React from "react";
import { useSelector } from "react-redux";
import { getCommonState } from "../../../store/common/selector";

function PermissionWrapper(props: any) {
  const commonState = useSelector(getCommonState);
  // console.log('commonState', commonState.user.role)
  if (
    commonState &&
    commonState.user &&
    (commonState.user.role === "superadmin" || commonState.user.role === "admin")
  ) {
    return props.children;
  }
  return <></>;
}

export default PermissionWrapper;
