import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";

import Loading from '../../components/UI/Loading/Loading'
import { useHistory } from 'react-router';

interface Props {}

export const Support = (props: any) => {
  const windoeObj: any = window
  const [iframeLoading, setiframeLoading] = useState(false)
  const { t } = useTranslation('translation');
  const spUrl = t('https://3359170.hs-sites.com/en/knowledge')
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);

  useEffect(() => {
    setiframeLoading(true)
  }, [spUrl])

  const iframeLoadedHandler = () => {
    setiframeLoading(false)
  }

  return (
    <>
      {iframeLoading && <Loading />}
      <section className='page-mid-wraper h-without-foter pb-0 p-0'>
        <div className='container h-100 p-0'>
          <div className='row h-100'>
            <div className='col-md-12 h-100'>
              <iframe
                id='myframe'
                src={query.get("force") ? query.get("force") : spUrl}
                title='Forms'
                className='full-iframe'
                onLoad={iframeLoadedHandler}
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {/* <footer></footer> */}
    </>
  )
}

export default Support
