import React, { useEffect, useState } from "react";
import { Modal, ModalFooter, Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import Select from "react-select";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";
import closeimg from "../../assets/images/close-blk.svg";
import "./index.scss";
import { updateSelectedLanguage } from "../../store/common/actions";
import i18n from "../UI/Internationalisation/i18n";

export const LANGUAGE_OPTIONS = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "pt-pt",
    label: "Português - Portugal",
  },
  {
    value: "id",
    label: "Bahasa Indonesia",
  },
  {
    value: "es",
    label: "Español",
  },
  {
    value: "tr",
    label: "Türkçe",
  },
];

const ChangeLanguage = (props: any) => {
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: "en",
    label: "English",
  });

  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("selectedLanguageCode")
  );

  useEffect(() => {
    if (currentLanguage !== null) {
      i18n.changeLanguage(currentLanguage);
      const selectedOption = LANGUAGE_OPTIONS.filter(
        (option) => option.value === currentLanguage
      )[0];
      setSelectedLanguage(
        selectedOption || {
          value: null,
          label: null,
        }
      );
    }
  }, []);

  const saveLanguageSetting = () => {
    dispatch(updateSelectedLanguage(selectedLanguage.value));
    setCurrentLanguage(selectedLanguage.value);
    i18n.changeLanguage(selectedLanguage.value);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-400"
      onHide={props.onHide}
    >
      <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
        <ModalTitle
          className="h5 py-1 font-600"
          id="contained-modal-title-vcenter"
        >
          Change Language
        </ModalTitle>
        <Button onClick={props.onHide} className="ml-auto">
          <Image src={closeimg} alt="" />
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="mb-12">
          <h3 className="mb-1">View Page in</h3>
          <Select
            className="select-sort select-target select-blue shadow-none radius-2"
            classNamePrefix="select-target-prefix"
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder="Change Language"
            options={LANGUAGE_OPTIONS}
            onChange={(e: any) => {
              setSelectedLanguage(e);
            }}
            value={selectedLanguage}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <PermissionWrapper>
          <button
            className="btn-outline-primary btn font-600 py-1"
            onClick={props.onHide}
          >
            Close
          </button>
          <button
            className="btn btn-primary ml-3 font-600 py-1 save-changes"
            onClick={() => saveLanguageSetting()}
            disabled={selectedLanguage.value === currentLanguage}
          >
            Save Changes
          </button>
        </PermissionWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default ChangeLanguage;
