import { AnyAction } from "redux";
import ApiUtil from "../utils/apiUtil";
import {
  getAgentFromStorage,
  serialize,
  convertArrayToMarkdownFormat,
  serializeFilter,
} from "../utils/appUtils";
import DataApiUtil from "../utils/dataApiUtils";

type Type = any;

export default class KnowledgeService {
  /**
   *
   *
   * @param {*} document
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async getDocumentFromID(document: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/documents/${document}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  /**
   *
   *
   * @param {*} document
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async getKeywordsFromDocument(
    document: any,
    offset: any = 1,
    limit: any = 10
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document}/generated-keywords?offset=${offset}&limit=${limit}&agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  /**
   * Used to fetch documents on the basis of limit and offset
   *
   * @param {*} document
   * @param {*} [offset=0]
   * @param {*} [limit=10]
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async getKeywordsFromDocumentUsingOffset(
    document: any,
    offset: any = 0,
    limit: any = 10
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document}/generated-questions?offset=${offset}&limit=${limit}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  /**
   *
   *
   * @param {string} documentId
   * @param {string} keyword
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async generateAnswersBasedOnKeywordsFromDocument(
    documentId: string,
    keyword: string
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${documentId}/generated-answers?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        qas: [
          {
            question: keyword,
          },
        ],
      },
    });
    return res;
  }

  /**
   *
   *
   * @param {string} documentId
   * @param {*} qas
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async saveResponsesToKnowledge(documentId: string, qas: any): Promise<Type> {
    qas.forEach((element: any) => {
      if (element.original_question === null) {
        delete element.original_question;
      }
      if (element.original_response === null) {
        delete element.original_response;
      }
    });
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${documentId}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        qas: [...qas],
      },
    });
    return res;
  }

  /**
   *
   *
   * @param {FormData} formData
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async generateWebLinksFromUrl(formData: FormData): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `storage/weblinks`,
      method: "POST",
      data: formData,
    });
    return res;
  }

  /**
   *
   *
   * @param {FormData} formData
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async storeUserSelectedWebLinks(formData: FormData): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `storage/websites`,
      method: "POST",
      data: formData,
    });
    return res;
  }

  /**
   *
   *
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async getAllTopics(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/topics?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  /**
   *
   *
   * @param {number} type
   * @param {number} page
   * @param {number} page_size
   * @param {string} q
   * @param {string} [topicIds='']
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async getAllKnowledge(
    type: number,
    page: number,
    page_size: number,
    q: string,
    topicIds: string = "",
    sorting?: {
      sort_field?: string;
      sort_order?: string;
    },
    is_suggested: boolean | null = null,
    hasCategoryFilterApplied?: boolean,
    documentId?: string,
    knowledge_group_ids: string = null,
    hasTopLevelApplied: boolean = false,
    isArchived: boolean = false,
    isActiveArchived: boolean = false
  ): Promise<Type> {
    const sortingQuery = `${
      sorting && sorting.sort_field && sorting.sort_order
        ? `&sort_field=${sorting.sort_field}&sort_order=${sorting.sort_order}`
        : ""
    }`;
    const topicsQuery =
      hasCategoryFilterApplied &&
      topicIds.split(",").filter((d: any) => d.trim().length > 0).length > 0
        ? `&topic_ids=${topicIds}`
        : "";
    const knowledge_group_id = knowledge_group_ids
      ? `&knowledge_group_ids=${knowledge_group_ids}`
      : "";
    const typedQuery = `${q !== "" ? `&q=${q}` : ""}`;
    const suggestedQuery =
      is_suggested === null ? "" : `&is_suggested=${is_suggested}`;
    const documentQuery = documentId ? `&document_id=${documentId}` : "";
    const hasTopLevelAppliedQuery = hasTopLevelApplied
      ? `&includes_all_subgroups=true`
      : "";
    let status = isArchived ? `&status=archived` : "";
    if (isActiveArchived) {
      status = "&status=archived,active";
    }
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents?type=${type}&page=${page}&page_size=${page_size}${topicsQuery}${sortingQuery}${typedQuery}${suggestedQuery}${documentQuery}${knowledge_group_id}${hasTopLevelAppliedQuery}&agent_id=${getAgentFromStorage()}${status}`,
      method: "GET",
    });
    return res;
  }

  /**
   *
   *
   * @param {string} knowledgeId
   * @param {{ subtopic_id?: string; topic_id?: string; }} data
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async updateKnowledgeById(
    knowledgeId: string,
    data: { subtopic_id?: string; topic_id?: string; topic_name?: string }
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents/${knowledgeId}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        subtopic_id: data.subtopic_id,
        topic_id: data.topic_id,
      },
    });
    return res;
  }

  /**
   * Function is used to suggest knowledge by id
   *
   * @param {string} knowledgeId
   * @param {boolean} is_suggested
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async suggestKnowledgeById(
    knowledgeId: string,
    is_suggested: boolean
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents/${knowledgeId}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        is_suggested,
      },
    });
    return res;
  }

  async getAllSuggestions(
    type: number,
    sorting?: {
      sort_field?: string;
      sort_order?: string;
      page_size?: number;
    },
    is_suggested: boolean | null = null
  ): Promise<Type> {
    const sortingQuery = `${
      sorting && sorting.sort_field && sorting.sort_order
        ? `&sort_field=${sorting.sort_field}&sort_order=${sorting.sort_order}`
        : ""
    }`;
    const suggestedQuery =
      is_suggested === null ? "" : `&is_suggested=${is_suggested}`;
    const pageSize = sorting.page_size ? `&${sorting.page_size}` : `&1000`;
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents?type=${type}${sortingQuery}${suggestedQuery}${pageSize}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  /**
   * Function is used to download knowledge as CSV
   *
   * @param {number} type
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */

  async downloadKnowledgeAsCSV(
    type: number,
    document_id: string | null,
    extras?: any
  ): Promise<Type> {
    const knowledge_group_id =
      extras && extras.knowledge_group_ids
        ? `&knowledge_group_ids=${extras.knowledge_group_ids}`
        : "";
    const hasTopLevelAppliedQuery =
      extras && extras.hasTopLevelApplied ? `&includes_all_subgroups=true` : "";

    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents/download?type=${type}${
        document_id ? "&document_id=" + document_id : ""
      }&agent_id=${getAgentFromStorage()}${knowledge_group_id}${hasTopLevelAppliedQuery}`,
      method: "POST",
    });
    return res;
  }

  /**
   * Function is used to delete knowledge by ID
   *
   * @param {string} knowledgeId
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async deleteKnowledgeById(
    knowledgeId: string,
    isArchiving: boolean = false
  ): Promise<Type> {
    const isArchivedParam = isArchiving ? `&is_archiving=1` : "";
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents/${knowledgeId}?agent_id=${getAgentFromStorage()}${isArchivedParam}`,
      method: "DELETE",
    });
    return res;
  }

  /**
   * Function is used to restore a deleted knowledge by ID
   *
   * @param {string} knowledgeId
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async restoreKnowledgeById(knowledgeId: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents/${knowledgeId}/restore?agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  /**
   * Function is used to fetch knowledge by ID
   *
   * @param {string} knowledgeId
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async getKnowledgeById(knowledgeId: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents/${knowledgeId}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  /**
   * Used to fetch all button options
   *
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async getAllButtonOptions(topic: any = null): Promise<Type> {
    let filter = {};
    if (topic) {
      filter = {
        knowledge_group_ids: topic,
        includes_all_subgroups: 1,
      };
    }
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/button-options?${
        topic ? serializeFilter(filter) + "&" : ""
      }agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  /**
   * Used to fetch all courses options
   *
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async getAllClasses(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  /**
   * Used to fetch all user group options
   *
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async getAllUserGroupOptions(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `usergroup/groups?is_system_group=0&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  /**
   * Used to update parent question of an intent
   *
   * @param {string} intentId
   * @param {string} parent_id
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async updateIntentParentQuestion(
    intentId: string,
    parent_id: string
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents/${intentId}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        parent_id,
      },
    });
    return res;
  }

  /**
   * Used to create new intent
   *
   * @param {string} intentId
   * {
   * "questions": {
   *  "text": string}[],
   *  "responses":{"text":string,"group_ids": string[]}[],
   *  "buttons":{"label":string,"type":string,"reference_id":string,"name":string}[]
   *  "is_input_disabled":boolean,
   *  "type": string,
   *  "name": string,
   *  "parent_id":string
   * }
   * @param {string} parent_id
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async createNewIntent(intent: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        ...intent,
      },
    });
    return res;
  }

  /**
   *
   *
   * @param {*} intentId
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async addTagsToIntent(intentId: any, tags: string[]): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents/${intentId}/tags?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        tags,
      },
    });
    return res;
  }

  /**
   *
   *
   * @param {{
   *     intent_id?: string,
   *     is_suggested?: boolean,
   *     order?: number,
   *   }[]} intents
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async updateSuggestedIntents(
    intents: {
      intent_id?: string;
      is_suggested?: boolean;
      order?: number;
    }[]
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents?agent_id=${getAgentFromStorage()}`,
      method: "PATCH",
      data: {
        intents: [...intents],
      },
    });
    return res;
  }

  /**
   * Used to update complete intent object
   *
   * @param {string} intentId
   * @param {string} parent_id
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async updateCompleteIntent(intentId: string, intent: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/intents/${intentId}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        ...intent,
        responses: await convertArrayToMarkdownFormat(intent.responses),
        questions:
          intent.questions && intent.questions.length
            ? await convertArrayToMarkdownFormat(intent.questions)
            : [],
        buttons:
          intent.buttons && intent.buttons.length
            ? intent.buttons.map((btn: any) => {
                if (btn.type === "suggest" || btn.type === "question") {
                  return {
                    group_ids: btn.group_ids,
                    label: btn.label,
                    question: btn.name || btn.question,
                    type: "question",
                  };
                } else {
                  return {
                    ...btn,
                  };
                }
              })
            : [],
      },
    });
    return res;
  }

  /**
   *
   *
   * @param {*} document
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async getFaqResponsesFromDocument(document: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document}/generated-questions?agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  async updateFaqResponsesFromDocument(document: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document}/generated-questions?agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  /**
   *
   *
   * @param {*} document
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async generateDocumentFaqs(document: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document}/generated-questions?agent_id=${getAgentFromStorage()}&offset=0&limit=1000`,
      method: "GET",
    });
    return res;
  }

  async getAllDocuments(filter: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/document-sections?${serializeFilter(
        filter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getAllDocumentsNewFlow(
    filter: any,
    hasTopLevelApplied: boolean,
    isArchived: boolean = false
  ): Promise<Type> {
    const hasTopLevelAppliedQuery = hasTopLevelApplied
      ? `&includes_all_subgroups=true`
      : "";
    const isStatusArchived = isArchived ? `&status=archived` : "";
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/documents?${serializeFilter(
        filter
      )}${hasTopLevelAppliedQuery}&agent_id=${getAgentFromStorage()}${isStatusArchived}`,
      method: "GET",
    });
    return res;
  }

  /**
   *
   *
   * @param {*} documentId
   * @param {*} tags: string[]
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async addTagsToDocument(documentId: any, data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${documentId}/tags?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        sections: data,
      },
    });
    return res;
  }

  async suggestDocumentsTags(
    documentIds: string[],
    tags: string[]
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/document-tag-mapping?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        document_ids: documentIds,
        tags,
      },
    });
    return res;
  }

  /**
   *
   *
   * @param {*} documentId
   * @param {*} data
   * @param {*} tags: string[]
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async updateDocumentSettings(documentId: any, data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/documents/${documentId}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        ...data,
      },
    });
    return res;
  }

  /**
   *
   *
   * @param {string[]} documents
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async generateDocumentsTags(documents: string[]): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/document-tags?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        document_ids: documents,
      },
    });
    return res;
  }

  /**
   *
   *
   * @param {string} documentId
   * @param {boolean} delete_intents
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async deleteDocumentById(
    documentId: string,
    deleteIntents: boolean,
    isArchiving: boolean
  ): Promise<Type> {
    const isArchivedParam = isArchiving ? `&is_archiving=1` : "";
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/documents/${documentId}?delete_intents=${deleteIntents}&agent_id=${getAgentFromStorage()}${isArchivedParam}`,
      method: "DELETE",
    });
    return res;
  }

  /**
   * Function is used to restore a deleted Document by document ID
   *
   * @param {string} documentId
   * @param {boolean} delete_intents
   * @returns {Promise<Type>}
   * @memberof KnowledgeService
   */
  async restoreDocumentById(documentId: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/documents/${documentId}/restore?&agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  async deleteDocumentSectionsById(documentId: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${documentId}/document-sections?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
    });
    return res;
  }

  async getGeneratedContent(data: {
    organisation_id: string;
    _id: string;
    keyword: string;
    preceed: number;
    succeed: number;
  }): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${data._id}/generated-content?organisation_id=${
        data.organisation_id
      }&agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        keyword: data.keyword,
        preceed: data.preceed,
        succeed: data.succeed,
      },
    });
    return res;
  }

  async getTextBlock(
    document_id: string,
    text_block_id: string
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document_id}/text-blocks/${text_block_id}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getMatchedAndUnmatchedQuestions(filters: {
    question_type: string;
    page: number;
    page_size: number;
    sort_field: string;
    sort_order: string;
    from_date: string;
    to_date: string;
    connection_ids: string;
  }) {
    const paginationFilter = {
      page: filters.page,
      page_size: filters.page_size,
    };
    const searchFilter = {
      filter_question_type: filters.question_type,
      sort: filters.sort_field
        ? filters.sort_field + "::" + filters.sort_order
        : "",
      filter_from_date: filters.from_date,
      filter_to_date: filters.to_date,
      filter_connection_ids: filters.connection_ids,
    };
    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/question-list?${serializeFilter(
        searchFilter
      )}&sort=last_asked_at::dsc&${serializeFilter(
        paginationFilter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getLearnerAttendance(filters: {
    filteredStudentId: string;
    filteredName: string;
    filteredTutorGroup: string;
    from_date: string;
    to_date: string;
    sort_field: string;
    sort_order: string;
  }) {
    const sortFilters = filters.sort_field
      ? `&sort_field=${filters.sort_field}&sort_order=${filters.sort_order}`
      : "";
    const sort = `&sort=${filters.sort_field}::${filters.sort_order}`;
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/students-report?student_id=${
        filters.filteredStudentId
      }&student_name=${filters.filteredName}&tutor_group=${
        filters.filteredTutorGroup
      }&last_login_from_date=${filters.from_date}&last_login_to_date=${
        filters.to_date
      }${sortFilters}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async studentTopicsReport({
    from_date,
    to_date,
    filteredStudentId,
    filteredName,
    filteredTutorGroup,
    topicQuery,
  }: {
    from_date: any;
    to_date: any;
    filteredStudentId: any;
    filteredName: any;
    filteredTutorGroup: any;
    topicQuery: any;
  }) {
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/student-topics-report?from_date=${from_date}&to_date=${to_date}&student_id=${filteredStudentId}&student_name=${filteredName}&tutor_group=${filteredTutorGroup}&agent_id=${getAgentFromStorage()}${
        topicQuery ? "&topic_ids=" + topicQuery : ""
      }`,
      method: "GET",
    });
    return res;
  }

  async getAgentSettings() {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/agents/current?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getOrganisationAgentSettings() {
    const res = await ApiUtil.SendAsync<any>({
      url: `organisation/agents/current?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async updateAgentSettings(data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/agents/current?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data,
    });
    return res;
  }

  async updateWebAgentSettings(data: any, agentId: string = "") {
    console.log("UPDATTING AGENT");
    console.log(data);

    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/agents/${
        agentId ? agentId : getAgentFromStorage()
      }/settings?agent_id=${getAgentFromStorage()}`,
      method: "PATCH",
      data: data,
    });
    return res;
  }

  async uploadFile(formData: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `media/images/upload`,
      method: "POST",
      data: formData,
    });
    return res;
  }

  async getConversationsRecords(userId: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `report/conversation-reports?search_key=author_id&search_value=${userId}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getQAndAsBasedOnDocument(
    document_id: string,
    offset: number = 0,
    limit: number = 10,
    sectionResponse: any = null
  ) {
    const documentSectionId =
      sectionResponse && sectionResponse._id
        ? `&document_section_id=${sectionResponse._id}`
        : "";
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document_id}/qnas?offset=${offset}&limit=${limit}${documentSectionId}&sort_field=sort_order&sort_order=asc&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async updateQnasSortOrder(documentId: string, data: any[]) {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${documentId}/qnas/sort-order?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        qnas: data,
      },
    });
    return res;
  }

  async getSectionsBasedOnDocumentId(document_id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document_id}/document-sections?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async generateCustomQuestionAnswer(document_id: string, question: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document_id}/qnas?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        question,
      },
    });
    return res;
  }

  async deleteQuestionFromDocument(document_id: string, qna_id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document_id}/qnas/${qna_id}?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
    });
    return res;
  }

  async updateQuestionFromDocument(
    document_id: string,
    qna_id: string,
    data: {
      question: string;
      answer: string;
    }
  ) {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document_id}/qnas/${qna_id}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        ...data,
      },
    });
    return res;
  }

  async getSuggestedKnowledge(filters: any) {
    const paginationFilter = {
      from_date: filters.from_date,
      to_date: filters.to_date,
      connection_ids: filters.connection_ids,
    };

    const searchFilter = {
      sort: filters.sort_field
        ? filters.sort_field + "::" + filters.sort_order
        : "",
      page: filters.page,
      page_size: filters.page_size,
    };

    const res = await DataApiUtil.SendAsync<any>({
      url: `report/report-data/suggest-answers-by-users?${serialize(
        paginationFilter
      )}&filter_is_solved=${filters.is_solved}&${serializeFilter(
        searchFilter
      )}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getSuggestedKnowledgeFeedback(id: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `chatlog/feedbacks/${id}/is-solved?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
    });
    return res;
  }

  async getAdminsBasedOnRoles(filter: any, withRoles: boolean = true) {
    const q: string = filter.q ? `&q=${filter.q}` : "";
    const sort: string = filter.sort_order
      ? `&sort_order=${filter.sort_order}&sort_field=${filter.sort_field}`
      : "";
    const status: string = filter.status ? `&status=${filter.status}` : "";
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/agents/${filter.agentId}/users?${
        withRoles ? "role=admin,readonlyadmin,superadmin&" : ""
      }page=${filter.page}&page_size=${
        filter.page_size
      }${q}${sort}${status}&agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async deleteAdminsFromRoles(
    agentId: string,
    deletedAgentId: any,
    role: string
  ) {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/agents/${agentId}/${role}s?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
      data: {
        user_ids:
          typeof deletedAgentId === "string"
            ? [deletedAgentId]
            : deletedAgentId,
      },
    });
    return res;
  }

  async addUserAsAdmins(agentId: string, deletedAgentId: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/agents/${agentId}/admins?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        user_ids:
          typeof deletedAgentId === "object"
            ? deletedAgentId
            : [deletedAgentId],
      },
    });
    return res;
  }

  async addRoleUserToAdmins(
    agentId: string,
    updateAgentId: string,
    role: string
  ) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/knowledge/agents/${agentId}/${role}?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        user_ids:
          typeof updateAgentId === "object" ? updateAgentId : [updateAgentId],
      },
    });
    return res;
  }

  async updateAdminUser(data: any, id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `organisation/users/${id}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        ...data,
      },
    });
    return res;
  }

  async addAdminUser(data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `organisation/users?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        ...data,
      },
    });
    return res;
  }

  async resendInvite(id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `organisation/users/${id}/registrations?agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  async updateKnowledgeAnswerStatus(
    unaswered_question_id: string,
    intentId: string
  ) {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/unaswered_questions/${unaswered_question_id}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        status: "answered",
        intent_id: intentId,
      },
    });
    return res;
  }

  async updateSuggestedAnswerStatus(
    unaswered_question_id: string,
    suggested_answer_id: string,
    intent_id: string
  ) {
    const obj: any = !intent_id
      ? {
          status: intent_id ? "accepted" : "rejected",
        }
      : {
          intent_id,
          status: intent_id ? "accepted" : "rejected",
        };
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/unaswered_questions/${unaswered_question_id}/suggested_answers/${suggested_answer_id}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        ...obj,
      },
    });
    return res;
  }

  async addDocumentSections(document: string, data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document}/document-sections?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        ...data,
      },
    });
    return res;
  }

  async updateDocumentSections(document: string, data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/${document}/document-sections?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        ...data,
      },
    });

    return res;
  }

  async getDocumentPreviewUrl(document: string, source: any) {
    let res;
    if (source) {
      res = await ApiUtil.SendAsync<any>({
        url: `convos/documents/${document}/preview-urls?agent_id=${getAgentFromStorage()}`,
        method: "POST",
        cancelToken: source.token,
      });
    } else {
      res = await ApiUtil.SendAsync<any>({
        url: `convos/documents/${document}/preview-urls?agent_id=${getAgentFromStorage()}`,
        method: "POST",
      });
    }
    return res;
  }

  async bulkDeleteUnansweredQuestions(unaswered_question_id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/unaswered_questions/${unaswered_question_id}?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
    });
    return res;
  }

  async deleteAnswersFromOutsideSource(conversation_log_id: string) {
    console.log(conversation_log_id);
    const res = await ApiUtil.SendAsync({
      url: `chatlog/conversation-logs/${conversation_log_id}/intent-reviewed?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
    });
    return res;
  }

  async getUnansweredQuestionCount() {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/unanswered-question-count?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getAllKnowledgeGroups() {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/groups?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getAllKnowledgeGroupsSubGroups() {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/groups?agent_id=${getAgentFromStorage()}&includes_all_subgroups=1`,
      method: "GET",
    });
    return res;
  }

  async getAllModulesFromYear(parent_id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/groups?agent_id=${getAgentFromStorage()}&parent_id=${parent_id}&includes_all_subgroups=1`,
      method: "GET",
    });
    return res;
  }

  async createKnowledgeGroup(data: { name?: "string"; parent_id?: "string" }) {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/groups?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        ...data,
      },
    });
    return res;
  }

  async updateKnowledgeGroup(
    data: {
      name: string;
    },
    knowledge_group_id: string
  ) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/knowledge/groups/${knowledge_group_id}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        ...data,
      },
    });
    return res;
  }

  async deleteKnowledgeGroup(knowledge_group_id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/groups/${knowledge_group_id}?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
    });
    return res;
  }

  // entity_type = 'form'| 'quiz' | 'intent' | 'question ( For question bank )'
  async addEntityToKnowledgeGroup(
    knowledge_group_id: string,
    entity_type: string,
    entity_ids: string[]
  ) {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/groups/${knowledge_group_id}/${entity_type}?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        entity_ids: entity_ids,
      },
    });
    return res;
  }

  async intentToConversationLogs(
    conversation_log_id: string,
    intent_id: string
  ) {
    const res = await ApiUtil.SendAsync<any>({
      url: `chatlog/conversation-logs/${conversation_log_id}/intent?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        intent_id: intent_id,
      },
    });
    return res;
  }

  async getCourseClasses() {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getCourseClassesById(id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes/${id}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getCourseLmsById(id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes/${id}/lmscourse?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getUserGroups(doNotAllowSystemGroup: boolean = false) {
    let q: string = "&is_system_group=0";
    const res = await ApiUtil.SendAsync<any>({
      url: `usergroup/groups?agent_id=${getAgentFromStorage()}${
        doNotAllowSystemGroup ? q : ""
      }`,
      method: "GET",
    });
    return res;
  }

  async getUserGroupById(id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `usergroup/groups/${id}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async updateUserGroupNameById(id: string, data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `usergroup/groups/${id}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data,
    });
    return res;
  }

  async updateCourseClassesById(
    id: string,
    knowledge_group_ids: string[],
    accessAllKnowledge: boolean = false
  ) {
    let obj: any = {};
    if (accessAllKnowledge) {
      obj = {
        access_all_knowledge: true,
      };
    } else {
      obj = {
        knowledge_group_ids,
      };
    }
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes/${id}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: obj,
    });
    return res;
  }

  async updateUserGroupById(
    id: string,
    knowledge_group_ids: string[],
    accessAllKnowledge: boolean = false
  ) {
    let obj: any = {};
    if (accessAllKnowledge) {
      obj = {
        access_all_knowledge: true,
      };
    } else {
      obj = {
        knowledge_group_ids,
      };
    }
    const res = await ApiUtil.SendAsync<any>({
      url: `usergroup/groups/${id}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: obj,
    });
    return res;
  }

  async addClass(data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data,
    });
    return res;
  }

  async deleteClass(classId: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes/${classId}?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
    });
    return res;
  }

  async duplicateClass(data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/duplicate-class?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data,
    });
    return res;
  }

  async duplicateLaernerGroup(data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `usergroup/duplicate-group?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data,
    });
    return res;
  }

  async linkACourseToClass(data: any, classId: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes/${classId}/lmscourse?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data,
    });
    return res;
  }

  async unlinkACourseToClass(classId: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes/${classId}/lmscourse?agent_id=${getAgentFromStorage()}`,
      method: "delete",
    });
    return res;
  }

  async updateClassDetailById(classId: string, data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes/${classId}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data,
    });
    return res;
  }

  async addAdminsToClasses(classId: string, deletedAgentId: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes/${classId}/admins?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        user_ids:
          typeof deletedAgentId === "string"
            ? [deletedAgentId]
            : deletedAgentId,
      },
    });
    return res;
  }

  async deleteAdminsFromClasses(classId: string, deletedAgentId: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `course/classes/${classId}/admins?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
      data: {
        user_ids: [deletedAgentId],
      },
    });
    return res;
  }

  async addLearnerToClasses(
    agentId: string,
    updateAgentId: string,
    role: string
  ) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/course/classes/${agentId}/${role}?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        user_ids: [updateAgentId],
      },
    });
    return res;
  }

  async deleteLearnerToClasses(
    agentId: string,
    updateAgentId: string,
    role: string
  ) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/course/classes/${agentId}/${role}?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
      data: {
        user_ids: [updateAgentId],
      },
    });
    return res;
  }

  async addLearnerGroup(name: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/usergroup/groups?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        name: name,
      },
    });
    return res;
  }

  async deleteLearnerGroup(name: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/usergroup/groups/${name}?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
    });
    return res;
  }

  async getLearningUserDetail(name: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/learning/users/${name}?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async addUsersAndClassesToLearnerGroup(groupId: string, data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `usergroup/groups/${groupId}/users?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data,
    });
    return res;
  }

  async deleteUsersAndClassesToLearnerGroup(groupId: string, data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `usergroup/groups/${groupId}/users?agent_id=${getAgentFromStorage()}`,
      method: "DELETE",
      data,
    });
    return res;
  }

  async uploadBulkLearners(data: any, importType: string, silent: boolean) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/learning/users/${importType}?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: silent
        ? {
            silent: true,
            users: data,
          }
        : {
            users: data,
          },
    });
    return res;
  }

  async getParaPhrasesBasedOnIntent(intentID: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/intents/${intentID}/generated-questions?agent_id=${getAgentFromStorage()}`,
      method: "POST",
    });
    return res;
  }

  async sendDocToSumRequest(document_ids: string[]) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/docsum/docsum-requests?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        document_ids,
      },
    });
    return res;
  }

  async sendBuildDocToSumRequest(data: any, isSmartQuiz: boolean = false) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/docsum/docsum-requests?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        ...data,
        builds_summarised_file: isSmartQuiz,
      },
    });
    return res;
  }

  async getDocumentsValidations(document_ids: string[]) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/docsum/validate-documents?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        document_ids,
      },
    });
    return res;
  }

  async getDocumentsValidationsV3(data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/docsum/validate-documents?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        ...data,
      },
    });
    return res;
  }

  async getDocumentsTextBlocks(document_id: string, source: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/documents/${document_id}/text-blocks?agent_id=${getAgentFromStorage()}`,
      method: "GET",
      cancelToken: source.token,
    });
    return res;
  }

  async generateLearningOutcomes(
    document_id: string,
    max_learning_outcomes: number = 20
  ) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/documents/${document_id}/generated-learning-outcomes?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        max_learning_outcomes,
      },
    });
    return res;
  }

  async getLearningOutcomes(document_id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/documents/${document_id}/learning-outcomes?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async getRecommendLearningOutcomes(document_ids: []) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/generated-recommended-learning-outcomes?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        document_ids,
      },
    });
    return res;
  }

  async getSuggestLearningOutcomes(knowledge_group_id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/generated-learning-outcomes?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        knowledge_group_id,
      },
    });
    return res;
  }

  async saveRecommendLearningOutcomes(document_ids: [], learning_outcomes: []) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/save-recommended-learning-outcomes?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        document_ids,
        learning_outcomes,
      },
    });
    return res;
  }

  async saveLearningOutcomesByGroup(
    knowledge_group_id: any,
    learning_outcomes: []
  ) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/save-learning-outcomes?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        knowledge_group_id,
        learning_outcomes,
      },
    });
    return res;
  }

  async getLearningOutcomesByGroup(groupId: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/learning-outcomes?agent_id=${getAgentFromStorage()}&knowledge_group_ids=${groupId}`,
      method: "GET",
    });
    return res;
  }

  async saveLearningOutcomes(outcomes: any[]) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/learning-outcomes?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        learning_outcomes: outcomes,
      },
    });
    return res;
  }

  async docSearchAddToIndex(document_ids: string[]) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/docsearch/add-to-index?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        document_ids,
      },
    });
    return res;
  }

  async updateUserFeedback(feedback_id: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/chatlog/feedbacks/${feedback_id}/is-solved?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        feedback_id,
      },
    });
    return res;
  }

  async generateQuestionAnswerBasedonNumberOfInput(
    document_id: string,
    max_qnas: number,
    source: any,
    questionType?: string
  ) {
    const payLoad: any = {
      max_qnas,
    };
    if (questionType) {
      payLoad.qna_type = questionType;
    }

    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/documents/${document_id}/generate-qnas?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: payLoad,
      cancelToken: source.token,
    });
    return res;
  }

  async exportQuestions(selectedNodes: any, filters: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `quiz/questions/download?${serializeFilter(
        filters
      )}&id=${selectedNodes}&agent_id=${getAgentFromStorage()}`,
      method: "POST",
      responseType: "blob",
    });
    return res;
  }

  async generateAssessmentquiz(data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/generate-assessment-quiz?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        ...data,
      },
    });
    return res;
  }

  async exportLearnerAttendance(
    filters: any,
    fileFormat: string
  ): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `learning/students-report/download?student_id=${
        filters.filteredStudentId
      }&student_name=${filters.filteredName}&tutor_group=${
        filters.filteredTutorGroup
      }&last_login_from_date=${filters.from_date}&last_login_to_date=${
        filters.to_date
      }&agent_id=${getAgentFromStorage()}`,
      method: "POST",
      responseType: "blob",
    });
    return res;
  }

  async triggerGenerateQna(document_ids: string[]) {
    const res = await ApiUtil.SendAsync<any>({
      url: `convos/documents/trigger-generate-qnas?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        document_ids,
      },
    });
    return res;
  }

  async getDocumentProcess(document_ids: string) {
    const res = await ApiUtil.SendAsync<any>({
      url: `/docsum/docsum-requests/${document_ids}/progress?agent_id=${getAgentFromStorage()}`,
      method: "get",
    });
    return res;
  }

  async getToolkitQuizQuestions(document_id: string, data: any, source: any) {
    // const type = queTypes.join(",");
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/documents/${document_id}/assessment-questions?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        ...data,
      },
      // cancelToken: source.token,
    });
    return res;
  }

  async renameDocument(documentId: string, name: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/documents/${documentId}?agent_id=${getAgentFromStorage()}`,
      method: "PUT",
      data: {
        name: name,
      },
    });
    return res;
  }

  async getAgentPersona(agentId: string = "") {
    const res = await ApiUtil.SendAsync<any>({
      url: `persona/agent/personas?agent_id=${
        agentId ? agentId : getAgentFromStorage()
      }`,
      method: "GET",
    });
    return res;
  }

  async getParaPhrasesWithoutIntent(questions: string[]) {
    const allQuestions = [...questions];
    const res = await ApiUtil.SendAsync<any>({
      url: `/convos/paraphrased-questions?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        questions: allQuestions,
      },
    });
    return res;
  }

  async exportQuizzesAndQuestions(data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `/quiz/download-quizzes?agent_id=${getAgentFromStorage()}`,
      method: "POST",
      data: {
        ...data,
      },
      responseType: "blob",
    });
    return res;
  }

  async getSampleDocuments() {
    const res = await ApiUtil.SendAsync<any>({
      url: `docsearch/documents/sample?agent_id=${getAgentFromStorage()}`,
      method: "GET",
    });
    return res;
  }

  async loadSampleDocuments(data: any) {
    const res = await ApiUtil.SendAsync<any>({
      url: `docsearch/documents/sample?agent_id=${getAgentFromStorage()}`,
      method: "PATCH",
      data: {
        ...data
      }
    });
    return res;
  }
}
