import React, { ReactElement } from 'react'
import Header from '../Header/Header';

interface Props {
    
}

export default function MainLayout(props: any): ReactElement {
    return (
        <>  
            {props.isHeader && <Header />}
            {props.children}
        </>
    )
}
