/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, ReactElement } from "react";
import {
  Col,
  Row,
} from "react-bootstrap";
import {
  KNOWLEDGE_ALL,
  QUIZZES,
  QUIZZES_BETA,
  SUPPORT,
} from "../../../../config";
import {
  isTopicsLoading,
} from "../../../../store/knowledge/selector";
import { useHistory } from "react-router-dom";
import knowledgeImg from "../../../../assets/New-images/quick-start-knowledge.svg";
import quizzesImg from "../../../../assets/New-images/quick-start-quizzes.svg";
import supportImg from "../../../../assets/New-images/quick-start-support.svg";
import {
  getUnansweredQuestionCount,
} from "../../../../store/knowledge/actions";
import {
  getSubmissionReviewCount
} from '../../../../store/dashboard/actions';
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

function QuickStart(props: any): ReactElement {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const dispatch = useDispatch();
  const topicsLoading = useSelector(isTopicsLoading)
  const [unansweredCount, setUnansweredCount] = useState(0);
  const [submissionsReviewCount, setSubmissionsReviewCount] = useState(0);
 
  useEffect(() => {
    if (!topicsLoading) {
      getUnansweredCount();
      getSubmissionsReviewCount();
    }
  }, []);

  const getUnansweredCount = async () => {
    try {
      const response: any = await dispatch(getUnansweredQuestionCount());
      setUnansweredCount(response);
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const getSubmissionsReviewCount = async () => {
    try {
      const response: any = await dispatch(getSubmissionReviewCount());
      setSubmissionsReviewCount(response);
    } catch (error: any) {
      console.log("error", error);
    }
  };

  return (
    <Row className={"mt-4"}>
            <Col md={4} className="mb-4">
              <div className="quick-start-box p-3 d-flex  align-items-center text-left"
                onClick={() => {
                  history.push(KNOWLEDGE_ALL);
                }}
              >
                <div className="inner-box pl-3">
                  <img src={knowledgeImg} alt="" />
                  <div className="inner-text">
                    <h2 className="mb-0">
                      {t("Manage Knowledge Base")}
                    </h2>
                    <span className="description">
                      <span className="counter">{unansweredCount}{" "}</span>
                      {t("Unanswered Questions")}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="quick-start-box p-3 d-flex align-items-center text-left"
                onClick={() => {
                  history.push(QUIZZES_BETA);
                }}
              >
                <div className="inner-box pl-3">
                  <img src={quizzesImg} alt="" />
                  <div className="inner-text">
                    <h2 className="mb-0">
                      {t("Track & Improve Quizzes")}
                    </h2>
                    <span className="description">
                      <span className="counter">{submissionsReviewCount}{" "}</span>
                      {t("Submissions to Review")}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div
                className="quick-start-box p-3 d-flex align-items-center text-left"
                onClick={() => {
                  history.push(SUPPORT);
                }}
              >
                <div className="inner-box pl-3">
                  <img src={supportImg} alt="" />
                  <div className="inner-text">
                    <h2 className="mb-0">
                      {t("How to use?")}
                    </h2>
                    <span className="description">
                      {t("Browse through our guides")}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
  );
}

export default QuickStart;
