import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import ReactSelect from "react-select";
import cx from "classnames";
import Loading from "../../components/UI/Loading/Loading";
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from "react-bootstrap";
import noodleLogo from "./../../assets/images/noodle-text.svg";
import backArrow from "./../../assets/images/arrow-back.svg";
import tooltipImg from "./../../assets/images/pink-tooltip.svg";
import closeimg from "./../../assets/images/close-blk.svg";

import CommonService from "../../services/CommonService";
import history from "../../utils/history";
import "./styles.scss";
import { LANGUAGE_OPTIONS } from "../../components/Header/ChangeLanguage";
import TwoButtonModal from "../../components/UI/Common/TwoButtonModal";
import ChatImages from "./ChatImages";
import InfoIconDetails from "../../components/UI/Dashboard/InfoIconDetails/InfoIconDetails";
import classNames from "classnames";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { t } from "i18next";

const windowObj: any = window;

const fields = {
  first_name: {
    field: "first_name",
    label: "First Name",
  },
  last_name: {
    field: "last_name",
    label: "Last Name",
  },
  email: {
    field: "email",
    label: "Email",
    isDisabled: true,
  },
  reEmail: {
    field: "reEmail",
    label: "Confirm Email",
  },
  password: {
    field: "password",
    label: "Password",
    type: "password",
  },
  rePassword: {
    field: "rePassword",
    label: "Confirm Password",
    type: "password",
  },
  name: {
    field: "name",
    label: "Organisation",
  },
  country: {
    field: "country",
    label: "Country",
  },
  lang: {
    field: "lang",
    label: "Select Language",
  },
};

const countries = [
  {
    value: "Afghanistan",
    label: "Afghanistan",
  },
  {
    value: "Åland Islands",
    label: "Åland Islands",
  },
  {
    value: "Albania",
    label: "Albania",
  },
  {
    value: "Algeria",
    label: "Algeria",
  },
  {
    value: "American Samoa",
    label: "American Samoa",
  },
  {
    value: "Andorra",
    label: "Andorra",
  },
  {
    value: "Angola",
    label: "Angola",
  },
  {
    value: "Anguilla",
    label: "Anguilla",
  },
  {
    value: "Antarctica",
    label: "Antarctica",
  },
  {
    value: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
  },
  {
    value: "Argentina",
    label: "Argentina",
  },
  {
    value: "Armenia",
    label: "Armenia",
  },
  {
    value: "Aruba",
    label: "Aruba",
  },
  {
    value: "Australia",
    label: "Australia",
  },
  {
    value: "Austria",
    label: "Austria",
  },
  {
    value: "Azerbaijan",
    label: "Azerbaijan",
  },
  {
    value: "Bahamas",
    label: "Bahamas",
  },
  {
    value: "Bahrain",
    label: "Bahrain",
  },
  {
    value: "Bangladesh",
    label: "Bangladesh",
  },
  {
    value: "Barbados",
    label: "Barbados",
  },
  {
    value: "Belarus",
    label: "Belarus",
  },
  {
    value: "Belgium",
    label: "Belgium",
  },
  {
    value: "Belize",
    label: "Belize",
  },
  {
    value: "Benin",
    label: "Benin",
  },
  {
    value: "Bermuda",
    label: "Bermuda",
  },
  {
    value: "Bhutan",
    label: "Bhutan",
  },
  {
    value: "Bolivia",
    label: "Bolivia",
  },
  {
    value: "Bonaire",
    label: "Bonaire",
  },
  {
    value: "Bosnia and Herzegovina",
    label: "Bosnia and Herzegovina",
  },
  {
    value: "Botswana",
    label: "Botswana",
  },
  {
    value: "Bouvet Island",
    label: "Bouvet Island",
  },
  {
    value: "Brazil",
    label: "Brazil",
  },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
  },
  {
    value: "Brunei",
    label: "Brunei",
  },
  {
    value: "Bulgaria",
    label: "Bulgaria",
  },
  {
    value: "Burkina Faso",
    label: "Burkina Faso",
  },
  {
    value: "Burundi",
    label: "Burundi",
  },
  {
    value: "Cabo Verde",
    label: "Cabo Verde",
  },
  {
    value: "Cambodia",
    label: "Cambodia",
  },
  {
    value: "Cameroon",
    label: "Cameroon",
  },
  {
    value: "Canada",
    label: "Canada",
  },
  {
    value: "Cayman Islands",
    label: "Cayman Islands",
  },
  {
    value: "Central African Republic",
    label: "Central African Republic",
  },
  {
    value: "Chad",
    label: "Chad",
  },
  {
    value: "Chile",
    label: "Chile",
  },
  {
    value: "China",
    label: "China",
  },
  {
    value: "Christmas Island",
    label: "Christmas Island",
  },
  {
    value: "Cocos (Keeling) Islands",
    label: "Cocos (Keeling) Islands",
  },
  {
    value: "Colombia",
    label: "Colombia",
  },
  {
    value: "Comoros",
    label: "Comoros",
  },
  {
    value: "Congo",
    label: "Congo",
  },
  {
    value: "Congo, Democratic Republic of the",
    label: "Congo, Democratic Republic of the",
  },
  {
    value: "Cook Islands",
    label: "Cook Islands",
  },
  {
    value: "Costa Rica",
    label: "Costa Rica",
  },
  {
    value: "Côte d'Ivoire",
    label: "Côte d'Ivoire",
  },
  {
    value: "Croatia",
    label: "Croatia",
  },
  {
    value: "Curaçao",
    label: "Curaçao",
  },
  {
    value: "Cyprus",
    label: "Cyprus",
  },
  {
    value: "Czechia",
    label: "Czechia",
  },
  {
    value: "Denmark",
    label: "Denmark",
  },
  {
    value: "Djibouti",
    label: "Djibouti",
  },
  {
    value: "Dominica",
    label: "Dominica",
  },
  {
    value: "Dominican Republic",
    label: "Dominican Republic",
  },
  {
    value: "Ecuador",
    label: "Ecuador",
  },
  {
    value: "Egypt",
    label: "Egypt",
  },
  {
    value: "El Salvador",
    label: "El Salvador",
  },
  {
    value: "Equatorial Guinea",
    label: "Equatorial Guinea",
  },
  {
    value: "Eritrea",
    label: "Eritrea",
  },
  {
    value: "Estonia",
    label: "Estonia",
  },
  {
    value: "Eswatini",
    label: "Eswatini",
  },
  {
    value: "Ethiopia",
    label: "Ethiopia",
  },
  {
    value: "Falkland Islands (Malvinas)",
    label: "Falkland Islands (Malvinas)",
  },
  {
    value: "Faroe Islands",
    label: "Faroe Islands",
  },
  {
    value: "Fiji",
    label: "Fiji",
  },
  {
    value: "Finland",
    label: "Finland",
  },
  {
    value: "France",
    label: "France",
  },
  {
    value: "French Guiana",
    label: "French Guiana",
  },
  {
    value: "French Polynesia",
    label: "French Polynesia",
  },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
  },
  {
    value: "Gabon",
    label: "Gabon",
  },
  {
    value: "Gambia",
    label: "Gambia",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Germany",
    label: "Germany",
  },
  {
    value: "Ghana",
    label: "Ghana",
  },
  {
    value: "Gibraltar",
    label: "Gibraltar",
  },
  {
    value: "Greece",
    label: "Greece",
  },
  {
    value: "Greenland",
    label: "Greenland",
  },
  {
    value: "Grenada",
    label: "Grenada",
  },
  {
    value: "Guadeloupe",
    label: "Guadeloupe",
  },
  {
    value: "Guam",
    label: "Guam",
  },
  {
    value: "Guatemala",
    label: "Guatemala",
  },
  {
    value: "Guernsey",
    label: "Guernsey",
  },
  {
    value: "Guinea",
    label: "Guinea",
  },
  {
    value: "Guinea-Bissau",
    label: "Guinea-Bissau",
  },
  {
    value: "Guyana",
    label: "Guyana",
  },
  {
    value: "Haiti",
    label: "Haiti",
  },
  {
    value: "Heard Island and McDonald Islands",
    label: "Heard Island and McDonald Islands",
  },
  {
    value: "Holy See",
    label: "Holy See",
  },
  {
    value: "Honduras",
    label: "Honduras",
  },
  {
    value: "Hong Kong",
    label: "Hong Kong",
  },
  {
    value: "Hungary",
    label: "Hungary",
  },
  {
    value: "Iceland",
    label: "Iceland",
  },
  {
    value: "India",
    label: "India",
  },
  {
    value: "Indonesia",
    label: "Indonesia",
  },
  {
    value: "Ireland",
    label: "Ireland",
  },
  {
    value: "Isle of Man",
    label: "Isle of Man",
  },
  {
    value: "Israel",
    label: "Israel",
  },
  {
    value: "Italy",
    label: "Italy",
  },
  {
    value: "Jamaica",
    label: "Jamaica",
  },
  {
    value: "Japan",
    label: "Japan",
  },
  {
    value: "Jersey",
    label: "Jersey",
  },
  {
    value: "Jordan",
    label: "Jordan",
  },
  {
    value: "Kazakhstan",
    label: "Kazakhstan",
  },
  {
    value: "Kenya",
    label: "Kenya",
  },
  {
    value: "Kiribati",
    label: "Kiribati",
  },
  {
    value: "Korea (the Republic of)",
    label: "Korea (the Republic of)",
  },
  {
    value: "Kuwait",
    label: "Kuwait",
  },
  {
    value: "Kyrgyzstan",
    label: "Kyrgyzstan",
  },
  {
    value: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
  },
  {
    value: "Latvia",
    label: "Latvia",
  },
  {
    value: "Lebanon",
    label: "Lebanon",
  },
  {
    value: "Lesotho",
    label: "Lesotho",
  },
  {
    value: "Liberia",
    label: "Liberia",
  },
  {
    value: "Libya",
    label: "Libya",
  },
  {
    value: "Liechtenstein",
    label: "Liechtenstein",
  },
  {
    value: "Lithuania",
    label: "Lithuania",
  },
  {
    value: "Luxembourg",
    label: "Luxembourg",
  },
  {
    value: "Macao",
    label: "Macao",
  },
  {
    value: "Madagascar",
    label: "Madagascar",
  },
  {
    value: "Malawi",
    label: "Malawi",
  },
  {
    value: "Malaysia",
    label: "Malaysia",
  },
  {
    value: "Maldives",
    label: "Maldives",
  },
  {
    value: "Mali",
    label: "Mali",
  },
  {
    value: "Malta",
    label: "Malta",
  },
  {
    value: "Marshall Islands",
    label: "Marshall Islands",
  },
  {
    value: "Martinique",
    label: "Martinique",
  },
  {
    value: "Mauritania",
    label: "Mauritania",
  },
  {
    value: "Mauritius",
    label: "Mauritius",
  },
  {
    value: "Mayotte",
    label: "Mayotte",
  },
  {
    value: "Mexico",
    label: "Mexico",
  },
  {
    value: "Micronesia (Federated States of)",
    label: "Micronesia (Federated States of)",
  },
  {
    value: "Moldova",
    label: "Moldova",
  },
  {
    value: "Monaco",
    label: "Monaco",
  },
  {
    value: "Mongolia",
    label: "Mongolia",
  },
  {
    value: "Montenegro",
    label: "Montenegro",
  },
  {
    value: "Montserrat",
    label: "Montserrat",
  },
  {
    value: "Morocco",
    label: "Morocco",
  },
  {
    value: "Mozambique",
    label: "Mozambique",
  },
  {
    value: "Myanmar",
    label: "Myanmar",
  },
  {
    value: "Namibia",
    label: "Namibia",
  },
  {
    value: "Nauru",
    label: "Nauru",
  },
  {
    value: "Nepal",
    label: "Nepal",
  },
  {
    value: "Netherlands",
    label: "Netherlands",
  },
  {
    value: "New Caledonia",
    label: "New Caledonia",
  },
  {
    value: "New Zealand",
    label: "New Zealand",
  },
  {
    value: "Nicaragua",
    label: "Nicaragua",
  },
  {
    value: "Niger",
    label: "Niger",
  },
  {
    value: "Nigeria",
    label: "Nigeria",
  },
  {
    value: "Niue",
    label: "Niue",
  },
  {
    value: "Norfolk Island",
    label: "Norfolk Island",
  },
  {
    value: "North Macedonia",
    label: "North Macedonia",
  },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
  },
  {
    value: "Norway",
    label: "Norway",
  },
  {
    value: "Oman",
    label: "Oman",
  },
  {
    value: "Pakistan",
    label: "Pakistan",
  },
  {
    value: "Palau",
    label: "Palau",
  },
  {
    value: "Palestine",
    label: "Palestine",
  },
  {
    value: "Panama",
    label: "Panama",
  },
  {
    value: "Papua New Guinea",
    label: "Papua New Guinea",
  },
  {
    value: "Paraguay",
    label: "Paraguay",
  },
  {
    value: "Peru",
    label: "Peru",
  },
  {
    value: "Philippines",
    label: "Philippines",
  },
  {
    value: "Pitcairn",
    label: "Pitcairn",
  },
  {
    value: "Poland",
    label: "Poland",
  },
  {
    value: "Portugal",
    label: "Portugal",
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico",
  },
  {
    value: "Qatar",
    label: "Qatar",
  },
  {
    value: "Réunion",
    label: "Réunion",
  },
  {
    value: "Romania",
    label: "Romania",
  },
  {
    value: "Rwanda",
    label: "Rwanda",
  },
  {
    value: "Saint Barthélemy",
    label: "Saint Barthélemy",
  },
  {
    value: "Saint Helena, Ascension and Tristan da Cunha",
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    value: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
  },
  {
    value: "Saint Lucia",
    label: "Saint Lucia",
  },
  {
    value: "Saint Martin (French part)",
    label: "Saint Martin (French part)",
  },
  {
    value: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
  },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  {
    value: "Samoa",
    label: "Samoa",
  },
  {
    value: "San Marino",
    label: "San Marino",
  },
  {
    value: "Sao Tome and Principe",
    label: "Sao Tome and Principe",
  },
  {
    value: "Saudi Arabia",
    label: "Saudi Arabia",
  },
  {
    value: "Senegal",
    label: "Senegal",
  },
  {
    value: "Serbia",
    label: "Serbia",
  },
  {
    value: "Seychelles",
    label: "Seychelles",
  },
  {
    value: "Sierra Leone",
    label: "Sierra Leone",
  },
  {
    value: "Singapore",
    label: "Singapore",
  },
  {
    value: "Sint Maarten (Dutch part)",
    label: "Sint Maarten (Dutch part)",
  },
  {
    value: "Slovakia",
    label: "Slovakia",
  },
  {
    value: "Slovenia",
    label: "Slovenia",
  },
  {
    value: "Solomon Islands",
    label: "Solomon Islands",
  },
  {
    value: "Somalia",
    label: "Somalia",
  },
  {
    value: "South Africa",
    label: "South Africa",
  },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    value: "Spain",
    label: "Spain",
  },
  {
    value: "Sri Lanka",
    label: "Sri Lanka",
  },
  {
    value: "Suriname",
    label: "Suriname",
  },
  {
    value: "Svalbard and Jan Mayen",
    label: "Svalbard and Jan Mayen",
  },
  {
    value: "Sweden",
    label: "Sweden",
  },
  {
    value: "Switzerland",
    label: "Switzerland",
  },
  {
    value: "Taiwan",
    label: "Taiwan",
  },
  {
    value: "Tajikistan",
    label: "Tajikistan",
  },
  {
    value: "Tanzania",
    label: "Tanzania",
  },
  {
    value: "Thailand",
    label: "Thailand",
  },
  {
    value: "Timor-Leste",
    label: "Timor-Leste",
  },
  {
    value: "Togo",
    label: "Togo",
  },
  {
    value: "Tokelau",
    label: "Tokelau",
  },
  {
    value: "Tonga",
    label: "Tonga",
  },
  {
    value: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
  },
  {
    value: "Tunisia",
    label: "Tunisia",
  },
  {
    value: "Turkey",
    label: "Turkey",
  },
  {
    value: "Turkmenistan",
    label: "Turkmenistan",
  },
  {
    value: "Turks and Caicos Islands",
    label: "Turks and Caicos Islands",
  },
  {
    value: "Tuvalu",
    label: "Tuvalu",
  },
  {
    value: "Uganda",
    label: "Uganda",
  },
  {
    value: "Ukraine",
    label: "Ukraine",
  },
  {
    value: "United Arab Emirates",
    label: "United Arab Emirates",
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
  },
  {
    value: "United States of America",
    label: "United States of America",
  },
  {
    value: "United States Minor Outlying Islands",
    label: "United States Minor Outlying Islands",
  },
  {
    value: "Uruguay",
    label: "Uruguay",
  },
  {
    value: "Uzbekistan",
    label: "Uzbekistan",
  },
  {
    value: "Vanuatu",
    label: "Vanuatu",
  },
  {
    value: "Venezuela",
    label: "Venezuela",
  },
  {
    value: "Viet Nam",
    label: "Viet Nam",
  },
  {
    value: "Virgin Islands (British)",
    label: "Virgin Islands (British)",
  },
  {
    value: "Virgin Islands (U.S.)",
    label: "Virgin Islands (U.S.)",
  },
  {
    value: "Wallis and Futuna",
    label: "Wallis and Futuna",
  },
  {
    value: "Western Sahara",
    label: "Western Sahara",
  },
  {
    value: "Yemen",
    label: "Yemen",
  },
  {
    value: "Zambia",
    label: "Zambia",
  },
  {
    value: "Zimbabwe",
    label: "Zimbabwe",
  },
];

const commonService = new CommonService();

export const plans = [
  {
    value: windowObj.REACT_APP_PLAN_PROD_STARTER || "5f4c20049bf51d38f9a84519",
    label: "Starter Plan- USD$9.90 Monthly",
  },
];

const SelectLanguageTooltipPrompt = (props: any) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size={"lg"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={"modal"}
    >
      <Modal.Header className="py-3 modal-header border-bottom" closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className={"font-16 font-weight-bold"}
        >
          {t("Select Language")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={""}>
          {t(
            "This would be the default language of your chatbot. You can change it later."
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <button
          className={"btn btn-primary font-600 py-1"}
          onClick={props.onHide}
        >
          {t("Close")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const Info = (props: any) => {
  const { t } = useTranslation("translation");
  const [isVerified, setIsVerified] = useState(false);
  const [showPwGuide, setShowPwGuide] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [isShowTooltipModal, setIsShowTooltipModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isAws, setIsAws] = useState(false);
  const { form, back, isMobile } = props;
  const query = new URLSearchParams(history.location.search);
  const redUrl = query.get("next_url")
  ? query.get("next_url")
  : windowObj.NEW_PORTAL_URI;

  let source = "web";
  let plan = plans[0].value;
  
  if (history.location && history.location.search) {
    const query = new URLSearchParams(history.location.search);
    source = query.get("source") ? query.get("source") : source;
    plan = query.get("product") ? query.get("product") : plan;
  }

  useEffect(() => {
    setApiError("");
    let plan = plans[0].value;
    const query = new URLSearchParams(history.location.search);
    plan = query.get("product") ? query.get("product") : plan;
    setIsAws(
      history.location &&
        history.location.pathname &&
        history.location.pathname.includes("aws-signup")
        ? true
        : false
    );
  }, []);

  const getLanguage = async () => {
    const res = await commonService.getLang();
    if (res?.data?.data?.country) {
      const country = countries.find(
        (c: any) => c.value === res?.data?.data?.country
      );
      if (country) {
        form.setFieldValue("country", country.value);
      }
    }

    if (res?.data?.data?.language_code) {
      const lang = LANGUAGE_OPTIONS.find(
        (c: any) => c.value === res?.data?.data?.language_code
      );
      if (lang && !props.selectedLanguageCode) {
        form.setFieldValue("lang", lang.value);
      }
    }
  };

  useEffect(() => {
    getLanguage();
  }, []);

  const renderInput = ({ field, label, isDisabled, type }: any) => {
    if (field === "country") {
      const value = countries.find((c: any) => c.value === form.values[field]);
      return (
        <>
          <div className="py-2">{t(label)}</div>
          <ReactSelect
            isClearable={false}
            className="add-tag-select"
            onChange={(c) => form.setFieldValue(field, c.value)}
            defaultValue={value}
            options={countries}
            placeholder={t("Country")}
            value={countries.find((c) => c.value === form.values[field])}
          />
        </>
      );
    }
    if (field === "lang") {
      return (
        <>
          <div className="py-2 d-flex">
            {t(label)}
            {!isMobile && (
              <InfoIconDetails
                isPinkImage
                placement="right"
                class="agent-popover"
                message={t(
                  "This would be the default language of your chatbot. You can change it later."
                )}
              />
            )}
            {isMobile && (
              <div className="ml-2">
                <Image
                  src={tooltipImg}
                  onClick={() => setIsShowTooltipModal(true)}
                />
              </div>
            )}
          </div>

          <ReactSelect
            isSearchable={false}
            isClearable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            placeholder="Change Language"
            options={LANGUAGE_OPTIONS}
            defaultValue={LANGUAGE_OPTIONS.find((l) => l.value === form.values[field])}
            value={LANGUAGE_OPTIONS.find((l) => l.value === form.values[field])}
            onChange={(l: any) => {
              form.setFieldValue(field, l.value);
            }}
          />
        </>
      );
    }

    return (
      <>
        <div className="py-2">{t(label)}*</div>
        <input
          disabled={isDisabled}
          type={type === "password" ? "password" : "text"}
          value={form.values[field]}
          className={classNames({
            "form-control h-auto w-100 pt-2": true,
            "border-danger": form.errors[field],
          })}
          onChange={(e: any) => {
            form.setFieldValue(field, e?.target?.value);
          }}
          onFocus={() => field === "password" && setShowPwGuide(true)}
          onBlur={() => field === "password" && setShowPwGuide(false)}
        />
        <div className="p-1 clr-red">{t(form.errors[field])}</div>
      </>
    );
  };

  const register = async () => {
    setApiError("");
    setLoading(true);
    try {
      const {
        email,
        country,
        first_name,
        last_name,
        name,
        password,
        product_definition_id,
        lang,
        subscription_id
      } = form.values;
      const res = await commonService.register({
        email,
        country,
        first_name,
        last_name,
        name,
        password,
        product_definition_id,
        source,
        default_language_code: lang,
        subscription_id 
      }, isAws);
      window.location.href = res?.data?.data?.checkout_url;
    } catch (error: any) {
      setLoading(false);
      const msg = error?.response?.data?.status?.message;
      setApiError(msg);
      if (msg.includes("Email")) {
        form.resetForm();
        form.setFieldError("email", msg);
        back();
      }
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="d-flex">
      <SelectLanguageTooltipPrompt
        show={isShowTooltipModal}
        onHide={() => {
          setIsShowTooltipModal(false);
        }}
      />
      <div className="green-bg d-flex flex-1 flex-column align-items-center max-col email-input-wrap">
        <Image className="top-logo" src={noodleLogo} />
        <div className="content-wrapper w-100" style={{ paddingTop: "135px" }}>
          <div className="d-flex w-100">
            <Image
              className="back-btn"
              src={backArrow}
              onClick={() => {
                setApiError("");
                back();
              }}
            />
            <div
              className="pink-color-text"
              style={{ flex: 1, textAlign: "center" }}
            >
              {t("Create an account")}
            </div>
          </div>
          <div className="input-wrapper sub-text">{t("*Required fields")}</div>
          <div className="input-wrapper">{renderInput(fields.email)}</div>
          <div className="input-wrapper">{renderInput(fields.reEmail)}</div>

          <div className={cx("input-wrapper ", { "d-flex": !isMobile })}>
            <div className={cx({ "pr-2 input-wrapper": !isMobile })}>
              {renderInput(fields.password)}
            </div>
            <div className={cx({ "input-wrapper": !isMobile })}>
              {renderInput(fields.rePassword)}
            </div>
          </div>
          {/* <div className="input-wrapper">{renderInput(fields.password)}</div> */}
          {showPwGuide && (
            <div className="input-wrapper opt-step">
              <div>
                <div>{t("An English uppercase character (A-Z)")}</div>
                <div>{t("An English lowercase character (a-z)")}</div>
                <div>{t("A number (0-9) or symbol (such as !, #, or %)")}</div>
                <div>{t("More than 7 characters")}</div>
                <div>{t("No Spaces")}</div>
              </div>
            </div>
          )}
          <div className="input-wrapper">{renderInput(fields.name)}</div>
          <div className={cx("input-wrapper ", { "d-flex": !isMobile })}>
            <div className={cx({ "pr-2 input-wrapper": !isMobile })}>
              {renderInput(fields.first_name)}
            </div>
            <div className={cx({ "input-wrapper": !isMobile })}>
              {renderInput(fields.last_name)}
            </div>
          </div>
          <div className={cx("input-wrapper mb-4", { "d-flex": !isMobile })}>
            <div className={cx("mb-4", { "pr-2 input-wrapper": !isMobile })}>
              {renderInput(fields.country)}
            </div>
            <div className={cx({ "input-wrapper": !isMobile })}>
              {renderInput(fields.lang)}
            </div>
          </div>
          <div className="input-wrapper d-flex justify-content-center">
            <ReCAPTCHA
              sitekey="6LchFuQUAAAAAOJJ4_H_zt_i0-IeE1Re0utu-4FT"
              onExpired={() => setIsVerified(false)}
              onChange={(value) => {
                value && setIsVerified(true);
              }}
            />
          </div>
          <div className="btn-wrapper">
            <button
              className="btn btn-primary w-100"
              disabled={!form.isValid || !isVerified}
              onClick={register}
            >
              {t("Create an account")}
            </button>
            <div className="p-1 clr-red">{apiError}</div>
          </div>
          {
            isMobile && (
              <div className="have-an-account d-flex align-items-center pb-4">
                {t("Already have an account?")}
                <button
                  className="btn clr-blue font-weight-bold p-0 font-sm ml-1"
                  onClick={() => {
                    window.location.href = redUrl;
                  }}
                >
                  {t("Click here to login")}
                </button>
              </div>
            )
          }
        </div>
      </div>
      {!isMobile && (
        <div className="d-flex flex-column align-items-center justify-content-center max-col">
          {/* <Image src={infoImg} /> */}
          <ChatImages />
        </div>
      )}
      <TwoButtonModal
        show={isShowTooltip}
        leftButtonText={t("Close")}
        rightButtonText=""
        onClickRightButton={() => {}}
        title={t("Select Language")}
        message={t(
          "This would be the default language of your chatbot. You can change it later."
        )}
        size={"sm"}
        leftButtonClassName="btn btn-primary mr-3"
        onClickClose={() => {
          setIsShowTooltip(false);
        }}
        onClickLeftButton={() => {
          setIsShowTooltip(false);
        }}
      />
    </div>
  );
};

export default Info;
