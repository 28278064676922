import React, { useEffect, useState } from "react";
import { Modal, ModalFooter, Button, Image } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useDispatch } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import { HelperService } from "../../../../services/helper.service";
import { getAllOrganisationUsers } from "../../../../store/agents/actions";
import {
  addAdminUser,
  addLearnerToClasses,
  addRoleUserToAdmins,
  addUserAsAdmins,
  addUsersAndClassesToLearnerGroup,
  deleteLearnerToClasses,
  deleteUsersAndClassesToLearnerGroup,
  getLearningUserDetail,
  updateAdminUser,
} from "../../../../store/knowledge/actions";
import closeimg from "../../../../assets/images/close-blk.svg";
import downarrowblk from "../../../../assets/images/chevron-down-blk.svg";
import closeRed from "../../../../assets/New-images/close-icon-red.svg";
import { useParams } from "react-router";

import { useTranslation } from "react-i18next";

const AddLearnerToAgent = (props: any) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const helper = new HelperService();
  const { classId }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [showRestValues, setShowRestValues] = useState(false);
  const [creatableValue, setCreatableValue] = useState(undefined);
  const [selectedButtonTarget, setSelectedButtonTarget] = useState({
    email: "",
    first_name: "",
    last_name: "",
    editMode: false,
    permission: "",
  });
  const [userGroupAndClasses, setUserGroupAndClasses] = useState({
    classes: [],
    groups: [],
  });

  useEffect(() => {
    if (!props.show) {
      return;
    }
    setShowRestValues(false);
    setCreatableValue(null);
    setSelectedButtonTarget({
      email: "",
      first_name: "",
      last_name: "",
      editMode: false,
      permission: "",
    });
    setUserGroupAndClasses({
      classes: [],
      groups: [],
    })
    getAllUsers();
  }, [props]);

  useEffect(() => {
    if (
      !props.editData
    ) {
      return;
    }
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      first_name: props.editData.first_name,
      last_name: props.editData.last_name,
      editMode: true,
    });
    getUserClassAndGroupDetails();
  }, [props.editData]);

  const getUserClassAndGroupDetails = async () => {
    try {
      const response: any = await dispatch(
        getLearningUserDetail(props.editData._id)
      );
      setUserGroupAndClasses({
        ...userGroupAndClasses,
        classes: response.classes
          ? response.classes.map((d: any) => {
              return {
                value: d._id,
                label: d.name,
              };
            })
          : [],
        groups: response.groups
          ? response.groups.map((d: any) => {
              return {
                value: d._id,
                label: d.name,
              };
            })
          : [],
      });

      setUser(response);
    } catch (error) {
      setUser(null);
    }
  };

  const getAllUsers = async () => {
    try {
      const response: any = await dispatch(getAllOrganisationUsers());
      const data: any = [];
      response.users.forEach((element: any) => {
        data.push({
          _id: element._id,
          label: element.email,
          value: element.email,
        });
      });
      setUsers(data);
    } catch (error) {
      setUsers([]);
    }
  };

  const validateEmail = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleChangeHandler = async (newValue: any, actionMeta: any) => {
    if (!newValue) {
      setCreatableValue(undefined);
      return;
    }

    setCreatableValue(newValue);
  };

  const handleInputChange = (inputValue: any, actionMeta: any) => {
    // console.group("Input Changed");
    // console.log(inputValue);
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();
  };

  const createOrUpdateAgent = async () => {
    if (props.editMode) {
      if (!selectedButtonTarget.first_name || !selectedButtonTarget.last_name) {
        helper.showToaster(t('Please enter first name and last name.'));
        return;
      }

      try {
        setLoading(true);
        await dispatch(
          updateAdminUser(
            {
              first_name: selectedButtonTarget.first_name,
              last_name: selectedButtonTarget.last_name,
            },
            props.editData._id
          )
        );

        // For classes
        if (userGroupAndClasses.classes.length) {
          for await (const userClass of userGroupAndClasses.classes) {
            await dispatch(
              addLearnerToClasses(
                userClass.value,
                props.editData._id,
                "students"
              )
            );
          }
        }

        // For groups
        if (userGroupAndClasses.groups.length) {
          for await (const group of userGroupAndClasses.groups) {
            await dispatch(
              addUsersAndClassesToLearnerGroup(group.value, {
                user_ids: [props.editData._id],
              })
            );
          }
        }

        props.confirmHandler();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    } else {
      if (!creatableValue) {
        helper.showToaster(t("Please enter email."));
        return;
      }

      if (creatableValue && creatableValue._id) {
        try {
          setLoading(true);
          if (props.classMode) {
            await dispatch(
              addLearnerToClasses(classId, creatableValue._id, "students")
            );
          } else {
            await dispatch(
              addRoleUserToAdmins(props.agentId, creatableValue._id, "users", false)
            );
          }
          props.confirmHandler();
          setLoading(false);
        } catch (error: any) {
          setLoading(false);
        }
      }

      //--------[ ]----------/
      if (creatableValue && !creatableValue._id && creatableValue.value) {

        if (!selectedButtonTarget.first_name || !selectedButtonTarget.last_name) {
          helper.showToaster(t("Please enter first name and last name."));
          return;
        }

        try {
          setLoading(true);
          const reponse: any = await dispatch(
            addAdminUser({
              email: creatableValue.value,
              first_name: selectedButtonTarget.first_name,
              last_name: selectedButtonTarget.last_name,
              role: "user",
              registration_context_id: classId,
            })
          );
          if (props.classMode) {
            await dispatch(
              addLearnerToClasses(classId, reponse._id, "students")
            );
          } else {
            await dispatch(
              addRoleUserToAdmins(props.agentId, reponse._id, "users", false)
            );
          }

          // For classes
          if (userGroupAndClasses.classes.length) {
            for await (const userClass of userGroupAndClasses.classes) {
              await dispatch(
                addLearnerToClasses(
                  userClass.value,
                  reponse._id,
                  "students"
                )
              );
            }
          }

          // For groups
          if (userGroupAndClasses.groups.length) {
            for await (const group of userGroupAndClasses.groups) {
              await dispatch(
                addUsersAndClassesToLearnerGroup(group.value, {
                  user_ids: [reponse._id],
                })
              );
            }
          }
          props.confirmHandler();
          setLoading(false);
        } catch (error: any) {
          setLoading(false);
        }
        // email: "guddu@gmail.com"
        // first_name: "guddu"
        // last_name: "guddu"
        // role: "admin"
      }
    }
  };

  const handleCreate = (inputValue: string) => {
    if (validateEmail(inputValue)) {
      const obj: any = {
        _id: null,
        label: inputValue,
        value: inputValue,
      };
      setCreatableValue(obj);
      setUsers([...users, obj]);
      setShowRestValues(true);
    }
    return;
  };

  const inputChangeHandler = (e: any) => {
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      [e.target.name]: e.target.value,
    });
  };

  const removeClassesOfAUser = (classIndex: number) => {
    const oldData = [...userGroupAndClasses.classes];
    const deletedData: any = oldData.splice(classIndex, 1);
    if (props.editMode) {
      dispatch(
        deleteLearnerToClasses(
          deletedData[0].value,
          props.editData._id,
          "students"
        )
      );
    }
    setUserGroupAndClasses({
      ...userGroupAndClasses,
      classes: [...oldData],
    });
  };

  const removeGroupsOfAUser = (classIndex: number) => {
    const oldData = [...userGroupAndClasses.groups];
    const deletedData: any = oldData.splice(classIndex, 1);
    if (props.editMode) {
      dispatch(
        deleteUsersAndClassesToLearnerGroup(deletedData[0].value, {
          user_ids: [props.editData._id],
        })
      );
    }
    setUserGroupAndClasses({
      ...userGroupAndClasses,
      groups: [...oldData],
    });
  };

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-400"
      onHide={props.onHide}
    >
      <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
        <ModalTitle
          className="h5 py-1 font-600"
          id="contained-modal-title-vcenter"
        >
          {props.editMode ? t("Update User") : t("Add User")}
        </ModalTitle>
        <Button onClick={props.onHide} className="ml-auto">
          <Image src={closeimg} alt="" />
        </Button>
      </ModalHeader>
      <ModalBody>
        {!props.editMode && (
          <div className="mb-12">
            <h3 className="mb-1">{t("User Email")}</h3>
            <CreatableSelect
              className="select-target radius-2"
              classNamePrefix="select-creatable-prefix"
              isClearable
              onChange={handleChangeHandler}
              placeholder={t("Type user Email ID")}
              onCreateOption={handleCreate}
              value={creatableValue}
              // getOptionLabel={(option: any) => option.email}
              // getOptionValue={(option: any) => option.email}
              onInputChange={handleInputChange}
              options={users}
            />
          </div>
        )}

        {(showRestValues || props.editMode) && (
          <>
            <div className="mb-12">
              <h3 className="mb-1">{t("First Name")}</h3>

              <input
                type="text"
                className="form-control he-35 radius-2"
                placeholder={t("Please enter first name")}
                name="first_name"
                value={selectedButtonTarget.first_name || ""}
                onChange={inputChangeHandler}
              />
            </div>

            <div className="mb-12">
              <h3 className="mb-1">{t("Last Name")}</h3>

              <input
                type="text"
                className="form-control he-35 radius-2"
                placeholder={t("Please enter last name")}
                name="last_name"
                value={selectedButtonTarget.last_name || ""}
                onChange={inputChangeHandler}
              />
            </div>
          </>
        )}

        {(props.editMode || (creatableValue && !creatableValue._id && creatableValue.value)) && !props.classMode && (
          <>
            <div className="mb-12">
              <h3 className="mb-1">{t("Assign to Class")}</h3>
              <Select
                isSearchable
                isMulti
                isClearable={false}
                controlShouldRenderValue={false}
                className="select-target radius-2"
                classNamePrefix="select-target-prefix"
                components={{
                  DropdownIndicator: (props: any) => (
                    <img src={downarrowblk} alt={t("dropdown arrow")} />
                  ),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e: any) => {
                  setUserGroupAndClasses({
                    ...userGroupAndClasses,
                    classes: e,
                  });
                }}
                value={userGroupAndClasses.classes || []}
                // defaultValue={user.classes}
                placeholder="Classes"
                options={[...props.classes]}
              />
            </div>

            {userGroupAndClasses.classes.map(
              (classes: any, classIndex: number) => {
                return (
                  <div
                    className=" group-cross-tags d-flex align-items-center font-sm pl-2"
                    key={"_class_" + classIndex}
                  >
                    <div className="font-600 font-16">{classes?.label}</div>
                    <button
                      type="button"
                      className="btn ml-auto py-0"
                      onClick={() => {
                        removeClassesOfAUser(classIndex);
                      }}
                    >
                      <img src={closeRed} alt="" />
                    </button>
                  </div>
                );
              }
            )}

            <div className="mb-12 mt-3">
              <h3 className="mb-1">{t("Assign to Learner Group")}</h3>
              <Select
                isSearchable
                isMulti
                isClearable={false}
                controlShouldRenderValue={false}
                className="select-target radius-2"
                classNamePrefix="select-target-prefix"
                components={{
                  DropdownIndicator: (props: any) => (
                    <img src={downarrowblk} alt={t("dropdown arrow")} />
                  ),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e: any) => {
                  setUserGroupAndClasses({
                    ...userGroupAndClasses,
                    groups: e,
                  });
                }}
                value={userGroupAndClasses.groups || []}
                // defaultValue={user.groups}
                placeholder="Group"
                options={[...props.groups]}
              />
            </div>
            {userGroupAndClasses.groups.map(
              (classes: any, classIndex: number) => {
                return (
                  <div
                    className="group-cross-tags d-flex align-items-center font-sm pl-2"
                    key={"_class_" + classIndex}
                  >
                    <div className="font-600 font-16">{classes?.label}</div>
                    <button
                      type="button"
                      className="btn ml-auto py-0"
                      onClick={() => {
                        removeGroupsOfAUser(classIndex);
                      }}
                    >
                      <img src={closeRed} alt="" />
                    </button>
                  </div>
                );
              }
            )}
          </>
        )}

        {/* {!props.editMode && (
          <div className="mb-12">
            <h3 className="mb-1">Permissions</h3>
            <Select
              isSearchable={false}
              className="select-target radius-2"
              classNamePrefix="select-target-prefix"
              components={{
                //DropdownIndicator: () => null,
                DropdownIndicator: (props: any) => (
                  <img src={downarrowblk} alt='dropdown arrow' />
                ),
                IndicatorSeparator: () => null,
              }}
              onChange={(e: any) => {
                setSelectedButtonTarget({
                  ...selectedButtonTarget,
                  permission: e.value,
                });
              }}
              placeholder="Permission"
              getOptionLabel={(option: any) => option["label"]}
              getOptionValue={(option: any) => option["value"]}
              options={[
                { value: "admins", label: "Can Edit" },
                { value: "readonlyadmins", label: "View Only" },
              ]}
            />
          </div>
        )} */}
      </ModalBody>
      <ModalFooter>
        <PermissionWrapper>
          <button
            disabled={loading}
            className="btn-outline-primary btn font-600 py-1"
            onClick={props.onHide}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary ml-3 font-600 py-1"
            onClick={createOrUpdateAgent}
            disabled={loading}
          >
            {props.editMode ? t("Update") : t("Add")}
          </button>
        </PermissionWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default AddLearnerToAgent;
