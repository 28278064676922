import React, { useState } from "react";
import { Modal, ModalFooter, Button, Image } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import closeimg from "../../../../assets/images/close-blk.svg";
import bulbimg from "../../../../assets/New-images/buld-yellow.svg";
import DragAndDrop from "../../../../components/UI/DragAndDrop";
import { HelperService } from "../../../../services/helper.service";
import { bulkUploadLearners } from "../../../../store/knowledge/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { UPLOAD_LIST_OF_USERS } from "../../../../config";

import { useTranslation } from "react-i18next";

const BulkUploadUsers = (props: any) => {
  const { t } = useTranslation("translation");
  const helper = new HelperService();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isFileInValid, setIsFileInValid] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [hasFileUploaded, setHasFileUploaded] = useState(false);
  const [documents, setDocuments] = React.useState(null);

  const getFileDocumentsHandler = async (d: File[]) => {
    setIsFileInValid(false);
    setIsFileUploading(false);
    if (d.length > 1) {
      helper.showToaster(t("You can upload only single file."));
      return;
    }
    await setDocuments(d[0]);
    setHasFileUploaded(false);
  };

  const uploadList = async () => {
    if (!documents) {
      return;
    }

    try {
      setIsFileUploading(true);
      const uploadedDocument: any = await dispatch(
        bulkUploadLearners(documents, (event: any) => {
          console.log("event", event, (100 * event.loaded) / event.total);
          // if (documents.length > 0) {
          //   document.progress = Math.round(
          //     (100 * event.loaded) / event.total
          //   );
          //   setDocuments([...oldDocuments]);
          // }
        })
      );
      setIsFileUploading(false);
      history.push(UPLOAD_LIST_OF_USERS + `?through=${props.through}`, {
        users: uploadedDocument.map((user: any) => {
          return {
            ...user,
            classes: user.classes.map((u: any) => {
              return {
                value: u._id,
                label: u.name,
              };
            }),
            groups: user.groups.map((u: any) => {
              return {
                value: u._id,
                label: u.name,
              };
            }),
          };
        }),
      });
    } catch (error: any) {}
  };

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-720"
      onHide={props.onHide}
    >
      <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
        <ModalTitle
          className="h5 py-1 font-600"
          id="contained-modal-title-vcenter"
        >
          {t("Upload list of ") +
            `${props.through === "admin" ? "Admins" : "users"}`}
        </ModalTitle>
        <Button onClick={props.onHide} className="ml-auto">
          <Image src={closeimg} alt="" />
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="upload-list-msg mb-2 d-flex align-items-start">
          <img src={bulbimg} alt="" />
          <div>
            {t(
              "Please ensure your document is in the required format or use our document template to ensure this process goes through seamlessly."
            )}
            <div>
              <button
                className="btn p-0 btn-link text-left"
                onClick={() => {
                  window.open(
                    "https://d17x1gpob0fzk.cloudfront.net/static/files/import-users-template.xlsx",
                    "_blank"
                  );
                }}
              >
                {t("Click here to download the document template")}
              </button>
            </div>
          </div>
        </div>
        <div className="bulk-upload-learner w-100">
          <DragAndDrop
            getFileDocumentsHandler={getFileDocumentsHandler}
            // supportedTypes={".xlsx"}
            supportedTypes={".xlsx"}
            maxFiles={1}
            message={`<h2 className='mb-1'>Drop your files here or click to browse.</h2><div>Supported File types: .xlsx</div>`}
            fileSizeMessage={documents ? documents.name : ""}
            {...props}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          disabled={isFileUploading}
          className="btn-outline-primary btn font-600 py-1"
          onClick={props.onHide}
        >
          {t("Cancel")}
        </button>
        <PermissionWrapper>
          <button
            className="btn btn-primary ml-3 font-600 py-1"
            onClick={uploadList}
            disabled={isFileUploading}
          >
            {props.editMode ? t("Update") : t("Continue")}
          </button>
        </PermissionWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default BulkUploadUsers;
